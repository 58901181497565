import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateRowBlockDto {
    @Expose()
    readonly table_name: string;

    @Expose()
    readonly username: string;

    @Expose()
    readonly e_id: number;
}
