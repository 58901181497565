import { TextField, TextFieldProps, MenuItem } from "@material-ui/core";
import { PageTypesManager } from "@modules";
import React from "react";

const PageTypeSelect = ({ ...props }: TextFieldProps) => {
    return (
        <TextField {...props} value={props.value || ""} select label="Тип страницы">
            {PageTypesManager.PAGE_TYPES.map((page) => {
                return (
                    <MenuItem key={page.type} value={page.type}>
                        {page.name}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default PageTypeSelect;
