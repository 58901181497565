import { Button } from "@material-ui/core";
import { Backup } from "@material-ui/icons";
import { IMPORT_DATA_TYPE } from "../../../hooks/useImportDataControls";
import React from "react";
import { useStoreon } from "storeon/react";
import { MODAL_TYPE } from "@modules/ModalManager";
import { ModalEvents, ModalState } from "../../../store/modal-module";

interface Props {
    importType: IMPORT_DATA_TYPE;
    otherModalProps?: { [key: string]: any };
}

const ImportDataButton: React.FC<Props> = ({ importType, otherModalProps }: Props) => {
    const { dispatch } = useStoreon<ModalState, ModalEvents>();

    const handleClick = () => {
        dispatch("modal/set/type", MODAL_TYPE.IMPORT_DATA);
        dispatch("modal/set/props", { importType, ...otherModalProps });
        dispatch("modal/toggle", true);
    };

    return (
        <Button onClick={handleClick} startIcon={<Backup />} color="primary">
            Загрузить данные
        </Button>
    );
};

export default ImportDataButton;
