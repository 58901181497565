import { TextField, TextFieldProps, MenuItem, ListItemText } from "@material-ui/core";
import React from "react";

const DiscountTypeSelect = ({ ...props }: TextFieldProps) => {
    const types = [
        { type: "add", name: "Добавляющий" },
        { type: "replace", name: "Заменяющий" },
    ];
    return (
        <TextField {...props} value={props.value || ""} select>
            {types.map((item) => {
                return (
                    <MenuItem key={item.type} value={item.type}>
                        <ListItemText primary={item.name} />
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default DiscountTypeSelect;
