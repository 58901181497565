import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { RowBlockForm } from "../../Forms/Settings";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { TableEvents, TableState } from "@store/table-module";
import { rowBlockService } from "@services/Settings";
import { ModalContentLoading } from "../../Common/Modal";
import { RowBlock } from "@interfaces/RowBlock";
import { CreateRowBlockDto, UpdateRowBlockDto } from "@dto/SettingsDto/RowBlockDto";
import { useAsync } from "@hooks";

const RowBlockDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();

    const { data, loadingStatus } = useAsync<RowBlock>(rowBlockService.getOneRowBlock, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreateRowBlock = async (values) => {
        try {
            const dto = new CreateRowBlockDto(values);

            await rowBlockService.createRowBlock(dto);
            enqueueSnackbar("Блокировка успешно создана", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании блокировки", {
                variant: "error",
            });
        }
    };

    const handleUpdateRowBlock = async (values) => {
        if (!itemId) return;

        try {
            const dto = rowBlockService.plainToClass(UpdateRowBlockDto, values);

            await rowBlockService.updateRowBlock(itemId, dto);
            enqueueSnackbar("Блокировка успешно обновлена", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении блокировки", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <RowBlockForm
                        ref={formRef}
                        onSubmit={itemId ? handleUpdateRowBlock : handleCreateRowBlock}
                        data={itemId ? data : undefined}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button color="primary" variant="contained" onClick={invokeSubmit}>
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default RowBlockDialogContext;
