import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { StaffMenuItemForm } from "../../Forms/Settings";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { TableEvents, TableState } from "@store/table-module";
import { staffMenuService } from "@services/Settings";
import { ModalContentLoading } from "../../Common/Modal";
import { CreateStaffMenuItemDto, UpdateStaffMenuItemDto } from "@dto/SettingsDto/StaffMenuItemsDto";
import { useAsync, useLoading } from "@hooks";
import { CommonEvents, CommonState } from "@store/common-module";

const StaffMenuItemDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { dispatch: commonDispatch } = useStoreon<CommonState, CommonEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data: menuItem, loadingStatus } = useAsync(staffMenuService.getOneStaffMenuItem, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreateStaffMenuItem = async (values) => {
        setSubmitLoadingStatus("loading");
        try {
            const dto = staffMenuService.plainToClass(CreateStaffMenuItemDto, values);

            await staffMenuService.createStaffMenuItem(dto);
            enqueueSnackbar("Пункт меню успешн создан", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
            commonDispatch("common/call/update_menu_and_routes");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании пункта меню", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleUpdateStaffMenuItem = async (values) => {
        if (!itemId) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = staffMenuService.plainToClass(UpdateStaffMenuItemDto, values);

            await staffMenuService.updateStaffMenuItem(itemId, dto);
            enqueueSnackbar("Пункт меню успешно обновлен", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
            commonDispatch("common/call/update_menu_and_routes");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении пункта меню", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <StaffMenuItemForm
                        ref={formRef}
                        onSubmit={itemId ? handleUpdateStaffMenuItem : handleCreateStaffMenuItem}
                        data={itemId ? menuItem : undefined}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default StaffMenuItemDialogContext;
