import { CommonFormProps } from "@interfaces/System/FormProps";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { TextField } from "@material-ui/core";
import { Directory } from "@interfaces/Directory";
import { DirectorySchema } from "@schema/FileStorage";

const DirectoryForm = forwardRef(({ onSubmit, data }: CommonFormProps<Directory>, ref: any) => {
    const initialData = {
        title: data?.title ?? "",
    } as Directory;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={DirectorySchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values }) => {
                return (
                    <Form>
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.title}
                            required
                            autoFocus
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="title"
                            helperText={touched.title ? errors.title : ""}
                            error={touched.title && !!errors.title}
                            label="Название"
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default DirectoryForm;
