import { GeneralSettings } from "@interfaces/GeneralSettings";
import React from "react";
import { ServiceHelper } from "@modules";
import { generalSettingsService } from "@services/Settings";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useSnackbar } from "notistack";
import { useCurrentPageInfo, useModal } from "@hooks";

const GeneralSettingsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_GENERAL_SETTINGS", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: GeneralSettings | GeneralSettings[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await generalSettingsService.deleteGeneralSettings(ids);
            enqueueSnackbar("Настройка успешно удалена", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении настройки", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<GeneralSettings>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Ключ",
            field: "key",
            width: "auto",
        },
        {
            title: "Название",
            field: "name",
            width: "auto",
        },
        {
            title: "Описание",
            field: "description",
            width: "auto",
        },
        {
            title: "Значение",
            field: "value",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={generalSettingsService.getGeneralSettings}
            columns={columns}
            displayField="name"
            permission={permission}
            sorting
            selection
            search
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
        />
    );
};

export default GeneralSettingsTable;
