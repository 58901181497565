import ContentTypesManager, { CONTENT_TYPE } from "./ContentTypesManager";
import { ContentType } from "./ContentTypesManager";
import MultipleContentTypesManager, {
    MultipleContentType,
    MULTIPLE_CONTENT_TYPE,
} from "./MultipleContentTypesManager";

export type CombinedContentType = ContentType | MultipleContentType;
export type CombinedType = CONTENT_TYPE | MULTIPLE_CONTENT_TYPE;

class CombinedContentTypesManager {
    static getContentType = (type: CombinedType) => {
        const content =  CombinedContentTypesManager.COMBINED_CONTENT_TYPES.find(
            (content) => content.type === type
        );
        return content
    };

    static readonly COMBINED_CONTENT_TYPES: CombinedContentType[] = [
        ...ContentTypesManager.CONTENT_TYPES,
        ...MultipleContentTypesManager.MULTIPLE_CONTENT_TYPES,
    ];
}

export default CombinedContentTypesManager;
