import { Grid, IconButton, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { RestrictionsManager, ValidationManager } from "@modules";
import { EntityEvents, EntityState } from "@store/entity-module";
import { UniversalRestrictionChecker } from "@components/Common";
import UniversalInputField from "@components/Common/UniversalInputField";
import { AppAttribute } from "@interfaces/Internal/AppAttribute";
import { useAsync } from "@hooks";
import { vTypesService } from "@services";
import EntityAttributeItemSkeleton from "../EntityAttributeItemSkeleton";

interface EntityAttributeItemMultipleProps {
    attribute: AppAttribute;
}

const EntityAttributeItemMultiple = ({ attribute }: EntityAttributeItemMultipleProps) => {
    const { readOnly, dispatch } = useStoreon<EntityState, EntityEvents>("readOnly");
    const { enableValidation, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("enableValidation");

    const { data: vType, loadingStatus } = useAsync(vTypesService.getValueType, {
        canLoad: !!attribute.items?.length && !!attribute.items[0]?.vt_id,
        functionArgs: [attribute.items[0].vt_id],
    });

    const handleChangeAttributeValue = (key: string, value: any) => {
        dispatch("entity/attr/update/value", {
            at_id: attribute.atype_id,
            valueKey: key,
            value,
        });
    };

    const handleDeleteRow = (e: React.MouseEvent<HTMLButtonElement>) => {
        const key = e.currentTarget.getAttribute("data-itemkey")!;

        dispatch("entity/attr/remove/value", { aTypeId: attribute.atype_id, valueKeys: [key] });
    };

    const { valid } = ValidationManager.validateAppAttributeValueGroup(attribute.items);

    const useValidation = ValidationManager.isValidationRequired(
        attribute.required,
        attribute.items
    );

    useEffect(() => {
        validationDispatch("validation/add/validations", {
            [attribute.atype_id]: useValidation ? valid : true,
        });
    }, [validationDispatch, attribute, useValidation, valid]);

    if (loadingStatus === "loading") return <EntityAttributeItemSkeleton />;

    return (
        <>
            {attribute.items.map((attr) => {
                const { valid, messages } = ValidationManager.validateEntityAttributeValue(
                    attr.value,
                    attr.restrictions
                );

                return (
                    <Grid key={attr.key} container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <UniversalRestrictionChecker
                                key={attr.key}
                                restrictions={attr.restrictions}
                                render={(inputTypeName, { entityRelationTypeId }) => {
                                    return (
                                        <UniversalInputField
                                            key={attr.key}
                                            vTypeId={attr.vt_id}
                                            findBy={attr.edited ? "id" : "attrValue"}
                                            responseValue="id"
                                            inputTypeName={inputTypeName}
                                            value={attr.value || ""}
                                            onChange={(name, value) => {
                                                handleChangeAttributeValue(attr.key, value);
                                            }}
                                            label={vType?.name}
                                            name={vType?.name || ""}
                                            filterByEntityTypeId={entityRelationTypeId}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            margin="normal"
                                            disabled={readOnly}
                                            error={enableValidation && useValidation && !valid}
                                            helperText={
                                                <>
                                                    {attr.restrictions.map((rest) => {
                                                        const errorMessage =
                                                            enableValidation &&
                                                            useValidation &&
                                                            messages[rest.code];

                                                        return (
                                                            <Typography
                                                                key={rest.key}
                                                                variant="caption"
                                                                display="block"
                                                                color={
                                                                    errorMessage
                                                                        ? "error"
                                                                        : "textSecondary"
                                                                }
                                                            >
                                                                {errorMessage ||
                                                                    RestrictionsManager.getRestrictionItemByCode(
                                                                        rest.code
                                                                    )?.getHelpText(rest.value)}
                                                            </Typography>
                                                        );
                                                    })}
                                                </>
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        {!readOnly && (
                            <Grid item>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    disabled={attribute.items.length <= 1}
                                    data-itemkey={attr.key}
                                    onClick={handleDeleteRow}
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                );
            })}
        </>
    );
};

export default EntityAttributeItemMultiple;
