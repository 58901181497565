import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateStaffMemberDto {
    @Expose()
    readonly email: string;

    @Expose()
    readonly is_active: boolean;

    @Expose()
    readonly first_name: string;

    @Expose()
    readonly last_name: string;

    @Expose()
    readonly middle_name: string;

    @Expose()
    readonly phone: string;

    @Expose()
    readonly department?: string;

    @Expose()
    readonly work_phone?: string;

    @Expose()
    readonly additional_phone?: string;

    @Expose()
    readonly occupation?: string;

    @Expose()
    readonly birthday?: string;

    @Expose()
    readonly join_date?: string | null;

    @Expose()
    readonly dismiss_date?: string | null;
}
