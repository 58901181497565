import * as React from "react";
import { SectionCard } from "../Cards/SectionCard";
import { Box, Grid } from "@material-ui/core";
import { ProtectedPath } from "@interfaces/ProtectedPath";

interface Props {
    sections: ProtectedPath[];
    marginY?: number;
}

const SectionCards = ({ sections, marginY }: Props) => {
    return (
        <Box my={marginY}>
            <Grid container spacing={2}>
                {sections.map((e, i) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={i}>
                        <SectionCard {...e} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SectionCards;
