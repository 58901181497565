import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { StaffOrder } from "@interfaces/StaffOrder";
import { StaffOrderSchema } from "@schema/StaffOrder";
import { AutoSizeTextField } from "@components/Controls/Inputs";
import { AsyncAutocomplete, EntityAutocomplete } from "@components/Controls/Autocomplete";
import { staffService, staffBrandDealerBindService } from "@services/Staff";
import { StaffMember } from "@interfaces/StaffMember";
import { BrandDealerBind } from "@interfaces/BrandDealerBind";
import { OrderStatusSelect } from "@components/Controls/Selects";

const StaffOrderForm = forwardRef(({ onSubmit, data }: CommonFormProps<StaffOrder>, ref: any) => {
    const initialData = {
        collection_id: data?.collection_id ?? null,
        brand_dealer_binding_id: data?.brand_dealer_binding_id,
        brand_id: data?.brand_id ?? null,
        comment: data?.comment ?? "",
        status: data?.status ?? "new",
        rejected: data?.rejected,
        brand_manager_checked: data?.brand_manager_checked,
        content_manager_checked: data?.content_manager_checked,
        commodity_expert_checked: data?.commodity_expert_checked,
        dealer_checked: data?.dealer_checked,
        brand_manager_id: data?.brand_manager_id,
    } as StaffOrder;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={StaffOrderSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, setFieldValue }) => {
                return (
                    <Form>
                        <AsyncAutocomplete<BrandDealerBind>
                            fetchFn={staffBrandDealerBindService.getBrandDealerBinds}
                            value={values.brand_dealer_binding_id}
                            onChange={(name, value) => setFieldValue(name, value?.id || null)}
                            optionField="id"
                            getOptionFieldLabel={(option) => `Идентификатор предложения: ${option}`}
                            secondaryField="price"
                            getSecondaryFieldLabel={(option) => `Цена: ${option}`}
                            textFieldProps={{
                                fullWidth: true,
                                size: "small",
                                margin: "normal",
                                variant: "outlined",
                                name: "brand_dealer_binding_id",
                                label: "Предложение",
                                helperText: touched.brand_dealer_binding_id
                                    ? errors.brand_dealer_binding_id
                                    : "",
                                error:
                                    touched.brand_dealer_binding_id &&
                                    !!errors.brand_dealer_binding_id,
                            }}
                        />

                        <EntityAutocomplete
                            value={values.collection_id}
                            onChange={(name, value) => setFieldValue(name, value?.id || null)}
                            typeBindName="collection"
                            textFieldProps={{
                                fullWidth: true,
                                required: true,
                                size: "small",
                                margin: "normal",
                                variant: "outlined",
                                name: "collection_id",
                                label: "Коллекция",
                                helperText: touched.collection_id ? errors.collection_id : "",
                                error: touched.collection_id && !!errors.collection_id,
                            }}
                        />

                        <EntityAutocomplete
                            typeBindName="brand"
                            value={values.brand_id}
                            onChange={(name, value) => setFieldValue(name, value?.id || null)}
                            textFieldProps={{
                                fullWidth: true,
                                required: true,
                                size: "small",
                                margin: "normal",
                                variant: "outlined",
                                name: "brand_id",
                                label: "Бренд",
                                helperText: touched.brand_id ? errors.brand_id : "",
                                error: touched.brand_id && !!errors.brand_id,
                            }}
                        />

                        <OrderStatusSelect
                            name="status"
                            onChange={handleChange}
                            value={values.status}
                            variant="outlined"
                            size="small"
                            fullWidth
                            margin="normal"
                            label="Статус заказа"
                        />

                        <AsyncAutocomplete<StaffMember>
                            fetchFn={staffService.getAllStaffMembers}
                            value={values.brand_manager_id}
                            onChange={(name, value) => setFieldValue(name, value?.id || null)}
                            optionField="email"
                            filter={["memberof", "@>{brand_manager}"]}
                            textFieldProps={{
                                fullWidth: true,
                                size: "small",
                                margin: "normal",
                                variant: "outlined",
                                name: "brand_manager_id",
                                label: "Бренд-менеджер",
                                helperText: touched.brand_manager_id ? errors.brand_manager_id : "",
                                error: touched.brand_manager_id && !!errors.brand_manager_id,
                            }}
                        />

                        <AutoSizeTextField
                            name="comment"
                            onChange={handleChange}
                            value={values.comment}
                            variant="outlined"
                            size="small"
                            fullWidth
                            margin="normal"
                            label="Комментарий"
                            helperText="Необязательно"
                            rows={2}
                        />

                        {data && (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.rejected}
                                            onChange={handleChange}
                                            name="rejected"
                                            color="primary"
                                        />
                                    }
                                    label="Отклонено"
                                />
                            </FormGroup>
                        )}

                        <FormControl component="fieldset" margin="normal">
                            <FormLabel component="legend">Контроль</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.brand_manager_checked}
                                            onChange={handleChange}
                                            name="brand_manager_checked"
                                            color="primary"
                                        />
                                    }
                                    label="Проверено бренд-менеджером"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.content_manager_checked}
                                            onChange={handleChange}
                                            name="content_manager_checked"
                                            color="primary"
                                        />
                                    }
                                    label="Проверено контент-менеджером"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.commodity_expert_checked}
                                            onChange={handleChange}
                                            name="commodity_expert_checked"
                                            color="primary"
                                        />
                                    }
                                    label="Проверено товароведом"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.dealer_checked}
                                            onChange={handleChange}
                                            name="dealer_checked"
                                            color="primary"
                                        />
                                    }
                                    label="Проверено дилером"
                                />
                            </FormGroup>
                        </FormControl>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default StaffOrderForm;
