import { CreateCart } from "@interfaces/Cart";
import { FormProps } from "@interfaces/System/FormProps";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { CreateCartSchema } from "@schema/Cart";
import { EShop } from "@interfaces/EShop";
import { eShopService, userService } from "@services";
import { User } from "@interfaces/User";

const CreateCartForm = forwardRef(({ onSubmit }: FormProps<CreateCart>, ref: any) => {
    const initialData = {
        shop_id: null,
        user_id: null,
    } as CreateCart;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={CreateCartSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, setFieldValue, handleChange, values }) => {
                return (
                    <Form>
                        <AsyncAutocomplete<EShop>
                            fetchFn={eShopService.getEShops}
                            value={values.shop_id || null}
                            onChange={(name, value) => setFieldValue(name, value?.id || null)}
                            optionField="title"
                            secondaryField="url"
                            textFieldProps={{
                                fullWidth: true,
                                required: true,
                                variant: "outlined",
                                name: "shop_id",
                                size: "small",
                                helperText: touched.shop_id ? errors.shop_id : "",
                                error: touched.shop_id && !!errors.shop_id,
                                label: "Магазин",
                            }}
                        />

                        <AsyncAutocomplete<User>
                            fetchFn={userService.getUsers}
                            onChange={(name, value) => setFieldValue(name, value?.id || null)}
                            value={values.user_id}
                            optionField="email"
                            textFieldProps={{
                                required: true,
                                fullWidth: true,
                                name: "user_id",
                                variant: "outlined",
                                size: "small",
                                label: "Пользователь",
                                margin: "normal",
                                error: touched.user_id && !!errors.user_id,
                                helperText: touched.user_id ? errors.user_id : "",
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default CreateCartForm;
