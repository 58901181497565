import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { UpdateEntityDto } from "@dto/EntityDto";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { entitiesService } from "@services";
import { EntityEvents, EntityState } from "@store/entity-module";
import { TableEvents, TableState } from "@store/table-module";
import { ModalContentLoading } from "@components/Common/Modal";
import EntityAttributesList from "@components/Views/EntityAttributesList/EntityAttributesList";
import withCloseListener from "@hoc/withCloseListener";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import { ValidationManager } from "@modules";
import { useAsync, useLoading } from "@hooks";

const useStyles = makeStyles(() => ({
    content: {
        height: "80vh",
    },
}));

const EditEntityByEntityTypeDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const classes = useStyles();
    const { itemId, entityTypeId } = modalProps;

    const { enqueueSnackbar } = useSnackbar();
    const { attrs, valuesToRemove, dispatch } = useStoreon<EntityState, EntityEvents>(
        "attrs",
        "valuesToRemove"
    );
    const { validations, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("validations");
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data: entity, loadingStatus } = useAsync(entitiesService.getEntity, {
        functionArgs: [itemId, entityTypeId],
        canLoad: !!itemId && !!entityTypeId,
    });

    useEffect(() => {
        return () => {
            entitiesService.unsetEntityBlock(Number(itemId));
            dispatch("entity/set/valuesToRemove", []);
            dispatch("entity/set/readonly", false);
            validationDispatch("validation/toggle/validation", false);
            validationDispatch("validation/set/validations", {});
        };
    }, [dispatch, validationDispatch, itemId, entityTypeId]);

    const handleUpdateEntity = async () => {
        if (!itemId) return;
        validationDispatch("validation/toggle/validation", true);

        if (!ValidationManager.isFinalObjectValid(validations)) {
            enqueueSnackbar(
                "Не все поля заполнены корректно. Проверьте правильность ввода данных",
                {
                    variant: "error",
                }
            );
            return;
        }

        setSubmitLoadingStatus("loading");
        try {
            if (valuesToRemove.length > 0) {
                await entitiesService.deleteEntityAttributeValues(valuesToRemove);
            }

            const dto = new UpdateEntityDto(itemId, entityTypeId, attrs || []);
            if (dto.itemsToRemove.length > 0) {
                await entitiesService.deleteEntityAttributeValues(dto.itemsToRemove);
            }

            if (dto.items.length > 0) {
                await entitiesService.updateEntity(dto);
            }

            enqueueSnackbar("Сущность успешно обновлена", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;

            enqueueSnackbar(message || "Произошла ошибка при обновлении сущности", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent className={classes.content}>
                {loadingStatus === "loading" && <ModalContentLoading />}
                {loadingStatus === "loaded" && entity && (
                    <EntityAttributesList entityTypeId={entityTypeId} entity={entity} />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose} variant="text">
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleUpdateEntity}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default withCloseListener(EditEntityByEntityTypeDialogContext);
