import { Transform, Exclude, Expose } from "class-transformer";
import { RestrictionsManager } from "@modules";

@Exclude()
export class UpdateAttributeTypeDto {
    @Expose()
    readonly name: string;

    @Expose()
    readonly notes?: string;

    @Expose()
    readonly display_name?: string;

    @Expose()
    readonly multiple_choice: boolean;

    @Expose()
    readonly composite_choice: boolean;

    @Expose()
    @Transform(({ value }) =>
        value.map((el) => ({
            id: el.id,
            sorting: el.sorting,
            type_id: el.type_id,
            vtype_id: el.vtype_id,
            restrictions: el.useRestrictions
                ? RestrictionsManager.getRestrictionsObjectFromArray(el.restrictions)
                : RestrictionsManager.getEmptyResctrictionsObject(),
        }))
    )
    readonly items?: any[];

    @Expose()
    readonly is_active: boolean;
}
