import { Exclude, Expose } from "class-transformer";

@Exclude()
class CreateStoreDto {
    @Expose()
    readonly store_id: number;

    @Expose()
    readonly is_shop: boolean;

    @Expose()
    readonly is_countable: boolean;

    @Expose()
    readonly store_name: string;

    @Expose()
    readonly shown_name: string;

    @Expose()
    readonly comment: string;

    @Expose()
    readonly sorting: number;
}

export default CreateStoreDto;
