import React from "react";
import { Badge, Button } from "@material-ui/core";
import { ShoppingCartOutlined } from "@material-ui/icons";
import { useModal } from "@hooks";

type StaffCartButtonProps = { entityFilter: Record<string, string>; eTypeId: number };

const StaffCartButton = ({ entityFilter, eTypeId }: StaffCartButtonProps) => {
    const { handleOpenModal } = useModal();

    const handleClick = () => {
        handleOpenModal("EDIT_STAFF_CART", {
            entityFilter,
            eTypeId,
        });
    };

    return (
        <Badge color="primary">
            <Button onClick={handleClick} color="primary" startIcon={<ShoppingCartOutlined />}>
                Корзина
            </Button>
        </Badge>
    );
};

export default StaffCartButton;
