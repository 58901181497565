import { ServiceRole } from "@interfaces/ServiceRole";
import { useSnackbar } from "notistack";
import React from "react";
import { ServiceHelper } from "@modules";
import { serviceRoleService } from "@services/Settings";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo, useModal } from "../../hooks";

const ServiceRolesTable = () => {
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_SERVICE_ROLE", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: ServiceRole | ServiceRole[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await serviceRoleService.deleteServiceRoles(ids);
            enqueueSnackbar("Настройка успешно удалена", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении настройки", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<ServiceRole>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Системное название",
            field: "rolname",
            width: "auto",
        },
        {
            title: "Отображаемое название",
            field: "shown_name",
            width: "auto",
        },
        {
            title: "Описание",
            field: "description",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={serviceRoleService.getServiceRoles}
            columns={columns}
            displayField="rolname"
            permission={permission}
            sorting
            selection
            search
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
        />
    );
};

export default ServiceRolesTable;
