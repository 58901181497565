import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Subtitle } from "../Title";

type InnBindProps = {
    disabled: boolean;
    onSaveClick(inn: string): Promise<void>;
    inn: string;
};

const InnBind: React.FunctionComponent<InnBindProps> = (props) => {
    const { onSaveClick, inn, disabled } = props;
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [value, setValue] = useState<string>(inn);

    useEffect(() => {
        setValue(props.inn);
    }, [props]);

    const handleSaveInnClick = async () => {
        if (!onSaveClick) return;

        await onSaveClick(value);

        setIsEditable(!isEditable);
    };

    const handleEditInnClick = () => {
        setIsEditable(!isEditable);
    };

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setValue(value);
    };

    return (
        <>
            <Subtitle
                title="ИНН"
                bold
                icon="subject"
                endAdornment={
                    <Button
                        color="primary"
                        size="small"
                        disabled={isEditable}
                        onClick={handleEditInnClick}
                    >
                        Редактировать
                    </Button>
                }
            />

            <TextField
                label="ИНН"
                variant="outlined"
                value={value}
                onChange={handleChangeValue}
                disabled={!isEditable}
                size="small"
                margin="normal"
                fullWidth
            />

            {isEditable && (
                <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                        <Button color="default" size="small" onClick={handleEditInnClick}>
                            Отмена
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={handleSaveInnClick}
                            disabled={disabled}
                        >
                            Сохранить
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default InnBind;
