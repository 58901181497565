import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import Appbar from "../components/Views/Appbar/Appbar";
import Bg from "../images/auth-bg.png";

const useStyles = makeStyles(() => ({
    root: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bgImage: {
        background: `url(${Bg}) no-repeat`,
        backgroundSize: "100%",
        backgroundPosition: "center",
    },
}));

const AuthLayout: React.FC = ({ children }: any) => {
    const classes = useStyles();

    return (
        <>
            <Appbar hideSidebarButton />
            <main className={clsx(classes.bgImage, classes.root)}>{children}</main>
        </>
    );
};

export default AuthLayout;
