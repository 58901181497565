import { Box } from "@material-ui/core";
import React from "react";
import AddNewEntityItemButton from "../../Controls/Actions/EntityItems/AddNewEntityItemButton";

type EntityItemsActionsProps = { itemsCount: number; attrsCount: number };

const EntityItemsActions = ({ itemsCount, attrsCount }: EntityItemsActionsProps) => {
    return (
        <Box>
            <AddNewEntityItemButton
                color="primary"
                size="small"
                disabled={itemsCount >= attrsCount}
            />
        </Box>
    );
};

export default EntityItemsActions;
