import { Exclude, Expose } from "class-transformer";

@Exclude()
class CreateStaffCartItemDto {
    @Expose()
    readonly sku: string;

    @Expose()
    readonly quantity: number;
}

export default CreateStaffCartItemDto;
