import { Box, Grid, IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import { CreateNewFolder } from "@material-ui/icons";
import { MODAL_TYPE } from "@modules/ModalManager";
import { ModalEvents, ModalState } from "@store/modal-module";
import React from "react";
import { useStoreon } from "storeon/react";

const DirectoriesHeader = ({ onUpdate }) => {
    const { dispatch } = useStoreon<ModalState, ModalEvents>();

    const handleCreateNewDirectory = () => {
        dispatch("modal/set/type", MODAL_TYPE.ADD_DIRECTORY);
        dispatch("modal/set/props", { onUpdate });
        dispatch("modal/toggle", true);
    };

    return (
        <Paper elevation={1} square>
            <Box px={2} py={1} mb={2}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography>
                        <b>Директории</b>
                    </Typography>
                    <Tooltip arrow title="Создать новую папку">
                        <IconButton size="small" color="primary" onClick={handleCreateNewDirectory}>
                            <CreateNewFolder />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Box>
        </Paper>
    );
};

export default DirectoriesHeader;
