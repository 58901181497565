import { TextField, TextFieldProps, MenuItem, ListItemText } from "@material-ui/core";
import ContentTypesManager from "@modules/ContentTypesManagers/ContentTypesManager";
import React from "react";

const ContentTypeSelect = ({ ...props }: TextFieldProps) => {
    return (
        <TextField {...props} value={props.value || ""} select label="Тип контента">
            {ContentTypesManager.CONTENT_TYPES.map((content) => {
                return (
                    <MenuItem key={content.type} value={content.type} style={{ display: "block" }}>
                        <ListItemText primary={content.name} />
                        <ListItemText secondary={content.note} />
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default ContentTypeSelect;
