import { Button } from "@material-ui/core";
import { PathsManager } from "@modules";
import React from "react";
import { useHistory } from "react-router-dom";

const GoBackButton = () => {
    const histoty = useHistory();

    const handleClick = () => {
        histoty.push(PathsManager.PATHS.HOME.url);
    };

    return (
        <Button color="primary" onClick={handleClick}>
            На главную
        </Button>
    );
};

export default GoBackButton;
