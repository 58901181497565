import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const EntityAttributeItemSkeleton = () => {
    return (
        <Box>
            <Skeleton width="40%" height={40} />
        </Box>
    );
};

export default EntityAttributeItemSkeleton;
