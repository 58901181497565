import { AppBrand } from "@interfaces/Internal/AppBrand";
import { AppUserRole } from "@interfaces/Internal/AppUserRole";
import { StoreonModule } from "storeon";
import shortid from "shortid";
import { Brand } from "@interfaces/Brand";
import { AppStaffCartItem } from "@interfaces/Internal/AppStaffCartItem";
import { StaffCartItem } from "@interfaces/StaffCartItem";
import { Directory } from "@interfaces/Directory";

export interface ContentState {
    staffRoles: {
        userId: number | null;
        roles: AppUserRole[];
        rolesToRevoke: string[];
    };
    staffBrands: {
        brands: AppBrand[];
        brandsToUnbind: AppBrand[];
    };
    fileStorage: {
        selectedDirectory: Directory;
    };
    staffCart: {
        items: AppStaffCartItem[];
        itemsToRemove: AppStaffCartItem[];
    };
}

export interface ContentEvents {
    "content/staff_roles/set/userid": number | null;
    "content/staff_roles/set/roles": string[];
    "content/staff_roles/set/roles_to_revoke": string[];
    "content/staff_roles/add/roles";
    "content/staff_roles/remove/roles": string;
    "content/staff_roles/update/roles": { key: string; value: string };

    "content/file_storage/set/selected_directory": Directory;

    "content/staff_brands/set/brands": { brands: Brand[]; userId: number };
    "content/staff_brands/add/brands";
    "content/staff_brands/update/brands": { key: string; value: number | null };
    "content/staff_brands/remove/brands": string;
    "content/staff_brands/set/brandstounbind": {
        brandsToUnbind: Brand[];
        userId: number;
    };

    "content/staff_cart/set/items": StaffCartItem[];
    "content/staff_cart/add/items";
    "content/staff_cart/update/items": { key: string; field: string; value: any };
    "content/staff_cart/remove/items": string;
}

export const defaultDirectory = { title: "root", id: 1, childs: [], parent_id: null };

export const contentModule: StoreonModule<ContentState, ContentEvents> = (store) => {
    store.on("@init", () => ({
        staffRoles: {
            userId: null,
            roles: [],
            rolesToRevoke: [],
        },
        staffBrands: {
            brands: [],
            brandsToUnbind: [],
        },
        fileStorage: {
            selectedDirectory: defaultDirectory,
        },
        staffCart: {
            items: [],
            itemsToRemove: [],
        },
    }));

    store.on("content/file_storage/set/selected_directory", (state, value) => ({
        fileStorage: {
            ...state.fileStorage,
            selectedDirectory: value,
        },
    }));

    store.on("content/staff_roles/set/userid", (state, staffMemberId) => ({
        staffRoles: { ...state.staffRoles, userId: staffMemberId },
    }));

    store.on("content/staff_roles/set/roles", (state, roles) => {
        const appRoles: AppUserRole[] = roles.map((role) => ({
            key: shortid.generate(),
            value: role,
            action: "",
        }));

        return {
            staffRoles: { ...state.staffRoles, roles: appRoles },
        };
    });

    store.on("content/staff_roles/set/roles_to_revoke", (state, rolesToRevoke) => {
        return {
            staffRoles: {
                ...state.staffRoles,
                rolesToRevoke: rolesToRevoke,
            },
        };
    });

    store.on("content/staff_roles/add/roles", (state) => {
        const role: AppUserRole = {
            key: shortid.generate(),
            value: "",
            action: "add",
        };

        return {
            staffRoles: {
                ...state.staffRoles,
                roles: [...state.staffRoles.roles, role],
            },
        };
    });

    store.on("content/staff_roles/update/roles", (state, { key, value }) => {
        const copyArr = [...state.staffRoles.roles];
        const index = copyArr.findIndex((el) => el.key === key);

        const item = copyArr[index];
        if (!item.action) {
            item.action = "update";
        }

        const toRevoke = [...state.staffRoles.rolesToRevoke];
        if (item.action === "update") {
            item.action = "add";
            toRevoke.push(item.value);
        }
        item.value = value;

        copyArr[index] = item;

        return {
            staffRoles: {
                ...state.staffRoles,
                roles: copyArr,
                rolesToRevoke: toRevoke,
            },
        };
    });

    /** remove role by key */
    store.on("content/staff_roles/remove/roles", (state, key) => {
        const copyArr = [...state.staffRoles.roles];
        const index = copyArr.findIndex((el) => el.key === key);
        const item = copyArr[index];
        copyArr.splice(index, 1);

        const toRemove = [...state.staffRoles.rolesToRevoke];
        if (!item.action && item.value) {
            toRemove.push(item.value);
        }

        return {
            staffRoles: {
                ...state.staffRoles,
                roles: copyArr,
                rolesToRevoke: toRemove,
            },
        };
    });

    store.on("content/staff_brands/set/brandstounbind", (state, { brandsToUnbind, userId }) => {
        const appBrandsToUnbind: AppBrand[] = brandsToUnbind.map((brand) => ({
            key: shortid.generate(),
            userId,
            brandId: brand.id,
            action: "remove",
        }));

        return {
            staffBrands: {
                ...state.staffBrands,
                brandsToUnbind: appBrandsToUnbind,
            },
        };
    });

    store.on("content/staff_brands/set/brands", (state, { brands, userId }) => {
        const appBrands: AppBrand[] = brands.map((brand) => ({
            key: shortid.generate(),
            userId,
            brandId: brand.id,
            action: "",
        }));

        return {
            staffBrands: {
                ...state.staffBrands,
                brands: appBrands,
            },
        };
    });

    store.on("content/staff_brands/add/brands", (state) => {
        if (typeof state.staffRoles.userId !== "number") return;

        const brand: AppBrand = {
            key: shortid.generate(),
            brandId: null,
            userId: state.staffRoles.userId,
            action: "add",
        };

        return {
            staffBrands: {
                ...state.staffBrands,
                brands: [...state.staffBrands.brands, brand],
            },
        };
    });

    /** remove brand by key */
    store.on("content/staff_brands/remove/brands", (state, key) => {
        const copyArr = [...state.staffBrands.brands];
        const index = copyArr.findIndex((el) => el.key === key);
        copyArr.splice(index, 1);

        return {
            staffBrands: {
                ...state.staffBrands,
                brands: copyArr,
            },
        };
    });

    store.on("content/staff_brands/update/brands", (state, { key, value }) => {
        const copyArr = [...state.staffBrands.brands];
        const index = copyArr.findIndex((el) => el.key === key);

        const item = copyArr[index];

        item.brandId = value;

        copyArr[index] = item;

        return {
            staffBrands: {
                ...state.staffBrands,
                brands: copyArr,
            },
        };
    });

    store.on("content/staff_cart/set/items", (state, staffCartItems) => {
        const appStaffCartItems: AppStaffCartItem[] = staffCartItems.map((staffCartItem) => ({
            key: shortid.generate(),
            id: staffCartItem.id,
            sku: staffCartItem.sku,
            quantity: staffCartItem.quantity,
            action: "",
        }));

        return {
            staffCart: {
                ...state.staffCart,
                items: appStaffCartItems,
                itemsToRemove: appStaffCartItems,
            },
        };
    });

    store.on("content/staff_cart/add/items", (state) => {
        const item: AppStaffCartItem = {
            key: shortid.generate(),
            sku: null,
            quantity: 1,
            action: "add",
        };

        return {
            staffCart: {
                ...state.staffCart,
                items: [...state.staffCart.items, item],
            },
        };
    });

    store.on("content/staff_cart/update/items", (state, { key, field, value }) => {
        const copyArr = [...state.staffCart.items];
        const index = copyArr.findIndex((el) => el.key === key);

        const item = copyArr[index];

        item[field] = value;

        copyArr[index] = item;

        return {
            staffCart: {
                ...state.staffCart,
                items: copyArr,
            },
        };
    });

    store.on("content/staff_cart/remove/items", (state, key) => {
        const copyArr = [...state.staffCart.items];
        const index = copyArr.findIndex((el) => el.key === key);
        copyArr.splice(index, 1);

        return {
            staffCart: {
                ...state.staffCart,
                items: copyArr,
            },
        };
    });
};
