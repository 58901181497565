import { Button, ButtonProps } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { nsiService, storeService } from "@services";
import DownloadSnackbar from "../../Views/Snackbars/DownloadSnackbar";

type ExportDataButtonProps = ButtonProps & {
    exportDataType: "price" | "quantity" | "discount" | "store";
};

const ExportDataButton = (props: ExportDataButtonProps) => {
    const { exportDataType } = props;
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = async () => {
        let response: any = null;

        switch (exportDataType) {
            case "price":
                response = await storeService.downloadPriceCSVdata({
                    deferredLoading: true,
                });
                break;
            case "quantity":
                response = await storeService.downloadQuantitiesCSVdata({
                    deferredLoading: true,
                });
                break;
            case "discount":
                response = await storeService.downloadDiscountCSVdata({
                    deferredLoading: true,
                });
                break;
            case "store":
                response = await storeService.downloadStoreCSVdata({
                    deferredLoading: true,
                });
                break;
        }

        const fileName = nsiService.getFileNameFromHeaders(response);

        enqueueSnackbar("Данные выгружены", {
            persist: true,
            content: (id, message) => (
                <DownloadSnackbar
                    id={id}
                    message={message}
                    onDownload={() =>
                        nsiService.download(response, fileName, {
                            blobType: "text/csv",
                        })
                    }
                />
            ),
        });
    };

    return (
        <Button
            component="span"
            onClick={handleClick}
            startIcon={<GetApp />}
            color="secondary"
            {...props}
        >
            Выгрузить данные
        </Button>
    );
};

export default ExportDataButton;
