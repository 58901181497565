import { Avatar, Paper, Button, Grid, Typography, Box } from "@material-ui/core";
import { AuthEvents, AuthState } from "@store/auth-module";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useStoreon } from "storeon/react";
import { UpdateStaffProfileDto } from "@dto/StaffDto";
import useAsync from "@hooks/useAsync";
import { staffProfileService } from "@services/Staff";
import ProfileForm from "../../Forms/ProfileForm";
import NotFound from "../NotFound/NotFound";
import { useAuth, useLoading, useModal } from "@hooks";
import { CreateInnBindDto, UpdateInnBindDto } from "@dto/InnBind";
import { Skeleton } from "@material-ui/lab";
import { InnBind } from "@interfaces/InnBind";
import { StaffMember } from "@interfaces/StaffMember";
import InnBindForm from "../InnBind";

const Profile = () => {
    const { user } = useAuth();
    const formRef = useRef<HTMLFormElement>(null);
    const { dispatch: authDispatch } = useStoreon<AuthState, AuthEvents>();
    const { handleOpenModal } = useModal();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { enqueueSnackbar } = useSnackbar();
    const {
        data: staffMemberResult,
        loadingStatus: profileLoadingStatus,
        updateData: profileUpdateData,
    } = useAsync(staffProfileService.getCurrentStaffMember, {
        setLoadingStatusOnUpdate: false,
    });
    const { result: staffMember } = { ...staffMemberResult };

    const {
        data: innBind,
        loadingStatus: innLoadingStatus,
        updateData: innUpdateData,
    } = useAsync(staffProfileService.getOneInnBind, {
        functionArgs: [user?.id],
        canLoad: !!user,
        setLoadingStatusOnUpdate: false,
    });

    const [isEditable, setEditable] = useState(false);

    const invokeSubmit = () => {
        formRef.current!.handleSubmit();
    };

    const handleSubmit = async (values) => {
        if (!staffMember) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = staffProfileService.plainToClass(UpdateStaffProfileDto, values);
            await staffProfileService.updateCurrentStaffMember(staffMember.id, dto);
            setEditable(!isEditable);
            const updatedStaffMember = await profileUpdateData();
            if (updatedStaffMember) {
                authDispatch("auth/set/user", updatedStaffMember.result);
            }
            enqueueSnackbar("Данные успешно обновлены", { variant: "success" });
        } catch (err) {
            enqueueSnackbar("Произошла ошибка при обновлении данных", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleCreateInnClick = async (inn: string) => {
        if (!user) return;

        try {
            const dto = staffProfileService.plainToClass(CreateInnBindDto, {
                staff_id: user.id,
                inn,
            });
            await staffProfileService.createInnBind(dto);
            await innUpdateData();
            enqueueSnackbar("ИНН успешно привязан", { variant: "success" });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при привязке ИНН", {
                variant: "error",
            });
        }
    };

    const handleUpdateInnClick = async (inn: string) => {
        if (!user || !innBind) return;

        setSubmitLoadingStatus("loading");
        if (inn) {
            await updateInn(innBind, user, inn);
        } else {
            await removeInn(innBind.id);
        }

        setSubmitLoadingStatus("loaded");
        await innUpdateData();
    };

    const removeInn = async (innBindId: number) => {
        try {
            await staffProfileService.removeInnBinds([innBindId]);
            enqueueSnackbar("ИНН успешно отвязан", { variant: "success" });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при отвязке ИНН", {
                variant: "error",
            });
        }
    };

    const updateInn = async (innBind: InnBind, user: StaffMember, inn: string) => {
        try {
            const dto = staffProfileService.plainToClass(UpdateInnBindDto, {
                staffMemberId: user.id,
                inn,
            });
            await staffProfileService.updateInnBind(innBind.id, dto);
            enqueueSnackbar("ИНН успешно обновлен", { variant: "success" });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении ИНН", {
                variant: "error",
            });
        }
    };

    const handleChangePasswordClick = () => {
        if (!staffMember) return;

        handleOpenModal("CHANGE_CURRENT_STAFF_MEMBER_PASSWORD", { itemId: staffMember.id });
    };

    const handleEditClick = () => {
        formRef.current!.resetForm();
        setEditable(!isEditable);
    };

    if (profileLoadingStatus === "loaded" && !staffMember)
        return <NotFound title="Пользователь не найден" />;

    return (
        <Grid container>
            <Grid item xs={12} lg={7}>
                <Paper>
                    {profileLoadingStatus === "loading" && <Skeleton width="100%" height={100} />}

                    {profileLoadingStatus === "loaded" && staffMember && (
                        <Grid container component={Box} p={2}>
                            <Grid item xs={4} sm={3} md={2}>
                                <Avatar style={{ height: 60, width: 60, fontSize: 40 }}>
                                    {staffMember.login.charAt(0)}
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Box py={2}>
                                    <Typography
                                        style={{ fontWeight: 600 }}
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                        {staffMember.login}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Paper>

                <Box py={2}>
                    <Grid container justifyContent="flex-end">
                        <Grid item></Grid>
                        <Grid item>
                            <Button
                                size="small"
                                onClick={handleChangePasswordClick}
                                disabled={isEditable}
                            >
                                Смена пароля
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box component={Paper} p={2}>
                    {profileLoadingStatus === "loading" && <Skeleton width="100%" height={200} />}

                    {profileLoadingStatus === "loaded" && staffMember && (
                        <>
                            <ProfileForm
                                user={staffMember}
                                onSubmit={handleSubmit}
                                ref={formRef}
                                onEditClick={handleEditClick}
                                disabled={!isEditable}
                            />

                            {isEditable && (
                                <Grid container justifyContent="flex-end" spacing={1}>
                                    <Grid item>
                                        <Button size="small" onClick={handleEditClick}>
                                            Отмена
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            onClick={invokeSubmit}
                                            disabled={submitLoadingStatus === "loading"}
                                        >
                                            Сохранить
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </Box>

                <Box component={Paper} my={2} p={2}>
                    {innLoadingStatus === "loading" && <Skeleton width="100%" height={80} />}
                    {(innLoadingStatus === "inactivity" || innLoadingStatus === "loaded") && (
                        <InnBindForm
                            onSaveClick={innBind ? handleUpdateInnClick : handleCreateInnClick}
                            disabled={submitLoadingStatus === "loading"}
                            inn={innBind?.inn || ""}
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Profile;
