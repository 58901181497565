import { TimePicker } from "@material-ui/pickers";
import { Constants } from "@modules";
import dayjs from "dayjs";
import React from "react";
import { UniversalInputProps } from "@interfaces/System/UniversalInputProps";

const UTimePicker = ({ ...props }: UniversalInputProps) => {
    const format = Constants.Format.TIME;

    const handleChange = (date) => {
        const time = dayjs.isDayjs(date) ? dayjs(date).format(format) : null;
        props.onChange(props.name, time);
    };

    const value = props.value ? dayjs(props.value, format) : null;

    return (
        <TimePicker
            ampm={false}
            clearable
            label={props.label || "Выберите время"}
            size={props.size}
            inputVariant={props.variant}
            margin={props.margin}
            fullWidth={props.fullWidth}
            value={value}
            error={props.error}
            helperText={props.helperText}
            onChange={handleChange}
            disabled={props.disabled}
            okLabel="Ок"
            clearLabel="Очистить"
            cancelLabel="Отмена"
        />
    );
};

export default UTimePicker;
