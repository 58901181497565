import React from "react";
import { useCurrentPageInfo } from "@hooks";
import { ServiceHelper } from "@modules";
import { storeService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useSnackbar } from "notistack";
import { DiscountLoadStatus } from "@interfaces/DiscountLoadStatus";

const DiscountsLoadStatusesTable = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { permission } = useCurrentPageInfo();

    const handleDeleteClick = async (rowData: DiscountLoadStatus | DiscountLoadStatus[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await storeService.deleteQuantityLoadStatus(ids);
            enqueueSnackbar("Запись о загрузке успешно удалена", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении записи", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<DiscountLoadStatus>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "desc",
            width: 100,
        },
        {
            title: "Название файла",
            field: "file_name",
            width: "auto",
        },
        {
            title: "Статус",
            field: "status",
            type: "boolean",
            width: "auto",
        },
        {
            title: "Сообщение",
            field: "message",
            width: "auto",
        },
        {
            title: "Дата загрузки",
            field: "loaded_at",
            filtering: false,
            type: "datetime",
            width: "auto",
        },
    ];

    return (
        <DataTable<DiscountLoadStatus>
            fetchFn={storeService.getDiscountLoadStatuses}
            columns={columns}
            displayField="file_name"
            permission={permission}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            filtering
            selection
            sorting
            search
        />
    );
};

export default DiscountsLoadStatusesTable;
