import { Path } from "@interfaces/System/Path";
import ProtectedPathsManager from "./ProtectedPathsManager";

type AppPath = Record<string, Path>;

class PathsManager {
    /** get all static objects of paths in app */
    public static getPathsArray = (): Path[] => {
        return Object.values(PathsManager.PATHS);
    };

    /** get all static urls in app */
    public static getUrlPathsArray = (): string[] => {
        return PathsManager.getPathsArray().map((path) => path.url);
    };

    public static getPathByField = (
        byField: "path" | "original" | "title" | "key",
        value: string
    ) => {
        return PathsManager.getPathsArray().find((el) => el[byField] === value);
    };

    static getPathKey = (url: string): string => {
        if (!url) return "";
        return ProtectedPathsManager.excludeQueryParams(url).split("/").pop() || "";
    };

    /** here place only static paths */
    public static readonly PATHS: AppPath = {
        HOME: {
            url: "/",
            title: "Домашняя",
            icon: "home",
        },
        LOGIN: {
            url: "/login",
            title: "Авторизация",
            icon: "",
        },
        CABINET: {
            url: "/cabinet",
            title: "Личный кабинет",
            icon: "person",
        },
    };
}

export default PathsManager;
