import { Box, BoxProps, Grid, GridProps } from "@material-ui/core";
import React, { ReactNode } from "react";

interface ContentLayoutProps {
    mainContent: ReactNode;
    mainContentGridProps?: GridProps;
    sideContent: ReactNode;
    sideContentGridProps?: GridProps;
    boxProps?: BoxProps;
}

const ContentLayout: React.FC<ContentLayoutProps> = ({
    mainContent,
    mainContentGridProps,
    sideContent,
    sideContentGridProps,
    boxProps,
}: ContentLayoutProps) => {
    return (
        <Box {...boxProps}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9} {...mainContentGridProps}>
                    {mainContent}
                </Grid>
                <Grid item xs={12} md={3} {...sideContentGridProps}>
                    {sideContent}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContentLayout;
