import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ModalContentLoading } from "@components/Common/Modal";
import React, { useRef } from "react";
import { storeService } from "@services";
import { useSnackbar } from "notistack";
import { useAsync, useLoading, useTable } from "@hooks";
import { DiscountForm } from "@components/Forms/Discount";
import { AttributeValueBindDto, CreateDiscountDto, UpdateDiscountDto } from "@dto/DiscountDto";
import { Discount } from "@interfaces/Discount";
import { FormProps } from "@interfaces/System/FormProps";

const useStyles = makeStyles(() => ({
    content: {
        height: "70vh",
    },
}));

const DiscountDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const classes = useStyles();
    const formRef = useRef<HTMLFormElement>();
    const { enqueueSnackbar } = useSnackbar();
    const { updateData } = useTable();

    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data, loadingStatus } = useAsync(storeService.getOneDiscount, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current!.handleSubmit();
    };

    const handleCreate: FormProps<Discount>["onSubmit"] = async (values) => {
        setSubmitLoadingStatus("loading");

        try {
            const dto = storeService.plainToClass(CreateDiscountDto, values);
            await storeService.createDiscount(dto);
            enqueueSnackbar("Промокод успешно добавлен", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при добавлении промокода", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleUpdate: FormProps<Discount>["onSubmit"] = async (values) => {
        if (!data) return;

        setSubmitLoadingStatus("loading");
        try {
            /** Удалить старые биндинги */
            const oldAttrBinds: number[] | undefined = data.discount_attribute_value_bindings?.map(
                (el) => el.id
            );
            if (oldAttrBinds?.length) {
                await storeService.deleteAttributeValueBinds(oldAttrBinds);
            }

            const newAttrBinds: AttributeValueBindDto[] = storeService.plainToClassArray(
                AttributeValueBindDto,
                values.discount_attribute_value_bindings
            );

            /** Привязать новые биндинги и сохранить их id */
            let ids: number[] = [];
            if (newAttrBinds.length) {
                const { result } = await storeService.createAttributeValueBind(newAttrBinds);
                ids = result.ids;
            }

            const dto = storeService.plainToClass(UpdateDiscountDto, {
                ...values,
                discount_attribute_value_bindings: ids,
            });
            await storeService.updateDiscount(data.id, dto);

            enqueueSnackbar("Промокод успешно обновлен", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении промокода", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent className={classes.content}>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <DiscountForm
                        ref={formRef}
                        data={data}
                        onSubmit={data ? handleUpdate : handleCreate}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default DiscountDialogContext;
