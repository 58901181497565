import { CommonFormProps } from "@interfaces/System/FormProps";
import { Form, Formik } from "formik";
import React from "react";
import { StaffMenuPermission } from "@interfaces/StaffMenuPermission";
import { StaffMenuPermissionSchema } from "@schema/Settings/StaffMenuPermissionSchema";
import ServiceRoleSelect from "@components/Controls/Selects/ServiceRoleSelect";
import PermissionSlider from "@components/Controls/Sliders/PermissionSlider";
import { ProtectedPath } from "@interfaces/ProtectedPath";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { staffMenuService } from "@services/Settings";

const StaffMenuPermissionForm = React.forwardRef(
    ({ onSubmit, data }: CommonFormProps<StaffMenuPermission>, ref: any) => {
        const initialData = {
            rolname: data?.rolname || "",
            menu_item: data?.menu_item || null,
            permission: data?.permission || 1,
        } as StaffMenuPermission;

        return (
            <Formik
                innerRef={ref}
                initialValues={initialData}
                validationSchema={StaffMenuPermissionSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, setFieldValue, values }) => {
                    return (
                        <Form>
                            <ServiceRoleSelect
                                name="rolname"
                                variant="outlined"
                                size="small"
                                required
                                margin="normal"
                                helperText={touched.rolname ? errors.rolname : ""}
                                error={touched.rolname && !!errors.rolname}
                                label="Роль"
                                value={values.rolname}
                                onChange={handleChange}
                                fullWidth
                            />

                            <AsyncAutocomplete<ProtectedPath>
                                fetchFn={staffMenuService.getStaffMenuItems}
                                value={values.menu_item}
                                onChange={(name, value) => setFieldValue(name, value?.id || null)}
                                optionField="title"
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    size: "small",
                                    margin: "normal",
                                    variant: "outlined",
                                    name: "menu_item",
                                    label: "Элемент меню",
                                    helperText: touched.menu_item ? errors.menu_item : "",
                                    error: touched.menu_item && !!errors.menu_item,
                                }}
                            />

                            <PermissionSlider
                                name="permission"
                                value={values.permission}
                                onChange={(e, v) => setFieldValue("permission", v)}
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default StaffMenuPermissionForm;
