import {
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    SvgIconProps,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import React, { memo } from "react";
import { File } from "@interfaces/File";
import { isObjectsAreEqual, truncateText } from "@modules/Utils";
import { Common } from "@modules/Constants";
import useFileIdentity from "@hooks/useFileIdentity";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: theme.spacing(0.2, 1, 0.2, 1),
    },
    icon: {
        marginRight: 5,
    },
}));

type Props = {
    item: File;
    onClick(event: React.MouseEvent<HTMLElement>): void;
};

const FileItemListElement = ({ item, onClick }: Props) => {
    const { getFileIcon, handleOpen, canOpen } = useFileIdentity(item.path);
    const classes = useStyles();

    const Icon = getFileIcon();
    const iconProps: SvgIconProps = {
        fontSize: "small",
        color: "action",
        className: classes.icon,
    };

    return (
        <ListItem
            className={classes.item}
            button={(canOpen as true) || false}
            onClick={canOpen ? handleOpen : undefined}
        >
            <Icon {...iconProps} />

            <ListItemText
                primary={item.name || "Название не указано"}
                secondary={truncateText(item.description || "", Common.LONG_TEXT_TRUNCATE_LEN)}
            />

            <ListItemSecondaryAction>
                <IconButton onClick={onClick} data-itemid={item.id}>
                    <MoreVert fontSize="small" />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default memo(FileItemListElement, (prev, next) => isObjectsAreEqual(prev, next));
