import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { eTypesService } from "@services";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { EntityType } from "../../interfaces/EntityType";
import { EntityTypeSchema } from "../../schema/EntityTypeSchema";
import AutoSizeTextField from "../Controls/Inputs/AutoSizeTextField";

interface Props {
    onSubmit: (values) => Promise<void>;
    entityType?: EntityType;
}

const EntityTypeForm = forwardRef(({ onSubmit, entityType }: Props, ref: any) => {
    const initialData = {
        name: entityType ? entityType.name : "",
        notes: entityType ? entityType.notes : "",
        parent_et_id: entityType ? entityType.parent_et_id : null,
    };

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={EntityTypeSchema}
            onSubmit={(values) => {
                const copy = { ...values };
                copy.name = copy.name.trim();
                copy.notes = copy.notes?.trim();
                onSubmit(copy);
            }}
            validateOnMount
        >
            {({ touched, errors, handleChange, setFieldValue, values }) => {
                return (
                    <Form>
                        <AutoSizeTextField
                            name="name"
                            variant="outlined"
                            margin="normal"
                            size="small"
                            required
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            label="Наименование"
                            value={values.name}
                            onChange={handleChange}
                            fullWidth
                            maxRows={3}
                        />

                        <AutoSizeTextField
                            name="notes"
                            size="small"
                            label="Комментарий"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                            helperText="Необязательно"
                            value={values.notes}
                            margin="normal"
                            maxRows={5}
                            rows={2}
                        />

                        <AsyncAutocomplete
                            value={values.parent_et_id}
                            fetchFn={eTypesService.getEntityTypes}
                            optionField="name"
                            onChange={(name, entity) => {
                                setFieldValue(name, entity?.id || null);
                            }}
                            textFieldProps={{
                                fullWidth: true,
                                size: "small",
                                margin: "normal",
                                variant: "outlined",
                                name: "parent_et_id",
                                label: "Родительский тип сущности",
                                helperText: touched.parent_et_id ? errors.parent_et_id : "",
                                error: touched.parent_et_id && !!errors.parent_et_id,
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default EntityTypeForm;
