import { QueryResult, QueryParams, QueryResultWithoutData, PaginatedQueryResult } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { StaffMember } from "@interfaces/StaffMember";
import { CreateStaffMemberDto, UpdateStaffMemberDto } from "@dto/StaffDto";
import { ChangeStaffMemberPasswordDto } from "@dto/ChangePasswordDto";

class StaffService extends BaseService {
    /** sort by roles: `?memberof={brand_manager}` */
    public getAllStaffMembers = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<StaffMember>> => {
        const { data } = await this.instance.get("/staff", {
            params,
        });

        return this.toPaginatedUniversalOutput<StaffMember>(data);
    };

    public getOneStaffMember = async (staffMemberId: number): Promise<StaffMember> => {
        const { data } = await this.instance.get("/staff", {
            params: { id: staffMemberId },
        });

        return data.result.items[0];
    };

    public createStaffMember = async (
        createStaffMemberDto: CreateStaffMemberDto
    ): Promise<QueryResult<StaffMember>> => {
        const { data } = await this.instance.post("/staff", createStaffMemberDto);
        if (data.error) throw new Error(data.error);

        return data;
    };

    public updateStaffMember = async (
        id: number,
        updateStaffMemberDto: UpdateStaffMemberDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/staff", { id, data: updateStaffMemberDto });

        return data;
    };

    public changeStaffMemberPassword = async (
        changeStaffMemberPasswordDto: ChangeStaffMemberPasswordDto
    ): Promise<any> => {
        const { data } = await this.instance.post(
            "/staff/change_password",
            changeStaffMemberPasswordDto
        );

        return data;
    };

    public getStaffEntityPermission = async (
        params: QueryParams
    ): Promise<QueryResult<number | number[]>> => {
        const { data } = await this.instance.get("/staff/entity_permission", { params });

        return data;
    };
}

export default new StaffService(new HttpClient(Config.API_URL), AuthManager);
