import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ruRU } from "@material-ui/core/locale";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { red, deepOrange, grey, common } from "@material-ui/core/colors";

const breakpoints = createBreakpoints({});

const createLightTheme = () =>
    createTheme(
        {
            palette: { type: "light" },
            typography: {
                fontSize: 12,
            },
            overrides: {
                MuiToolbar: {
                    regular: {
                        [breakpoints.up("xs")]: {
                            minHeight: "56px",
                        },
                    },
                },
                MuiTooltip: {
                    tooltip: {
                        fontSize: "12px",
                    },
                },
                MuiTableCell: {
                    root: {
                        padding: "4px 8px",
                    },
                    head: {
                        padding: "4px 8px",
                    },
                },
            },
        },
        ruRU
    );

const createDarkTheme = () =>
    createTheme(
        {
            palette: {
                type: "dark",
                primary: deepOrange,
                secondary: red,
            },
            typography: {
                fontSize: 12,
            },
            overrides: {
                MuiInputBase: {
                    input: {
                        "&:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:active, &:-webkit-autofill:hover":
                            {
                                WebkitBoxShadow: `0 0 0px 1000px ${grey[700]} inset`,
                                WebkitTextFillColor: common.white,
                            },
                    },
                },
                MuiAppBar: {
                    colorPrimary: {
                        backgroundColor: grey[900],
                    },
                },
                MuiToolbar: {
                    regular: {
                        [breakpoints.up("xs")]: {
                            minHeight: "56px",
                        },
                    },
                },
                MuiTooltip: {
                    tooltip: {
                        fontSize: "12px",
                    },
                },
                MuiTableCell: {
                    root: {
                        padding: "4px 8px",
                    },
                    head: {
                        padding: "4px 8px",
                    },
                },
            },
        },
        ruRU
    );

const lightTheme = () => responsiveFontSizes(createLightTheme());
const darkTheme = () => responsiveFontSizes(createDarkTheme());

export { lightTheme, darkTheme };
