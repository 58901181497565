import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { fileService } from "@services";
import { CreateDirectoryDto, UpdateDirectoryDto } from "@dto/DirectoryDto";
import { DirectoryForm } from "@components/Forms/FileStorage";
import { useAsync, useLoading } from "@hooks";
import { Directory } from "@interfaces/Directory";
import { ModalContentLoading } from "@components/Common/Modal";

const DirectoryDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { parentDirId = 1, onUpdate = () => {}, itemId } = { ...modalProps };

    const { data, loadingStatus } = useAsync<Directory>(fileService.getOneDirectory, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreateDirectory = async (values) => {
        setSubmitLoadingStatus("loading");
        try {
            const dto = fileService.plainToClass(CreateDirectoryDto, {
                title: values.title,
                parentDirId: parentDirId,
            });

            await fileService.createDirectory(dto);
            enqueueSnackbar("Папка успешно создана", {
                variant: "success",
            });
            onClose();
            await onUpdate();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании папки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleUpdateDirectory = async (values) => {
        if (!itemId) return;

        try {
            const dto = fileService.plainToClass(UpdateDirectoryDto, values);

            await fileService.updateDirectory(itemId, dto);
            enqueueSnackbar("Папка успешно обновлена", {
                variant: "success",
            });
            onClose();
            await onUpdate();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении папки", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <DirectoryForm
                        onSubmit={itemId ? handleUpdateDirectory : handleCreateDirectory}
                        ref={formRef}
                        data={data}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default DirectoryDialogContext;
