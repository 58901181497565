import { Icon, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    text: {
        wordWrap: "break-word",
    },
}));
interface Props {
    text: string;
    href: string;
    icon?: string;
    nested?: boolean;
}

const SidebarListItem = ({ text, href, icon, nested }: Props) => {
    const classes = useStyles();
    const location = useLocation();

    return (
        <ListItem
            className={clsx({ [classes.nested]: nested })}
            button
            component={NavLink}
            to={href || "/"}
            selected={href === location.pathname}
        >
            {icon && (
                <ListItemIcon>
                    <Icon>{icon}</Icon>
                </ListItemIcon>
            )}
            <ListItemText primary={text} className={classes.text} />
        </ListItem>
    );
};

export default SidebarListItem;
