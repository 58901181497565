import { AppAttribute } from "@interfaces/Internal/AppAttribute";

export class UpdateEntityDto implements Readonly<UpdateEntityDto> {
    items: {
        type_id: number;
        e_id: number;
        a_id?: number;
        atype_id: number;
        value_id?: number;
        vt_id: number;
        sorting: number | null;
        value: any;
    }[];

    itemsToRemove: number[];

    constructor(entityId: number, entityTypeId: number, attrs: AppAttribute[]) {
        const items: UpdateEntityDto["items"] = [];
        const itemsToRemove: number[] = [];

        attrs.forEach((attr) => {
            attr.items.forEach((item) => {
                if (!item.edited) return;

                if (typeof item.value_id === "number" && this.isValueRemovable(item.value)) {
                    itemsToRemove.push(item.value_id);
                    return;
                }

                items.push({
                    type_id: entityTypeId,
                    e_id: entityId,
                    a_id: attr.id,
                    atype_id: attr.atype_id,
                    value_id: item.value_id,
                    value: item.value?.toString(),
                    sorting: item.sorting,
                    vt_id: item.vt_id,
                });
            });
        });

        this.items = items;
        this.itemsToRemove = itemsToRemove;
    }

    private isValueRemovable(value: string | number | boolean): boolean {
        if (typeof value === "boolean") return false;
        if (!value) return true;
        return false;
    }
}
