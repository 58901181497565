import { CommonFormProps } from "@interfaces/System/FormProps";
import { TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { aTypesService } from "@services";
import { AttributeTypeBind } from "@interfaces/AttributeTypeBind";
import { AttributeType } from "@interfaces/AttributeType";
import { AttributeTypeBindSchema } from "@schema/Settings";

const AttributeTypeBindForm = forwardRef(
    ({ onSubmit, data }: CommonFormProps<AttributeTypeBind>, ref: any) => {
        const initialData = {
            type_id: data?.type_id || null,
            attribute_type_name: data?.attribute_type_name || "",
        } as AttributeTypeBind;

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={AttributeTypeBindSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, setFieldValue, values }) => {
                    return (
                        <Form>
                            <AsyncAutocomplete<AttributeType>
                                fetchFn={aTypesService.getAttributeTypes}
                                value={values.type_id}
                                onChange={(name, value) => setFieldValue(name, value?.id || null)}
                                optionField="name"
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    size: "small",
                                    margin: "normal",
                                    variant: "outlined",
                                    name: "type_id",
                                    label: "Тип атрибута",
                                    helperText: touched.type_id ? errors.type_id : "",
                                    error: touched.type_id && !!errors.type_id,
                                }}
                            />

                            <TextField
                                fullWidth
                                onChange={handleChange}
                                required
                                value={values.attribute_type_name || ""}
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="attribute_type_name"
                                helperText={
                                    touched.attribute_type_name ? errors.attribute_type_name : ""
                                }
                                error={touched.attribute_type_name && !!errors.attribute_type_name}
                                label="Название привязки"
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default AttributeTypeBindForm;
