import { Checkbox, FormControlLabel, FormGroup, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { StaffOrderItem } from "@interfaces/StaffOrderItem";
import { StaffOrderItemSchema } from "@schema/StaffOrder";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { Price } from "@interfaces/Price";
import { storeService } from "@services";
import { staffOrderService } from "@services/Staff";
import { StaffOrder } from "@interfaces/StaffOrder";

const StaffOrderItemForm = forwardRef(
    ({ onSubmit, data }: CommonFormProps<StaffOrderItem>, ref: any) => {
        const initialData = {
            staff_order_id: data?.staff_order_id ?? null,
            price_id: data?.price_id ?? null,
            quantity: data?.quantity ?? 0,
            rejected: data?.rejected ?? false,
        } as StaffOrderItem;

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={StaffOrderItemSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, values, setFieldValue }) => {
                    return (
                        <Form>
                            <AsyncAutocomplete<StaffOrder>
                                fetchFn={staffOrderService.getStaffOrders}
                                value={values.staff_order_id}
                                onChange={(name, value) => setFieldValue(name, value?.id || null)}
                                optionField="number"
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    size: "small",
                                    margin: "normal",
                                    variant: "outlined",
                                    name: "staff_order_id",
                                    label: "Заказ",
                                    helperText: touched.staff_order_id ? errors.staff_order_id : "",
                                    error: touched.staff_order_id && !!errors.staff_order_id,
                                }}
                            />

                            <AsyncAutocomplete<Price>
                                fetchFn={storeService.getStorePrices}
                                value={values.price_id}
                                onChange={(name, value) => setFieldValue(name, value?.id || null)}
                                optionField="sale_price"
                                getOptionFieldLabel={(option) => "Цена: " + option}
                                secondaryField="sku"
                                getSecondaryFieldLabel={(option) => "SKU: " + option}
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    size: "small",
                                    margin: "normal",
                                    variant: "outlined",
                                    name: "price_id",
                                    label: "Цена",
                                    helperText: touched.price_id ? errors.price_id : "",
                                    error: touched.price_id && !!errors.price_id,
                                }}
                            />

                            <TextField
                                name="quantity"
                                onChange={handleChange}
                                value={values.quantity || ""}
                                variant="outlined"
                                type="number"
                                size="small"
                                fullWidth
                                margin="normal"
                                label="Количество"
                            />

                            {data && (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.rejected}
                                                onChange={handleChange}
                                                name="rejected"
                                                color="primary"
                                            />
                                        }
                                        label="Отклонено"
                                    />
                                </FormGroup>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default StaffOrderItemForm;
