import { getFileFormat, getFileUrl } from "@modules/Utils";
import { CommonEvents, CommonState } from "@store/common-module";
import { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import MimeTypes from "mime-types";
import { SvgIconComponent } from "@material-table/core";
import { InsertDriveFile, Image, Movie, Audiotrack } from "@material-ui/icons";

type useFileIdentityState = {
    extension: string;
    type: string;
    canOpen: boolean;
    url: string;
};

export const FileIcons = {
    image: Image,
    audio: Audiotrack,
    video: Movie,
};

const useFileIdentity = (target: string | Blob) => {
    const { dispatch } = useStoreon<CommonState, CommonEvents>();

    const [state, setState] = useState<useFileIdentityState>({
        extension: "",
        type: "",
        canOpen: false,
        url: "",
    });

    useEffect(() => {
        if (!target) return;

        const mime = target instanceof Blob ? target.type : MimeTypes.lookup(target);

        const type = mime ? mime.split("/")[0] : "";
        const extension = getFileFormat(
            target instanceof Blob ? target.type : target,
            target instanceof Blob ? "local" : "remote"
        );

        const url = target instanceof Blob ? URL.createObjectURL(target) : getFileUrl(target);

        const canOpen = ["image"].includes(type);

        setState((prev) => ({
            ...prev,
            extension,
            type,
            canOpen,
            url,
        }));
    }, [target]);

    useEffect(() => {
        return () => URL.revokeObjectURL(state.url);
    }, [state.url]);

    const handleOpen = () => {
        if (!state.canOpen) return;

        if (state.type === "image") handleOpenLightbox();
    };

    const handleOpenLightbox = () => {
        dispatch("common/set/lightboximages", [state.url]);
        dispatch("common/toggle/lightbox", true);
    };

    const getFileIcon = (): SvgIconComponent => {
        return FileIcons[state.type] || InsertDriveFile;
    };

    const fileIdentityDetails = {
        type: state.type,
        ext: state.extension,
        url: state.url,
        canOpen: state.canOpen,
        getFileIcon,
        handleOpen,
    };

    return fileIdentityDetails;
};

export default useFileIdentity;
