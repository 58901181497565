import { CommonFormProps } from "@interfaces/System/FormProps";
import { Form, Formik } from "formik";
import * as React from "react";
import { TypeBindSchema } from "@schema/Settings/index";
import { ValueTypeBind } from "@interfaces/ValueTypeBind";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import InputTypeSelect from "@components/Controls/Selects/InputTypeSelect";
import { ValueType } from "@interfaces/ValueType";
import { vTypesService } from "@services";

const ValueTypeBindForm = React.forwardRef(
    ({ onSubmit, data }: CommonFormProps<ValueTypeBind>, ref: any) => {
        const initialData = {
            type_id: data?.type_id ?? null,
            type_name: data?.type_name ?? "",
        } as ValueTypeBind;

        return (
            <Formik
                innerRef={ref}
                initialValues={initialData}
                validationSchema={TypeBindSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, setFieldValue, handleChange, values }) => {
                    return (
                        <Form>
                            <AsyncAutocomplete<ValueType>
                                fetchFn={vTypesService.getValueTypes}
                                value={values.type_id}
                                onChange={(name, value) => setFieldValue(name, value?.id || null)}
                                optionField="name"
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    variant: "outlined",
                                    size: "small",
                                    name: "type_id",
                                    margin: "normal",
                                    label: "Тип значения",
                                    helperText: touched.type_id ? errors.type_id : "",
                                    error: touched.type_id && !!errors.type_id,
                                }}
                            />

                            <InputTypeSelect
                                value={values.type_name}
                                onChange={handleChange}
                                name="type_name"
                                fullWidth
                                required
                                margin="normal"
                                size="small"
                                variant="outlined"
                                label="Тип поля"
                                helperText={touched.type_id ? errors.type_id : ""}
                                error={touched.type_id && !!errors.type_id}
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default ValueTypeBindForm;
