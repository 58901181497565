import { StoreonModule } from "storeon";
import { REQUIRED_TYPE, REQUIRED_TYPES } from "@modules/ValidationManager";
import { AppEntityTypeItem } from "@interfaces/Internal/AppEntityTypeItem";
import { EntityTypeItem } from "@interfaces/EntityTypeItem";
import shortid from "shortid";

export interface ETypeState {
    etypeId: number | null;
    attrsCount: number;
    items: AppEntityTypeItem[] | null;
    itemsToRemove: number[];
}

export interface ETypeEvents {
    "etype/set/attrs_count": number;
    "etype/set/etypeid": number | null;
    "etype/set/items": EntityTypeItem[];
    "etype/reset/items";
    "etype/add/item";
    "etype/update/item": {
        itemIndex: number;
        item: AppEntityTypeItem;
    };
    /** remove item by item index */
    "etype/remove/item": number;
    "etype/add/items_to_remove": number;
    "etype/reset/items_to_remove";
    "etype/swap/item": {
        itemIndex: number;
        pos: "up" | "down";
    };
}

export const eTypeModule: StoreonModule<ETypeState, ETypeEvents> = (store) => {
    store.on("@init", () => ({
        etypeId: null,
        attrsCount: 0,
        items: null,
        itemsToRemove: [],
    }));

    store.on("etype/add/items_to_remove", (state, itemId) => ({
        itemsToRemove: [...state.itemsToRemove, itemId],
    }));

    store.on("etype/set/attrs_count", (state, attrsCount) => ({
        attrsCount,
    }));

    store.on("etype/reset/items_to_remove", () => ({
        itemsToRemove: [],
    }));

    store.on("etype/set/etypeid", (state, value) => ({ etypeId: value }));
    store.on("etype/set/items", (state, items) => {
        const appEtypeItems: AppEntityTypeItem[] = items.map((el) => ({
            key: shortid.generate(),
            id: el.id,
            atype_id: el.atype_id,
            type_id: el.type_id,
            sorting: el.sorting,
            required: el.required,
        }));
        return {
            items: appEtypeItems,
        };
    });
    store.on("etype/reset/items", (state, items) => ({ items: null }));
    store.on("etype/add/item", (state) => {
        const items = [...(state.items || [])];

        const item: AppEntityTypeItem = {
            key: shortid.generate(),
            atype_id: null,
            sorting: items ? items.length + 1 : 1,
            required: REQUIRED_TYPES.NOT.value as REQUIRED_TYPE,
        };
        if (state.etypeId) {
            item["type_id"] = state.etypeId;
        }
        if (state.etypeId) {
            item["action"] = "add";
        }

        items.push(item);

        return {
            items,
        };
    });

    store.on("etype/update/item", (state, { itemIndex, item }) => {
        const copyArr = [...(state.items || [])];
        copyArr[itemIndex] = item;
        return {
            items: copyArr,
        };
    });
    store.on("etype/remove/item", (state, itemIndex) => {
        const copyArr = [...(state.items || [])];
        copyArr.splice(itemIndex, 1);

        copyArr.map((e, i) => (e.sorting = i + 1));

        return {
            items: copyArr,
        };
    });

    store.on("etype/swap/item", (state, { itemIndex, pos }) => {
        const copyArr = [...(state.items || [])];

        const elem = copyArr[itemIndex];

        if (pos === "down") {
            copyArr[itemIndex] = copyArr[itemIndex + 1];
            copyArr[itemIndex + 1] = elem;
        }

        if (pos === "up") {
            copyArr[itemIndex] = copyArr[itemIndex - 1];
            copyArr[itemIndex - 1] = elem;
        }

        copyArr.forEach((el, index) => {
            copyArr[index].sorting = index + 1;
        });

        return {
            items: copyArr,
        };
    });
};
