import * as Yup from "yup";

export const StaffMenuPermissionSchema = Yup.object().shape({
    rolname: Yup.string().required("Пожалуйста, укажите название"),
    menu_item: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, укажите элемент меню"),
    permission: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, укажите роль"),
});
