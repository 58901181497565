import { AxiosResponse } from "axios";
import { AuthManager } from "@modules";
import { PaginatedQueryResult, QueryResult } from "@services";
import { store } from "@store";
import HttpClient from "@modules/HttpClient";
import { ClassConstructor, plainToClass } from "class-transformer";

interface IService {
    download: (response: AxiosResponse, filename: string, { blobType: string }) => void;
}

interface IServiceHelper {
    toArray: <T>(data: T | T[]) => T[];
    getResponseCorrectArray: <T>(data: PaginatedQueryResult<T> | QueryResult<T[]>) => T[];
    toPaginatedUniversalOutput: <T>(data: PaginatedQueryResult<T>) => PaginatedQueryResult<T>;
    getFirstItemOrNull: <T>(items: T[]) => T | null;
}

abstract class BaseService implements IService, IServiceHelper {
    protected instance: typeof HttpClient.prototype.instance;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly AuthProvider?: typeof AuthManager
    ) {
        this.instance = this.httpClient.instance;

        this.instance.interceptors.request.use((config) => {
            const token = this.AuthProvider?.getToken();
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }

            return config;
        });

        this.instance.interceptors.response.use(
            (response) => {
                const error = response.data?.error;
                if (error) throw new Error(error);
                return response;
            },
            (error) => {
                const {
                    response: { status },
                } = error;

                if (status === 403) this.AuthProvider?.logout(store);

                return Promise.reject(error);
            }
        );
    }

    plainToClass<M, V = unknown>(Model: ClassConstructor<M>, data: V): M {
        return plainToClass<M, V>(Model, data);
    }

    plainToClassArray<M, V = unknown>(Model: ClassConstructor<M>, data: V[]): M[] {
        return plainToClass<M, V>(Model, data);
    }

    public download = (response: AxiosResponse, filename: string, { blobType = "" }): void => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: blobType }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    };

    public toArray = <T>(data: T | T[]): T[] => {
        if (Array.isArray(data)) return data;
        if (!data) return [];
        return [data];
    };

    public getResponseCorrectArray = <T>(data: PaginatedQueryResult<T> | QueryResult<T[]>): T[] => {
        return "items" in data.result ? data.result.items : data.result;
    };

    public toPaginatedUniversalOutput = <T>(
        data: PaginatedQueryResult<T>
    ): PaginatedQueryResult<T> => {
        const output = { ...data };
        const result = output.result;

        if (!result) {
            output.result = {
                items: [],
                count: 0,
                total_pages: 0,
            };
            return output;
        }

        if (!Array.isArray(result.items)) {
            output.result.items = [];
        }

        return output;
    };

    public getFirstItemOrNull = <T>(items: T[]) => {
        return items.length ? items[0] : null;
    };

    public getFileNameFromHeaders = (response: AxiosResponse): string => {
        const contentDisposition = response.headers["content-disposition"];
        const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
        return match[1];
    };
}

export default BaseService;
