import CallModalNewActionButton from "@components/Controls/Actions/CallModalNewActionButton";
import { SearchInput } from "@components/Controls/Inputs";
import { Box, Divider, Grid, IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import { ViewList, ViewModule } from "@material-ui/icons";
import { MODAL_TYPE } from "@modules/ModalManager";
import React from "react";

type Props = {
    directoryTitle: string;
    selectedDirectoryId: number;
    onUpdateFiles(): Promise<any>;
    searchValue: string;
    onChangeSearch(value: string): void;
    view: "list" | "grid";
    onChangeView(): void;
};

const FilesHeader = ({
    directoryTitle,
    selectedDirectoryId,
    onUpdateFiles,
    searchValue,
    onChangeSearch,
    view,
    onChangeView,
}: Props) => {
    return (
        <Paper elevation={1} square>
            <Box mb={2} px={2} py={1}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <SearchInput value={searchValue} onChangeInput={onChangeSearch} />

                    <Typography>
                        <b>{directoryTitle || "Не выбрано"}</b>
                    </Typography>

                    <Box display="flex">
                        <CallModalNewActionButton
                            modalType={MODAL_TYPE.MASS_FILE_UPLOAD}
                            buttonText="Загрузить файлы"
                            modalProps={{
                                selectedDirectoryId,
                                onUpdate: onUpdateFiles,
                            }}
                            buttonProps={{ variant: "text", size: "small" }}
                            iconProps={{ fontSize: "small" }}
                        />
                        <Box mx={1}>
                            <Divider orientation="vertical" />
                        </Box>

                        <Tooltip arrow title="Изменить вид">
                            <IconButton size="small" onClick={onChangeView} color="secondary">
                                {view === "list" && <ViewModule />}
                                {view === "grid" && <ViewList />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
            </Box>
        </Paper>
    );
};

export default FilesHeader;
