import { StoreonModule } from "storeon";
import { AppAttributeTypeItem } from "../interfaces/AttributeTypeItem";
import shortid from "shortid";
import { RestrictionsManager } from "@modules";

export interface ATypeState {
    atypeId: number | null;
    items: AppAttributeTypeItem[];
    isCompositeType: boolean;
    itemsToRemove: number[];
}

export interface ATypeEvents {
    "atype/set/atypeid": number | null;
    "atype/set/items": AppAttributeTypeItem[];
    "atype/reset/items";
    "atype/add/item";
    "atype/update/item": {
        itemIndex: number;
        item: AppAttributeTypeItem;
    };
    /** remove item by item index */
    "atype/remove/item": number;
    "atype/item/toggle/restrictions": { itemIndex: number; toggle: boolean };
    /** adding by restriction code */
    "atype/item/add/restriction": {
        itemIndex: number;
    };
    "atype/swap/item": {
        itemIndex: number;
        pos: "up" | "down";
    };
    "atype/item/update/restriction": {
        itemIndex: number;
        restKey: string;
        code: string;
        value: any;
    };
    "atype/item/remove/restriction": {
        itemIndex: number;
        restKey: string;
    };
    "atype/add/items_to_remove": number;
    "atype/reset/items_to_remove";
    "atype/toggle/composite_type": boolean;
}

export const aTypeModule: StoreonModule<ATypeState, ATypeEvents> = (store) => {
    store.on("@init", () => ({
        atypeId: null,
        items: [],
        isCompositeType: false,
        itemsToRemove: [],
    }));

    store.on("atype/toggle/composite_type", (state, value) => ({
        isCompositeType: value,
    }));

    store.on("atype/add/items_to_remove", (state, itemId) => ({
        itemsToRemove: [...state.itemsToRemove, itemId],
    }));

    store.on("atype/reset/items_to_remove", () => ({
        itemsToRemove: [],
    }));

    store.on("atype/set/atypeid", (state, value) => ({ atypeId: value }));
    store.on("atype/set/items", (state, items) => ({ items }));
    store.on("atype/reset/items", (state, items) => ({ items: [] }));
    store.on("atype/add/item", (state) => {
        const items = [...(state.items || [])];

        const item: AppAttributeTypeItem = {
            key: shortid.generate(),
            vtype_id: null,
            useRestrictions: false,
            restrictions: RestrictionsManager.addCustomRequiredRestrictionToRestrictionsArray([]),
            sorting: items ? items.length + 1 : 1,
            action: state.atypeId ? "add" : "",
        };

        if (state.atypeId) {
            item["type_id"] = state.atypeId;
        }

        items.push(item);

        return {
            items,
        };
    });
    store.on("atype/update/item", (state, { itemIndex, item }) => {
        const copyArr = [...(state.items || [])];
        copyArr[itemIndex] = item;

        return {
            items: copyArr,
        };
    });
    store.on("atype/remove/item", (state, itemIndex) => {
        const copyArr = [...(state.items || [])];
        copyArr.splice(itemIndex, 1);
        copyArr.map((el, i) => (el.sorting = i + 1));
        return {
            items: copyArr,
        };
    });

    store.on("atype/item/toggle/restrictions", (state, { itemIndex, toggle }) => {
        const copyArr = [...(state.items || [])];

        copyArr[itemIndex].useRestrictions = toggle;

        return {
            items: copyArr,
        };
    });

    store.on("atype/item/add/restriction", (state, { itemIndex }) => {
        const copyArr = [...(state.items || [])];

        copyArr[itemIndex].restrictions.push({
            key: shortid.generate(),
            code: "",
            value: null,
        });

        return {
            items: copyArr,
        };
    });

    store.on("atype/item/remove/restriction", (state, { itemIndex, restKey }) => {
        const copyArr = [...(state.items || [])];
        const index = copyArr[itemIndex].restrictions.findIndex((el) => el.key === restKey);
        copyArr[itemIndex].restrictions.splice(index, 1);

        return {
            items: copyArr,
        };
    });

    store.on("atype/item/update/restriction", (state, { itemIndex, restKey, code, value }) => {
        const copyArr = [...(state.items || [])];
        const index = copyArr[itemIndex].restrictions.findIndex((el) => el.key === restKey);

        copyArr[itemIndex].restrictions[index] = {
            key: restKey,
            code,
            value,
        };

        return {
            items: copyArr,
        };
    });

    store.on("atype/swap/item", (state, { itemIndex, pos }) => {
        const copyArr = [...(state.items || [])];

        const elem = copyArr[itemIndex];

        if (pos === "down") {
            copyArr[itemIndex] = copyArr[itemIndex + 1];
            copyArr[itemIndex + 1] = elem;
        }

        if (pos === "up") {
            copyArr[itemIndex] = copyArr[itemIndex - 1];
            copyArr[itemIndex - 1] = elem;
        }

        copyArr.forEach((el, index) => {
            copyArr[index].sorting = index + 1;
        });

        return {
            items: copyArr,
        };
    });
};
