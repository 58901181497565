import { QueryResult, QueryParams, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { StaffMember } from "@interfaces/StaffMember";
import { UpdateStaffProfileDto } from "@dto/StaffDto";
import { ChangeCurrentStaffMemberPasswordDto } from "@dto/ChangePasswordDto";
import { ProtectedPath } from "@interfaces/ProtectedPath";
import { InnBind } from "@interfaces/InnBind";
import { CreateInnBindDto, UpdateInnBindDto } from "@dto/InnBind";

class StaffProfileService extends BaseService {
    public getCurrentStaffMember = async (
        params: QueryParams = {}
    ): Promise<QueryResult<StaffMember>> => {
        const { data } = await this.instance.get("/staff_profile", {
            params,
        });

        return data.data;
    };

    public updateCurrentStaffMember = async (
        id: number,
        updateStaffProfileDto: UpdateStaffProfileDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/staff_profile", {
            id,
            data: updateStaffProfileDto,
        });

        return data;
    };

    public changeCurrentStaffMemberPassword = async (
        changeCurrentStaffMemberPasswordDto: ChangeCurrentStaffMemberPasswordDto
    ): Promise<QueryResult<any>> => {
        const { data } = await this.instance.post(
            "/staff_profile/change_password",
            changeCurrentStaffMemberPasswordDto
        );

        return data;
    };

    public getProtectedPaths = async (): Promise<ProtectedPath[]> => {
        try {
            const { data } = await this.instance.get("/staff_profile/menu");

            return data.data;
        } catch (err) {
            return [] as ProtectedPath[];
        }
    };

    public setStaffProfileBlock = async (staffMemberId: number) => {
        const { data } = await this.instance.post("/staff_profile/block", {
            id: staffMemberId,
        });

        return data;
    };

    public unsetStaffProfileBlock = async (staffMemberId: number) => {
        const { data } = await this.instance.delete("/staff_profile/block", {
            data: {
                id: staffMemberId,
            },
        });

        return data;
    };

    public getOneInnBind = async (staffMemberId: number): Promise<InnBind | null> => {
        const { data } = await this.instance.get("/staff_profile/inn_binding", {
            params: { staff_id: staffMemberId },
        });

        return this.getFirstItemOrNull<InnBind>(
            this.toPaginatedUniversalOutput<InnBind>(data).result.items
        );
    };

    public createInnBind = async (
        createInnBindDto: CreateInnBindDto
    ): Promise<QueryResult<InnBind>> => {
        const { data } = await this.instance.post("/staff_profile/inn_binding", createInnBindDto);

        return data;
    };

    public updateInnBind = async (
        id: number,
        updateInnBindDto: UpdateInnBindDto
    ): Promise<QueryResult<QueryResultWithoutData>> => {
        const { data } = await this.instance.patch("/staff_profile/inn_binding", {
            id,
            data: updateInnBindDto,
        });

        return data;
    };

    public removeInnBinds = async (ids: number[]): Promise<QueryResult<QueryResultWithoutData>> => {
        const { data } = await this.instance.delete("/staff_profile/inn_binding", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new StaffProfileService(new HttpClient(Config.API_URL), AuthManager);
