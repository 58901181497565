import { EnhancedContentLayout, EnhancedTabs } from "@components/Common/Content";
import { Typography } from "@material-ui/core";
import { PAGE_TYPE } from "@modules/PageTypesManager";
import CombinedContentTypesManager from "@modules/ContentTypesManagers/CombinedContentTypesManager";
import { CommonEvents, CommonState } from "@store/common-module";
import { useStoreon } from "storeon/react";
import React from "react";
import { ContentType } from "@modules/ContentTypesManagers/ContentTypesManager";
import { MultipleContentType } from "@modules/ContentTypesManagers/MultipleContentTypesManager";

const HomeContent = () => {
    const { menu } = useStoreon<CommonState, CommonEvents>("menu");

    const firstMenuItem = (menu.length > 0 && menu[0]) || undefined;

    const contentType =
        firstMenuItem &&
        firstMenuItem.content_type &&
        CombinedContentTypesManager.getContentType(firstMenuItem.content_type);

    const DefaultContent = <Typography>К сожалению, нам нечего вам показать :(</Typography>;

    if (!contentType || !firstMenuItem) return DefaultContent;

    switch (firstMenuItem.page_type) {
        case PAGE_TYPE.CONTENT:
            return <EnhancedContentLayout contentType={(contentType as ContentType).type} />;
        case PAGE_TYPE.MULTIPLE_CONTENT:
            return <EnhancedTabs contentType={(contentType as MultipleContentType).type} />;
        default:
            return DefaultContent;
    }
};

export default HomeContent;
