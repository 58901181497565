import { makeStyles } from "@material-ui/core/styles";
import React, { CSSProperties } from "react";
import { ZoomIn } from "@material-ui/icons";
import clsx from "clsx";
import useFileIdentity from "@hooks/useFileIdentity";
import { Box, SvgIconProps } from "@material-ui/core";
import Image from "material-ui-image";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 10,
        position: "relative",
        height: "100%",
        "&:hover $icon": {
            visibility: "visible",
        },
        overflow: "hidden",
    },
    icon: {
        visibility: "hidden",
        transition: theme.transitions.create(["padding"], {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.easeOut,
        }),
        padding: 4,
        backgroundColor: "#50505040",
        borderRadius: "50%",
        color: theme.palette.common.white,
        fontSize: 50,
        position: "absolute",
        cursor: "pointer",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "&:hover": {
            padding: 0,
        },
    },
    image: {
        backgroundImage: `linear-gradient( 45deg,${theme.palette.grey[400]} 25%, transparent 25%, transparent 74%, ${theme.palette.grey[400]} 75%, ${theme.palette.grey[400]}), 
        linear-gradient( 45deg,${theme.palette.grey[400]} 25%, ${theme.palette.grey[600]} 25%, ${theme.palette.grey[600]} 74%, ${theme.palette.grey[400]} 75%, ${theme.palette.grey[400]})`,
        backgroundSize: "16px 16px",
        backgroundPosition: "0 0, 8px 8px",
    },
    baseImage: {
        transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        height: "100%",
        minWidth: "100%",
        minHeight: "auto",
        margin: "auto",
        display: "block",
        width: "100%",
    },
    containerImageHover: {
        "&:hover $image": {
            filter: "brightness(.8)",
        },
    },
}));

type Props = {
    file: string | Blob;
    containerStyle?: CSSProperties;
    imageStyle?: CSSProperties;
    lightBox?: boolean;
};

const FilePreview = ({ file, containerStyle, imageStyle, lightBox = true }: Props) => {
    const classes = useStyles();

    const { type, handleOpen, getFileIcon, url } = useFileIdentity(file);

    const isImage = type === "image";

    const Icon = getFileIcon();
    const iconProps: SvgIconProps = {
        className: classes.baseImage,
        color: "disabled",
    };

    return (
        <Box
            className={clsx(classes.container, {
                [classes.containerImageHover]: isImage && lightBox,
            })}
            style={containerStyle}
        >
            {isImage && (
                <>
                    <Image
                        src={url}
                        className={clsx(classes.baseImage, classes.image)}
                        alt="img preview"
                        color="#ffffff00"
                        animationDuration={2000}
                        imageStyle={{
                            width: "100%",
                            height: "auto",
                            ...imageStyle,
                        }}
                        style={{
                            position: "initial",
                        }}
                    />
                    {lightBox && <ZoomIn className={classes.icon} onClick={handleOpen} />}
                </>
            )}

            {!isImage && <Icon {...iconProps} />}
        </Box>
    );
};

export default FilePreview;
