import { RestrictionsManager } from "@modules";
import { Transform, Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateAttributeTypeItemDto {
    @Expose()
    readonly type_id: number;

    @Expose()
    readonly vtype_id: number;

    @Expose()
    readonly sorting: number;

    @Expose()
    @Transform(({ obj, value }) =>
        obj.useRestrictions ? RestrictionsManager.getRestrictionsObjectFromArray(value) : undefined
    )
    readonly restrictions?: any;
}
