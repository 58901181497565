import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateCartItemDto {
    @Expose()
    readonly cart_id: number;

    @Expose()
    readonly sku: string;

    @Expose()
    readonly quantity: number;
}
