import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateQuantityDto {
    @Expose()
    readonly sku: number;

    @Expose()
    readonly store_id: number;

    @Expose()
    readonly quantity: number;
}
