import { makeStyles } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import { PermissionManager } from "@modules";
import CallModalNewActionButton from "@components/Controls/Actions/CallModalNewActionButton";
import { useContentTitle, useCurrentPageInfo, useTable } from "@hooks";
import { ExportEntitiesMenu } from "@components/Controls/Menu";
import ImportDataButton from "@components/Controls/Buttons/ImportDataButton";
import { IMPORT_DATA_TYPE } from "@hooks/useImportDataControls";
import MoreActionsEntitiesButton from "@components/Controls/Actions/MoreActionsEntitiesButton";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    root: {
        "& > *:nth-child(n):not(:last-child)": {
            margin: spacing(0, 1),
        },
        [breakpoints.down("sm")]: {
            margin: spacing(2, 0, 0, 0),
            "& > *": {
                margin: spacing(0, 0, 1, 0),
            },
        },
    },
}));

const EntitiesToolbar: FunctionComponent = () => {
    const classes = useStyles();
    const { permission, content_type, param } = useCurrentPageInfo();
    const { filters } = useTable();

    const { contentTitle } = useContentTitle(content_type, param);

    if (!PermissionManager.canCreate(permission)) return null;

    return (
        <div className={classes.root}>
            <ExportEntitiesMenu
                entityName={contentTitle}
                entityTypeId={+param}
                entityFilters={filters}
            />
            <ImportDataButton
                importType={IMPORT_DATA_TYPE.NEW_ENTITIES}
                otherModalProps={{ entityTypeId: +param }}
            />
            <CallModalNewActionButton
                buttonText="Добавить новую сущность"
                modalType="ADD_NEW_ENTITY_BY_ENTITY_TYPE"
                modalProps={{ titleProps: +param, entityTypeId: +param }}
            />
            <MoreActionsEntitiesButton />
        </div>
    );
};

export default EntitiesToolbar;
