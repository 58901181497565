import { Button, Card, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { RestrictionsManager } from "@modules";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { AppAttributeTypeItem } from "@interfaces/AttributeTypeItem";
import { ValueType } from "@interfaces/ValueType";
import { ATypeEvents, ATypeState } from "@store/atype-module";
import AttributeTypeItemRestrictions from "./AttributeTypeItemRestrictions";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { vTypesService } from "@services";

const useStyles = makeStyles(() => ({
    card: {
        margin: "1rem 0",
        padding: ".5rem 1rem",
    },
}));

interface Props {
    itemIndex: number;
    itemsLength: number;
    item: AppAttributeTypeItem;
}

const AttributeTypeCard = ({ itemIndex, itemsLength, item }: Props) => {
    const classes = useStyles();

    const { enableValidation, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("enableValidation");
    const { items, isCompositeType, dispatch } = useStoreon<ATypeState, ATypeEvents>(
        "items",
        "isCompositeType"
    );

    const handleDeleteItem = () => {
        if (item.id) {
            dispatch("atype/add/items_to_remove", item.id);
        }
        dispatch("atype/remove/item", itemIndex);
    };

    const handleChangeValueType = (value: ValueType | null) => {
        dispatch("atype/update/item", {
            itemIndex,
            item: {
                ...item,
                vtype_id: value?.id || null,
            },
        });
    };

    const handleSwapItem = (position: "up" | "down") => {
        dispatch("atype/swap/item", {
            itemIndex: itemIndex,
            pos: position,
        });
    };

    const customRequiredValidation = RestrictionsManager.getRestrictionItemByCode("required");
    const valid = !!customRequiredValidation?.validate(item.vtype_id, true);
    const helpText = customRequiredValidation?.getHelpText();

    useEffect(() => {
        validationDispatch("validation/add/validations", {
            [item.key]: valid,
        });
    }, [validationDispatch, item, valid]);

    return (
        <Card className={classes.card} component="li" elevation={2}>
            {isCompositeType && (
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Tooltip title="Переместить вверх" arrow>
                            <span>
                                <IconButton
                                    size="small"
                                    disabled={itemIndex === 0}
                                    onClick={() => handleSwapItem("up")}
                                >
                                    <ArrowUpward />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Переместить вниз" arrow>
                            <span>
                                <IconButton
                                    size="small"
                                    disabled={itemsLength - 1 === itemIndex}
                                    onClick={() => handleSwapItem("down")}
                                >
                                    <ArrowDownward />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Button
                        size="small"
                        onClick={handleDeleteItem}
                        color="secondary"
                        disabled={Array.isArray(items) && items.length <= 1}
                    >
                        Удалить
                    </Button>
                </Grid>
            )}

            <Grid item xs={12}>
                <AsyncAutocomplete<ValueType>
                    fetchFn={vTypesService.getValueTypes}
                    value={item.vtype_id}
                    onChange={(name, value) => {
                        handleChangeValueType(value);
                    }}
                    optionField="name"
                    textFieldProps={{
                        name: "vtype_id",
                        margin: "normal",
                        label: "Тип значения",
                        variant: "outlined",
                        size: "small",
                        required: true,
                        error: enableValidation && !valid,
                        helperText: (
                            <Typography
                                variant="caption"
                                display="block"
                                color={enableValidation && !valid ? "error" : "textSecondary"}
                            >
                                {helpText}
                            </Typography>
                        ),
                    }}
                />

                <AttributeTypeItemRestrictions aTypeItemIndex={itemIndex} />
            </Grid>
        </Card>
    );
};

export default AttributeTypeCard;
