import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";

const ConfirmationDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { onOk, content } = modalProps;

    const handleOk = async () => {
        try {
            await onOk();
            onClose();
        } catch (err) {
            throw err;
        }
    };

    return (
        <>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button color="primary" variant="contained" onClick={handleOk}>
                    Подтвердить
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmationDialogContext;
