import * as Yup from "yup";
import { BaseOrderSchema } from "./BaseOrderSchema";

export const CreateOrderSchema = BaseOrderSchema.shape({
    cart_item_ids: Yup.array()
        .of(
            Yup.number().nullable("Выберите хотя бы 1 элемент корзины")
            // .transform(
            //     (value) => (isNaN(value) ? undefined : value),
            //     "Выберите хотя бы 1 элемент корзины"
            // )
            // .required("Выберите элемент корзины")
        )
        .compact((v) => v === null)
        .min(1, "Выберите как минимум 1 элемент корзины"),
});
