import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateEShopDto {
    @Expose()
    readonly url: string;

    @Expose()
    readonly title: string;

    @Expose()
    readonly last_number: number;

    @Expose()
    readonly cart_number_prefix: string;

    @Expose()
    readonly is_active: boolean;
}
