import { CommonFormProps } from "@interfaces/System/FormProps";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { TextField } from "@material-ui/core";
import { File } from "@interfaces/File";
import { AutoSizeTextField } from "@components/Controls/Inputs";

const FileForm = forwardRef(({ onSubmit, data }: CommonFormProps<File>, ref: any) => {
    const initialData = {
        name: data?.name ?? "",
        description: data?.description ?? "",
    } as File;

    return (
        <Formik initialValues={initialData} innerRef={ref} onSubmit={onSubmit} validateOnMount>
            {({ touched, errors, handleChange, values }) => {
                return (
                    <Form>
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="name"
                            label="Название"
                            inputProps={{ maxLength: 64 }}
                        />

                        <AutoSizeTextField
                            fullWidth
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="description"
                            label="Описание"
                            rows={2}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default FileForm;
