import * as Yup from "yup";

export const StaffOrderSchema = Yup.object().shape({
    collection_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, выберите коллекцию"),
    brand_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, выберите бренд"),
});
