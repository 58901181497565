import { List } from "@material-ui/core";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { aTypesService } from "@services";
import { ATypeEvents, ATypeState } from "@store/atype-module";
import AttributeTypeCard from "./AtrributeTypeCard/AttributeTypeCard";
import { ModalContentLoading } from "../../Common/Modal";
import RestrictionsManager from "@modules/RestrictionsManager";
import { useAsync } from "@hooks";

const AttributeItemsList = () => {
    const { atypeId, items, dispatch } = useStoreon<ATypeState, ATypeEvents>("atypeId", "items");

    const { loadingStatus } = useAsync(aTypesService.getAttributeTypesItems, {
        functionArgs: [{ type_id: atypeId }],
        canLoad: !!atypeId,
        fetchCallback: (value) => {
            const items = RestrictionsManager.getAppAttributeTypeItems(value.result.items || [], {
                useRequiredRestriction: true,
            });

            if (items.length > 0) {
                dispatch("atype/set/items", items);
            } else {
                dispatch("atype/add/item");
            }
        },
    });

    useEffect(() => {
        if (!atypeId) {
            dispatch("atype/add/item");
        }
    }, [dispatch, atypeId]);

    if (loadingStatus === "loading") return <ModalContentLoading />;

    return (
        <List disablePadding>
            {items?.map((item, index) => {
                return (
                    <AttributeTypeCard
                        itemsLength={items.length}
                        key={item.key}
                        itemIndex={index}
                        item={item}
                    />
                );
            })}
        </List>
    );
};

export default AttributeItemsList;
