import { Button, DialogActions, DialogContent, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { UpdateAttributeTypeDto } from "@dto/AttributeTypeDto/UpdateAttributeTypeDto";
import { CreateAttributeTypeItemDto } from "@dto/AttributeTypeItemDto";
import { AppAttributeTypeItem } from "@interfaces/AttributeTypeItem";
import { ServiceHelper, ValidationManager } from "@modules";
import { aTypesService } from "@services";
import { ATypeEvents, ATypeState } from "@store/atype-module";
import { ModalContentLoading } from "../../Common/Modal";
import AttributeTypeForm from "../../Forms/AttributeTypeForm";
import AttributeItemsActions from "../../Views/AttributeTypeItems/AttributeItemsActions";
import AttributeItemsList from "../../Views/AttributeTypeItems/AttributeItemsList";
import withCloseListener from "@hoc/withCloseListener";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import { useAsync, useLoading, useTable } from "@hooks";
import { Subtitle } from "@components/Views/Title";

const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
        height: "100vh",
        [breakpoints.up("md")]: {
            overflowY: "hidden",
        },
    },
    content: {
        height: "100%",
    },
    list: {
        height: "100%",
        overflowY: "auto",
        padding: "0 .1rem",
        paddingBottom: "1rem",
    },
}));

const EditAttributeTypeDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = modalProps;
    const classes = useStyles();

    const formRef = useRef<any>();
    const { updateData } = useTable();
    const { items, itemsToRemove, isCompositeType, dispatch } = useStoreon<ATypeState, ATypeEvents>(
        "items",
        "itemsToRemove",
        "isCompositeType"
    );
    const { validations, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("validations");
    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const invokeSubmit = () => {
        validationDispatch("validation/toggle/validation", true);
        formRef.current.handleSubmit();
    };

    const { data: aType, loadingStatus } = useAsync(aTypesService.getAttributeType, {
        functionArgs: [itemId],
        canLoad: !!itemId,
        fetchCallback: (value) => {
            if (!value) return;
            dispatch("atype/set/atypeid", value.id);
            dispatch("atype/toggle/composite_type", value.composite_choice);
        },
    });

    useEffect(() => {
        return () => {
            aTypesService.unsetAttributeTypeBlock(Number(modalProps.itemId));
            dispatch("atype/reset/items");
            dispatch("atype/reset/items_to_remove");
            dispatch("atype/set/atypeid", null);
            dispatch("atype/toggle/composite_type", false);
            validationDispatch("validation/toggle/validation", false);
            validationDispatch("validation/set/validations", {});
        };
    }, [dispatch, validationDispatch, modalProps]);

    const handleUpdateAttributeType = async (values) => {
        if (!itemId) return;

        if (!isCompositeType && items?.length > 1) {
            enqueueSnackbar("Только составной тип может содержать более 1 типа значений", {
                variant: "error",
            });
            return;
        }

        if (!ValidationManager.isFinalObjectValid(validations)) return;

        setSubmitLoadingStatus("loading");
        try {
            if (itemsToRemove.length) {
                await aTypesService.deleteAttributeTypeItem(itemsToRemove);
            }

            const itemsToCreate = ServiceHelper.getItemsForCreate<AppAttributeTypeItem>(
                items || []
            );
            if (itemsToCreate.length) {
                const itemsToCreateDto = itemsToCreate.map((el) =>
                    aTypesService.plainToClass(CreateAttributeTypeItemDto, el)
                );

                await aTypesService.createAttributeTypeItems(itemsToCreateDto);
            }

            const dto = aTypesService.plainToClass(
                UpdateAttributeTypeDto,

                { ...values, items: items?.filter((el) => el.id) as AppAttributeTypeItem[] }
            );

            await aTypesService.updateAttributeType(itemId, dto);
            enqueueSnackbar("Тип атрибута успешно обновлен", {
                variant: "success",
            });

            onClose();
            updateData();
        } catch (err) {
            setSubmitLoadingStatus("loaded");
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении типа атрибута", {
                variant: "error",
            });
        }
    };

    const handleChangeCompositeType = (value: boolean) => {
        dispatch("atype/toggle/composite_type", value);
    };

    return (
        <>
            <DialogContent className={classes.root}>
                {loadingStatus === "loading" && <ModalContentLoading />}
                {loadingStatus === "loaded" && aType && (
                    <Grid container spacing={2} className={classes.content}>
                        <Grid item xs={12} md={4}>
                            <Subtitle title="Общее" titleVariant="h6" bold />
                            <AttributeTypeForm
                                onSubmit={handleUpdateAttributeType}
                                attributeType={aType}
                                ref={formRef}
                                onChangeCompositeType={handleChangeCompositeType}
                            />
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.content}>
                            <Subtitle
                                title="Типы значений"
                                titleVariant="h6"
                                bold
                                endAdornment={<AttributeItemsActions />}
                            />
                            <Box className={classes.list}>
                                <AttributeItemsList />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default withCloseListener(EditAttributeTypeDialogContext);
