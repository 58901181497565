import { Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { Form, Formik, FormikConfig } from "formik";
import React, { forwardRef } from "react";
import { StaffMember } from "@interfaces/StaffMember";
import { PhoneNumberInput } from "../../Controls/Inputs";
import { Subtitle } from "../../Views/Title";
import { UDatePicker } from "../../Controls/Universal";
import { UpdateStaffMemberSchema } from "../../../schema/Staff";
import { UpdateFormProps } from "@interfaces/System/FormProps";

type UpdateStaffMember = Omit<
    StaffMember,
    | "id"
    | "password"
    | "login"
    | "sub_role"
    | "is_superuser"
    | "memberof"
    | "created_at"
    | "updated_at"
>;

type UpdateStaffMemberFormProps = Omit<UpdateFormProps<StaffMember>, "onSubmit"> &
    Pick<FormikConfig<UpdateStaffMember>, "onSubmit">;

const UpdateStaffMemberForm = forwardRef(
    ({ onSubmit, data }: UpdateStaffMemberFormProps, ref: any) => {
        const initialData = {
            email: data?.email ?? "",
            is_active: data?.is_active ?? false,
            first_name: data?.first_name ?? "",
            last_name: data?.last_name ?? "",
            middle_name: data?.middle_name ?? "",
            phone: data?.phone ?? "",
            department: data?.department ?? "",
            work_phone: data?.work_phone ?? "",
            additional_phone: data?.additional_phone ?? "",
            occupation: data?.occupation ?? "",
            birthday: data?.birthday ?? null,
            join_date: data?.join_date ?? null,
            dismiss_date: data?.dismiss_date ?? null,
        };

        return (
            <Formik<UpdateStaffMember>
                initialValues={initialData}
                innerRef={ref}
                validationSchema={UpdateStaffMemberSchema}
                onSubmit={(values, helpers) => {
                    const copy = { ...values };
                    copy.first_name = copy.first_name.trim();
                    copy.last_name = copy.last_name.trim();
                    copy.middle_name = copy.middle_name.trim();
                    copy.occupation = copy.occupation.trim();
                    copy.department = copy.department.trim();

                    onSubmit(copy, helpers);
                }}
                validateOnMount
            >
                {({ touched, errors, handleChange, values, setFieldValue }) => {
                    return (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <Subtitle title="Основное" bold />

                                    <TextField
                                        name="login"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        required
                                        label="Логин"
                                        value={data?.login}
                                        onChange={() => {}}
                                        fullWidth
                                        disabled
                                        helperText="Невозможно изменить"
                                    />

                                    <TextField
                                        name="email"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        required
                                        helperText={touched.email ? errors.email : ""}
                                        error={touched.email && Boolean(errors.email)}
                                        label="Электронная почта"
                                        value={values.email}
                                        onChange={handleChange}
                                        fullWidth
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.is_active}
                                                onChange={handleChange}
                                                name="is_active"
                                                color="primary"
                                            />
                                        }
                                        label="Активен"
                                    />

                                    <Subtitle title="Личная информация" bold />

                                    <TextField
                                        name="last_name"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        required
                                        helperText={touched.last_name ? errors.last_name : ""}
                                        error={touched.last_name && Boolean(errors.last_name)}
                                        label="Фамилия"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        fullWidth
                                    />

                                    <TextField
                                        name="first_name"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        required
                                        helperText={touched.first_name ? errors.first_name : ""}
                                        error={touched.first_name && Boolean(errors.first_name)}
                                        label="Имя"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        fullWidth
                                    />

                                    <TextField
                                        name="middle_name"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        required
                                        helperText={touched.middle_name ? errors.middle_name : ""}
                                        error={touched.middle_name && Boolean(errors.middle_name)}
                                        label="Отчество"
                                        value={values.middle_name}
                                        onChange={handleChange}
                                        fullWidth
                                    />

                                    <UDatePicker
                                        name="birthday"
                                        size="small"
                                        required
                                        variant="outlined"
                                        margin="normal"
                                        helperText={touched.birthday ? errors.birthday : ""}
                                        error={touched.birthday && Boolean(errors.birthday)}
                                        label="Дата рождения"
                                        pickerProps={{ maxDate: new Date() }}
                                        value={values.birthday}
                                        onChange={(name, value) => setFieldValue(name, value || "")}
                                        fullWidth
                                    />

                                    <PhoneNumberInput
                                        name="phone"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        helperText={touched.phone ? errors.phone : ""}
                                        error={touched.phone && Boolean(errors.phone)}
                                        label="Номер телефона"
                                        value={values.phone}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <Subtitle title="Рабочие данные" bold />

                                    <TextField
                                        name="occupation"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        helperText={touched.occupation ? errors.occupation : ""}
                                        error={touched.occupation && Boolean(errors.occupation)}
                                        label="Должность"
                                        value={values.occupation}
                                        onChange={handleChange}
                                        fullWidth
                                    />

                                    <TextField
                                        name="department"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        helperText={touched.department ? errors.department : ""}
                                        error={touched.department && Boolean(errors.department)}
                                        label="Отдел"
                                        value={values.department}
                                        onChange={handleChange}
                                        fullWidth
                                    />

                                    <PhoneNumberInput
                                        name="work_phone"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        helperText={touched.work_phone ? errors.work_phone : ""}
                                        error={touched.work_phone && Boolean(errors.work_phone)}
                                        label="Рабочий номер телефона"
                                        value={values.work_phone}
                                        onChange={handleChange}
                                        fullWidth
                                    />

                                    <PhoneNumberInput
                                        name="additional_phone"
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        helperText={
                                            touched.additional_phone ? errors.additional_phone : ""
                                        }
                                        error={
                                            touched.additional_phone &&
                                            Boolean(errors.additional_phone)
                                        }
                                        label="Доп. номер телефона"
                                        value={values.additional_phone}
                                        onChange={handleChange}
                                        fullWidth
                                    />

                                    <UDatePicker
                                        name="join_date"
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        helperText={touched.join_date ? errors.join_date : ""}
                                        error={touched.join_date && Boolean(errors.join_date)}
                                        label="Дата приема на работу"
                                        pickerProps={{ maxDate: new Date() }}
                                        value={values.join_date}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        fullWidth
                                    />

                                    <UDatePicker
                                        name="dismiss_date"
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        helperText={touched.dismiss_date ? errors.dismiss_date : ""}
                                        error={touched.dismiss_date && Boolean(errors.dismiss_date)}
                                        label="Дата увольнения"
                                        pickerProps={{ maxDate: new Date() }}
                                        value={values.dismiss_date}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default UpdateStaffMemberForm;
