import { Tooltip } from "@material-ui/core";
import React, { PropsWithChildren } from "react";

type Props = { title: string; maxLen: number };

const TitleTooltip = ({ title, maxLen, children }: PropsWithChildren<Props>) => (
    <Tooltip arrow title={title} disableHoverListener={title.length <= maxLen}>
        {children as JSX.Element}
    </Tooltip>
);

export default TitleTooltip;
