import { List } from "@material-ui/core";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { eTypesService } from "@services";
import { ETypeEvents, ETypeState } from "@store/etype-module";
import { ModalContentLoading } from "../../Common/Modal";
import EntityTypeCard from "./EntityTypeCard/EntityTypeCard";
import { useAsync } from "@hooks";

type Props = { etypeId?: number };

const EntityItemsList = ({ etypeId }: Props) => {
    const { items, dispatch } = useStoreon<ETypeState, ETypeEvents>("items");

    const { loadingStatus } = useAsync(eTypesService.getEntityTypesItems, {
        functionArgs: [{ type_id: etypeId }],
        canLoad: !!etypeId,
        fetchCallback: (value) => {
            dispatch("etype/set/attrs_count", value.result.count);
            dispatch("etype/set/items", value.result.items);
            dispatch("etype/set/etypeid", etypeId);
        },
    });

    useEffect(() => {
        if (!etypeId) {
            dispatch("etype/add/item");
        }
    }, [etypeId, dispatch]);

    if (loadingStatus === "loading") return <ModalContentLoading />;

    return (
        <List disablePadding>
            {items?.map((item, index) => {
                return (
                    <EntityTypeCard
                        key={item.key}
                        itemIndex={index}
                        itemsLength={items.length}
                        selectedAtypes={items
                            .map((i) => i.atype_id)
                            .filter((el): el is number => typeof el === "number")}
                        item={item}
                    />
                );
            })}
        </List>
    );
};

export default EntityItemsList;
