import { CommonEvents, CommonState } from "@store/common-module";
import { useStoreon } from "storeon/react";
import React, { useCallback, useState } from "react";
import ReactLightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Lightbox = () => {
    const { lightBoxOpen, lightBoxImages, dispatch } = useStoreon<CommonState, CommonEvents>(
        "lightBoxOpen",
        "lightBoxImages"
    );

    const [state, setState] = useState({
        photoIndex: 0,
    });

    const handleClose = useCallback(() => {
        dispatch("common/toggle/lightbox", false);
        dispatch("common/set/lightboximages", []);
    }, [dispatch]);

    if (!lightBoxOpen) return null;

    return (
        <ReactLightbox
            reactModalStyle={{
                overlay: {
                    zIndex: 2000,
                },
            }}
            mainSrc={lightBoxImages[state.photoIndex]}
            nextSrc={
                lightBoxImages.length > 1
                    ? lightBoxImages[(state.photoIndex + 1) % lightBoxImages.length]
                    : ""
            }
            prevSrc={
                lightBoxImages.length > 1
                    ? lightBoxImages[
                          (state.photoIndex + lightBoxImages.length - 1) % lightBoxImages.length
                      ]
                    : ""
            }
            onCloseRequest={handleClose}
            onMovePrevRequest={() =>
                setState((prevState) => ({
                    photoIndex:
                        (prevState.photoIndex + lightBoxImages.length - 1) % lightBoxImages.length,
                }))
            }
            onMoveNextRequest={() =>
                setState((prevState) => ({
                    photoIndex: (prevState.photoIndex + 1) % lightBoxImages.length,
                }))
            }
        />
    );
};

export default Lightbox;
