import { Button, SvgIconProps, ButtonProps } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";

type Props = ButtonProps & {
    text?: string;
    onClick: (e) => void;
    iconProps?: SvgIconProps;
};

const AddNewButton = ({ text = "Добавить новый", onClick, iconProps, ...other }: Props) => {
    return (
        <Button color="primary" onClick={onClick} variant="contained" {...other}>
            <Add style={{ marginRight: 10 }} {...iconProps} /> {text}
        </Button>
    );
};

export default AddNewButton;
