import { QueryResult } from ".";
import BaseService from "./BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";

class AuthService extends BaseService {
    public login = async ({ user, password }): Promise<QueryResult<{ token: string }>> => {
        const { data } = await this.instance.post("/login", {
            user,
            password,
        });

        return data;
    };

    public checkAuthToken = async (): Promise<{ status: boolean }> => {
        const { data } = await this.instance.post("/check_token");

        return data;
    };
}

export default new AuthService(new HttpClient(Config.API_URL), AuthManager);
