import { Brand } from "@interfaces/Brand";
import { TextFieldProps } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useAsync } from "@hooks";
import { staffBrandBindService } from "@services/Staff";
import CustomAsyncAutocomplete from "./CustomAsyncAutocomplete";

interface Props {
    textFieldProps: TextFieldProps;
    onChange: (name, value) => void;
    value: number | null;
    disabledOptions?: number[];
    [key: string]: any;
}

const BrandAutocomplete = ({
    textFieldProps,
    onChange,
    value,
    disabledOptions,
    ...props
}: Props) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [selectedValue, setSelectedValue] = useState<Brand | null>(null);

    const { data, loadingStatus } = useAsync(staffBrandBindService.getStaffBrands, {
        functionArgs: [
            null,
            {
                q: inputValue || undefined,
            },
        ],
        setLoadingStatusOnUpdate: false,
    });

    useEffect(() => {
        if (loadingStatus === "loaded") {
            const item = data?.find((el) => value === el.id);
            setSelectedValue(item || null);
        }
    }, [value, loadingStatus, data]);

    const handleChangeValue = useCallback(
        (e, brand: Brand) => {
            onChange(textFieldProps.name, brand?.id || null);
            setInputValue("");
        },
        [onChange, textFieldProps]
    );

    return (
        <CustomAsyncAutocomplete
            {...props}
            isLoading={loadingStatus === "loading"}
            value={selectedValue || null}
            disabledOptions={disabledOptions}
            items={data || []}
            textFieldProps={textFieldProps}
            optionField="title"
            onChange={handleChangeValue}
            onChangeInput={setInputValue}
        />
    );
};

export default BrandAutocomplete;
