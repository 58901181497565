import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { ModalProps } from "@interfaces/System/ModalProps";
import { Button, DialogActions, DialogContent, Typography } from "@material-ui/core";
import React from "react";

type ViewContentModalProps = ModalProps & {
    content: any;
};

const ViewContentDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { content } = { ...modalProps } as ViewContentModalProps;
    return (
        <>
            <DialogContent>
                <Typography>{content}</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={onClose}>
                    Закрыть
                </Button>
            </DialogActions>
        </>
    );
};

export default ViewContentDialogContext;
