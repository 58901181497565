import { IconButton, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { memo, useMemo } from "react";
import { useStoreon } from "storeon/react";
import { EntityEvents, EntityState } from "@store/entity-module";
import { ValueType } from "@interfaces/ValueType";
import { isObjectsAreEqual } from "@modules/Utils";
import { AppAttribute } from "@interfaces/Internal/AppAttribute";
import { EntityAttributeSchema } from "@interfaces/EntityAttributeSchema";

type EntityAttributeItemTableHeadProps = {
    aTypeId: number;
    vTypes: ValueType[];
    attribute: AppAttribute;
    schema: EntityAttributeSchema[];
};

const EntityAttributeItemTableHead = ({
    aTypeId,
    vTypes,
    attribute,
    schema,
}: EntityAttributeItemTableHeadProps) => {
    const { readOnly, dispatch } = useStoreon<EntityState, EntityEvents>("readOnly");

    const handleAddRow = () => {
        dispatch("entity/attr/add/value", {
            at_id: aTypeId,
        });
    };

    const schemaAttribute = useMemo(
        () => schema.find((el) => el.at_id === attribute.atype_id),
        [schema, attribute]
    );

    return (
        <TableHead>
            <TableRow>
                <TableCell width="40">#</TableCell>
                {schemaAttribute?.items.map((column) => {
                    const vType = vTypes.find((el) => el.id === column.vtype_id);

                    return <TableCell key={column.sorting}>{vType?.name}</TableCell>;
                })}

                <TableCell>Сортировка</TableCell>

                {!readOnly && (
                    <TableCell width="40" aria-label="actions">
                        <Tooltip arrow title="Добавить новую запись">
                            <IconButton size="small" onClick={handleAddRow} color="primary">
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};

export default memo(EntityAttributeItemTableHead, (prev, next) => isObjectsAreEqual(prev, next));
