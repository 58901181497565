import { TextField, TextFieldProps, MenuItem, ListItemText } from "@material-ui/core";
import React from "react";
import useAsync from "../../../hooks/useAsync";
import { staffRoleService } from "@services/Staff";

const ServiceRoleSelect = ({ ...props }: TextFieldProps) => {
    const { data, loadingStatus } = useAsync(staffRoleService.getStaffRoles);

    const options = (loadingStatus === "loaded" && data?.result.items) || [];

    const value = options.find((el) => el.rolname === props?.value)?.rolname;

    return (
        <TextField {...props} select value={value || ""}>
            <MenuItem value="">Выберите значение</MenuItem>
            {options.map((item) => {
                return (
                    <MenuItem
                        key={item.id}
                        value={item.rolname}
                        divider
                        style={{ display: "block" }}
                    >
                        <ListItemText primary={item.shown_name} />
                        <ListItemText secondary={item.description} />
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default ServiceRoleSelect;
