import { AppRestriction } from "@interfaces/Internal/AppRestriction";
import { RestrictionsManager } from "@modules";
import { InputType } from "@modules/InputTypesManager";

interface RestrictionCheckerRenderProps {
    hasEntityRelation: boolean;
    entityRelationTypeId?: number;
}

interface Props {
    restrictions: AppRestriction[];
    render: (inputTypeName: InputType, props: RestrictionCheckerRenderProps) => any;
}

const UniversalRestrictionChecker = ({ restrictions, render }: Props) => {
    const entityTypeRelation = restrictions.find(
        (el) => el.code === RestrictionsManager.RESTRICTIONS.ENTITY_TYPE.code
    );

    const props: RestrictionCheckerRenderProps = {
        hasEntityRelation: !!entityTypeRelation,
        entityRelationTypeId: entityTypeRelation?.value,
    };

    return render(entityTypeRelation?.code as InputType, props);
};

export default UniversalRestrictionChecker;
