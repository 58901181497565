import { TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { BrandDealerBindSchema } from "@schema/StaffOrder";
import { BrandDealerBind } from "@interfaces/BrandDealerBind";
import { AsyncAutocomplete, BrandAutocomplete } from "@components/Controls/Autocomplete";
import { staffService } from "@services/Staff";
import { StaffMember } from "@interfaces/StaffMember";

const BrandDealerBindForm = forwardRef(
    ({ onSubmit, data }: CommonFormProps<BrandDealerBind>, ref: any) => {
        const initialData = {
            dealer_id: data?.dealer_id ?? null,
            brand_id: data?.brand_id ?? null,
            price: data?.price ?? null,
        } as BrandDealerBind;

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={BrandDealerBindSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, values, setFieldValue }) => {
                    return (
                        <Form>
                            <BrandAutocomplete
                                value={values.brand_id}
                                onChange={(name, value) => setFieldValue(name, value || null)}
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    size: "small",
                                    margin: "normal",
                                    variant: "outlined",
                                    name: "brand_id",
                                    label: "Бренд",
                                    helperText: touched.brand_id ? errors.brand_id : "",
                                    error: touched.brand_id && !!errors.brand_id,
                                }}
                            />

                            <AsyncAutocomplete<StaffMember>
                                fetchFn={staffService.getAllStaffMembers}
                                value={values.dealer_id}
                                onChange={(name, value) => setFieldValue(name, value?.id || null)}
                                optionField="login"
                                filter={["memberof", "@>{dealer}"]}
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    size: "small",
                                    margin: "normal",
                                    variant: "outlined",
                                    name: "dealer_id",
                                    label: "Дилер",
                                    helperText: touched.dealer_id ? errors.dealer_id : "",
                                    error: touched.dealer_id && !!errors.dealer_id,
                                }}
                            />

                            <TextField
                                name="price"
                                onChange={handleChange}
                                value={values.price || ""}
                                variant="outlined"
                                type="number"
                                size="small"
                                fullWidth
                                margin="normal"
                                label="Цена"
                                helperText={touched.price ? errors.price : ""}
                                error={touched.price && !!errors.price}
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default BrandDealerBindForm;
