import { TextField, TextFieldProps, MenuItem, ListItemText } from "@material-ui/core";
import React from "react";

const OrderStatusSelect = ({ ...props }: TextFieldProps) => {
    const statuses = [
        {
            code: "new",
            name: "Новый",
        },
        { code: "accepted", name: "Принято" },
        { code: "paid", name: "Оплачено" },
        { code: "packing", name: "Упаковка" },
        { code: "shipping", name: "Доставка" },
        { code: "delivered", name: "Доставлено" },
        { code: "refunded", name: "Возвращено" },
        { code: "closed", name: "Закрыто" },
        { code: "cancelled", name: "Отменено" },
    ];
    return (
        <TextField {...props} value={props.value || ""} select>
            {statuses.map((status) => {
                return (
                    <MenuItem key={status.code} value={status.code}>
                        <ListItemText primary={status.name} />
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default OrderStatusSelect;
