import { Grid, List } from "@material-ui/core";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { useStoreon } from "storeon/react";
import { ContentEvents, ContentState } from "@store/content-module";
import StaffCartListItem from "./StaffCartListItem";
import { useAsync } from "@hooks";
import { staffCartService } from "@services/Staff";
import AddNewButton from "@components/Controls/Buttons/AddNewButton";
import { CreateStaffCartItemDto } from "@dto/StaffCartDto";
import { StaffCart } from "@interfaces/StaffCart";
import { attributeTypeBindService } from "@services/Settings";
import { combineLoadingStatuses } from "@modules/Utils";
import { aTypesService } from "@services";

type StaffCartItemsListProps = {
    cart: StaffCart;
    eTypeId: number;
    entityFilter: Record<string, string>;
};

const StaffCartItemsList = forwardRef(
    ({ cart, eTypeId, entityFilter }: StaffCartItemsListProps, ref: any) => {
        const {
            staffCart: { items, itemsToRemove },
            dispatch,
        } = useStoreon<ContentState, ContentEvents>("staffCart");

        const { loadingStatus: staffCartItemsloadingStatus } = useAsync(
            staffCartService.getStaffCartItems,
            {
                functionArgs: [{ number: cart.number }],
                canLoad: !!cart,
                fetchCallback: (staffCartItems) => {
                    if (staffCartItems.length) {
                        dispatch("content/staff_cart/set/items", staffCartItems);
                    }
                },
            }
        );

        const { data: aTypeBind, loadingStatus: aTypeBindLoadingStatus } = useAsync(
            attributeTypeBindService.getOneATypeBind,
            {
                functionArgs: [undefined, { attribute_type_name: "sku" }],
            }
        );

        const { data: aType, loadingStatus: aTypeLoadingStatus } = useAsync(
            aTypesService.getAttributeType,
            {
                functionArgs: [aTypeBind?.type_id],
                canLoad: !!aTypeBind,
            }
        );

        useEffect(() => {
            return () => {
                dispatch("content/staff_cart/set/items", []);
            };
        }, [dispatch]);

        useImperativeHandle(ref, () => ({
            handleSubmit: async () => {
                const cartItemsToRemove = itemsToRemove
                    .map((el) => el.id)
                    .filter((el): el is number => Number.isInteger(el));

                if (cartItemsToRemove.length) {
                    await staffCartService.removeStaffCartItems(cartItemsToRemove);
                }

                for (const item of items) {
                    if (!item.sku) continue;

                    const dto = staffCartService.plainToClass(CreateStaffCartItemDto, {
                        sku: item.sku,
                        quantity: item.quantity,
                    });
                    await staffCartService.createStaffCartItem(dto);
                }
            },
        }));

        const handleAddCartItem = useCallback(() => {
            dispatch("content/staff_cart/add/items");
        }, [dispatch]);

        const handleChangeValue = useCallback(
            (key: string, field: string, value: any) => {
                dispatch("content/staff_cart/update/items", { key, field, value });
            },
            [dispatch]
        );

        const handleDeleteRole = useCallback(
            (key: string) => {
                dispatch("content/staff_cart/remove/items", key);
            },
            [dispatch]
        );

        const loadingStatus = combineLoadingStatuses(
            staffCartItemsloadingStatus,
            aTypeBindLoadingStatus,
            aTypeLoadingStatus
        );

        return (
            <>
                <Grid container justifyContent="flex-end">
                    <AddNewButton
                        text="Добавить еще"
                        onClick={handleAddCartItem}
                        variant="text"
                        size="small"
                    />
                </Grid>
                <List disablePadding>
                    {loadingStatus === "loaded" &&
                        aTypeBind &&
                        items.map((staffCartItem) => {
                            return (
                                <StaffCartListItem
                                    key={staffCartItem.key}
                                    attributeSkuId={aTypeBind.type_id}
                                    entityFilter={entityFilter}
                                    item={staffCartItem}
                                    eTypeId={eTypeId}
                                    attributeTypeSkuName={aType?.name || ""}
                                    onChange={handleChangeValue}
                                    onDelete={handleDeleteRole}
                                />
                            );
                        })}
                </List>
            </>
        );
    }
);

export default StaffCartItemsList;
