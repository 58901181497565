import { useCurrentPageInfo, useModal } from "@hooks";
import React from "react";
import { orderService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { Order } from "@interfaces/Order";
import { ServiceHelper } from "@modules";
import { useSnackbar } from "notistack";

const OrdersTable = () => {
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_ORDER", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: Order | Order[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await orderService.deleteOrders(ids);
            enqueueSnackbar(
                rowData instanceof Array ? "Заказы успешно удалены" : "Заказ успешно удален",
                {
                    variant: "success",
                }
            );
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(
                message || rowData instanceof Array
                    ? "Произошла ошибка при удалении заказов"
                    : "Произошла ошибка при удалении заказа",
                {
                    variant: "error",
                }
            );
        }
    };

    const columns: DataTableColumn<Order>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            align: "left",
            type: "numeric",
            width: 100,
        },
        {
            title: "Номер заказа",
            field: "number",
            width: "auto",
        },
        {
            title: "Статус",
            field: "status",
            width: "auto",
        },
        {
            title: "Пользователь",
            field: "user_id",
            width: "auto",
        },
        {
            title: "Дата обновления",
            filtering: false,
            field: "updated_date",
            type: "datetime",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={orderService.getOrders}
            columns={columns}
            displayField="number"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            filtering
            sorting
            search
        />
    );
};

export default OrdersTable;
