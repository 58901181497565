import { Button, Grid, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { StaffMember } from "../../interfaces/StaffMember";
import { UpdateCurrentStaffMemberSchema } from "../../schema/Staff/UpdateCurrentStaffMemberSchema";
import { PhoneNumberInput } from "../Controls/Inputs";
import { UDatePicker } from "../Controls/Universal";
import { Subtitle } from "../Views/Title";

interface Props {
    onSubmit: (values) => Promise<void>;
    onEditClick(): void;
    user: StaffMember;
    disabled: boolean;
}

const ProfileForm = forwardRef(({ onSubmit, user, disabled, onEditClick }: Props, ref: any) => {
    const initialData = {
        first_name: user.first_name || "",
        middle_name: user.middle_name || "",
        last_name: user.last_name || "",
        birthday: user.birthday || null,
        email: user.email || "",
        phone: user.phone || "",
        work_phone: user.work_phone || "",
        additional_phone: user.additional_phone || "",
        department: user.department || "",
        occupation: user.occupation || "",
    };
    return (
        <Formik
            initialValues={initialData}
            validationSchema={UpdateCurrentStaffMemberSchema}
            enableReinitialize
            onSubmit={onSubmit}
            innerRef={ref}
        >
            {({ touched, errors, handleChange, values, setFieldValue }) => {
                return (
                    <Form>
                        <Subtitle
                            title="Личная информация"
                            bold
                            icon="account_box"
                            endAdornment={
                                <Button
                                    size="small"
                                    onClick={onEditClick}
                                    disabled={!disabled}
                                    color="primary"
                                >
                                    Редактировать
                                </Button>
                            }
                        />

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="last_name"
                                    variant="outlined"
                                    margin="normal"
                                    value={values.last_name}
                                    helperText={touched.last_name ? errors.last_name : ""}
                                    error={touched.last_name && !!errors.last_name}
                                    required
                                    label="Фамилия"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="first_name"
                                    variant="outlined"
                                    value={values.first_name}
                                    helperText={touched.first_name ? errors.first_name : ""}
                                    error={touched.first_name && !!errors.first_name}
                                    required
                                    margin="normal"
                                    label="Имя"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="middle_name"
                                    variant="outlined"
                                    value={values.middle_name}
                                    helperText={touched.middle_name ? errors.middle_name : ""}
                                    error={touched.middle_name && !!errors.middle_name}
                                    required
                                    margin="normal"
                                    label="Отчество"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    name="email"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    required
                                    helperText={touched.email ? errors.email : ""}
                                    error={touched.email && Boolean(errors.email)}
                                    label="Электронная почта"
                                    value={values.email}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <UDatePicker
                                    name="birthday"
                                    size="small"
                                    variant="outlined"
                                    required
                                    margin="normal"
                                    helperText={touched.birthday ? errors.birthday : ""}
                                    error={touched.birthday && Boolean(errors.birthday)}
                                    label="Дата рождения"
                                    value={values.birthday}
                                    onChange={(name, value) => setFieldValue(name, value)}
                                    fullWidth
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <PhoneNumberInput
                                    name="phone"
                                    variant="outlined"
                                    value={values.phone}
                                    helperText={touched.phone ? errors.phone : ""}
                                    error={touched.phone && !!errors.phone}
                                    margin="normal"
                                    label="Номер телефона"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                    disabled={disabled}
                                />
                            </Grid>
                        </Grid>

                        <Subtitle title="Рабочие данные" bold icon="work" />

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <PhoneNumberInput
                                    name="work_phone"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    helperText={touched.work_phone ? errors.work_phone : ""}
                                    error={touched.work_phone && Boolean(errors.work_phone)}
                                    label="Рабочий номер телефона"
                                    value={values.work_phone}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <PhoneNumberInput
                                    name="additional_phone"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    helperText={
                                        touched.additional_phone ? errors.additional_phone : ""
                                    }
                                    error={
                                        touched.additional_phone && Boolean(errors.additional_phone)
                                    }
                                    label="Доп. номер телефона"
                                    value={values.additional_phone}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="department"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    helperText={
                                        touched.department
                                            ? errors.department
                                            : "Невозможно изменить"
                                    }
                                    error={touched.department && Boolean(errors.department)}
                                    label="Отдел"
                                    value={values.department}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="occupation"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    helperText={
                                        touched.occupation
                                            ? errors.occupation
                                            : "Невозможно изменить"
                                    }
                                    error={touched.occupation && Boolean(errors.occupation)}
                                    label="Должность"
                                    value={values.occupation}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default ProfileForm;
