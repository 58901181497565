import { QueryResult, QueryParams, PaginatedQueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { StaffCartItem } from "@interfaces/StaffCartItem";
import {
    CreateStaffCartDto,
    CreateStaffCartItemDto,
    UpdateStaffCartDto,
    UpdateStaffCartItemDto,
} from "@dto/StaffCartDto";
import { StaffCart } from "@interfaces/StaffCart";

class StaffCartService extends BaseService {
    public getStaffCarts = async (
        params: QueryParams
    ): Promise<PaginatedQueryResult<StaffCart>> => {
        const { data } = await this.instance.get("/staff_cart", {
            params,
        });

        return this.toPaginatedUniversalOutput<StaffCart>(data);
    };

    public getOneStaffCart = async (params: QueryParams): Promise<StaffCart | null> => {
        const { data } = await this.instance.get("/staff_cart", { params });

        return data.result;
    };

    public createStaffCart = async (
        createStaffCartDto: CreateStaffCartDto
    ): Promise<QueryResult<StaffCart>> => {
        const { data } = await this.instance.post("/staff_cart", {
            data: createStaffCartDto,
        });

        return data;
    };

    public updateStaffCart = async (
        id: number,
        updateStaffCartDto: UpdateStaffCartDto
    ): Promise<QueryResult<QueryResultWithoutData>> => {
        const { data } = await this.instance.patch("/staff_cart", {
            id,
            data: updateStaffCartDto,
        });

        return data;
    };

    public removeStaffCarts = async (
        ids: number[]
    ): Promise<QueryResult<QueryResultWithoutData>> => {
        const { data } = await this.instance.delete("/staff_cart", {
            data: { ids_list: ids },
        });

        return data;
    };

    public getStaffCartItems = async (): Promise<StaffCartItem[]> => {
        const { data } = await this.instance.get("/staff_cart_item");

        return data.result || [];
    };

    public getOneStaffCartItem = async (id: number): Promise<StaffCartItem | null> => {
        const { data } = await this.instance.get("/staff_cart_item", {
            params: { ids_list: id },
        });

        return this.getFirstItemOrNull<StaffCartItem>(
            this.toPaginatedUniversalOutput<StaffCartItem>(data).result.items
        );
    };

    public createStaffCartItem = async (
        createStaffCartItemDto: CreateStaffCartItemDto
    ): Promise<QueryResult<StaffCartItem>> => {
        const { data } = await this.instance.post("/staff_cart_item", createStaffCartItemDto);

        return data;
    };

    public updateStaffCartItem = async (
        updateStaffCartItemDto: UpdateStaffCartItemDto
    ): Promise<QueryResult<QueryResultWithoutData>> => {
        const { data } = await this.instance.patch("/staff_cart_item", {
            data: updateStaffCartItemDto,
        });

        return data;
    };

    public removeStaffCartItems = async (
        ids: number[]
    ): Promise<QueryResult<QueryResultWithoutData>> => {
        const { data } = await this.instance.delete("/staff_cart_item", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new StaffCartService(new HttpClient(Config.API_URL), AuthManager);
