import React from "react";
import { SectionPage, ContentPage, MultipleContentPage, ErrorPage } from "../pages";

type PageType = {
    type: string;
    name: string;
    getPage(...props: any): React.ReactNode;
    hasContentType?: boolean;
};

export type WithPageProps<T> = T & {
    url: string;
    title: string;
    icon?: string;
    description?: string;
};

export enum PAGE_TYPE {
    SECTION = "section",
    CONTENT = "content",
    MULTIPLE_CONTENT = "multiple_content",
    DETAIL = "detail",
}

class PageTypesManager {
    static getPageType = (type: string) => {
        return PageTypesManager.PAGE_TYPES.find((page) => page.type === type);
    };

    static getErrorPage = (error: Error, { ...pageProps }: WithPageProps<{}>): React.ReactNode => {
        return React.createElement(ErrorPage, { error, ...pageProps });
    };

    static readonly PAGE_TYPES: PageType[] = [
        {
            type: PAGE_TYPE.SECTION,
            name: "Страница раздела",
            getPage: ({ ...props }) => React.createElement(SectionPage, { ...props }),
        },
        {
            type: PAGE_TYPE.CONTENT,
            name: "Контентная страница",
            hasContentType: true,
            getPage: ({ ...props }) => React.createElement(ContentPage, { ...props }),
        },
        {
            type: PAGE_TYPE.MULTIPLE_CONTENT,
            name: "Мульти контентная страница",
            hasContentType: true,
            getPage: ({ ...props }) => React.createElement(MultipleContentPage, { ...props }),
        },
    ];
}

export default PageTypesManager;
