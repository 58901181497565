import { makeStyles } from "@material-ui/core/styles";
import { SvgIconComponent } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    iconWrapper: {
        borderRadius: "50%",
        height: "4rem",
        width: "4rem",
        backgroundColor: theme.palette.secondary.light,
        margin: "1rem auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        color: theme.palette.common.white,
        fontSize: 40,
    },
}));

type Props = { Icon: SvgIconComponent };

const IconCircle = ({ Icon }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.iconWrapper}>
            <Icon className={classes.icon} />
        </div>
    );
};

export default IconCircle;
