import { useSnackbar } from "notistack";
import React from "react";
import { useStoreon } from "storeon/react";
import { staffMenuService } from "@services/Settings";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { ProtectedPath } from "@interfaces/ProtectedPath";
import { useCurrentPageInfo, useModal } from "../../hooks";
import { ServiceHelper } from "@modules";
import { CommonEvents, CommonState } from "@store/common-module";
import useContextHelp from "@hooks/useContextHelp";

const StaffMenuItemsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { onContextClickHandler } = useContextHelp();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();
    const { dispatch: commonDispatch } = useStoreon<CommonState, CommonEvents>();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_STAFF_MENU_ITEM", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: ProtectedPath | ProtectedPath[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await staffMenuService.deleteStaffMenuItems(ids);
            commonDispatch("common/call/update_menu_and_routes");
            enqueueSnackbar("Пункты меню успешно удалены", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении пунктов меню", {
                variant: "error",
            });
        }
    };

    const handleContextHelpClick = (e, rowData) => {
        onContextClickHandler(e, "staff_menu_item_id", rowData.id);
    };

    const columns: DataTableColumn<ProtectedPath>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Название",
            field: "title",
            width: "auto",
        },
        {
            title: "Описание",
            field: "description",
            width: "auto",
        },
        {
            title: "Адрес",
            field: "url",
            width: "auto",
        },
        {
            title: "Тип страницы",
            field: "page_type",
            width: "auto",
        },
        {
            title: "Тип контента",
            field: "content_type",
            width: "auto",
        },
        {
            title: "Сортировка",
            field: "sort",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={staffMenuService.getStaffMenuItems}
            columns={columns}
            displayField="title"
            permission={permission}
            sorting
            search
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            customActions={[
                {
                    name: "context_help",
                    icon: "help_outline",
                    onClick: handleContextHelpClick,
                    tooltip: "Изменить контекстную справку",
                },
            ]}
        />
    );
};

export default StaffMenuItemsTable;
