import { Error as ErrorView } from "@components/Views/Error";
import { Box } from "@material-ui/core";
import React from "react";
import { WithPageProps } from "@modules/PageTypesManager";
import { useTitle } from "../hooks";

type Props = { error: Error };

const ErrorPage = ({ error, title = "Внутренная ошибка" }: WithPageProps<Props>) => {
    useTitle(title);

    return (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <ErrorView error={error} center />
        </Box>
    );
};

export default ErrorPage;
