import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAsync from "@hooks/useAsync";
import { entitiesService } from "@services";
import { typeBindService } from "@services/Settings";
import CustomAsyncAutocomplete, { CustomAutocompleteProps } from "./CustomAsyncAutocomplete";
import { Entity } from "@interfaces/Entity";

type EntityAutocompleteProps = Omit<
    CustomAutocompleteProps<CustomEntity>,
    "items" | "isLoading" | "onChange" | "value" | "optionField"
> & {
    onChange: (name: string, value: CustomEntity) => void;
    value: any;
    filterByEntityTypeId?: number;
    typeBindName?: string;
    filter?: Record<string, string>;
    findBy?: string;
};

type CustomEntity = Entity & { primaryField: string; secondaryField: string };

const EntityAutocomplete = ({
    textFieldProps,
    onChange,
    value,
    filterByEntityTypeId,
    typeBindName,
    filter,
    findBy = "q",
}: EntityAutocompleteProps) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [selectedValue, setSelectedValue] = useState<CustomEntity | null>(null);

    const { data: typeBind, loadingStatus: bindLoadingStatus } = useAsync(
        typeBindService.getOneTypeBind,
        {
            functionArgs: [undefined, { type_name: typeBindName }],
            canLoad: !!typeBindName,
        }
    );

    const { data, loadingStatus } = useAsync(entitiesService.getEntities, {
        functionArgs: [
            {
                et_id: filterByEntityTypeId || typeBind?.type_id,
                page_size: 20,
                page: 1,
                q: inputValue || value,
                ...filter,
            },
        ],
        canLoad: !!filterByEntityTypeId || bindLoadingStatus !== "loading",
        setLoadingStatusOnUpdate: false,
    });

    const items = useMemo(
        () =>
            loadingStatus === "loaded" && data
                ? data?.result.items?.map((item) => ({
                      ...item,
                      primaryField: (item.attrs[0]?.values && item.attrs[0].values[0].value) || "",
                      secondaryField:
                          (item.attrs[1]?.values && item.attrs[1].values[0].value) || "",
                  }))
                : [],
        [data, loadingStatus]
    );

    useEffect(() => {
        if (loadingStatus === "loaded") {
            const element = items?.find((item) => {
                if (findBy === "ids_list") {
                    return Number(value) === item.id;
                } else {
                    return item.primaryField === value;
                }
            });
            setSelectedValue(element || null);
        }
    }, [value, items, loadingStatus, data, findBy]);

    const handleChangeValue = useCallback(
        (e, value: CustomEntity) => {
            onChange(textFieldProps?.name || "", value || null);
        },
        [onChange, textFieldProps]
    );

    return (
        <CustomAsyncAutocomplete<CustomEntity>
            isLoading={loadingStatus === "loading" || bindLoadingStatus === "loading"}
            value={selectedValue}
            items={items || []}
            textFieldProps={textFieldProps}
            optionField="primaryField"
            secondaryField="secondaryField"
            onChange={handleChangeValue}
            onChangeInput={setInputValue}
        />
    );
};

export default EntityAutocomplete;
