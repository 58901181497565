import {
    TextField,
    TextFieldProps,
    TextareaAutosize,
    TextareaAutosizeProps,
} from "@material-ui/core";
import React from "react";

export default function AutoSizeTextField({
    maxRows = 2,
    minRows = 1,
    ...props
}: TextareaAutosizeProps & TextFieldProps) {
    return (
        <TextField
            {...props}
            multiline
            InputLabelProps={{
                shrink: !!props.value || undefined,
            }}
            InputProps={{
                inputComponent: TextareaAutosize,
                maxRows,
                minRows,
            }}
        />
    );
}
