import { Exclude, Expose } from "class-transformer";

@Exclude()
export class ChangeCurrentStaffMemberPasswordDto {
    @Expose({ name: "old_password" })
    readonly password_old: string;

    @Expose()
    readonly password: string;

    @Expose({ name: "repeat_password" })
    readonly password2: string;
}
