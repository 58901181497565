import { StoreonStore } from "storeon";

class AuthManager {
    public static getToken = () => {
        return localStorage.getItem("token");
    };

    public static isUserAuthenticated = (): boolean => {
        const token = AuthManager.getToken();

        return !!token;
    };

    public static authenticate = (token: string, store?: StoreonStore) => {
        localStorage.setItem("token", token);
        if (store) {
            store.dispatch("auth/set/token", token);
            store.dispatch("auth/set/is_logged_in", true);
        }
    };

    public static logout = (store?: StoreonStore) => {
        localStorage.removeItem("token");
        if (store) {
            store.dispatch("auth/remove/user", null);
            store.dispatch("auth/remove/token", null);
            store.dispatch("auth/remove/is_logged_in", false);
        }
    };

    public static isRememberMeActive = (): boolean => {
        return !!localStorage.getItem("remember-me");
    };

    public static setRememberMe = () => {
        localStorage.setItem("remember-me", "true");
    };

    public static removeRememberMe = () => {
        localStorage.removeItem("remember-me");
    };

    public static getSavedUsername = () => {
        return localStorage.getItem("saved-username") || "";
    };

    public static setSavedUsername = (username: string) => {
        localStorage.setItem("saved-username", username);
    };

    public static removeSavedUsername = () => {
        localStorage.removeItem("saved-username");
    };
}

export default AuthManager;
