import { TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { Quantity } from "@interfaces/Quantity";
import { QuantitySchema } from "@schema/Quantity";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { Store } from "@interfaces/Store";
import { storeService } from "@services";

const QuantityForm = forwardRef(({ onSubmit, data }: CommonFormProps<Quantity>, ref: any) => {
    const initialData = {
        sku: data?.sku ?? "",
        store_id: data?.store_id ?? null,
        quantity: data?.quantity ?? 0,
    } as Quantity;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={QuantitySchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, setFieldValue }) => {
                return (
                    <Form>
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.sku}
                            required
                            autoFocus
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="sku"
                            helperText={touched.sku ? errors.sku : ""}
                            error={touched.sku && !!errors.sku}
                            label="SKU"
                        />

                        <AsyncAutocomplete<Store>
                            fetchFn={storeService.getStores}
                            value={values.store_id || null}
                            onChange={(name, value) => setFieldValue(name, value?.store_id || null)}
                            optionField="store_name"
                            secondaryField="shown_name"
                            findBy="store_id"
                            textFieldProps={{
                                fullWidth: true,
                                required: true,
                                margin: "normal",
                                variant: "outlined",
                                name: "store_id",
                                size: "small",
                                helperText: touched.store_id ? errors.store_id : "",
                                error: touched.store_id && !!errors.store_id,
                                label: "Магазин",
                            }}
                        />

                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.quantity}
                            required
                            type="number"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="quantity"
                            helperText={touched.quantity ? errors.quantity : ""}
                            error={touched.quantity && !!errors.quantity}
                            label="Количество"
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default QuantityForm;
