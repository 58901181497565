import { Exclude, Expose } from "class-transformer";

@Exclude()
class UpdateStaffCartItemDto {
    @Expose()
    readonly sku: string;

    @Expose()
    readonly quantity: number;
}

export default UpdateStaffCartItemDto;
