import { Box, Button, DialogActions, DialogContent, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { staffService } from "@services/Staff";
import { TableEvents, TableState } from "@store/table-module";
import { ModalContentLoading } from "../../Common/Modal";
import StaffBrandsList from "../../Content/StaffBrands/StaffBrandsList";
import { Subtitle } from "../../Views/Title";
import AddNewStaffBrand from "../../Controls/Actions/StaffMemberBrands/AddNewStaffBrand";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ContentEvents, ContentState } from "@store/content-module";
import { useAsync, useLoading } from "@hooks";

const BindStaffMemberBrandsDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = modalProps;

    const brandsRef = useRef<any>(); // ref to brands list
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch } = useStoreon<ContentState, ContentEvents>();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data: staffMember, loadingStatus } = useAsync(staffService.getOneStaffMember, {
        functionArgs: [itemId],
        canLoad: !!itemId,
        fetchCallback: (value) => {
            if (!value) return;
            dispatch("content/staff_roles/set/userid", value.id);
        },
    });

    useEffect(() => {
        return () => dispatch("content/staff_roles/set/userid", null);
    }, [dispatch]);

    const handleUpdateBrands = async () => {
        setSubmitLoadingStatus("loading");
        try {
            await brandsRef.current.handleSubmit();
            enqueueSnackbar("Бренды успешно привязаны к сотруднику", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при привязке брендов к сотруднику", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {loadingStatus === "loaded" && staffMember && (
                    <>
                        <Alert severity="info">
                            <AlertTitle>Заметка</AlertTitle>
                            <Typography variant="body2">
                                В этом окне вы можете привязать бренды к сотруднику с ролью «
                                <b>Бренд менеджер</b>»
                            </Typography>
                        </Alert>

                        <Box pt={1}>
                            <Typography>
                                Вы редактируете бренды сотрудника «<b>{staffMember.login}</b>»
                            </Typography>
                        </Box>

                        <Subtitle title="" endAdornment={<AddNewStaffBrand />} />
                        <StaffBrandsList ref={brandsRef} />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleUpdateBrands}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default BindStaffMemberBrandsDialogContext;
