import { Regex } from "@modules/Constants";
import * as Yup from "yup";

export const AttributeTypeSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required("Пожалуйста, введите название")
        .matches(Regex.STR_WITHOUT_SPECIAL_SYMBOLS, "Название не должно содержать спец. символов")
        .max(64, "Название слишком длинное"),
    notes: Yup.string(),
});
