import { TextField, TextFieldProps } from "@material-ui/core";
import React from "react";
import InputMask from "react-input-mask";

export default function PhoneNumberInput({ ...props }: TextFieldProps) {
    return (
        <InputMask
            mask="+9 (999) 999-99-99"
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
        >
            {(inputProps) => (
                <TextField {...inputProps} {...props} type="tel" disabled={props.disabled} />
            )}
        </InputMask>
    );
}
