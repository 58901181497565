import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { PaginatedQueryResult, QueryParams, QueryResult } from ".";
import { CustomType } from "../interfaces/CustomType";
import { PhysType } from "../interfaces/PhysType";
import BaseService from "./BaseService";

class DataTypesService extends BaseService {
    public getPhysTypes = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<PhysType>> => {
        const { data } = await this.instance.get("/nsi_meta/ptype", {
            params,
        });

        return data;
    };

    public getPhysType = async (physTypeId: number): Promise<PhysType> => {
        const { data } = await this.instance.get("/nsi_meta/ptype", {
            params: { ids_list: physTypeId },
        });

        return data.result.items[0];
    };

    /** @deprecated */
    public getEnumValues = async (): Promise<PaginatedQueryResult<any>> => {
        const { data } = await this.instance.get("/nsi_meta/enum_values");

        return data;
    };

    /** @deprecated */
    public createCustomType = async (
        customType: CustomType
    ): Promise<PaginatedQueryResult<CustomType>> => {
        const { data } = await this.instance.post("/nsi_meta/custom_type", customType);

        return data;
    };

    /** @deprecated */
    public createPhysType = async (): Promise<QueryResult<any>> => {
        const { data } = await this.instance.post("/nsi_meta/ptype");

        return data;
    };

    /** @deprecated */
    public deletePhysType = async (physType: PhysType): Promise<QueryResult<PhysType>> => {
        const { data } = await this.instance.delete("/nsi_meta/ptype", {
            data: { id: physType.id },
        });

        return data;
    };
}

export default new DataTypesService(new HttpClient(Config.API_URL), AuthManager);
