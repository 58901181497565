import { Error } from "@components/Views/Error";
import { Config } from "@modules/Constants";
import { Component, ErrorInfo, PropsWithChildren } from "react";

type State = { error?: Error; errorInfo?: ErrorInfo; showStack: boolean };

class ErrorBoundary extends Component<PropsWithChildren<{}>, State> {
    constructor(props) {
        super(props);
        this.state = {
            showStack: Config.ERROR_STACK_TRACE,
        };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({ error, errorInfo: info });
    }

    render() {
        const { error, errorInfo, showStack } = this.state;

        if (error) {
            return <Error {...{ error, errorInfo, showStack }} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
