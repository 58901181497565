import { Box, Button, DialogActions, DialogContent, Divider, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { CheckCircle, Error } from "@material-ui/icons";
import React, { useState } from "react";
import { DropzoneField } from "../Controls/Inputs";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { fileService } from "@services";
import { useSnackbar } from "notistack";

const FileUploadDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { onUpdate = () => {}, selectedDirectoryId = undefined } = {
        ...modalProps,
    };
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState<{
        uploadStatus: "" | "success" | "error";
        errorMessage: string;
    }>({
        uploadStatus: "",
        errorMessage: "",
    });

    const handleSubmit = async (files: File[]) => {
        setState((prev) => ({
            ...prev,
            uploadStatus: "",
            errorMessage: "",
        }));

        try {
            await fileService.uploadFile(files, {
                directoryId: selectedDirectoryId,
            });

            setState((prev) => ({
                ...prev,
                uploadStatus: "success",
            }));
            enqueueSnackbar("Файлы успешно загружены", { variant: "success" });
        } catch (err) {
            const message = err.response?.data?.error_tooltip;

            setState((prev) => ({
                ...prev,
                uploadStatus: "error",
                errorMessage: message,
            }));
            enqueueSnackbar("Произошла ошибка при загрузке файлов", {
                variant: "error",
            });
        }

        onUpdate();
    };

    const { uploadStatus, errorMessage } = state;

    return (
        <>
            <DialogContent>
                <DropzoneField onSubmit={handleSubmit} maxFiles={100} preview />

                {uploadStatus && (
                    <>
                        <Divider />
                        <Box py={1}>
                            <Typography
                                gutterBottom
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {uploadStatus === "success" && (
                                    <>
                                        <CheckCircle
                                            style={{
                                                marginRight: 10,
                                                color: green[500],
                                            }}
                                        />
                                        Успешно!
                                    </>
                                )}
                                {uploadStatus === "error" && (
                                    <>
                                        <Error style={{ marginRight: 10 }} color="error" />
                                        Ошибка!
                                    </>
                                )}
                            </Typography>

                            {uploadStatus === "success" && (
                                <Typography>Файлы успешно загружены</Typography>
                            )}

                            {uploadStatus === "error" && (
                                <>
                                    {errorMessage ? (
                                        <pre>{errorMessage}</pre>
                                    ) : (
                                        <Typography>
                                            Произошла ошибка при загрузке файлов
                                        </Typography>
                                    )}
                                </>
                            )}
                        </Box>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Закрыть</Button>
            </DialogActions>
        </>
    );
};

export default FileUploadDialogContext;
