import { ThemeType } from "@store/common-module";

export const Config = {
    ERROR_STACK_TRACE: (process.env.REACT_APP_ERROR_STACK_TRACE === "true") as boolean,
    API_URL: process.env.REACT_APP_API_URL as string,
} as const;

export const Common = {
    DEFAULT_APP_THEME: "light" as ThemeType,
    SIDEBAR_WIDTH: 240 as number,
    TITLE_TRUNCATE_LEN: 35 as number,
    LONG_TEXT_TRUNCATE_LEN: 150 as number,
    TABLE_TEXT_TRUNCATE_LEN: 45 as number,
    DEFAULT_ERROR_MESSAGE: "Произошла ошибка при выполнении операции",
} as const;

export const Format = {
    DATE: "YYYY-MM-DD" as string,
    DATE_TIME: "YYYY-MM-DD HH:MM:ss" as string,
    TIME: "HH:mm:ss" as string,
} as const;

export const Regex = {
    /** first character only letter */
    PASSWORD: /^[A-Za-zА-Яа-я].*$/ as RegExp,
    LOGIN: /^[A-Za-zА-Яа-я].*$/ as RegExp,
    PHONE: /^[+][0-9]\s?\(?[0-9]{3}\)\s?[0-9]{3}-?[0-9]{2}-?[0-9]{2}$/ as RegExp,
    STR_WITHOUT_SPECIAL_SYMBOLS: /^[а-яА-Яa-zA-Z0-9!@#$%^&*)(+=.,_-\s]+$/g as RegExp,
} as const;
