import * as Yup from "yup";
import { Regex } from "@modules/Constants";

export const BaseUserSchema = Yup.object().shape({
    username: Yup.string().required("Пожалуйста, укажите логин"),
    email: Yup.string()
        .required("Пожалуйста, укажите адрес эл. почты")
        .email("Пожалуйста, укажите корректный адрес эл. почты"),
    first_name: Yup.string().required("Пожалуйста, введите имя"),
    last_name: Yup.string().required("Пожалуйста, введите фамилию"),
    gender: Yup.string().required("Пожалуйста, укажите пол").oneOf(["male", "female", ""]),
    phone: Yup.string()
        .required("Пожалуйста, укажите номер телефона")
        .matches(Regex.PHONE, "Номер указан неверно"),
    birthday: Yup.string().nullable().required("Пожалуйста, укажите дату рождения"),
    loyalty_card: Yup.string().required("Пожалуйста, карту лояльности"),
});
