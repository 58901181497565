import { CartItem } from "@interfaces/CartItem";
import { Cart } from "@interfaces/Cart";
import { PaginatedQueryResult, QueryParams, QueryResult } from ".";
import BaseService from "./BaseService";
import { AuthManager } from "@modules";
import HttpClient from "@modules/HttpClient";
import { Config } from "@modules/Constants";
import { CreateCartDto, UpdateCartDto } from "@dto/CartDto";
import { CreateCartItemDto, UpdateCartItemDto } from "@dto/CartItemDto";

class CartService extends BaseService {
    /* Cart Menu Section */

    public getCarts = async ({ ...params }: QueryParams): Promise<PaginatedQueryResult<Cart>> => {
        const { data } = await this.instance.get("/cart", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneCart = async (cartId: number): Promise<Cart> => {
        const { data } = await this.instance.get("/cart", {
            params: { ids_list: cartId },
        });

        return data.result.items[0];
    };

    public createCart = async (createCartDto: CreateCartDto): Promise<QueryResult<Cart>> => {
        const { data } = await this.instance.post("/cart", createCartDto);

        return data;
    };

    public updateCart = async (
        id: number,
        updateCartDto: UpdateCartDto
    ): Promise<QueryResult<Cart>> => {
        const { data } = await this.instance.patch("/cart", { id, data: updateCartDto });

        return data;
    };

    public deleteCart = async (ids: number[]): Promise<void> => {
        await this.instance.delete("/cart", {
            data: { ids_list: ids },
        });
    };

    /* End of Cart Menu Section */

    /* Cart Item Menu Section */

    public getCartItems = async ({
        ...params
    }: QueryParams): Promise<PaginatedQueryResult<CartItem>> => {
        const { data } = await this.instance.get("/cart/item", { params });

        return data;
    };

    public getOneCartItem = async (cartItemId: number): Promise<CartItem> => {
        const { data } = await this.instance.get("/cart/item", {
            params: { ids_list: cartItemId },
        });

        return data.result.items[0];
    };

    public createCartItem = async (
        createCartItemDto: CreateCartItemDto
    ): Promise<PaginatedQueryResult<CartItem>> => {
        const { data } = await this.instance.post("/cart/item", createCartItemDto);

        return data;
    };

    public updateCartItem = async (
        id: number,
        updateCartItemDto: UpdateCartItemDto
    ): Promise<QueryResult<CartItem>> => {
        const { data } = await this.instance.patch("/cart/item", { id, data: updateCartItemDto });

        return data;
    };

    public deleteCartItems = async (items_ids: number[]): Promise<void> => {
        await this.instance.delete("/cart/item", {
            data: { ids_list: items_ids },
        });
    };

    /* End of Cart Item Menu Section */
}

export default new CartService(new HttpClient(Config.API_URL), AuthManager);
