import { Card, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useMemo } from "react";
import { useStoreon } from "storeon/react";
import { ETypeEvents, ETypeState } from "@store/etype-module";
import RequiredTypeSelect from "@components/Controls/Selects/RequiredTypeSelect";
import EntityTypeSorting from "./EntityTypeSorting";
import { ValidationState, ValidationEvents } from "@store/validation-module";
import { AppEntityTypeItem } from "@interfaces/Internal/AppEntityTypeItem";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { AttributeType } from "@interfaces/AttributeType";
import { aTypesService } from "@services";

const useStyles = makeStyles(() => ({
    card: {
        margin: "1rem 0",
        padding: ".5rem 1rem",
    },
}));

interface Props {
    itemIndex: number;
    itemsLength: number;
    item: AppEntityTypeItem;
    selectedAtypes: number[];
}

const EntityTypeCard = ({ itemIndex, itemsLength, item, selectedAtypes }: Props) => {
    const classes = useStyles();
    const { dispatch } = useStoreon<ETypeState, ETypeEvents>();
    const { enableValidation, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("enableValidation");

    const handleDeleteItem = () => {
        if (item.id) {
            dispatch("etype/add/items_to_remove", item.id);
        }
        dispatch("etype/remove/item", itemIndex);
        validationDispatch("validation/remove/validation", item.key);
    };

    const handleChangeAttributeType = (name: string, value: any) => {
        dispatch("etype/update/item", {
            itemIndex,
            item: {
                ...item,
                [name]: value,
            },
        });
    };

    const isValid = useMemo(() => item.atype_id !== null, [item.atype_id]);

    useEffect(() => {
        validationDispatch("validation/add/validations", {
            [item.key]: isValid,
        });
    }, [validationDispatch, item.key, isValid]);

    return (
        <Card className={classes.card} component="li" elevation={2}>
            <Grid container justifyContent="space-between">
                <EntityTypeSorting itemsLength={itemsLength} itemIndex={itemIndex} />
                <Button
                    size="small"
                    onClick={handleDeleteItem}
                    color="secondary"
                    disabled={itemsLength <= 1}
                >
                    Удалить
                </Button>
            </Grid>

            <Grid item xs={12}>
                <AsyncAutocomplete<AttributeType>
                    fetchFn={aTypesService.getAttributeTypes}
                    value={item.atype_id}
                    onChange={(name, value) => handleChangeAttributeType(name, value?.id || null)}
                    optionField="name"
                    disabledOptions={selectedAtypes}
                    textFieldProps={{
                        name: "atype_id",
                        margin: "normal",
                        label: "Тип атрибута",
                        variant: "outlined",
                        size: "small",
                        required: true,
                        error: enableValidation && !isValid,
                        helperText: "Обязательно к заполнению",
                    }}
                />

                <RequiredTypeSelect
                    value={item.required}
                    margin="normal"
                    name="required"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                        handleChangeAttributeType("required", e.target.value);
                    }}
                />
            </Grid>
        </Card>
    );
};

export default EntityTypeCard;
