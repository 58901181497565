import { Regex } from "@modules/Constants";
import * as Yup from "yup";
import { BaseUserSchema } from "./BaseUserSchema";

export const CreateUserSchema = BaseUserSchema.shape({
    password: Yup.string()
        .required("Пожалуйста, укажите пароль")
        .matches(Regex.PASSWORD, "Пароль не должен начинаться с цифр и спец. символов")
        .min(6, "Пароль должен содержать минимум 6 символов"),
});
