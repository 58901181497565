import { ProtectedPath } from "@interfaces/ProtectedPath";
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React from "react";
import SidebarListItem from "./SidebarListItem";

interface Props {
    parentTitle: string;
    childs: ProtectedPath[];
}

const SidebarNestedPathsLinks = ({ parentTitle, childs }: Props) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText
                    secondary={parentTitle}
                    secondaryTypographyProps={{ variant: "subtitle2" }}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto">
                <List component="ul" disablePadding>
                    {childs.map((route) => {
                        return (
                            <SidebarListItem
                                key={route.id}
                                text={route.title}
                                href={route.url}
                                icon={route.icon}
                                nested
                            />
                        );
                    })}
                </List>
            </Collapse>
        </>
    );
};

export default SidebarNestedPathsLinks;
