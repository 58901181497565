import { CreateStoreDto, UpdateStoreDto } from "@dto/StoreDto";
import { Price } from "@interfaces/Price";
import { Store } from "@interfaces/Store";
import { PaginatedQueryResult, QueryParams, QueryResultWithoutData, QueryResult } from "@services";
import BaseService from "./BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { CreatePriceDto, UpdatePriceDto } from "@dto/PriceDto";
import { Quantity } from "@interfaces/Quantity";
import {
    CreateQuantityDto,
    CreateQuantityReserveDto,
    CreateReserveStoreBindingDto,
    UpdateQuantityDto,
    UpdateQuantityReserveDto,
    UpdateReserveStoreBindingDto,
} from "@dto/QuantityDto";
import { PriceLoadStatus } from "@interfaces/PriceLoadStatus";
import { QuantityLoadStatus } from "@interfaces/QuantityLoadStatus";
import { Discount } from "@interfaces/Discount";
import { AttributeValueBindDto, CreateDiscountDto, UpdateDiscountDto } from "@dto/DiscountDto";
import { DiscountLoadStatus } from "@interfaces/DiscountLoadStatus";
import { StoreLoadStatus } from "@interfaces/StoreLoadStatus";
import { AttributeValueBind } from "@interfaces/AttributeValueBind";
import { QuantityReserve } from "@interfaces/QuantityReserve";
import { ReserveStoreBinding } from "@interfaces/ReserveStoreBinding";

class StoreService extends BaseService {
    public getStores = async (params: QueryParams = {}): Promise<PaginatedQueryResult<Store>> => {
        const { data } = await this.instance.get("/store", { params });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneStore = async (storeId: number): Promise<Store> => {
        const { data } = await this.instance.get("/store", {
            params: { id: storeId },
        });

        return data.result.items[0];
    };

    public createStore = async (
        createStoreDto: CreateStoreDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.post("/store", { data: createStoreDto });

        return data;
    };

    public updateStore = async (id: number, store: UpdateStoreDto): Promise<any> => {
        const { data } = await this.instance.patch("/store", { id, data: store });

        return data;
    };

    public getStoreLoadStatuses = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<StoreLoadStatus>> => {
        const { data } = await this.instance.get("/store/csv_store_load_status", {
            params,
        });

        return data;
    };

    public deleteStoreLoadStatus = async (idsList: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/csv_store_load_status", {
            data: {
                ids_list: idsList,
            },
        });

        return data;
    };

    public downloadStoreCSVdata = async (options?: { deferredLoading: boolean }) => {
        const response = await this.instance.get("/store/csv", {
            responseType: "blob",
        });

        if (!options?.deferredLoading) {
            this.download(response, "template.csv", {
                blobType: "text/csv",
            });
        }

        return response;
    };

    public getStorePrices = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<Price>> => {
        const { data } = await this.instance.get("/store/price", {
            params,
        });

        return data;
    };

    public getOneStorePrice = async (id: number): Promise<Price | null> => {
        const { data } = await this.instance.get("/store/price", {
            params: { id },
        });

        return this.getFirstItemOrNull(data.result.items);
    };

    public createPrice = async (
        createPriceDto: CreatePriceDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.post("/store/price", { data: createPriceDto });

        return data;
    };

    public updatePrice = async (
        id: number,
        updatePriceDto: UpdatePriceDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/store/price", { id, data: updatePriceDto });

        return data;
    };

    public deletePrices = async (idsList: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/price", {
            data: {
                ids_list: idsList,
            },
        });

        return data;
    };

    public downloadPriceCSVdata = async (options?: { deferredLoading: boolean }) => {
        const response = await this.instance.get("/store/price/csv", {
            responseType: "blob",
        });

        if (!options?.deferredLoading) {
            this.download(response, "template.csv", {
                blobType: "text/csv",
            });
        }

        return response;
    };

    public uploadPriceCSVdata = async (file: File): Promise<QueryResult<string>> => {
        const formData = new FormData();
        formData.append("file", file);

        const { data } = await this.instance.post("/store/price/csv", formData);

        return data;
    };

    public getPriceLoadStatuses = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<PriceLoadStatus>> => {
        const { data } = await this.instance.get("/store/price/csv_price_load_status", {
            params,
        });

        return data;
    };

    public deletePriceLoadStatus = async (idsList: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/price/csv_price_load_status", {
            data: {
                ids_list: idsList,
            },
        });

        return data;
    };

    public getStoreQuantities = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<Quantity>> => {
        const { data } = await this.instance.get("/store/quantity", {
            params,
        });

        return data;
    };

    public getOneStoreQuantity = async (id: number): Promise<Quantity | null> => {
        const { data } = await this.instance.get("/store/quantity", {
            params: { id },
        });

        return this.getFirstItemOrNull(data.result.items);
    };

    public createStoreQuantity = async (
        createQuantityDto: CreateQuantityDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.post("/store/quantity", { data: createQuantityDto });

        return data;
    };

    public updateStoreQuantity = async (
        id: number,
        updateQuantityDto: UpdateQuantityDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/store/quantity", {
            id,
            data: updateQuantityDto,
        });

        return data;
    };

    public deleteStoreQuantities = async (idsList: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/quantity", {
            data: {
                ids_list: idsList,
            },
        });

        return data;
    };

    public getQuantityReserves = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<QuantityReserve>> => {
        const { data } = await this.instance.get("/store/quantity/reserve", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneQuantityReserve = async (id: number): Promise<QuantityReserve | null> => {
        const { data } = await this.instance.get("/store/quantity/reserve", {
            params: { id },
        });

        return this.getFirstItemOrNull(data.result.items);
    };

    public createQuantityReserve = async (
        createQuantityReserveDto: CreateQuantityReserveDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.post("/store/quantity/reserve", {
            data: [createQuantityReserveDto],
        });

        return data;
    };

    public updateQuantityReserve = async (
        id: number,
        updateQuantityReserveDto: UpdateQuantityReserveDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/store/quantity/reserve", {
            id,
            data: updateQuantityReserveDto,
        });

        return data;
    };

    public deleteQuantityReserves = async (idsList: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/quantity/reserve", {
            data: {
                ids_list: idsList,
            },
        });

        return data;
    };

    public getReserveStoreBindings = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<ReserveStoreBinding>> => {
        const { data } = await this.instance.get("/store/quantity/reserve_store_binding", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneReserveStoreBinding = async (id: number): Promise<ReserveStoreBinding | null> => {
        const { data } = await this.instance.get("/store/quantity/reserve_store_binding", {
            params: { id },
        });

        return this.getFirstItemOrNull(data.result.items);
    };

    public createReserveStoreBinding = async (
        createReserveStoreBindingDto: CreateReserveStoreBindingDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.post("/store/quantity/reserve_store_binding", {
            data: createReserveStoreBindingDto,
        });

        return data;
    };

    public updateReserveStoreBinding = async (
        id: number,
        updateReserveStoreBindingDto: UpdateReserveStoreBindingDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/store/quantity/reserve_store_binding", {
            id,
            data: updateReserveStoreBindingDto,
        });

        return data;
    };

    public deleteReserveStoreBindings = async (
        idsList: number[]
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/quantity/reserve_store_binding", {
            data: {
                ids_list: idsList,
            },
        });

        return data;
    };

    public downloadQuantitiesCSVdata = async (options?: { deferredLoading: boolean }) => {
        const response = await this.instance.get("/store/quantity/csv", {
            responseType: "blob",
        });

        if (!options?.deferredLoading) {
            this.download(response, "template.csv", {
                blobType: "text/csv",
            });
        }

        return response;
    };

    public uploadQuantityCSVdata = async (file: File): Promise<QueryResult<string>> => {
        const formData = new FormData();
        formData.append("file", file);

        const { data } = await this.instance.post("/store/quantity/csv", formData);

        return data;
    };

    public getQuantityLoadStatuses = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<QuantityLoadStatus>> => {
        const { data } = await this.instance.get("/store/quantity/csv_quantity_load_status", {
            params,
        });

        return data;
    };

    public deleteQuantityLoadStatus = async (
        idsList: number[]
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/quantity/csv_quantity_load_status", {
            data: {
                ids_list: idsList,
            },
        });

        return data;
    };

    public uploadCSVStoreFile = async (file: File): Promise<QueryResult<string>> => {
        const formData = new FormData();
        formData.append("file", file);

        const { data } = await this.instance.post("/store/csv", formData);

        return data;
    };

    public getOneDiscount = async (id: number): Promise<Discount | null> => {
        const { data } = await this.instance.get("/store/discount", {
            params: { id },
        });

        return this.getFirstItemOrNull(data.result.items);
    };

    public createDiscount = async (
        createDiscountDto: CreateDiscountDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.post("/store/discount", { data: createDiscountDto });

        return data;
    };

    public updateDiscount = async (
        id: number,
        updateDiscountDto: UpdateDiscountDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/store/discount", {
            id,
            data: updateDiscountDto,
        });

        return data;
    };

    public deleteDiscounts = async (idsList: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/discount", {
            data: {
                ids_list: idsList,
            },
        });

        return data;
    };

    public downloadDiscountCSVdata = async (options?: { deferredLoading: boolean }) => {
        const response = await this.instance.get("/store/discount/csv", {
            responseType: "blob",
        });

        if (!options?.deferredLoading) {
            this.download(response, "template.csv", {
                blobType: "text/csv",
            });
        }

        return response;
    };

    public uploadDiscountCSVdata = async (file: File): Promise<QueryResult<string>> => {
        const formData = new FormData();
        formData.append("file", file);

        const { data } = await this.instance.post("/store/discount/csv", formData);

        return data;
    };

    public getDiscounts = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<Discount>> => {
        const { data } = await this.instance.get("/store/discount", {
            params,
        });

        return data;
    };

    public getDiscountLoadStatuses = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<DiscountLoadStatus>> => {
        const { data } = await this.instance.get("/store/discount/csv_discount_load_status", {
            params,
        });

        return data;
    };

    public deleteDiscountLoadStatus = async (
        idsList: number[]
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/discount/csv_discount_load_status", {
            data: {
                ids_list: idsList,
            },
        });

        return data;
    };

    public getAttributeValueBinds = async (
        params: QueryParams
    ): Promise<PaginatedQueryResult<AttributeValueBind>> => {
        const { data } = await this.instance.get("/store/discount/attribute_value_bind", {
            params,
        });
        return data;
    };

    public createAttributeValueBind = async (
        createAttributeValueBindDto: AttributeValueBindDto[]
    ): Promise<QueryResult<{ ids: number[] }>> => {
        const { data } = await this.instance.post("/store/discount/attribute_value_bind", {
            data: createAttributeValueBindDto,
        });
        return data;
    };

    public updateAttributeValueBind = async (
        id: number,
        updateAttributeValueBindDto: AttributeValueBindDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/store/discount/attribute_value_bind", {
            id,
            data: updateAttributeValueBindDto,
        });
        return data;
    };

    public deleteAttributeValueBinds = async (
        idsList: number[]
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/store/discount/attribute_value_bind", {
            data: {
                ids_list: idsList,
            },
        });
        return data;
    };

    public refresh = async (): Promise<void> => {
        await this.instance.post("/store/refresh");
    };
}

export default new StoreService(new HttpClient(Config.API_URL), AuthManager);
