import { TextField } from "@material-ui/core";
import React from "react";
import { UniversalInputProps } from "../../../interfaces/System/UniversalInputProps";

const UTextField = ({ ...props }: UniversalInputProps) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        props.onChange(name, value || null);
    };

    return (
        <TextField {...props} onChange={handleChange} label={props.label || "Введите значение"} />
    );
};

export default UTextField;
