import { PaginatedQueryResult, QueryParams, QueryResultWithoutData, QueryResult } from "@services";
import BaseService from "./BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { User } from "@interfaces/User";
import { CreateUserDto, UpdateUserDto } from "@dto/UserDto";

class UserService extends BaseService {
    public getUsers = async (params: QueryParams = {}): Promise<PaginatedQueryResult<User>> => {
        const { data } = await this.instance.get("/user", { params });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneUser = async (userId: number): Promise<User> => {
        const { data } = await this.instance.get("/user", {
            params: { id: userId },
        });

        return data.result.items[0];
    };

    public createUser = async (createUserDto: CreateUserDto): Promise<QueryResult<User>> => {
        const { data } = await this.instance.post("/user", createUserDto);

        return data;
    };

    public updateUser = async (
        id: number,
        updateUserDto: UpdateUserDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/user", { id, data: updateUserDto });

        return data;
    };

    public deleteUsers = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/user", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new UserService(new HttpClient(Config.API_URL), AuthManager);
