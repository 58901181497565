import * as React from "react";
import { Fab, Zoom } from "@material-ui/core";
import { KeyboardArrowUp } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useState } from "react";
import { useEffect } from "react";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    container: {
        position: "fixed",
        bottom: 15,
        right: 15,
    },

    btn: {
        position: "relative",
    },
}));

const BackToTopButton = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        const checkYOffset = () => {
            if (!showBtn && window.pageYOffset > 400) {
                setShowBtn(true);
            } else if (showBtn && window.pageYOffset <= 400) {
                setShowBtn(false);
            }
        };

        window.addEventListener("scroll", checkYOffset);

        return () => window.removeEventListener("scroll", checkYOffset);
    }, [showBtn]);

    const goToTopHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div className={classes.container}>
            <Zoom
                in={showBtn}
                timeout={transitionDuration}
                style={{
                    transitionDelay: `${showBtn ? transitionDuration.exit : 0}ms`,
                }}
                unmountOnExit
            >
                <Tooltip
                    title="Наверх"
                    placement="left"
                    arrow
                    PopperProps={{
                        popperOptions: {
                            positionFixed: true,
                        },
                    }}
                >
                    <Fab color="primary" onClick={goToTopHandler}>
                        <KeyboardArrowUp className={classes.btn} />
                    </Fab>
                </Tooltip>
            </Zoom>
        </div>
    );
};

export default BackToTopButton;
