import { TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { ReserveStoreBindingSchema } from "@schema/Quantity";
import { QuantityReserve } from "@interfaces/QuantityReserve";
import { ReserveStoreBinding } from "@interfaces/ReserveStoreBinding";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { Store } from "@interfaces/Store";
import { storeService } from "@services";

const ReserveStoreBindingForm = forwardRef(
    ({ onSubmit, data }: CommonFormProps<ReserveStoreBinding>, ref: any) => {
        const initialData = {
            store_id: data?.store_id ?? null,
            reserve_id: data?.reserve_id ?? null,
            count: data?.count ?? 0,
        } as ReserveStoreBinding;

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={ReserveStoreBindingSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, values, setFieldValue }) => {
                    return (
                        <Form>
                            <AsyncAutocomplete<Store>
                                fetchFn={storeService.getStores}
                                value={values.store_id || null}
                                onChange={(name, value) => setFieldValue(name, value?.id || null)}
                                optionField="store_name"
                                secondaryField="shown_name"
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    margin: "normal",
                                    variant: "outlined",
                                    name: "store_id",
                                    size: "small",
                                    helperText: touched.store_id ? errors.store_id : "",
                                    error: touched.store_id && !!errors.store_id,
                                    label: "Магазин",
                                    disabled: Boolean(data),
                                }}
                            />

                            <AsyncAutocomplete<QuantityReserve>
                                fetchFn={storeService.getQuantityReserves}
                                value={values.reserve_id || null}
                                onChange={(name, value) => setFieldValue(name, value?.id || null)}
                                optionField="sku"
                                secondaryField="id"
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    margin: "normal",
                                    variant: "outlined",
                                    name: "reserve_id",
                                    size: "small",
                                    helperText: touched.reserve_id ? errors.reserve_id : "",
                                    error: touched.reserve_id && !!errors.reserve_id,
                                    label: "Резерв",
                                    disabled: Boolean(data),
                                }}
                            />

                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.count}
                                required
                                type="number"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="count"
                                helperText={touched.count ? errors.count : ""}
                                error={touched.count && !!errors.count}
                                label="Количество"
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default ReserveStoreBindingForm;
