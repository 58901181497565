import { Exclude, Expose } from "class-transformer";
import { User } from "@interfaces/User";

@Exclude()
export class UpdateUserDto {
    @Expose()
    readonly first_name: string;

    @Expose()
    readonly last_name: string;

    @Expose()
    readonly gender?: User["gender"];

    @Expose()
    readonly phone: string;

    @Expose()
    readonly loyalty_card: string;

    @Expose()
    readonly email: string;

    @Expose()
    readonly username: string;

    @Expose()
    readonly birthday: string;

    @Expose()
    readonly is_active?: boolean;
}
