import BaseService from "./BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { PaginatedQueryResult, QueryParams, QueryResultWithoutData } from "@services";
import { Order } from "@interfaces/Order";
import { QueryResult } from "@material-table/core";
import {
    CreateOrderDto,
    CreateOrderItemDto,
    UpdateOrderDto,
    UpdateOrderItemDto,
} from "@dto/OrderDto";
import { OrderItem } from "@interfaces/OrderItem";

class OrderService extends BaseService {
    public getOrders = async (params: QueryParams): Promise<PaginatedQueryResult<Order>> => {
        const { data } = await this.instance.get("/order", { params });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneOrder = async (id: number): Promise<Order> => {
        const { data } = await this.instance.get("/order", {
            params: { ids_list: id },
        });

        return data.result.items[0];
    };

    public createOrder = async (createOrderDto: CreateOrderDto): Promise<QueryResult<Order>> => {
        const { data } = await this.instance.post("/order", createOrderDto);

        return data;
    };

    public updateOrder = async (
        id: number,
        orderNumber: string,
        updateOrderDto: UpdateOrderDto
    ): Promise<QueryResult<Order>> => {
        const { data } = await this.instance.patch("/order", {
            id,
            number: orderNumber,
            data: updateOrderDto,
        });

        return data;
    };

    public deleteOrders = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/order", {
            data: { ids_list: ids },
        });

        return data;
    };

    public getOrderItems = async (
        params: QueryParams
    ): Promise<PaginatedQueryResult<OrderItem>> => {
        const { data } = await this.instance.get("/order/item", { params });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneOrderItem = async (id: number): Promise<OrderItem> => {
        const { data } = await this.instance.get("/order/item", {
            params: { ids_list: id },
        });

        return data.result.items[0];
    };

    public createOrderItem = async (
        createOrderItemDto: CreateOrderItemDto
    ): Promise<QueryResult<OrderItem>> => {
        const { data } = await this.instance.post("/order/item", createOrderItemDto);

        return data;
    };

    public updateOrderItem = async (
        id: number,
        updateOrderItemDto: UpdateOrderItemDto
    ): Promise<QueryResult<OrderItem>> => {
        const { data } = await this.instance.patch("/order/item", { id, data: updateOrderItemDto });

        return data;
    };

    public deleteOrderItems = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/order/item", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new OrderService(new HttpClient(Config.API_URL), AuthManager);
