import React from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import { LoginSchema } from "../../schema/LoginSchema";
import PasswordField from "../Controls/Inputs/PasswordField";
import RememberMe from "@components/Controls/Actions/RememberMe";
import { AuthManager } from "@modules";
import { FormProps } from "@interfaces/System/FormProps";
import { LoginValues } from "@interfaces/LoginValues";

const useStyles = makeStyles(() => ({
    title: {
        textAlign: "center",
        fontWeight: "bold",
        margin: ".5rem",
    },
    input: {
        margin: ".5rem 0",
    },
    submit: {
        marginTop: "1rem",
    },
}));

const LoginForm = ({ onSubmit, isLoading }: FormProps<LoginValues>) => {
    const classes = useStyles();

    return (
        <Formik
            initialValues={{
                user: AuthManager.getSavedUsername(),
                password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, isValid, setFieldValue, values }) => {
                return (
                    <Form>
                        <TextField
                            className={classes.input}
                            name="user"
                            required
                            helperText={touched.user ? errors.user : ""}
                            error={touched.user && Boolean(errors.user)}
                            label="Логин"
                            value={values.user}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                setFieldValue(name, value.trim());
                            }}
                            fullWidth
                        />

                        <PasswordField
                            className={classes.input}
                            name="password"
                            required
                            helperText={touched.password ? errors.password : ""}
                            error={touched.password && Boolean(errors.password)}
                            label="Пароль"
                            fullWidth
                            onChange={(e) => {
                                const { name, value } = e.target;
                                setFieldValue(name, value.trim());
                            }}
                            value={values.password}
                        />

                        <RememberMe />

                        <Button
                            type="submit"
                            className={classes.submit}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!isValid || isLoading}
                        >
                            {isLoading ? "Входим..." : "Войти"}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default LoginForm;
