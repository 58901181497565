import { Box } from "@material-ui/core";
import React from "react";
import { useStoreon } from "storeon/react";
import { ATypeEvents, ATypeState } from "../../../store/atype-module";
import AddNewAttributeItemButton from "../../Controls/Actions/AttributeItems/AddNewAttributeItemButton";

const AttributeItemsActions = () => {
    const { isCompositeType } = useStoreon<ATypeState, ATypeEvents>("isCompositeType");
    return (
        <Box>{isCompositeType && <AddNewAttributeItemButton size="small" color="primary" />}</Box>
    );
};

export default AttributeItemsActions;
