import { TextField, TextFieldProps, MenuItem, ListItemText } from "@material-ui/core";
import MultipleContentTypesManager from "@modules/ContentTypesManagers/MultipleContentTypesManager";
import React from "react";

const MultipleContentTypeSelect = ({ ...props }: TextFieldProps) => {
    return (
        <TextField {...props} value={props.value || ""} select label="Тип контента">
            {MultipleContentTypesManager.MULTIPLE_CONTENT_TYPES.map((content) => {
                return (
                    <MenuItem key={content.type} value={content.type} style={{ display: "block" }}>
                        <ListItemText primary={content.name} />
                        <ListItemText secondary={content.note} />
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default MultipleContentTypeSelect;
