import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useSnackbar } from "notistack";
import React from "react";
import { AttributeType } from "@interfaces/AttributeType";
import { ServiceHelper } from "@modules";
import { aTypesService } from "@services";
import { DataTable } from "../Common";
import { useCurrentPageInfo, useModal } from "@hooks";
import useContextHelp from "@hooks/useContextHelp";

const AttributeTypesTable = () => {
    const { onContextClickHandler } = useContextHelp();
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = async (rowData) => {
        try {
            await aTypesService.setAttributeTypeBlock(rowData.id);
            handleOpenModal("EDIT_ATTRIBUTE_TYPE", { itemId: rowData.id });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message, {
                variant: "info",
            });
        }
    };

    const handleDeleteClick = async (rowData: AttributeType | AttributeType[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await aTypesService.deleteAttributeType(ids);
            enqueueSnackbar("Тип атрибута успешно удален", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении типа атрибута", {
                variant: "error",
            });
        }
    };

    const handleContextHelpClick = (e, rowData) => {
        onContextClickHandler(e, "attribute_types_id", rowData.id);
    };

    const columns: DataTableColumn<AttributeType>[] = [
        {
            title: "#",
            field: "id",
            width: 50,
            defaultSort: "asc",
        },
        {
            title: "Наименование",
            field: "name",
            width: "auto",
        },
        {
            title: "Комментарий",
            field: "notes",
            width: "auto",
        },
    ];

    return (
        <DataTable
            title="Типы атрибутов"
            fetchFn={aTypesService.getAttributeTypes}
            columns={columns}
            selection
            sorting
            permission={permission}
            search
            displayField="name"
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            customActions={[
                {
                    name: "view_context_help",
                    icon: "help_outline",
                    iconProps: {
                        fontSize: "small",
                    },
                    position: "row",
                    tooltip: "Контекстная справка",
                    onClick: handleContextHelpClick,
                },
            ]}
        />
    );
};

export default AttributeTypesTable;
