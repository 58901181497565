import { ProtectedPath } from "@interfaces/ProtectedPath";
import { Path } from "@interfaces/System/Path";
import PathsManager from "./PathsManager";
import { flatten } from "./Utils";

class ProtectedPathsManager {
    static getAllPaths = (protectedPaths: ProtectedPath[]): Array<Path | ProtectedPath> => {
        return [
            ...PathsManager.getPathsArray(),
            ...ProtectedPathsManager.getProtectedPathsFlatArray(protectedPaths),
        ];
    };
    public static getProtectedPathsFlatArray = (
        protectedPaths: ProtectedPath[]
    ): ProtectedPath[] => {
        return flatten<ProtectedPath>(protectedPaths, "permission");
    };

    static getPath = (
        protectedPaths: ProtectedPath[],
        searchValue: any,
        byField: string = "url"
    ) => {
        return ProtectedPathsManager.getAllPaths(protectedPaths).find((el) => {
            if (byField === "url") {
                return ProtectedPathsManager.excludeQueryParams(el[byField]) === searchValue;
            }
            return el[byField] === searchValue;
        });
    };

    public static getChildPaths = (
        protectedPaths: ProtectedPath[],
        targetUrl: string
    ): ProtectedPath[] => {
        const path = ProtectedPathsManager.getPath(protectedPaths, targetUrl) as ProtectedPath;

        return typeof path.permission !== "number" ? path.permission : [];
    };

    public static getParentPath = (
        protectedPaths: ProtectedPath[],
        targetUrl: string
    ): ProtectedPath | Path | undefined => {
        const parentKeysArr: Array<string> = targetUrl.split("/");
        const parentUrl = parentKeysArr.splice(0, parentKeysArr.length - 1).join("/");

        const path = ProtectedPathsManager.getPath(protectedPaths, parentUrl);

        return path;
    };

    public static excludeQueryParams = (url: string): string => {
        if (!url) return "";
        return url.split("?")[0];
    };

    public static replaceParams = (url: string, params: Record<string, string>): string => {
        const paramsArr = Object.entries(params);
        let output: string = url;

        paramsArr.forEach((param) => {
            output = output.replace(param[0], param[1]);
        });

        output = output.replaceAll(":", "");

        return output;
    };

    public static getAllAccessiblePaths = (protectedPaths: ProtectedPath[]): string[] => {
        const staticPaths = PathsManager.getPathsArray().map((el) => el.url);

        if (!protectedPaths.length) return staticPaths;

        return [
            ...staticPaths,
            ...ProtectedPathsManager.getProtectedPathsFlatArray(protectedPaths).map((p) =>
                ProtectedPathsManager.excludeQueryParams(p.url)
            ),
        ];
    };

    public static getBreadcrumbsPaths = (
        protectedPaths: ProtectedPath[],
        currentPath: ProtectedPath,
        withHomePath: boolean = true
    ): Array<Path | ProtectedPath> => {
        const location: Array<Path | ProtectedPath> = [];

        let parentPath: ProtectedPath | Path | undefined = currentPath;
        while (parentPath !== undefined && parentPath.url !== PathsManager.PATHS.HOME.url) {
            parentPath = ProtectedPathsManager.getParentPath(protectedPaths, parentPath?.url || "");

            if (parentPath) location.unshift(parentPath);
        }

        location.push(currentPath);

        if (withHomePath && currentPath.url !== PathsManager.PATHS.HOME.url) {
            location.unshift(PathsManager.PATHS.HOME);
        }

        return location;
    };
}

export default ProtectedPathsManager;
