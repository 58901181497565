import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { ModalContentLoading } from "@components/Common/Modal";
import React, { useRef } from "react";
import { staffCartService } from "@services/Staff";
import { useStoreon } from "storeon/react";
import { TableEvents, TableState } from "@store/table-module";
import { useSnackbar } from "notistack";
import { useAsync } from "@hooks";
import { CreateStaffCartDto, UpdateStaffCartDto } from "@dto/StaffCartDto";
import { StaffCartItemsList } from "@components/Content/StaffCart";

const StaffCartDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { eTypeId, entityFilter } = modalProps;
    const brandId = Object.values(entityFilter)[0];

    const ref = useRef<HTMLFormElement>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();

    const { data, loadingStatus } = useAsync(staffCartService.getOneStaffCart, {
        functionArgs: [{ brand_id: brandId }],
        canLoad: !!brandId,
    });

    const handleSaveCart = async () => {
        try {
            await ref.current!.handleSubmit();
            enqueueSnackbar("Корзина успешно обновлена", {
                variant: "success",
            });
            onClose();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении корзины", {
                variant: "error",
            });
        }
    };

    const handleCreateStaffOrder = async (values) => {
        try {
            const dto = staffCartService.plainToClass(CreateStaffCartDto, values);
            await staffCartService.createStaffCart(dto);
            enqueueSnackbar("Корзина успешно создана", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании корзины", {
                variant: "error",
            });
        }
    };

    const handleUpdateStaffOrder = async (values) => {
        if (!data) return;

        try {
            const dto = staffCartService.plainToClass(UpdateStaffCartDto, values);
            await staffCartService.updateStaffCart(data.id, dto);
            enqueueSnackbar("Корзина успешно обновлена", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении корзины", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {loadingStatus === "loaded" && data && (
                    <StaffCartItemsList
                        ref={ref}
                        cart={data}
                        eTypeId={eTypeId}
                        entityFilter={entityFilter}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button color="primary" variant="contained" onClick={handleSaveCart}>
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default StaffCartDialogContext;
