import { useModal } from "@hooks";
import { Fade, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { storeService } from "@services";
import { useSnackbar } from "notistack";
import React, { FunctionComponent, MouseEventHandler, useState } from "react";

const MoreActionsEntitiesButton: FunctionComponent = () => {
    const { handleOpenModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleClose: VoidFunction = () => {
        setAnchorEl(null);
    };

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const refreshPermissions: VoidFunction = () => {
        handleOpenModal("CONFIRMATION", {
            content: (
                <Typography>
                    Вы действительно хотите запустить процесс обновления скидок и прав доступа? Это
                    займет некоторое время
                </Typography>
            ),
            onOk: async (): Promise<void> => {
                await storeService.refresh();
                enqueueSnackbar("Процесс обновления успешно запущен", { variant: "success" });
            },
        });
        handleClose();
    };

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={refreshPermissions}>Обновить данные</MenuItem>
            </Menu>
        </>
    );
};

export default MoreActionsEntitiesButton;
