import { QueryParams, PaginatedQueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { StaffMenuPermission } from "@interfaces/StaffMenuPermission";
import {
    CreateStaffMenuPermissionDto,
    UpdateStaffMenuPermissionDto,
} from "@dto/SettingsDto/StaffMenuPermissionDto";

class StaffMenuPermissionService extends BaseService {
    public getPermissions = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<StaffMenuPermission>> => {
        const { data } = await this.instance.get("/settings/staff_menu_permission", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOnePermission = async (id: number): Promise<StaffMenuPermission> => {
        const { data } = await this.instance.get("/settings/staff_menu_permission", {
            params: { id },
        });

        return data.result.items[0];
    };

    public createPermission = async (
        createStaffMenuPermissionDto: CreateStaffMenuPermissionDto
    ): Promise<any> => {
        const { data } = await this.instance.post("/settings/staff_menu_permission", {
            data: createStaffMenuPermissionDto,
        });

        return data;
    };

    public updatePermission = async (
        id: number,
        updateStaffMenuPermissionDto: UpdateStaffMenuPermissionDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/settings/staff_menu_permission", {
            id,
            data: updateStaffMenuPermissionDto,
        });

        return data;
    };

    public deletePermission = async (ids: number[]): Promise<any> => {
        const { data } = await this.instance.delete("/settings/staff_menu_permission", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new StaffMenuPermissionService(new HttpClient(Config.API_URL), AuthManager);
