import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { RestrictionsManager, ValidationManager } from "@modules";
import { EntityEvents, EntityState } from "@store/entity-module";
import { UniversalRestrictionChecker } from "@components/Common";
import UniversalInputField from "@components/Common/UniversalInputField";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import { AppAttribute } from "@interfaces/Internal/AppAttribute";
import { useAsync } from "@hooks";
import { vTypesService } from "@services";
import EntityAttributeItemSkeleton from "../EntityAttributeItemSkeleton";

type EntityAttributeItemCompositeProps = { attribute: AppAttribute };

const EntityAttributeItemComposite = ({ attribute }: EntityAttributeItemCompositeProps) => {
    const { readOnly, dispatch } = useStoreon<EntityState, EntityEvents>("readOnly");
    const { enableValidation, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("enableValidation");

    const { data: vTypes, loadingStatus } = useAsync(vTypesService.getValueTypes, {
        canLoad: !!attribute.items?.length,
        functionArgs: [{ ids_list: attribute.items.map((item) => item?.vt_id).join(",") }],
    });

    const handleChangeAttributeValue = (key: string, value: any) => {
        dispatch("entity/attr/update/value", {
            at_id: attribute.atype_id,
            valueKey: key,
            value,
        });
    };

    const { valid } = ValidationManager.validateAppAttributeValueGroup(attribute.items);

    const useValidation = ValidationManager.isValidationRequired(
        attribute.required,
        attribute.items
    );

    useEffect(() => {
        validationDispatch("validation/add/validations", {
            [attribute.atype_id]: useValidation ? valid : true,
        });
    }, [validationDispatch, attribute, useValidation, valid]);

    if (loadingStatus === "loading") return <EntityAttributeItemSkeleton />;

    return (
        <>
            {attribute.items.map((attr, index) => {
                const vType = vTypes?.result.items.find((vt) => vt.id === attr.vt_id);

                const { valid, messages } = ValidationManager.validateEntityAttributeValue(
                    attr.value,
                    attr.restrictions
                );

                return (
                    <UniversalRestrictionChecker
                        key={attr.key}
                        restrictions={attr.restrictions}
                        render={(inputTypeName, { entityRelationTypeId }) => {
                            return (
                                <UniversalInputField
                                    vTypeId={attr.vt_id}
                                    findBy={attr.edited ? "id" : "attrValue"}
                                    responseValue="id"
                                    inputTypeName={inputTypeName}
                                    label={vType?.name}
                                    value={attr.value || ""}
                                    name={vType?.name || ""}
                                    onChange={(name, val) =>
                                        handleChangeAttributeValue(attr.key, val)
                                    }
                                    filterByEntityTypeId={entityRelationTypeId}
                                    fullWidth
                                    disabled={readOnly}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    error={enableValidation && useValidation && !valid}
                                    helperText={
                                        <>
                                            {attr.restrictions.map((rest) => {
                                                const errorMessage =
                                                    enableValidation &&
                                                    useValidation &&
                                                    messages[rest.code];
                                                return (
                                                    <Typography
                                                        key={rest.key}
                                                        variant="caption"
                                                        display="block"
                                                        color={
                                                            errorMessage ? "error" : "textSecondary"
                                                        }
                                                    >
                                                        {errorMessage ||
                                                            RestrictionsManager.getRestrictionItemByCode(
                                                                rest.code
                                                            )?.getHelpText(rest.value)}
                                                    </Typography>
                                                );
                                            })}
                                        </>
                                    }
                                />
                            );
                        }}
                    />
                );
            })}
        </>
    );
};

export default EntityAttributeItemComposite;
