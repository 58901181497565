import { Grid, List, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { fileService } from "@services";
import { ContentEvents, ContentState } from "@store/content-module";
import React, { useCallback, useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import FileItemMenu from "./FileItemMenu";
import { useAsync, usePagination } from "@hooks";
import FilesHeader from "./FilesHeader";
import { File } from "@interfaces/File";
import { FileItemCard, FileItemListElement } from "./FileItemVariant";
import { FileItemCardSkeleton } from "./FileItemVariant/FileItemCard";
import Pagination from "@components/Common/Pagination/Pagination";
import { Directory } from "@interfaces/Directory";

type FilesListProps = Partial<{ directory: Directory | null }>;

const FilesList = ({ directory }: FilesListProps) => {
    const {
        fileStorage: { selectedDirectory },
    } = useStoreon<ContentState, ContentEvents>("fileStorage");

    const { page, pageSize, setPage, handleChangePage, handleChangePageSize } = usePagination();
    const [selectedItem, setSelectedItem] = useState<File | null>(null);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const [view, setView] = useState<"list" | "grid">("list");

    useEffect(() => {
        setSearchValue("");
        setPage(1);
    }, [selectedDirectory, setPage]);

    const {
        data: filesResult,
        loadingStatus,
        updateData,
    } = useAsync(fileService.getFilesByDirectory, {
        functionArgs: [selectedDirectory.id, { page_size: pageSize, page, q: searchValue }],
        debounceInterval: 500,
        debounceImmediate: (prevArgs, nextArgs) => {
            return prevArgs[1]?.q === nextArgs[1]?.q;
        },
    });

    const { result: files } = { ...filesResult };

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
            const itemId = event.currentTarget.getAttribute("data-itemid");
            if (!itemId) return;

            const item = files?.items.find((el) => el.id === +itemId) || null;
            setSelectedItem(item);
            setAnchorEl(event.currentTarget);
        },
        [files]
    );

    const handleChangeView = () => {
        setView((prev) => (prev === "list" ? "grid" : "list"));
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleChangeSearchValue = useCallback(
        (value) => {
            setSearchValue(value);
            setPage(1);
        },
        [setPage]
    );

    return (
        <>
            <FilesHeader
                directoryTitle={directory?.title || ""}
                selectedDirectoryId={selectedDirectory.id}
                onUpdateFiles={updateData}
                searchValue={searchValue}
                onChangeSearch={handleChangeSearchValue}
                view={view}
                onChangeView={handleChangeView}
            />

            {view === "list" && (
                <List disablePadding>
                    {loadingStatus === "loading" &&
                        [...new Array(5)].map((e) => <Skeleton key={e} height={30} />)}

                    {loadingStatus === "loaded" && (
                        <>
                            {files?.items?.map((file) => (
                                <FileItemListElement
                                    key={file.id}
                                    item={file}
                                    onClick={handleClick}
                                />
                            ))}
                        </>
                    )}
                </List>
            )}

            {view === "grid" && (
                <>
                    {loadingStatus === "loading" && (
                        <Grid container spacing={2}>
                            {[...new Array(10)].map((e) => (
                                <FileItemCardSkeleton key={e} />
                            ))}
                        </Grid>
                    )}

                    {loadingStatus === "loaded" && (
                        <Grid container>
                            {files?.items?.map((file) => (
                                <FileItemCard key={file.id} item={file} onClick={handleClick} />
                            ))}
                        </Grid>
                    )}
                </>
            )}

            {loadingStatus === "loaded" && !files?.items?.length && (
                <Grid item>
                    <Typography>Ничего не найдено</Typography>
                </Grid>
            )}

            {loadingStatus === "loaded" && files && (
                <Pagination
                    page={page}
                    pageSize={pageSize}
                    onChangePageSize={handleChangePageSize}
                    count={files.total_pages}
                    onChangePage={handleChangePage}
                />
            )}
            <FileItemMenu
                anchorEl={anchorEl}
                onClose={handleClose}
                item={selectedItem}
                onUpdate={updateData}
            />
        </>
    );
};

export default FilesList;
