import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { ModalContentLoading } from "@components/Common/Modal";
import React, { useRef } from "react";
import { storeService } from "@services";
import { useSnackbar } from "notistack";
import { useAsync, useTable } from "@hooks";
import { CreateQuantityDto, UpdateQuantityDto } from "@dto/QuantityDto";
import { QuantityForm } from "@components/Forms/Quantity";

const QuantityDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<HTMLFormElement>();
    const { enqueueSnackbar } = useSnackbar();
    const { updateData } = useTable();

    const { data, loadingStatus } = useAsync(storeService.getOneStoreQuantity, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current!.handleSubmit();
    };

    const handleCreateQuantity = async (values) => {
        try {
            const dto = storeService.plainToClass(CreateQuantityDto, values);
            await storeService.createStoreQuantity(dto);
            enqueueSnackbar("Остаток успешно добавлен", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при добавлении остатка", {
                variant: "error",
            });
        }
    };

    const handleUpdateQuantity = async (values) => {
        if (!data) return;

        try {
            const dto = storeService.plainToClass(UpdateQuantityDto, values);
            await storeService.updateStoreQuantity(data.id, dto);
            enqueueSnackbar("Остаток успешно обновлен", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении остатка", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <QuantityForm
                        ref={formRef}
                        data={data}
                        onSubmit={data ? handleUpdateQuantity : handleCreateQuantity}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button color="primary" variant="contained" onClick={invokeSubmit}>
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default QuantityDialogContext;
