import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateTypeBindDto {
    @Expose()
    readonly type_id: number;

    @Expose()
    readonly type_name: string;
}
