import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateStaffMenuItemDto {
    @Expose()
    readonly title: string;

    @Expose()
    readonly url?: string;

    @Expose()
    readonly sort: number;

    @Expose()
    readonly parent_id?: number | null;

    @Expose()
    readonly description?: string;

    @Expose()
    readonly page_type: string;

    @Expose()
    readonly content_type?: string;

    @Expose()
    readonly icon?: string;
}
