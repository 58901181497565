import * as Yup from "yup";

export const PermissionSettingsSchema = Yup.object().shape({
    table_name: Yup.string().required("Пожалуйста, укажите название таблицы"),
    username: Yup.string().required("Пожалуйста, укажите логин пользователя"),
    e_id: Yup.number(),
    permission: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, укажите уровень доступа"),
});
