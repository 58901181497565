import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateReserveStoreBindingDto {
    @Expose()
    readonly store_id: number;

    @Expose()
    readonly reserve_id: number;

    @Expose()
    readonly count: number;
}
