import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { AppRestriction } from "@interfaces/Internal/AppRestriction";
import RestrictionItem from "./RestrictionItem";
import shortid from "shortid";
import { Add } from "@material-ui/icons";
import { RestrictionsManager } from "@modules";

interface Props {
    restrictions: AppRestriction[];
    onChange: (values) => void;
    onChangeUseRestrictions?: (value) => void;
}

const ValueTypeRestrictions = ({ restrictions, onChange, onChangeUseRestrictions }: Props) => {
    const [state, setState] = useState<{
        useRestrictions: boolean;
    }>({
        useRestrictions: Boolean(restrictions.length),
    });

    const handleUseRestrictionsClick = () => {
        setState((prevState) => ({
            ...prevState,
            useRestrictions: !state.useRestrictions,
        }));

        onChangeUseRestrictions && onChangeUseRestrictions(!state.useRestrictions);
    };

    const handleAddRestrictionClick = () => {
        const items = [
            ...restrictions,
            {
                key: shortid.generate(),
                code: "",
                value: null,
            },
        ];

        onChange(items);
    };

    const handleDeleteRestrictionClick = (restKey: string) => {
        const items = [...restrictions];
        const index = items.findIndex((el) => el.key === restKey);

        items.splice(index, 1);

        onChange(items);
    };

    const handleChangeRestriction = (attr: string, value: any, restKey: string) => {
        const items = [...restrictions];
        const index = items.findIndex((el) => el.key === restKey);

        items[index][attr] = value;
        onChange(items);
    };

    const activeRestrictions: string[] = restrictions
        .filter((el) => !!el.code && !el.custom)
        .map((r) => r.code);

    return (
        <>
            <Grid container justifyContent="space-between">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state.useRestrictions}
                            onChange={handleUseRestrictionsClick}
                            name="use_restrictions"
                            color="primary"
                        />
                    }
                    label="Использовать ограничения"
                />
                {state.useRestrictions && (
                    <Button
                        color="primary"
                        size="small"
                        onClick={handleAddRestrictionClick}
                        disabled={
                            restrictions.length >=
                            RestrictionsManager.getRestrictionsArrayWithoutCustom().length
                        }
                        startIcon={<Add />}
                    >
                        Добавить ограничение
                    </Button>
                )}
            </Grid>
            {state.useRestrictions &&
                restrictions?.map((item, i) => {
                    return (
                        <RestrictionItem
                            disabledRestrictions={RestrictionsManager.getDisabledRestrictions(
                                i,
                                activeRestrictions
                            )()}
                            restItem={item}
                            key={item.key}
                            onDelete={handleDeleteRestrictionClick}
                            onChangeType={(value, restKey) =>
                                handleChangeRestriction("code", value, restKey)
                            }
                            onChangeValue={(code, value, restKey) =>
                                handleChangeRestriction("value", value, restKey)
                            }
                        />
                    );
                })}
        </>
    );
};

export default ValueTypeRestrictions;
