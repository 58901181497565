import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { AppAttribute } from "@interfaces/Internal/AppAttribute";
import { AttributeTypeVariant } from "@modules/EntityAttributesManager";
import AddNewEntityAttributeValueButton from "../../../Controls/Actions/AddNewEntityAttributeValueButton";
import EntityAttributeItemSingle from "../EntityAttributeItemSingle/EntityAttributeItemSingle";
import EntityAttributeItemTable from "../EntityAttributeItemMultipleComposite/EntityAttributeItemTable";
import EntityAttributeItemMultiple from "../EntityAttributeItemMultiple/EntityAttributeItemMultiple";
import EntityAttributeItemComposite from "../EntityAttributeItemComposite/EntityAttributeItemComposite";
import RequiredTypeSymbol from "../../RequiredTypeSymbol/RequiredTypeSymbol";
import { EntityAttributeSchema } from "@interfaces/EntityAttributeSchema";

const useStyles = makeStyles(() => ({
    card: {
        margin: "1rem 0",
        padding: ".5rem 1rem",
    },
}));

interface EntityAttributeCardProps {
    attribute: AppAttribute;
    schema: EntityAttributeSchema[];
    readOnly?: boolean;
}

const EntityAttributeCard = (props: EntityAttributeCardProps) => {
    const { attribute, schema, readOnly } = props;

    const classes = useStyles();

    return (
        <Card className={classes.card} component="li">
            <Grid container justifyContent="space-between">
                <div>
                    <RequiredTypeSymbol type={attribute.required} />
                    <Typography variant="subtitle1" display="inline">
                        (#<strong>{attribute.atype_id}</strong>) {attribute.a_name}
                    </Typography>
                </div>
                {attribute.variant === AttributeTypeVariant.multiple && !readOnly && (
                    <AddNewEntityAttributeValueButton
                        attribute={attribute}
                        color="primary"
                        size="small"
                    />
                )}
            </Grid>

            {attribute.variant === AttributeTypeVariant.single && (
                <EntityAttributeItemSingle attribute={attribute} />
            )}

            {attribute.variant === AttributeTypeVariant.multiple && (
                <EntityAttributeItemMultiple attribute={attribute} />
            )}

            {attribute.variant === AttributeTypeVariant.composite && (
                <EntityAttributeItemComposite attribute={attribute} />
            )}

            {attribute.variant === AttributeTypeVariant.composite_multiple && (
                <EntityAttributeItemTable attribute={attribute} schema={schema} />
            )}
        </Card>
    );
};

export default EntityAttributeCard;
