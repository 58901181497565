import { StaffMember } from "@interfaces/StaffMember";
import { Typography } from "@material-ui/core";
import React from "react";
import { useCurrentPageInfo, useModal } from "@hooks";
import { Utils } from "@modules";
import { staffService } from "@services/Staff";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";

const BrandManagersTable = () => {
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("BIND_STAFF_MEMBER_BRANDS", { itemId: rowData.id });
    };

    const columns: DataTableColumn<StaffMember>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Логин",
            field: "login",
            width: "auto",
        },
        {
            title: "Email",
            field: "email",
            width: "auto",
        },
        {
            title: "ФИО",
            field: "fullname",
            sorting: false,
            render: (rowData) => {
                return <Typography variant="body2">{Utils.getUserFullName(rowData)}</Typography>;
            },
            width: "auto",
        },
        {
            title: "Роль",
            field: "memberof",
            render: (rowData) => {
                return (
                    <>
                        {rowData.memberof.map((el) => (
                            <Typography variant="body2" key={el}>
                                {el}
                            </Typography>
                        ))}
                    </>
                );
            },
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={({ ...params }) =>
                staffService.getAllStaffMembers({
                    ...params,
                    memberof: "@>{brand_manager}",
                })
            }
            columns={columns}
            displayField="email"
            permission={permission}
            onEditClick={handleEditClick}
            sorting
            search
        />
    );
};

export default BrandManagersTable;
