import { Button, ButtonProps } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";
import { useStoreon } from "storeon/react";
import { ETypeEvents, ETypeState } from "../../../../store/etype-module";

const AddNewEntityItemButton = ({ ...props }: ButtonProps) => {
    const { dispatch } = useStoreon<ETypeState, ETypeEvents>();

    const handleClick = () => {
        dispatch("etype/add/item");
    };
    return (
        <Button {...props} onClick={handleClick} startIcon={<Add />}>
            Новый тип атрибута
        </Button>
    );
};

export default AddNewEntityItemButton;
