import React, { createRef, ComponentType } from "react";
import { BaseRouting } from "react-base-routing";
import { withProtectedPaths } from "@hoc";
import { WithProtectedPathsProps } from "@hoc/withProtectedPaths";
import Error404Page from "./pages/Error404Page";
import { ErrorBoundary } from "@components/Common/ErrorBoundary";
import { ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayJsUtils from "@date-io/dayjs";
import { CssBaseline, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useAppTheme } from "@hooks";
import { compose } from "@modules/Utils";

import dayjs from "dayjs";
import "dayjs/locale/ru";

import GlobalStyles from "./globalStyles";

dayjs.locale("ru");

const withTheme = (Component: ComponentType) => (props) => {
    const theme = useAppTheme();

    const notistackRef = createRef<SnackbarProvider>();
    const onClickDismiss = (key) => () => {
        notistackRef.current?.closeSnackbar(key);
    };

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                ref={notistackRef}
                action={(key) => (
                    <IconButton color="inherit" size="small" onClick={onClickDismiss(key)}>
                        <Close />
                    </IconButton>
                )}
            >
                <MuiPickersUtilsProvider utils={DayJsUtils}>
                    <CssBaseline />
                    <GlobalStyles />
                    <Component {...props} />
                </MuiPickersUtilsProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

const App = ({ paths, routes }: WithProtectedPathsProps<{}>) => {
    return (
        <ErrorBoundary>
            <BaseRouting routes={routes} paths={paths} notFoundPage={Error404Page} />
        </ErrorBoundary>
    );
};

export default compose(withTheme, withProtectedPaths)(App);
