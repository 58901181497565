import { createStoreon } from "storeon";
import { storeonDevtools } from "storeon/devtools";

import { authModule, AuthState, AuthEvents } from "./auth-module";
import { modalModule, ModalEvents, ModalState } from "./modal-module";
import { tableModule, TableState, TableEvents } from "./table-module";
import { aTypeModule, ATypeState, ATypeEvents } from "./atype-module";
import { eTypeModule, ETypeState, ETypeEvents } from "./etype-module";
import { entityModule, EntityState, EntityEvents } from "./entity-module";
import { commonModule, CommonState, CommonEvents } from "./common-module";
import { validationModule, ValidationState, ValidationEvents } from "./validation-module";
import { ContentEvents, contentModule, ContentState } from "./content-module";

export type StoreState = CommonState &
    AuthState &
    ModalState &
    TableState &
    ATypeState &
    ETypeState &
    EntityState &
    ValidationState &
    ContentState;

export type StoreEvents = CommonEvents &
    AuthEvents &
    ModalEvents &
    TableEvents &
    ATypeEvents &
    ETypeEvents &
    EntityEvents &
    ValidationEvents &
    ContentEvents;

export const store = createStoreon<StoreState, StoreEvents>([
    commonModule,
    authModule,
    modalModule,
    tableModule,
    aTypeModule,
    eTypeModule,
    entityModule,
    validationModule,
    contentModule,
    process.env.NODE_ENV !== "production" && storeonDevtools,
]);
