import { ProtectedPath } from "@interfaces/ProtectedPath";
import { PageTypesManager, PathsManager } from "@modules";
import { AppRoute, LayoutRoute } from "react-base-routing";
import { CabinetPage, HomePage, LoginPage } from "../pages";
import { AuthLayout, DefaultLayout } from "../layouts";
import { WithPageProps } from "./PageTypesManager";
import { WithCombinedContentTypeProps } from "./ContentTypesManagers";
import ProtectedPathsManager from "./ProtectedPathsManager";

class RouteManager {
    static getStaticRoutes = (): LayoutRoute[] => {
        return RouteManager.STATIC_ROUTES;
    };

    static readonly STATIC_ROUTES: LayoutRoute[] = [
        {
            layout: AuthLayout,
            routes: [
                {
                    path: PathsManager.PATHS.LOGIN.url,
                    exact: true,
                    component: LoginPage,
                },
            ],
        },
        {
            layout: DefaultLayout,
            routes: [
                {
                    path: PathsManager.PATHS.HOME.url,
                    exact: true,
                    component: HomePage,
                },
                {
                    path: PathsManager.PATHS.CABINET.url,
                    exact: true,
                    component: CabinetPage,
                },
            ],
        },
    ];

    static getProtectedRoutes = (protectedPaths: ProtectedPath[]): AppRoute[] => {
        let routes = [] as AppRoute[];

        protectedPaths.forEach((page) => {
            if (page.permission instanceof Array) {
                routes = [...routes, ...RouteManager.getProtectedRoutes(page.permission)];
            }

            const contentProps: WithCombinedContentTypeProps<{}> = {
                contentType: page.content_type,
            };

            const pageProps: WithPageProps<{}> = {
                url: page.url,
                icon: page.icon,
                title: page.title,
                description: page.description,
            };

            const Page = PageTypesManager.getPageType(page.page_type);

            const Component = Page
                ? Page.getPage({ ...pageProps, ...contentProps })
                : PageTypesManager.getErrorPage(new Error("Page type not found!"), pageProps);

            const route: AppRoute = {
                path: ProtectedPathsManager.excludeQueryParams(page.url),
                exact: true,
                render: () => Component,
            };

            routes.push(route);
        });

        return routes;
    };

    static insertRoutes = (
        target: LayoutRoute[],
        routes: AppRoute[],
        layout = "default"
    ): LayoutRoute[] => {
        const copy = [...target];
        copy[1].routes.push(...routes);
        return copy;
    };
}

export default RouteManager;
