import { QueryParams, PaginatedQueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { ProtectedPath } from "@interfaces/ProtectedPath";
import { CreateStaffMenuItemDto, UpdateStaffMenuItemDto } from "@dto/SettingsDto/StaffMenuItemsDto";

class StaffMenuService extends BaseService {
    public getStaffMenuItems = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<ProtectedPath>> => {
        const { data } = await this.instance.get("/settings/staff_menu_item", {
            params,
        });

        return this.toPaginatedUniversalOutput<ProtectedPath>(data);
    };

    public getOneStaffMenuItem = async (id: number): Promise<ProtectedPath> => {
        const { data } = await this.instance.get("/settings/staff_menu_item", {
            params: { id },
        });

        return data.result.items[0];
    };

    public createStaffMenuItem = async (
        createStaffMenuItemDto: CreateStaffMenuItemDto
    ): Promise<any> => {
        const { data } = await this.instance.post("/settings/staff_menu_item", {
            data: createStaffMenuItemDto,
        });

        return data;
    };

    public updateStaffMenuItem = async (
        id: number,
        updateStaffMenuItemDto: UpdateStaffMenuItemDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/settings/staff_menu_item", {
            id,
            data: updateStaffMenuItemDto,
        });

        return data;
    };

    public deleteStaffMenuItems = async (ids: number[]): Promise<void> => {
        const { data } = await this.instance.delete("/settings/staff_menu_item", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new StaffMenuService(new HttpClient(Config.API_URL), AuthManager);
