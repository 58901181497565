import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateOrderDto {
    @Expose()
    readonly user_id: number;

    // readonly delivery_address_id: number;

    @Expose()
    readonly status: string;
}
