import React from "react";
import { storeService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn, DataTableProps } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo, useModal } from "@hooks";
import { useSnackbar } from "notistack";
import { ServiceHelper } from "@modules";
import { ReserveStoreBinding } from "@interfaces/ReserveStoreBinding";

const ReserveStoreBindingsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();

    const handleEditClick: DataTableProps<ReserveStoreBinding>["onEditClick"] = (rowData) => {
        if (rowData instanceof Array) return;
        handleOpenModal("EDIT_RESERVE_STORE_BINDING", {
            itemId: rowData.id,
            titleProps: rowData.store_id,
        });
    };

    const handleDeleteClick = async (rowData: ReserveStoreBinding | ReserveStoreBinding[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await storeService.deleteQuantityReserves(ids);
            enqueueSnackbar("Привязки успешно удалены", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении привязок", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<ReserveStoreBinding>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 100,
        },
        {
            title: "ID Магазина",
            field: "store_id",
            width: "auto",
        },
        {
            title: "ID Резерва",
            field: "reserve_id",
            width: "auto",
        },
        {
            title: "Количество",
            field: "count",
            width: "auto",
        },
    ];

    return (
        <DataTable<ReserveStoreBinding>
            fetchFn={storeService.getReserveStoreBindings}
            columns={columns}
            displayField="id"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            filtering
            sorting
            search
        />
    );
};

export default ReserveStoreBindingsTable;
