import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialTable, {
    MaterialTableProps,
    MTableAction,
    Column,
    Action,
    Query,
} from "@material-table/core";
import { Icon, IconButton, Tooltip, PropTypes } from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import { PaginatedQueryResult, QueryParams } from "@services";
import { useStoreon } from "storeon/react";
import { store } from "@store";
import { TableEvents, TableState } from "@store/table-module";
import { PermissionManager, ServiceHelper } from "@modules";
import ConfirmationContent from "./ConfirmationContent";
import { tableIcons, tableLocalization } from "./DataTableModules";
import { PermissionWithNoValue } from "@modules/PermissionManager";
import { useTheme } from "@material-ui/core/styles";
import { useModal } from "@hooks";

type FetchDynamicResult<DataType> = {
    data: PaginatedQueryResult<DataType>;
    columns: DataTableColumn<DataType>[];
};

type FetchResult<DataType> = PaginatedQueryResult<DataType>;
export interface DataTableColumn<DataType extends {}> extends Column<DataType> {}

export interface RowAction<DataType extends {}> extends Action<DataType> {
    name: string;
    color?: PropTypes.Color;
}

export type DataTableProps<DataType extends object> = Omit<
    MaterialTableProps<DataType>,
    "columns" | "data"
> & {
    title?: string;
    columns?: DataTableColumn<DataType>[];
    data?: DataType[];
    customActions?: RowAction<DataType>[];
    dynamicColumns?: boolean;
    fetchFn: ({
        ...params
    }: QueryParams) => Promise<FetchResult<DataType>> | Promise<FetchDynamicResult<DataType>>;
    displayField?: keyof DataType | "id";
    toolbar?: boolean;
    search?: boolean;
    grouping?: boolean;
    selection?: boolean;
    pageSize?: number;
    filtering?: boolean;
    sorting?: boolean;
    permission?: PermissionWithNoValue;
    onRowClick?: (e, rowData?: DataType) => void;
    onEditClick?: (rowData: DataType | DataType[]) => void;
    onDeleteClick?: (rowData: DataType) => Promise<void>;
    onMassDeleteClick?: (rowData: DataType | DataType[]) => Promise<void>;
    isEditable?: (rowData: DataType) => boolean;
    isDeletable?: (rowData: DataType) => boolean;
};

const DataTable = <T extends object>({
    title,
    columns = [],
    dynamicColumns = false,
    fetchFn,
    permission = -1,
    customActions = [],
    displayField = "id",
    search = false,
    toolbar = true,
    grouping = false,
    selection = false,
    pageSize = 50,
    filtering = false,
    sorting = false,
    onRowClick,
    onEditClick,
    onDeleteClick,
    onMassDeleteClick,
    isEditable,
    isDeletable,
    ...other
}: DataTableProps<T>): React.ReactElement => {
    const { palette } = useTheme();
    const tableRef = useRef<any>();

    const { handleOpenModal } = useModal();
    const { dispatch } = useStoreon<TableState, TableEvents>();

    const [state, setState] = useState({
        columns: [] as DataTableColumn<T>[],
        isDelete: false,
        hideSelection: selection,
    });

    useEffect(() => {
        return () => dispatch("table/set/filters", []);
    }, [dispatch]);

    useEffect(() => {
        if (tableRef.current) {
            dispatch("table/set/ref", tableRef);
        }

        return () => dispatch("table/set/ref", null);
    }, [tableRef, dispatch]);

    const fetch = async (q: Query<T>) => {
        const { page, pageSize, orderDirection, orderBy, totalCount, search, filters } = q;

        let nextPage = page + 1;
        if (totalCount === pageSize * page + 1 && page !== 0 && state.isDelete) {
            nextPage = 1;
            setState((prevState) => ({
                ...prevState,
                isDelete: false,
            }));
        }

        const response = await fetchFn({
            page: nextPage,
            page_size: pageSize,
            q: search ? search : undefined,
            sorting: ServiceHelper.getSortingParam(
                orderBy?.field?.toString() || "",
                orderDirection
            ),
            ...ServiceHelper.getSchemaBasedFiltersObject(store.get().filters),
            ...ServiceHelper.getFiltersObjectFromTable<T>(filters),
        });

        if ("result" in response) {
            const data = response.result.items || [];

            setState((prevState) => ({
                ...prevState,
                hideSelection: !data.length,
            }));

            return {
                data,
                page: nextPage - 1,
                totalCount: response.result.count,
            };
        } else {
            /** for dynamic columns rendering */

            const data = response.data.result.items || [];

            if (!state.columns.length) {
                setState((prevState) => ({
                    ...prevState,
                    columns: response.columns,
                    hideSelection: !data.length,
                }));
            }

            return {
                data,
                page: nextPage - 1,
                totalCount: response.data.result.count,
            };
        }
    };

    const getActions = useMemo((): MaterialTableProps<T>["actions"] => {
        const actions: MaterialTableProps<T>["actions"] = [];

        actions.push(...customActions);

        if (PermissionManager.canEdit(permission) && onEditClick) {
            actions.push({
                action: (rowData: T) => ({
                    name: "cedit",
                    color: "primary",
                    icon: "edit",
                    tooltip: "Редактировать",
                    iconProps: {
                        fontSize: "small",
                    },
                    onClick: (_, rowData) => onEditClick?.(rowData),
                    disabled: isEditable ? !isEditable(rowData) : false,
                }),
                position: "row",
            });
        }
        if (PermissionManager.canDelete(permission) && onMassDeleteClick) {
            actions.push({
                tooltip: "Удалить выбранные элементы",
                icon: "delete",
                position: "toolbarOnSelect",
                onClick: (_, data) => {
                    handleOpenModal("CONFIRMATION", {
                        onOk: async () => {
                            await onMassDeleteClick(data);
                            dispatch("table/update/data");
                        },
                        content: (
                            <ConfirmationContent
                                data={data instanceof Array ? data : [data]}
                                displayField={displayField as string}
                            />
                        ),
                    });
                },
            });
        }

        return actions;
    }, [
        permission,
        onEditClick,
        onMassDeleteClick,
        handleOpenModal,
        customActions,
        dispatch,
        displayField,
        isEditable,
    ]);

    return (
        <MaterialTable
            title={title || ""}
            columns={dynamicColumns ? state.columns : columns}
            tableRef={tableRef}
            icons={tableIcons}
            data={fetch}
            onRowClick={onRowClick}
            localization={tableLocalization}
            onPageChange={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            components={{
                Action: (props) => {
                    const action =
                        typeof props.action.action === "function"
                            ? props.action.action(props.data)
                            : props.action;

                    if (!action.name) return <MTableAction {...props} />;

                    return (
                        <Tooltip title={action.tooltip}>
                            <span>
                                <IconButton
                                    style={{ marginRight: 5 }}
                                    color={action.color}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        action.onClick(e, props.data);
                                    }}
                                    size={props.size}
                                    disabled={action.disabled}
                                >
                                    <Icon {...action.iconProps}>{action.icon}</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    );
                },
            }}
            options={{
                columnsButton: true,
                debounceInterval: 500,
                emptyRowsWhenPaging: false,
                tableLayout: "auto",
                pageSizeOptions: [50, 100, 200],
                actionsColumnIndex: -1,
                selection:
                    selection && !state.hideSelection && PermissionManager.canDelete(permission),
                filtering,
                search,
                toolbar,
                pageSize,
                grouping,
                sorting,
                padding: "dense",
                thirdSortClick: false,
                rowStyle: (rowData) =>
                    rowData.tableData.checked && {
                        backgroundColor: pink[50],
                        color: palette.common.black,
                    },
                ...other.options,
            }}
            actions={getActions}
            editable={{
                isDeleteHidden: () => !PermissionManager.canDelete(permission),
                isDeletable,
                onRowDelete: (rowData) =>
                    new Promise(async (resolve) => {
                        await onDeleteClick?.(rowData);
                        setState((prevState) => ({
                            ...prevState,
                            isDelete: true,
                        }));
                        resolve(rowData);
                    }),
            }}
        />
    );
};

export default DataTable;
