import { PaginatedQueryResult } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { StaffRoleDto } from "@dto/StaffRoleDto";
import { ServiceRole } from "@interfaces/ServiceRole";

class StaffRoleService extends BaseService {
    public getStaffRoles = async (): Promise<PaginatedQueryResult<ServiceRole>> => {
        const { data } = await this.instance.get("/staff_permission");

        return data;
    };

    public grantStaffRole = async (staffRoleDto: StaffRoleDto): Promise<any> => {
        const { data } = await this.instance.post("/staff_permission", staffRoleDto);

        return data;
    };

    public revokeStaffRole = async (staffRoleDto: StaffRoleDto): Promise<any> => {
        const { data } = await this.instance.delete("/staff_permission", {
            data: staffRoleDto,
        });

        return data;
    };
}

export default new StaffRoleService(new HttpClient(Config.API_URL), AuthManager);
