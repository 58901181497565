import { Exclude, Expose, Transform } from "class-transformer";

@Exclude()
export class CreateDirectoryDto {
    @Expose()
    readonly title: string;

    @Expose({ name: "parentDirId" })
    @Transform(({ value }) => value ?? 1)
    readonly parent_dir_id: number;
}
