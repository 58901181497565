import { Store } from "@interfaces/Store";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { ModalContentLoading } from "../../Common/Modal";
import StoreForm from "../../Forms/Store/StoreForm";
import React, { useRef } from "react";
import { storeService } from "@services";
import { useSnackbar } from "notistack";
import { CreateStoreDto, UpdateStoreDto } from "@dto/StoreDto";
import { useAsync, useLoading, useTable } from "@hooks";

const StoreDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<HTMLFormElement>();
    const { enqueueSnackbar } = useSnackbar();
    const { updateData } = useTable();

    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data, loadingStatus } = useAsync<Store>(storeService.getOneStore, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current!.handleSubmit();
    };

    const handleCreate = async (values) => {
        try {
            const dto = storeService.plainToClass(CreateStoreDto, values);
            await storeService.createStore(dto);
            enqueueSnackbar("Склад успешно добавлен", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при добавлении склада", {
                variant: "error",
            });
        }
    };

    const handleUpdate = async (values) => {
        if (!data) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = storeService.plainToClass(UpdateStoreDto, values);
            await storeService.updateStore(data.id, dto);
            enqueueSnackbar("Склад успешно обновлен", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении склада", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <StoreForm
                        onSubmit={data ? handleUpdate : handleCreate}
                        ref={formRef}
                        data={data}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default StoreDialogContext;
