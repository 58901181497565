import { Exclude, Expose } from "class-transformer";

@Exclude()
class CreateStaffOrderDto {
    @Expose()
    readonly brand_id: number;

    @Expose()
    readonly brand_dealer_binding_id: number | null;

    @Expose()
    readonly collection_id: number;

    @Expose()
    readonly status: string;

    @Expose()
    readonly comment: string;

    @Expose()
    readonly brand_manager_checked: boolean;

    @Expose()
    readonly content_manager_checked: boolean;

    @Expose()
    readonly commodity_expert_checked: boolean;

    @Expose()
    readonly dealer_checked: boolean;
}

export default CreateStaffOrderDto;
