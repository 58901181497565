import BaseService from "./BaseService";
import { AxiosResponse } from "axios";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient, { HttpResponse } from "@modules/HttpClient";
import { PaginatedQueryResult, QueryParams, QueryResultWithoutData } from "@services";
import { CsvLoadStatus } from "@interfaces/CsvLoadStatus";

class NsiService extends BaseService {
    public downloadCSVtemplate = async (
        eTypeId: number,
        options?: { deferredLoading: boolean }
    ): Promise<AxiosResponse<any>> => {
        const response = await this.instance.get("/nsi/csv_file", {
            params: { id: eTypeId },
            responseType: "blob",
        });

        if (!options?.deferredLoading) {
            this.download(response, "template.csv", {
                blobType: "text/csv",
            });
        }

        return response;
    };

    public uploadCSVdata = async (file: File, typeId: number): Promise<string> => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type_id", String(typeId));

        const { data } = await this.instance.post("/nsi/csv_file", formData);

        return data.result;
    };

    public downloadEntityShapshot = async (
        eTypeId: number,
        options?: { deferredLoading: boolean; filters?: Record<string, unknown> }
    ): Promise<HttpResponse> => {
        const response = await this.instance.get("/nsi/entity_view_snapshop", {
            params: { id: eTypeId, ...options?.filters },
            responseType: "blob",
        });

        if (!options?.deferredLoading) {
            this.download(response, "shapshot.csv", {
                blobType: "text/csv",
            });
        }

        return response;
    };

    public uploadEntityShapshot = async (file: File, typeId: number): Promise<any> => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type_id", String(typeId));

        const { data } = await this.instance.post("/nsi/entity_view_snapshop", formData);

        return data.result;
    };

    public getCsvLoadStatuses = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<CsvLoadStatus>> => {
        const { data } = await this.instance.get("/nsi/csv_load_status", { params });

        return this.toPaginatedUniversalOutput(data);
    };

    public removeCsvLoadStatus = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/nsi/csv_load_status", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new NsiService(new HttpClient(Config.API_URL), AuthManager);
