import { QueryParams, QueryResult } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { ContextHelp } from "@interfaces/ContextHelp";
import { CreateContextHelpDto, UpdateContextHelpDto } from "@dto/SettingsDto/ContextHelpDto";

class StaffContextHelpService extends BaseService {
    public getOneContextHelp = async (params: QueryParams): Promise<QueryResult<ContextHelp>> => {
        const { data } = await this.instance.get("/staff/context_help", {
            params,
        });

        return data;
    };

    public createContextHelp = async (
        createContextHelp: CreateContextHelpDto
    ): Promise<QueryResult<ContextHelp>> => {
        const { data } = await this.instance.post("/staff/context_help", createContextHelp);

        return data;
    };

    public updateContextHelp = async (
        id: number,
        updateContextHelp: UpdateContextHelpDto
    ): Promise<QueryResult<ContextHelp>> => {
        const { data } = await this.instance.patch("/staff/context_help", {
            id,
            data: updateContextHelp,
        });

        return data;
    };

    public deleteContextHelp = async (id: number): Promise<QueryResult<ContextHelp>> => {
        const { data } = await this.instance.delete("/staff/context_help", {
            data: { ids_list: [id] },
        });

        return data;
    };
}

export default new StaffContextHelpService(new HttpClient(Config.API_URL), AuthManager);
