import { Breadcrumbs as MuiBreadcrumbs, Icon, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavigateNext } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { CommonEvents, CommonState } from "@store/common-module";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useParams, useRouteMatch } from "react-router-dom";
import { useStoreon } from "storeon/react";
import { Path } from "@interfaces/System/Path";
import { ProtectedPathsManager } from "@modules";
import { ContentTypesManager } from "@modules/ContentTypesManagers";
import CombinedContentTypesManager from "@modules/ContentTypesManagers/CombinedContentTypesManager";
import { truncateText } from "@modules/Utils";
import { Common } from "@modules/Constants";
import { TitleTooltip } from "../Title";
import { useLoading } from "@hooks";
import { ProtectedPath } from "@interfaces/ProtectedPath";

const useStyles = makeStyles((theme) => ({
    link: {
        display: "flex",
    },
    icon: {
        marginRight: theme.spacing(1),
        width: 20,
        height: 20,
    },
}));

const Breadcrumbs = () => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const params = useParams();
    const { loadingStatus, setLoadingStatus } = useLoading();
    const { menu } = useStoreon<CommonState, CommonEvents>("menu");
    const [routes, setRoutes] = useState<Path[]>([] as Path[]);

    const getRoutes = useCallback((): Array<Path | ProtectedPath> => {
        const currentPath = ProtectedPathsManager.getPath(menu, path) as ProtectedPath;
        return ProtectedPathsManager.getBreadcrumbsPaths(menu, currentPath);
    }, [menu, path]);

    useEffect(() => {
        const fetch = async () => {
            const routes = getRoutes();
            const promises = routes.map(async (el: any) => {
                const contentType = CombinedContentTypesManager.getContentType(
                    el.content_type || ""
                );
                const content = contentType?.content;
                if (!content) return el;

                const getTitle =
                    content instanceof Array
                        ? ContentTypesManager.getContentType(content[0])?.content.getTitle
                        : content.getTitle;

                const param: string = Object.values<string>(params).splice(-1, 1)[0];
                const title = getTitle && param ? await getTitle(param) : el.title;

                return {
                    ...el,
                    title: title || el.title,
                };
            });

            const result = await Promise.all(promises);
            setRoutes(result);
        };

        setLoadingStatus("loading");
        fetch().then(() => setLoadingStatus("loaded"));
    }, [params, getRoutes, setLoadingStatus]);

    if (loadingStatus === "loading") return <Skeleton width={600} height={20} />;

    return (
        <MuiBreadcrumbs separator={<NavigateNext fontSize="small" />}>
            {routes.map((route, index) => {
                const maxLen = Common.TITLE_TRUNCATE_LEN;
                const truncatedTitle = truncateText(route.title, maxLen);

                if (index !== routes.length - 1) {
                    return (
                        <TitleTooltip key={index} title={route.title} maxLen={maxLen}>
                            <Link
                                color="inherit"
                                component={NavLink}
                                className={classes.link}
                                to={ProtectedPathsManager.replaceParams(route.url, params)}
                            >
                                {route.icon && <Icon className={classes.icon}>{route.icon}</Icon>}
                                {truncatedTitle}
                            </Link>
                        </TitleTooltip>
                    );
                } else {
                    return (
                        <TitleTooltip key={index} title={route.title} maxLen={maxLen}>
                            <Typography color="textPrimary" className={classes.link}>
                                {route.icon && <Icon className={classes.icon}>{route.icon}</Icon>}
                                {truncatedTitle}
                            </Typography>
                        </TitleTooltip>
                    );
                }
            })}
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;
