import {
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Typography,
    Button,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Help } from "@material-ui/icons";
import clsx from "clsx";
import React, { createRef, useRef, useEffect, useCallback } from "react";
import useContextHelp from "@hooks/useContextHelp";
import EditIcon from "@material-ui/icons/Edit";
import { CombinedType } from "@modules/ContentTypesManagers/CombinedContentTypesManager";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    cardContent: {
        maxHeight: 350,
        overflow: "hidden",
        padding: theme.spacing(1, 2),
    },
    sticky: {
        position: "sticky",
        top: 80,
    },
    title: {
        fontSize: 14,
        display: "flex",
        alignItems: "center",
    },
    header: {
        padding: theme.spacing(2, 2, 0, 2),
    },
    action: {
        marginTop: "auto",
        marginBottom: "auto",
    },
}));

type Props = { contentType?: CombinedType; sticky?: boolean };

const SectionHelpCard = ({ contentType, sticky = false }: Props) => {
    const classes = useStyles();

    const contentRef = createRef<HTMLElement>();
    const actionsRef = useRef<HTMLElement>();

    const { onContextClickHandler, html, loadingStatus, canModify } = useContextHelp({
        overrideContentType: contentType,
        autoLoad: true,
    });

    const resize = useCallback(() => {
        if (!contentRef.current || !actionsRef.current) return;

        const { offsetHeight, scrollHeight } = contentRef.current;

        if (offsetHeight < scrollHeight) {
            actionsRef.current.style.display = "block";
        } else {
            actionsRef.current.style.display = "none";
        }
    }, [contentRef, actionsRef]);

    useEffect(() => {
        resize();
        window.addEventListener("resize", resize);

        return () => window.removeEventListener("resize", resize);
    }, [resize]);

    return (
        <Card className={clsx({ [classes.sticky]: sticky })}>
            <CardHeader
                className={classes.header}
                classes={{
                    action: classes.action,
                }}
                title={
                    <Typography className={classes.title} color="textSecondary">
                        <Help style={{ marginRight: 10 }} /> Справка по разделу
                    </Typography>
                }
                action={
                    canModify && (
                        <Tooltip title={html ? "Изменить" : "Создать"} placement="top" arrow>
                            <IconButton
                                aria-label="edit"
                                color="primary"
                                size="small"
                                onClick={onContextClickHandler}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
            ></CardHeader>
            <CardContent className={classes.cardContent} innerRef={contentRef}>
                {loadingStatus === "loading" && <Skeleton width="100%" height={40} />}

                {loadingStatus === "loaded" && html && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: html,
                        }}
                    />
                )}

                {loadingStatus === "loaded" && !html && (
                    <Typography color="textSecondary" gutterBottom>
                        Для этого раздела справка отсутствует.
                    </Typography>
                )}
            </CardContent>
            <CardActions innerRef={actionsRef}>
                <Button variant="text" size="small" color="primary" onClick={onContextClickHandler}>
                    Подробнее
                </Button>
            </CardActions>
        </Card>
    );
};

export default SectionHelpCard;
