import { Grid, TextField } from "@material-ui/core";
import { Form, Formik, FormikConfig } from "formik";
import React, { forwardRef } from "react";
import { Subtitle } from "../../Views/Title";
import { CreateStaffMemberSchema } from "../../../schema/Staff/CreateStaffMemberSchema";
import { PasswordField, PhoneNumberInput } from "../../Controls/Inputs";
import ServiceRoleSelect from "../../Controls/Selects/ServiceRoleSelect";
import { UDatePicker } from "../../Controls/Universal";
import { StaffMember } from "@interfaces/StaffMember";

type CreateStaffMember = Omit<
    StaffMember,
    "id" | "is_superuser" | "is_active" | "memberof" | "created_at" | "updated_at"
> & { repeat_password: string };

type CreateStaffMemberFormProps = Pick<FormikConfig<CreateStaffMember>, "onSubmit">;

const CreateStaffMemberForm = forwardRef(({ onSubmit }: CreateStaffMemberFormProps, ref: any) => {
    const initialData = {
        login: "",
        email: "",
        password: "",
        repeat_password: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        phone: "",
        sub_role: "",
        department: "",
        work_phone: "",
        additional_phone: "",
        occupation: "",
        birthday: null,
        join_date: null,
        dismiss_date: null,
    };

    return (
        <Formik<CreateStaffMember>
            initialValues={initialData}
            innerRef={ref}
            validationSchema={CreateStaffMemberSchema}
            onSubmit={(values, helpers) => {
                const copy = { ...values };
                copy.first_name = copy.first_name.trim();
                copy.last_name = copy.last_name.trim();
                copy.middle_name = copy.middle_name.trim();
                copy.occupation = copy.occupation.trim();
                copy.department = copy.department.trim();

                onSubmit(copy, helpers);
            }}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, setFieldValue }) => {
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <Subtitle title="Основное" bold />

                                <TextField
                                    name="login"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    required
                                    helperText={touched.login ? errors.login : ""}
                                    error={touched.login && Boolean(errors.login)}
                                    label="Логин"
                                    value={values.login}
                                    onChange={handleChange}
                                    fullWidth
                                />

                                <TextField
                                    name="email"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    required
                                    helperText={touched.email ? errors.email : ""}
                                    error={touched.email && Boolean(errors.email)}
                                    label="Электронная почта"
                                    value={values.email}
                                    onChange={handleChange}
                                    fullWidth
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <PasswordField
                                            name="password"
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            label="Пароль"
                                            required
                                            helperText={touched.password ? errors.password : ""}
                                            error={touched.password && Boolean(errors.password)}
                                            value={values.password}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <PasswordField
                                            name="repeat_password"
                                            variant="outlined"
                                            size="small"
                                            label="Повторите пароль"
                                            margin="normal"
                                            required
                                            helperText={
                                                touched.repeat_password
                                                    ? errors.repeat_password
                                                    : ""
                                            }
                                            error={
                                                touched.repeat_password &&
                                                Boolean(errors.repeat_password)
                                            }
                                            value={values.repeat_password}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                <Subtitle title="Личная информация" bold />

                                <TextField
                                    name="last_name"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    required
                                    helperText={touched.last_name ? errors.last_name : ""}
                                    error={touched.last_name && Boolean(errors.last_name)}
                                    label="Фамилия"
                                    value={values.last_name}
                                    onChange={handleChange}
                                    fullWidth
                                />

                                <TextField
                                    name="first_name"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    required
                                    helperText={touched.first_name ? errors.first_name : ""}
                                    error={touched.first_name && Boolean(errors.first_name)}
                                    label="Имя"
                                    value={values.first_name}
                                    onChange={handleChange}
                                    fullWidth
                                />

                                <TextField
                                    name="middle_name"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    required
                                    helperText={touched.middle_name ? errors.middle_name : ""}
                                    error={touched.middle_name && Boolean(errors.middle_name)}
                                    label="Отчество"
                                    value={values.middle_name}
                                    onChange={handleChange}
                                    fullWidth
                                />

                                <UDatePicker
                                    name="birthday"
                                    size="small"
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    helperText={touched.birthday ? errors.birthday : ""}
                                    error={touched.birthday && Boolean(errors.birthday)}
                                    label="Дата рождения"
                                    pickerProps={{ maxDate: new Date() }}
                                    value={values.birthday}
                                    onChange={setFieldValue}
                                    fullWidth
                                />

                                <PhoneNumberInput
                                    name="phone"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    helperText={touched.phone ? errors.phone : ""}
                                    error={touched.phone && Boolean(errors.phone)}
                                    label="Номер телефона"
                                    value={values.phone}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item md={6}>
                                <Subtitle title="Рабочие данные" bold />

                                <TextField
                                    name="occupation"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    helperText={touched.occupation ? errors.occupation : ""}
                                    error={touched.occupation && Boolean(errors.occupation)}
                                    label="Должность"
                                    value={values.occupation}
                                    onChange={handleChange}
                                    fullWidth
                                />

                                <TextField
                                    name="department"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    helperText={touched.department ? errors.department : ""}
                                    error={touched.department && Boolean(errors.department)}
                                    label="Отдел"
                                    value={values.department}
                                    onChange={handleChange}
                                    fullWidth
                                />

                                <PhoneNumberInput
                                    name="work_phone"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    helperText={touched.work_phone ? errors.work_phone : ""}
                                    error={touched.work_phone && Boolean(errors.work_phone)}
                                    label="Рабочий номер телефона"
                                    value={values.work_phone}
                                    onChange={handleChange}
                                    fullWidth
                                />

                                <PhoneNumberInput
                                    name="additional_phone"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    helperText={
                                        touched.additional_phone ? errors.additional_phone : ""
                                    }
                                    error={touched.phone && Boolean(errors.phone)}
                                    label="Доп. номер телефона"
                                    value={values.additional_phone}
                                    onChange={handleChange}
                                    fullWidth
                                />

                                <UDatePicker
                                    name="join_date"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    helperText={touched.join_date ? errors.join_date : ""}
                                    error={touched.join_date && Boolean(errors.join_date)}
                                    label="Дата приема на работу"
                                    pickerProps={{ maxDate: new Date() }}
                                    value={values.join_date}
                                    onChange={setFieldValue}
                                    fullWidth
                                />

                                <UDatePicker
                                    name="dismiss_date"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    helperText={touched.dismiss_date ? errors.dismiss_date : ""}
                                    error={touched.dismiss_date && Boolean(errors.dismiss_date)}
                                    label="Дата увольнения"
                                    pickerProps={{ maxDate: new Date() }}
                                    value={values.dismiss_date}
                                    onChange={setFieldValue}
                                    fullWidth
                                />

                                <Subtitle title="Базовая роль" bold />

                                <ServiceRoleSelect
                                    name="sub_role"
                                    variant="outlined"
                                    size="small"
                                    required
                                    margin="normal"
                                    helperText={touched.sub_role ? errors.sub_role : ""}
                                    error={touched.sub_role && Boolean(errors.sub_role)}
                                    label="Роль"
                                    value={values.sub_role}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default CreateStaffMemberForm;
