import { Transform, Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateEntityTypeDto {
    @Expose()
    readonly name: string;

    @Expose()
    readonly notes?: string;

    @Expose()
    @Transform(({ value }) =>
        value.map((item) => ({
            id: item.id,
            type_id: item.type_id,
            atype_id: item.atype_id,
            required: item.required,
            sorting: item.sorting,
        }))
    )
    readonly items?: any[];
}
