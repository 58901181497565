import { Skeleton } from "@material-ui/lab";
import { InputType } from "@modules/InputTypesManager";
import React from "react";
import useAsync from "@hooks/useAsync";
import { UniversalInputProps } from "@interfaces/System/UniversalInputProps";
import { InputTypesManager } from "@modules";
import { valueTypeBindService } from "@services/Settings";
import { UEntityAutocompleteExtraProps } from "@components/Controls/Universal/UEntityAutocomplete";

export type UniversalInputFieldProps = UEntityAutocompleteExtraProps &
    Partial<{
        vTypeId: number;
        inputTypeName: InputType;
    }>;

const UniversalInputField = ({
    vTypeId,
    inputTypeName,
    findBy,
    filterByEntityTypeId,
    responseValue,
    ...props
}: UniversalInputFieldProps & UniversalInputProps) => {
    const { data, loadingStatus } = useAsync(valueTypeBindService.getOneVTypeBind, {
        functionArgs: [undefined, { type_id: vTypeId }],
        canLoad: !!vTypeId && !inputTypeName,
    });

    if (loadingStatus === "loading") return <Skeleton width="100%" height={50} />;

    const type = inputTypeName || (data?.type_name as InputType) || InputType.TEXT;

    const InputComponent = InputTypesManager.getInputTypeByCode(type)?.component;

    if (type === InputType.ENTITY_AUTOCOMPLETE) {
        const extraProps = { findBy, filterByEntityTypeId, responseValue };
        return <InputComponent {...props} {...extraProps} />;
    }

    return <InputComponent {...props} />;
};

export default UniversalInputField;
