import { Box, Button, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { CreateEntityTypeDto } from "@dto/EntityTypeDto";
import { eTypesService } from "@services";
import { ETypeEvents, ETypeState } from "@store/etype-module";
import EntityTypeForm from "../../Forms/EntityTypeForm";
import EntityItemsActions from "../../Views/EntityTypeItems/EntityItemsActions";
import EntityItemsList from "../../Views/EntityTypeItems/EntityItemsList";
import { ValidationState, ValidationEvents } from "@store/validation-module";
import { ValidationManager } from "@modules";
import { useLoading, useTable } from "@hooks";
import { Subtitle } from "@components/Views/Title";

const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
        height: "100vh",
        [breakpoints.up("md")]: {
            overflowY: "hidden",
        },
    },
    content: {
        height: "100%",
    },
    list: {
        height: "100%",
        overflowY: "auto",
        padding: "0 .1rem",
        paddingBottom: "1rem",
    },
}));

const CreateEntityTypeDialogContext = ({ onClose }: DialogContextProps) => {
    const classes = useStyles();
    const formRef = useRef<any>();
    const { updateData } = useTable();
    const { validations, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("validations");
    const { items, attrsCount, dispatch } = useStoreon<ETypeState, ETypeEvents>("items");
    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const invokeSubmit = () => {
        validationDispatch("validation/toggle/validation", true);
        formRef.current.handleSubmit();
    };

    useEffect(() => {
        return () => {
            dispatch("etype/set/attrs_count", 0);
            dispatch("etype/reset/items");
            dispatch("etype/reset/items_to_remove");
            dispatch("etype/set/etypeid", null);
            validationDispatch("validation/toggle/validation", false);
            validationDispatch("validation/set/validations", {});
        };
    }, [dispatch, validationDispatch]);

    const handleCreateEntityType = async (values) => {
        if (!ValidationManager.isFinalObjectValid(validations)) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = eTypesService.plainToClass(CreateEntityTypeDto, {
                ...values,
                items,
            });

            await eTypesService.createEntityType(dto);
            enqueueSnackbar("Тип сущности успешно создан", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            setSubmitLoadingStatus("loaded");
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании типа сущности", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent className={classes.root}>
                <Grid container spacing={2} className={classes.content}>
                    <Grid item xs={12} md={4}>
                        <Subtitle title="Общее" titleVariant="h6" bold />
                        <EntityTypeForm onSubmit={handleCreateEntityType} ref={formRef} />
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.content}>
                        <Subtitle
                            title="Типы атрибутов"
                            titleVariant="h6"
                            bold
                            endAdornment={
                                <EntityItemsActions
                                    itemsCount={items?.length ?? 0}
                                    attrsCount={attrsCount}
                                />
                            }
                        />
                        <Box className={classes.list}>
                            <EntityItemsList />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateEntityTypeDialogContext;
