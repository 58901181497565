import ContentLayout from "@components/Views/ContentLayout";
import { useCombinedContentType, useCurrentPageInfo } from "@hooks";
import { WithContentTypeProps } from "@modules/ContentTypesManagers/";
import { ContentType } from "@modules/ContentTypesManagers/ContentTypesManager";
import React from "react";

const EnhancedContentLayout = ({ contentType }: WithContentTypeProps<{}>) => {
    const pageInfo = useCurrentPageInfo();
    const type = useCombinedContentType(contentType) as ContentType;

    if (!type) return null;

    return (
        <ContentLayout
            mainContent={type.content.getMainContent(pageInfo)}
            sideContent={type.content.getSideContent({ contentType })}
        />
    );
};

export default EnhancedContentLayout;
