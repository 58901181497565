import { Transform, Exclude, Expose } from "class-transformer";
import { RestrictionsManager } from "@modules";

@Exclude()
export class UpdateValueTypeDto {
    @Expose()
    readonly name: string;

    @Expose()
    readonly notes?: string;

    @Expose()
    readonly pt_id: number;

    @Expose()
    @Transform(({ obj, value }) =>
        obj.useRestrictions
            ? RestrictionsManager.getRestrictionsObjectFromArray(value || [])
            : RestrictionsManager.getEmptyResctrictionsObject()
    )
    readonly restrictions: object;
}
