import * as Yup from "yup";
import { Regex } from "@modules/Constants";

export const UpdateStaffMemberSchema = Yup.object().shape({
    email: Yup.string()
        .email("Пожалуйста, укажите корректный адрес эл. почты")
        .required("Пожалуйста, укажите адрес эл. почты"),
    first_name: Yup.string().trim().required("Пожалуйста, введите имя"),
    last_name: Yup.string().trim().required("Пожалуйста, введите фамилию"),
    middle_name: Yup.string().trim().required("Пожалуйста, введите отчество"),
    birthday: Yup.string().nullable().required("Пожалуйста, укажите дату рождения"),
    phone: Yup.string().matches(Regex.PHONE, "Номер указан неверно"),
    work_phone: Yup.string().matches(Regex.PHONE, "Номер указан неверно"),
    additional_phone: Yup.string().matches(Regex.PHONE, "Номер указан неверно"),
    occupation: Yup.string().trim(),
    department: Yup.string().trim(),
    join_date: Yup.string().nullable(),
    dismiss_date: Yup.string().nullable(),
});
