import { Slider, Typography, Box, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Help } from "@material-ui/icons";
import { PermissionManager } from "@modules";
import React from "react";

const useStyles = makeStyles(() =>
    createStyles({
        text: {
            display: "flex",
            alignItems: "center",
        },
        icon: {
            marginLeft: 5,
        },
        tooltip: {
            maxWidth: "none",
        },
    })
);

const PermissionSlider = ({ ...props }) => {
    const classes = useStyles();

    const legend = Object.entries(PermissionManager.PERMISSIONS).map((x) => (
        <Typography>
            {x[0]}.{" "}
            {Object.values(x[1])
                .map((p) => p.locale)
                .join(", ")
                .toString()}
        </Typography>
    ));

    return (
        <Box my={1}>
            <Typography gutterBottom color="textSecondary" variant="body2" className={classes.text}>
                Уровень доступа
                <Tooltip arrow title={legend} classes={{ tooltip: classes.tooltip }}>
                    <Help fontSize="small" className={classes.icon} />
                </Tooltip>
            </Typography>
            <Slider
                {...props}
                defaultValue={1}
                valueLabelDisplay="auto"
                step={1}
                min={1}
                max={4}
                marks
            />
        </Box>
    );
};

export default PermissionSlider;
