import { CommonFormProps } from "@interfaces/System/FormProps";
import { ServiceRole } from "@interfaces/ServiceRole";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { ServiceRoleSchema } from "../../../schema/Settings";
import { AutoSizeTextField } from "../../Controls/Inputs";

const ServiceRoleForm = forwardRef(({ onSubmit, data }: CommonFormProps<ServiceRole>, ref: any) => {
    const initialData = {
        rolname: data?.rolname || "",
        shown_name: data?.shown_name || "",
        description: data?.description || "",
    } as ServiceRole;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={ServiceRoleSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values }) => {
                return (
                    <Form>
                        <AutoSizeTextField
                            fullWidth
                            onChange={handleChange}
                            value={values.rolname}
                            required
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="rolname"
                            helperText={touched.rolname ? errors.rolname : ""}
                            error={touched.rolname && !!errors.rolname}
                            label="Системное название"
                        />

                        <AutoSizeTextField
                            fullWidth
                            onChange={handleChange}
                            value={values.shown_name}
                            required
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="shown_name"
                            helperText={touched.shown_name ? errors.shown_name : ""}
                            error={touched.shown_name && !!errors.shown_name}
                            label="Отображаемое название"
                        />

                        <AutoSizeTextField
                            name="description"
                            variant="outlined"
                            size="small"
                            label="Описание"
                            rows={2}
                            margin="normal"
                            helperText={touched.description ? errors.description : "Необязательно"}
                            error={touched.description && !!errors.description}
                            value={values.description}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default ServiceRoleForm;
