import { ProtectedPath } from "@interfaces/ProtectedPath";
import { Constants } from "@modules";
import { LayoutRoute } from "react-base-routing";
import { StoreonModule } from "storeon";

export type ThemeType = "light" | "dark";
export interface CommonState {
    theme: ThemeType;
    sidebarOpen: boolean;
    lightBoxOpen: boolean;
    lightBoxImages: any[];
    routes: LayoutRoute[];
    menu: ProtectedPath[];
    updateMenuAndRoutes?(): Promise<any>;
}

export interface CommonEvents {
    "common/set/theme": ThemeType;
    "common/toggle/sidebar": boolean;
    "common/toggle/lightbox": boolean;
    "common/set/lightboximages": any[];
    "common/set/menu": ProtectedPath[];
    "common/set/routes": LayoutRoute[];
    "common/set/update_menu_and_routes": () => Promise<any>;
    "common/call/update_menu_and_routes";
}

export const commonModule: StoreonModule<CommonState, CommonEvents> = (store) => {
    store.on("@init", () => ({
        theme: (localStorage.getItem("theme") as ThemeType) || Constants.Common.DEFAULT_APP_THEME,
        sidebarOpen: true,
        lightBoxOpen: false,
        lightBoxImages: [],
        menu: [],
        routes: [],
    }));

    store.on("common/set/theme", (state, value) => ({
        theme: value,
    }));

    store.on("common/toggle/sidebar", (state, value) => ({
        sidebarOpen: value,
    }));

    store.on("common/toggle/lightbox", (state, value) => ({
        lightBoxOpen: value,
    }));

    store.on("common/set/lightboximages", (state, images) => ({
        lightBoxImages: images,
    }));

    store.on("common/set/menu", (state, value) => ({
        menu: value,
    }));

    store.on("common/set/routes", (state, value) => ({
        routes: value,
    }));

    store.on("common/set/update_menu_and_routes", (state, value) => ({
        updateMenuAndRoutes: value,
    }));

    store.on("common/call/update_menu_and_routes", async (state) => {
        const { updateMenuAndRoutes } = state;

        if (updateMenuAndRoutes) {
            await updateMenuAndRoutes();
        }
    });
};
