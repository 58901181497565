import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { ChangeUserPassword } from "../../../interfaces/Internal/ChangeUserPassword";
import { ChangeCurrentStaffMemberPasswordSchema } from "@schema/Staff";
import { PasswordField } from "../../Controls/Inputs";

interface Props {
    onSubmit: (values: ChangeUserPassword) => Promise<void>;
}

const ChangeCurrentStaffMemberPasswordForm = forwardRef(({ onSubmit }: Props, ref: any) => {
    const initialData: ChangeUserPassword = {
        old_password: "",
        password: "",
        repeat_password: "",
    };

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={ChangeCurrentStaffMemberPasswordSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values }) => {
                return (
                    <Form>
                        <PasswordField
                            name="old_password"
                            variant="outlined"
                            margin="normal"
                            size="small"
                            label="Старый пароль"
                            required
                            helperText={touched.old_password ? errors.old_password : ""}
                            error={touched.old_password && Boolean(errors.old_password)}
                            value={values.old_password}
                            onChange={handleChange}
                            fullWidth
                        />

                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <PasswordField
                                    name="password"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    label="Новый пароль"
                                    required
                                    helperText={touched.password ? errors.password : ""}
                                    error={touched.password && Boolean(errors.password)}
                                    value={values.password}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <PasswordField
                                    name="repeat_password"
                                    variant="outlined"
                                    size="small"
                                    label="Повторите новый пароль"
                                    margin="normal"
                                    required
                                    helperText={
                                        touched.repeat_password ? errors.repeat_password : ""
                                    }
                                    error={
                                        touched.repeat_password && Boolean(errors.repeat_password)
                                    }
                                    value={values.repeat_password}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default ChangeCurrentStaffMemberPasswordForm;
