import { TextField, TextFieldProps, MenuItem, ListItemText } from "@material-ui/core";
import InputTypesManager from "@modules/InputTypesManager";
import React from "react";

const InputTypeSelect = ({ ...props }: TextFieldProps) => {
    return (
        <TextField {...props} value={props.value || ""} select label="Тип поля">
            {InputTypesManager.INPUT_TYPES.filter((el) => !el.private).map((inputType) => {
                return (
                    <MenuItem
                        key={inputType.type}
                        value={inputType.type}
                        style={{ display: "block" }}
                    >
                        <ListItemText primary={inputType.name} />
                        <ListItemText secondary={inputType.description} />
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default InputTypeSelect;
