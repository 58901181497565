import * as Yup from "yup";

export const BrandDealerBindSchema = Yup.object().shape({
    brand_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, выберите бренд"),
    dealer_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, выберите дилера"),
    price: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, укажите цену"),
});
