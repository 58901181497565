import { Dialog, DialogTitle, IconButton, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { ModalEvents, ModalState } from "../../../store/modal-module";
import { ModalManager } from "../../../modules";
import { Close } from "@material-ui/icons";
import { Modal as IModal } from "../../../interfaces/System/Modal";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

interface State {
    loading: boolean;
    modal: IModal | null;
    title: string;
}

const Modal = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();

    const { open, type, props, dispatch } = useStoreon<ModalState, ModalEvents>(
        "open",
        "type",
        "props"
    );

    const [state, setState] = useState<State>({
        loading: false,
        modal: null,
        title: "",
    });

    useEffect(() => {
        if (!type) return;

        const modal = ModalManager.getModal(type);

        if (!modal) {
            setState((prevState) => ({
                ...prevState,
                modal: null,
                title: "",
            }));
            return;
        }

        setState((prevState) => ({
            ...prevState,
            modal,
            loading: Boolean(modal.getTitle),
        }));

        const getTitle = async () => {
            if (!modal.getTitle) return modal.title;

            return await modal.getTitle(props?.titleProps);
        };

        if (modal.getTitle) {
            getTitle().then((title) => {
                setState((prevState) => ({
                    ...prevState,
                    title,
                    loading: false,
                }));
            });
        }
    }, [type, props]);

    const closeModal = () => {
        setState((prevState) => ({
            ...prevState,
            modal: null,
            title: "",
        }));
        dispatch("modal/toggle", false);
        dispatch("modal/set/type", null);
        dispatch("modal/set/props", null);
    };

    if (!state.modal) return null;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={(e, reason) => reason === "backdropClick" && closeModal}
            fullWidth
            maxWidth={state.modal.width || "sm"}
        >
            <DialogTitle disableTypography>
                {state.loading ? (
                    <Skeleton width={400} height={40} />
                ) : (
                    <Typography variant="h6">{state.title || state.modal.title}</Typography>
                )}
                <IconButton className={classes.closeButton} onClick={closeModal}>
                    <Close />
                </IconButton>
            </DialogTitle>

            <state.modal.content onClose={closeModal} modalProps={props} />
        </Dialog>
    );
};

export default Modal;
