import { UniversalInputField, UniversalRestrictionChecker } from "@components/Common";
import { TableFilter } from "@interfaces/System/TableFilter";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { useStoreon } from "storeon/react";
import { TableEvents, TableState } from "@store/table-module";

type Props = { filter: TableFilter };

const TableFilterItem = ({ filter }: Props) => {
    const { key, name, value, restrictions } = filter;

    const { dispatch } = useStoreon<TableState, TableEvents>();

    const handleChangeFilterValue = (name, value) => {
        dispatch("table/update/filter", { key, value });
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: 0 }}>
                <UniversalRestrictionChecker
                    restrictions={restrictions}
                    render={(inputTypeName, { entityRelationTypeId }) => (
                        <UniversalInputField
                            name={name}
                            inputTypeName={inputTypeName}
                            findBy="attrValue"
                            responseValue="attrValue"
                            filterByEntityTypeId={entityRelationTypeId}
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={value || ""}
                            onChange={handleChangeFilterValue}
                        />
                    )}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default TableFilterItem;
