import RestrictionItem from "@components/Views/Restrictions/RestrictionItem";
import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { RestrictionsManager } from "@modules";
import React, { useCallback } from "react";
import { useStoreon } from "storeon/react";
import { ATypeEvents, ATypeState } from "@store/atype-module";

const AttributeTypeItemRestrictions = ({ aTypeItemIndex }) => {
    const { items, dispatch } = useStoreon<ATypeState, ATypeEvents>("items");
    const useRestrictions = (items && items[aTypeItemIndex]?.useRestrictions) || false;
    const restrictions = (items && items[aTypeItemIndex]?.restrictions) || [];

    const handleUseRestrictionsClick = useCallback(() => {
        dispatch("atype/item/toggle/restrictions", {
            itemIndex: aTypeItemIndex,
            toggle: !useRestrictions,
        });
    }, [dispatch, aTypeItemIndex, useRestrictions]);

    const handleAddRestrictionClick = useCallback(() => {
        dispatch("atype/item/add/restriction", { itemIndex: aTypeItemIndex });
    }, [dispatch, aTypeItemIndex]);

    const handleDeleteRestrictionClick = useCallback(
        (restKey: string) => {
            dispatch("atype/item/remove/restriction", {
                itemIndex: aTypeItemIndex,
                restKey,
            });
        },
        [dispatch, aTypeItemIndex]
    );

    const handleSelectRestrictionType = useCallback(
        (value, restKey: string) => {
            dispatch("atype/item/update/restriction", {
                itemIndex: aTypeItemIndex,
                restKey,
                code: value,
                value: null,
            });
        },
        [dispatch, aTypeItemIndex]
    );

    const handleSelectRestrictionValue = useCallback(
        (code, value, restKey: string) => {
            dispatch("atype/item/update/restriction", {
                itemIndex: aTypeItemIndex,
                restKey,
                code,
                value,
            });
        },
        [dispatch, aTypeItemIndex]
    );

    const restrictionsWithoutCustom = restrictions.filter((r) => !r.custom);
    const customRestrictionsCount: number = restrictions.length - restrictionsWithoutCustom.length;

    const activeRestrictions: string[] = restrictionsWithoutCustom
        .filter((el) => !!el.code)
        .map((r) => r.code);

    return (
        <>
            <Grid container justifyContent="space-between">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={useRestrictions}
                            onChange={handleUseRestrictionsClick}
                            name="use_restrictions"
                            color="primary"
                        />
                    }
                    label="Использовать ограничения"
                />
                {useRestrictions && (
                    <Button
                        color="primary"
                        size="small"
                        onClick={handleAddRestrictionClick}
                        disabled={
                            restrictions.length - customRestrictionsCount >=
                            RestrictionsManager.getRestrictionsArrayWithoutCustom().length
                        }
                        startIcon={<Add />}
                    >
                        Добавить ограничение
                    </Button>
                )}
            </Grid>
            {useRestrictions &&
                restrictionsWithoutCustom.map((item, i) => {
                    return (
                        <RestrictionItem
                            key={item.key}
                            disabledRestrictions={RestrictionsManager.getDisabledRestrictions(
                                i,
                                activeRestrictions
                            )()}
                            restItem={item}
                            onDelete={handleDeleteRestrictionClick}
                            onChangeType={handleSelectRestrictionType}
                            onChangeValue={handleSelectRestrictionValue}
                        />
                    );
                })}
        </>
    );
};

export default AttributeTypeItemRestrictions;
