import * as Yup from "yup";

export const QuantitySchema = Yup.object().shape({
    sku: Yup.string().required("Поле обязательно к заполнению"),
    store_id: Yup.number()
        .required("Пожалуйста, выберите магазин")
        .transform((value) => (isNaN(value) ? undefined : value)),
    quantity: Yup.number()
        .required("Пожалуйста, укажите количество")
        .transform((value) => (isNaN(value) ? undefined : value)),
});
