import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { ChangeCurrentStaffMemberPasswordDto } from "@dto/ChangePasswordDto";
import { staffProfileService } from "@services/Staff";
import ChangeCurrentStaffMemberPasswordForm from "../../Forms/Staff/ChangeCurrentStaffMemberPasswordForm";
import { useLoading } from "@hooks";

const ChangeCurrentStaffMemberPasswordDialogContext = ({ onClose }: DialogContextProps) => {
    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleSubmit = async (values) => {
        setSubmitLoadingStatus("loading");
        try {
            const dto = staffProfileService.plainToClass(
                ChangeCurrentStaffMemberPasswordDto,
                values
            );
            await staffProfileService.changeCurrentStaffMemberPassword(dto);
            enqueueSnackbar("Пароль успешно изменен", {
                variant: "success",
            });
            onClose();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при изменении пароля", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                <ChangeCurrentStaffMemberPasswordForm onSubmit={handleSubmit} ref={formRef} />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default ChangeCurrentStaffMemberPasswordDialogContext;
