import React from "react";
import { useStoreon } from "storeon/react";
import { Add } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { ContentEvents, ContentState } from "@store/content-module";

const AddNewStaffBrand = () => {
    const { dispatch } = useStoreon<ContentState, ContentEvents>();

    const handleAddStaffBrand = () => {
        dispatch("content/staff_brands/add/brands");
    };

    return (
        <Button color="primary" size="small" onClick={handleAddStaffBrand} startIcon={<Add />}>
            Добавить бренд
        </Button>
    );
};

export default AddNewStaffBrand;
