import { Store } from "@interfaces/Store";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { Checkbox, FormControlLabel, FormGroup, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { StoreSchema } from "../../../schema/Store/StoreSchema";
import { AutoSizeTextField } from "../../Controls/Inputs";

const StoreForm = forwardRef(({ onSubmit, data }: CommonFormProps<Store>, ref: any) => {
    const initialData = {
        store_id: data?.store_id,
        is_shop: data?.is_shop ?? false,
        is_countable: data?.is_countable ?? false,
        store_name: data?.store_name ?? "",
        shown_name: data?.shown_name ?? "",
        sorting: data?.sorting ?? 0,
        comment: data?.comment ?? "",
    } as Store;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={StoreSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values }) => {
                return (
                    <Form>
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.store_id}
                            type="number"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="store_id"
                            helperText={touched.store_id ? errors.store_id : ""}
                            error={touched.store_id && Boolean(errors.store_id)}
                            label="ID Склада"
                            required
                        />

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.is_countable}
                                        onChange={handleChange}
                                        name="is_countable"
                                        color="primary"
                                    />
                                }
                                label="Учитывать остатки"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.is_shop}
                                        onChange={handleChange}
                                        name="is_shop"
                                        color="primary"
                                    />
                                }
                                label="Является магазином"
                            />
                        </FormGroup>

                        <AutoSizeTextField
                            fullWidth
                            onChange={handleChange}
                            value={values.store_name}
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="store_name"
                            helperText={touched.store_name ? errors.store_name : ""}
                            error={touched.store_name && Boolean(errors.store_name)}
                            label="Название"
                            required
                        />

                        <AutoSizeTextField
                            fullWidth
                            onChange={handleChange}
                            value={values.shown_name}
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="shown_name"
                            helperText={touched.shown_name ? errors.shown_name : ""}
                            error={touched.shown_name && Boolean(errors.shown_name)}
                            label="Отображаемое название"
                        />

                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.sorting}
                            type="number"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="sorting"
                            helperText={touched.sorting ? errors.sorting : ""}
                            error={touched.sorting && Boolean(errors.sorting)}
                            label="Сортировка"
                        />

                        <AutoSizeTextField
                            name="comment"
                            variant="outlined"
                            size="small"
                            label="Комментарий"
                            rows={2}
                            margin="normal"
                            helperText={touched.comment ? errors.comment : ""}
                            error={touched.comment && Boolean(errors.comment)}
                            value={values.comment}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default StoreForm;
