import { Button, DialogActions, DialogContent, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "../../../interfaces/System/DialogContextProps";
import { CreateAttributeTypeDto } from "../../../dto/AttributeTypeDto";
import { aTypesService } from "../../../services";
import { ATypeEvents, ATypeState } from "../../../store/atype-module";
import AttributeTypeForm from "../../Forms/AttributeTypeForm";
import AttributeItemsActions from "../../Views/AttributeTypeItems/AttributeItemsActions";
import AttributeItemsList from "../../Views/AttributeTypeItems/AttributeItemsList";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import { ValidationManager } from "@modules";
import { useLoading, useTable } from "@hooks";
import { Subtitle } from "@components/Views/Title";

const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
        height: "100vh",
        [breakpoints.up("md")]: {
            overflowY: "hidden",
        },
    },
    content: {
        height: "100%",
    },
    list: {
        height: "100%",
        overflowY: "auto",
        padding: "0 .1rem",
        paddingBottom: "1rem",
    },
}));

const CreateAttributeTypeDialogContext = ({ onClose }: DialogContextProps) => {
    const classes = useStyles();

    const formRef = useRef<any>();
    const { updateData } = useTable();
    const { items, isCompositeType, dispatch } = useStoreon<ATypeState, ATypeEvents>(
        "items",
        "isCompositeType"
    );
    const { validations, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("validations");
    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const invokeSubmit = () => {
        validationDispatch("validation/toggle/validation", true);
        formRef.current.handleSubmit();
    };

    useEffect(() => {
        return () => {
            dispatch("atype/reset/items");
            dispatch("atype/reset/items_to_remove");
            dispatch("atype/set/atypeid", null);
            dispatch("atype/toggle/composite_type", false);
            validationDispatch("validation/toggle/validation", false);
            validationDispatch("validation/set/validations", {});
        };
    }, [dispatch, validationDispatch]);

    const handleCreateAttributeType = async (values) => {
        if (!isCompositeType && items?.length > 1) {
            enqueueSnackbar("Только составной тип может содержать более 1 типа значений", {
                variant: "error",
            });
            return;
        }

        if (!ValidationManager.isFinalObjectValid(validations)) return;
        setSubmitLoadingStatus("loading");

        try {
            const dto = aTypesService.plainToClass(CreateAttributeTypeDto, {
                ...values,
                items,
            });
            await aTypesService.createAttributeType(dto);
            enqueueSnackbar("Тип атрибута успешно создан", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            setSubmitLoadingStatus("loaded");
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании типа атрибута", {
                variant: "error",
            });
        }
    };

    const handleChangeCompositeType = (value: boolean) => {
        dispatch("atype/toggle/composite_type", value);
    };

    return (
        <>
            <DialogContent className={classes.root}>
                <Grid container spacing={2} className={classes.content}>
                    <Grid item xs={12} md={4}>
                        <Subtitle title="Общее" titleVariant="h6" bold />
                        <AttributeTypeForm
                            onSubmit={handleCreateAttributeType}
                            ref={formRef}
                            onChangeCompositeType={handleChangeCompositeType}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.content}>
                        <Subtitle
                            title="Типы значений"
                            titleVariant="h6"
                            bold
                            endAdornment={<AttributeItemsActions />}
                        />
                        <Box className={classes.list}>
                            <AttributeItemsList />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateAttributeTypeDialogContext;
