import { QueryParams } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { Brand } from "@interfaces/Brand";
import { StaffBrandDto } from "@dto/StaffDto";

class StaffBrandBindService extends BaseService {
    /** pass `userId` to get all brands which binds to this user */
    public getStaffBrands = async (
        staffMemberId?: number,
        params: QueryParams = {}
    ): Promise<Brand[]> => {
        const { data } = await this.instance.get("/staff_brand_bind", {
            params: { id: staffMemberId, ...params },
        });

        const result = this.getResponseCorrectArray<Brand>(data);
        return this.toArray(result);
    };

    public bindStaffBrand = async (staffBrand: StaffBrandDto): Promise<any> => {
        const { data } = await this.instance.post("/staff_brand_bind", staffBrand);

        return data;
    };

    public unbindStaffBrand = async (staffBrandDto: StaffBrandDto): Promise<any> => {
        const { data } = await this.instance.delete("/staff_brand_bind", {
            data: staffBrandDto,
        });

        return data;
    };
}

export default new StaffBrandBindService(new HttpClient(Config.API_URL), AuthManager);
