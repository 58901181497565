import { Button, IconButton, Link } from "@material-ui/core";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useStoreon } from "storeon/react";
import { Constants } from "@modules";
import { AuthManager, PathsManager } from "../../../modules";
import { AuthState } from "../../../store/auth-module";
import { Menu as MenuIcon } from "@material-ui/icons";
import UserMenuButton from "../../Controls/Buttons/UserMenuButton";
import { CommonEvents, CommonState } from "@store/common-module";
import { store } from "@store";

const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${Constants.Common.SIDEBAR_WIDTH}px)`,
        marginLeft: Constants.Common.SIDEBAR_WIDTH,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    grow: {
        flexGrow: 1,
    },
    email: {
        cursor: "pointer",
    },
}));

interface Props {
    hideSidebarButton?: boolean;
}

const Appbar = ({ hideSidebarButton = false }: Props): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const { token, user } = useStoreon<AuthState>("token", "user");
    const { sidebarOpen, dispatch } = useStoreon<CommonState, CommonEvents>("sidebarOpen");

    const openSidebar = () => {
        dispatch("common/toggle/sidebar", true);
    };

    const openCabinet = () => {
        history.push(PathsManager.PATHS.CABINET.url);
    };

    const logout = () => {
        AuthManager.logout(store);
    };

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: sidebarOpen && !hideSidebarButton,
            })}
        >
            <Toolbar>
                {!hideSidebarButton && (
                    <IconButton
                        color="inherit"
                        onClick={openSidebar}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: sidebarOpen,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Link
                    variant="h6"
                    underline="none"
                    color="inherit"
                    component={NavLink}
                    to={PathsManager.PATHS.HOME.url}
                >
                    ДС-Сити
                </Link>
                <div className={classes.grow} />
                {!token && (
                    <Button color="inherit" component={NavLink} to={PathsManager.PATHS.LOGIN.url}>
                        Войти
                    </Button>
                )}
                {token && user && (
                    <UserMenuButton
                        user={user}
                        onLogout={logout}
                        onRedirectToCabinet={openCabinet}
                    />
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Appbar;
