import { QueryResult, QueryParams, PaginatedQueryResult } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { PermissionSettings } from "@interfaces/PermissionSettings";
import { CreatePermissionSettingsDto } from "@dto/SettingsDto/PermissionSettingsDto";

class PermissionSettingsService extends BaseService {
    public getPermissionsSettings = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<PermissionSettings>> => {
        const { data } = await this.instance.get("/settings/permission", {
            params,
        });

        return this.toPaginatedUniversalOutput<PermissionSettings>(data);
    };

    public getOnePermissionSettings = async (id: number): Promise<PermissionSettings> => {
        const { data } = await this.instance.get("/settings/permission", {
            params: { id },
        });

        return data.result.items[0];
    };

    public createPermissionSettings = async (
        createPermissionSettingsDto: CreatePermissionSettingsDto
    ): Promise<QueryResult<PermissionSettings>> => {
        const { data } = await this.instance.post("/settings/permission", {
            data: createPermissionSettingsDto,
        });

        return data;
    };

    public deletePermissionSettings = async (
        ids: number[]
    ): Promise<QueryResult<PermissionSettings>> => {
        const { data } = await this.instance.delete("/settings/permission", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new PermissionSettingsService(new HttpClient(Config.API_URL), AuthManager);
