import { AttributeTypeItem, AppAttributeTypeItem } from "@interfaces/AttributeTypeItem";
import shortid from "shortid";
import { AppRestriction } from "@interfaces/Internal/AppRestriction";
import { Restrictions } from "@interfaces/EntityAttributeSchema";

interface RestrictionItem {
    title: string;
    code: string;
    defaultValue?: any;
    /** returns `true` if value is valid */
    validate: (value: any, restValue?: any) => boolean;
    getHelpText: (restValue?: any) => string;
    getErrorValidationMessage: (restValue: any) => string;
    custom?: boolean;
}

type IRestrictions = Record<string, RestrictionItem>;

class RestrictionsManager {
    static getRestrictionsArray = () => {
        return Object.values(RestrictionsManager.RESTRICTIONS);
    };

    static getCustomRestrictionsArray = () => {
        return RestrictionsManager.getRestrictionsArray().filter((r) => r.custom);
    };

    static getRestrictionsArrayWithoutCustom = () => {
        return RestrictionsManager.getRestrictionsArray().filter((r) => !r.custom);
    };

    static readonly RESTRICTIONS: IRestrictions = {
        ENTITY_TYPE: {
            title: "Тип сущности",
            code: "et_id",
            validate: (value: number, restValue: number) => true,
            getHelpText: () => "",
            getErrorValidationMessage: () => "",
        },
        REGEXP: {
            title: "Регулярное выражение",
            code: "regexp",
            validate: (value: any, restValue: string) => new RegExp(restValue).test(value),
            getHelpText: (restValue: string) =>
                `Значение должно соответствовать формату ${restValue}`,
            getErrorValidationMessage: (restValue: string) =>
                `Значение не соответствует формату ${restValue}`,
        },
        GT: {
            title: "Больше чем",
            code: "gt",
            validate: (value: number, restValue: number) => value > restValue,
            getHelpText: (restValue: number) => `Значение должно быть не меньше ${restValue}`,
            getErrorValidationMessage: (restValue: number) =>
                `Значение должно быть не меньше ${restValue}`,
        },
        GTE: {
            title: "Больше или равно",
            code: "gte",
            validate: (value: number, restValue: number) => value >= restValue,
            getHelpText: (restValue: number) =>
                `Значение должно быть больше или равно ${restValue}`,
            getErrorValidationMessage: (restValue: number) =>
                `Значение должно быть больше или равно ${restValue}`,
        },
        LT: {
            title: "Меньше чем",
            code: "lt",
            validate: (value: number, restValue: number) => value < restValue,
            getHelpText: (restValue: number) => `Значение должно быть не больше ${restValue}`,
            getErrorValidationMessage: (restValue: number) =>
                `Значение должно быть не больше ${restValue}`,
        },
        LTE: {
            title: "Меньше или равно",
            code: "lte",
            validate: (value: number, restValue: number) => value <= restValue,
            getHelpText: (restValue: number) =>
                `Значение должно быть меньше или равно ${restValue}`,
            getErrorValidationMessage: (restValue: number) =>
                `Значение должно быть меньше или равно ${restValue}`,
        },
        EQUAL: {
            title: "Равно",
            code: "equal",
            validate: (value: number, restValue: any) => value === restValue,
            getHelpText: (restValue: any) => `Значение должно быть равно ${restValue}`,
            getErrorValidationMessage: (restValue: any) =>
                `Значение должно быть равно ${restValue}`,
        },
        NOT_EQUAL: {
            title: "Не равно",
            code: "not_equal",
            validate: (value: number, restValue: any) => value !== restValue,
            getHelpText: (restValue: any) => `Значение должно быть не равно ${restValue}`,
            getErrorValidationMessage: (restValue: any) =>
                `Значение должно быть не равно ${restValue}`,
        },
        MAX_LEN: {
            title: "Макс. длина",
            code: "max_len",
            validate: (value: string, restValue: number) => {
                if (value === null || value === undefined) value = "";
                return value.length <= restValue;
            },
            getHelpText: (restValue: number) => `Максимальная длина - ${restValue} символов`,
            getErrorValidationMessage: (restValue: number) =>
                `Максимальная длина должна быть не больше ${restValue} символов`,
        },
        MIN_LEN: {
            title: "Мин. длина",
            code: "min_len",
            validate: (value: string, restValue: number) => {
                if (value === null || value === undefined) value = "";
                return value.length >= restValue;
            },
            getHelpText: (restValue: number) => `Минимальная длина - ${restValue} символов`,
            getErrorValidationMessage: (restValue: number) =>
                `Минимальная длина должна быть не меньше ${restValue} символов`,
        },
        NULLABLE: {
            title: "Пустое",
            code: "nullable",
            validate: (value: any, restValue: boolean) => !restValue && value !== null,
            getHelpText: () => "",
            getErrorValidationMessage: () => "",
        },
        REQUIRED: {
            title: "Обязательное",
            code: "required",
            validate: (value: any, restValue: boolean) => {
                if (!restValue) return true;
                if (typeof value === "string") return value.trim() !== "";
                return value !== null && value !== undefined;
            },
            getHelpText: () => "Обязательно к заполнению",
            getErrorValidationMessage: () => "Поле является обязательным к заполнению",
            custom: true,
        },
    };

    static getRestrictionItemByCode = (code: string) => {
        return RestrictionsManager.getRestrictionsArray().find((el) => el.code === code);
    };

    static getEmptyResctrictionsObject = (withCustom?: boolean): object => {
        const restArr = RestrictionsManager.getRestrictionsArray();

        return restArr.reduce((result, item) => {
            if (item.custom && !withCustom) return result;
            result[item.code] = null;

            return result;
        }, {});
    };

    static getRestrictionsObjectFromArray = (restrictionsArray: AppRestriction[]): object => {
        const restArr = RestrictionsManager.getRestrictionsArrayWithoutCustom();

        return restArr.reduce((restrictionsObj, rest) => {
            restrictionsObj[rest.code] = null;
            restrictionsArray.forEach((item) => {
                if (item.code === rest.code) {
                    restrictionsObj[rest.code] = item.value;
                }
            });

            return restrictionsObj;
        }, {});
    };

    static getRestrictionsArrayFromObject = (restrictions: object): AppRestriction[] => {
        return Object.entries(restrictions || {})
            .map((el) => ({
                key: shortid.generate(),
                code: el[0],
                value: el[1],
            }))
            .filter(
                (el) =>
                    el.code !== "ati_id" &&
                    el.code !== "vt_id" &&
                    el.code !== "id" &&
                    el.value !== null
            );
    };

    static isRestrictionsObjectEmpty = (restrictions: object) => {
        return RestrictionsManager.getRestrictionsArrayFromObject(restrictions).length === 0;
    };

    static getAppAttributeTypeItems = (
        items: AttributeTypeItem[],
        { useRequiredRestriction = false }
    ): AppAttributeTypeItem[] => {
        return items.map((item) => {
            let restrictions = RestrictionsManager.getRestrictionsArrayFromObject(
                item.restrictions
            );
            if (useRequiredRestriction) {
                restrictions =
                    RestrictionsManager.addCustomRequiredRestrictionToRestrictionsArray(
                        restrictions
                    );
            }
            return {
                key: shortid.generate(),
                id: item.id,
                sorting: item.sorting,
                useRestrictions: !RestrictionsManager.isRestrictionsObjectEmpty(item.restrictions),
                restrictions,
                type_id: item.type_id,
                vtype_id: item.vtype_id,
                action: "",
            };
        });
    };

    static addCustomRequiredRestrictionToRestrictionsArray = (
        restrictions: AppRestriction[]
    ): AppRestriction[] => {
        const restriction = RestrictionsManager.getRestrictionItemByCode("required");
        if (restriction) {
            restrictions.push({
                key: shortid.generate(),
                code: restriction.code,
                value: true,
                custom: true,
            });
        }

        return restrictions;
    };

    static mergeRestrictionObjects = (obj1: Restrictions, obj2?: Restrictions): Restrictions => {
        return Object.entries(obj1).reduce((result, [key, value]) => {
            result[key] = value ?? obj2?.[key] ?? null;
            return result;
        }, obj1);
    };

    static getAppRestrictions = (
        restrictions: Restrictions,
        options: Partial<{ required: boolean; vtRestrictions: Restrictions }> = {}
    ): AppRestriction[] => {
        const { required = false, vtRestrictions } = options;

        const mergedRestrictions: Restrictions = RestrictionsManager.mergeRestrictionObjects(
            restrictions,
            vtRestrictions
        );

        let appRestrictions: AppRestriction[] =
            RestrictionsManager.getRestrictionsArrayFromObject(mergedRestrictions);

        if (required) {
            appRestrictions =
                RestrictionsManager.addCustomRequiredRestrictionToRestrictionsArray(
                    appRestrictions
                );
        }

        return appRestrictions;
    };

    static checkAvailableRestrictions = (activeRestrictions: string[]) => {
        if (!activeRestrictions.length) return [];

        const combinations = [
            [RestrictionsManager.RESTRICTIONS.ENTITY_TYPE.code],
            [
                RestrictionsManager.RESTRICTIONS.MAX_LEN.code,
                RestrictionsManager.RESTRICTIONS.MIN_LEN.code,
                RestrictionsManager.RESTRICTIONS.REGEXP.code,
                RestrictionsManager.RESTRICTIONS.NULLABLE.code,
            ],
            [
                RestrictionsManager.RESTRICTIONS.GT.code,
                RestrictionsManager.RESTRICTIONS.GTE.code,
                RestrictionsManager.RESTRICTIONS.LT.code,
                RestrictionsManager.RESTRICTIONS.LTE.code,
                RestrictionsManager.RESTRICTIONS.EQUAL.code,
                RestrictionsManager.RESTRICTIONS.NOT_EQUAL.code,
            ],
        ];

        let disabledRestrictions: string[] = [];

        for (const combination of combinations) {
            const hasRest = combination.includes(activeRestrictions[0]);
            if (hasRest) {
                disabledRestrictions = Object.values(RestrictionsManager.RESTRICTIONS)
                    .filter((rest) => !combination.includes(rest.code))
                    .map((rest) => rest.code)
                    .concat(activeRestrictions);
                break;
            }
        }

        return disabledRestrictions;
    };

    static getDisabledRestrictions =
        (index: number, activeRestrictions: string[]) => (): string[] =>
            index < 1 && activeRestrictions.length <= 1
                ? []
                : RestrictionsManager.checkAvailableRestrictions(activeRestrictions);
}

export default RestrictionsManager;
