import { ProtectedPath } from "../interfaces/ProtectedPath";
import ProtectedPathsManager from "./ProtectedPathsManager";

export const PERMISSION = {
    VIEW: { value: "view", locale: "Просмотр" },
    EDIT: { value: "edit", locale: "Редактирование" },
    CREATE: { value: "create", locale: "Создание" },
    DELETE: { value: "delete", locale: "Удаление" },
} as const;

export type Permission = keyof typeof PermissionManager.PERMISSIONS;
export type PermissionWithNoValue = Permission | -1;

class PermissionManager {
    static getPermissionByCode = (code: number): typeof PERMISSION => {
        return PermissionManager.PERMISSIONS[code];
    };

    static readonly PERMISSIONS = {
        0: {},
        1: {
            VIEW: PERMISSION.VIEW,
        },
        2: {
            VIEW: PERMISSION.VIEW,
            EDIT: PERMISSION.EDIT,
        },
        3: {
            VIEW: PERMISSION.VIEW,
            EDIT: PERMISSION.EDIT,
            CREATE: PERMISSION.CREATE,
        },
        4: {
            VIEW: PERMISSION.VIEW,
            EDIT: PERMISSION.EDIT,
            CREATE: PERMISSION.CREATE,
            DELETE: PERMISSION.DELETE,
        },
    };

    static canView = (permission: number) => {
        if (!permission) return false;
        return !!PermissionManager.getPermissionByCode(permission)?.VIEW;
    };

    static canEdit = (permission: number) => {
        if (!permission) return false;
        return !!PermissionManager.getPermissionByCode(permission)?.EDIT;
    };

    static canCreate = (permission: number) => {
        if (!permission) return false;
        return !!PermissionManager.getPermissionByCode(permission)?.CREATE;
    };

    static canDelete = (permission: number) => {
        if (!permission) return false;
        return !!PermissionManager.getPermissionByCode(permission)?.DELETE;
    };

    /**
     *  Returns `4` if path have nested paths.
     *  Returns `-1` if path not found
     */
    static getPermissionFromProtectedPaths = (
        protectedPaths: ProtectedPath[],
        searchValue: string,
        byField: string = "url"
    ): PermissionWithNoValue => {
        const path = ProtectedPathsManager.getPath(
            protectedPaths,
            searchValue,
            byField
        ) as ProtectedPath;

        if (!path) return -1;

        if (path.permission instanceof Array) {
            return 4;
        } else {
            return path.permission;
        }
    };
}

export default PermissionManager;
