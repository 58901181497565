import { Divider, Drawer, IconButton, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronLeft } from "@material-ui/icons";
import React, { useMemo } from "react";
import { useStoreon } from "storeon/react";
import { Constants } from "@modules";
import SidebarListItem from "./SidebarListItem";
import SidebarNestedPathsLinks from "./SidebarNestedPathsLinks";
import { CommonEvents, CommonState } from "@store/common-module";
import { PAGE_TYPE } from "@modules/PageTypesManager";
import ThemeSwitcher from "@components/Common/Theme";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: Constants.Common.SIDEBAR_WIDTH,
        flexShrink: 0,
    },
    drawerPaper: {
        width: Constants.Common.SIDEBAR_WIDTH,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    menu: {
        flexGrow: 1,
    },
}));

const Sidebar = () => {
    const classes = useStyles();
    const { sidebarOpen, menu, dispatch } = useStoreon<CommonState, CommonEvents>(
        "sidebarOpen",
        "menu"
    );

    const closeSidebar = () => {
        dispatch("common/toggle/sidebar", false);
    };

    useMemo(() => menu.sort((a, b) => a.sort - b.sort), [menu]);

    return (
        <Drawer
            variant="persistent"
            open={sidebarOpen}
            anchor="left"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={closeSidebar}>
                    <ChevronLeft />
                </IconButton>
            </div>
            <List className={classes.menu}>
                {menu.map((item) => {
                    const childs = item.permission instanceof Array ? item.permission : [];

                    if (childs?.length && item.page_type === PAGE_TYPE.SECTION) {
                        childs.sort((a, b) => a.sort - b.sort);

                        if (childs?.length) {
                            return (
                                <div key={item.id}>
                                    <Divider />
                                    <SidebarNestedPathsLinks
                                        parentTitle={item.title}
                                        childs={childs}
                                    />
                                </div>
                            );
                        }
                    }

                    if (
                        [PAGE_TYPE.SECTION, PAGE_TYPE.CONTENT, PAGE_TYPE.MULTIPLE_CONTENT].includes(
                            item.page_type
                        )
                    ) {
                        return (
                            <SidebarListItem
                                key={item.id}
                                text={item.title}
                                href={item.url}
                                icon={item.icon}
                            />
                        );
                    }

                    return null;
                })}
            </List>

            <Divider />
            <ThemeSwitcher fullWidth size="large" />
        </Drawer>
    );
};

export default Sidebar;
