import { useCombinedContentType } from "@hooks";
import { ContentTypesManager } from "@modules/ContentTypesManagers";
import { CombinedType } from "@modules/ContentTypesManagers/CombinedContentTypesManager";
import { useEffect, useState } from "react";
import useLoading from "./useLoading";

const useContentTitle = (contentType?: CombinedType, param?: number | string) => {
    const { loadingStatus, setLoadingStatus } = useLoading(!!param ? "loading" : "inactivity");
    const type = useCombinedContentType(contentType);
    const [contentTitle, setContentTitle] = useState("");

    useEffect(() => {
        const setTitle = async (combinedType, param) => {
            const type =
                combinedType.content instanceof Array
                    ? ContentTypesManager.getContentType(combinedType.content[0])
                    : combinedType;

            if (!type) return;

            const getTitle = type.content.getTitle;
            if (!getTitle) {
                setContentTitle(type?.name || "");
                return;
            }

            const title = await getTitle(param);
            setContentTitle(title || "");
            setLoadingStatus("loaded");
        };

        if (type) {
            if (param) setLoadingStatus("loading");
            setTitle(type, param);
        }
    }, [param, type, setLoadingStatus]);

    return { contentTitle, loadingStatus };
};

export default useContentTitle;
