import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { BaseService, PaginatedQueryResult, QueryParams, QueryResult } from ".";
import { CreateAttributeTypeDto } from "@dto/AttributeTypeDto";
import { UpdateAttributeTypeDto } from "@dto/AttributeTypeDto/UpdateAttributeTypeDto";
import { CreateAttributeTypeItemDto } from "@dto/AttributeTypeItemDto";
import { AttributeType } from "@interfaces/AttributeType";
import { AttributeTypeItem } from "@interfaces/AttributeTypeItem";

class AttributeTypesService extends BaseService {
    public getAttributeTypes = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<AttributeType>> => {
        const { data } = await this.instance.get("/nsi_meta/atype", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getAttributeType = async (attributeTypeId: number): Promise<AttributeType | null> => {
        const { data } = await this.instance.get("/nsi_meta/atype", {
            params: { ids_list: attributeTypeId },
        });

        return this.getFirstItemOrNull<AttributeType>(
            this.toPaginatedUniversalOutput<AttributeType>(data).result.items
        );
    };

    public getAttributeTypesItems = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<AttributeTypeItem>> => {
        const { data } = await this.instance.get("/nsi_meta/atype_item", {
            params,
        });

        return data;
    };

    public createAttributeTypeItems = async (
        aTypeItems: CreateAttributeTypeItemDto[]
    ): Promise<QueryResult<AttributeTypeItem>> => {
        const { data } = await this.instance.post("/nsi_meta/atype_item", aTypeItems);

        return data;
    };

    public createAttributeType = async (
        aType: CreateAttributeTypeDto
    ): Promise<QueryResult<AttributeType>> => {
        const { data } = await this.instance.post("/nsi_meta/atype", { data: aType });

        return data;
    };

    public updateAttributeType = async (
        id: number,
        attributeTypeDto: UpdateAttributeTypeDto
    ): Promise<QueryResult<AttributeType>> => {
        const { data } = await this.instance.patch("/nsi_meta/atype", {
            id,
            data: attributeTypeDto,
        });

        return data;
    };

    public deleteAttributeType = async (
        aTypeIds: number[]
    ): Promise<QueryResult<AttributeType>> => {
        const { data } = await this.instance.delete("/nsi_meta/atype", {
            data: {
                ids_list: aTypeIds,
            },
        });

        return data;
    };

    public deleteAttributeTypeItem = async (
        aTypeItemsIds: number[]
    ): Promise<QueryResult<AttributeTypeItem>> => {
        const { data } = await this.instance.delete("/nsi_meta/atype_item", {
            data: {
                ids_list: aTypeItemsIds,
            },
        });

        return data;
    };

    public setAttributeTypeBlock = async (aTypeId: number) => {
        const { data } = await this.instance.post("/nsi_meta/atype/block", {
            id: aTypeId,
        });

        return data;
    };

    public unsetAttributeTypeBlock = async (aTypeId: number) => {
        const { data } = await this.instance.delete("/nsi_meta/atype/block", {
            data: {
                id: aTypeId,
            },
        });

        return data;
    };
}

export default new AttributeTypesService(new HttpClient(Config.API_URL), AuthManager);
