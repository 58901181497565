import { Checkbox, FormControlLabel, FormGroup, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { AttributeType } from "../../interfaces/AttributeType";
import { AttributeTypeSchema } from "../../schema/AttributeTypeSchema";
import AutoSizeTextField from "../Controls/Inputs/AutoSizeTextField";

interface Props {
    onSubmit: (values) => Promise<void>;
    attributeType?: AttributeType;
    onChangeCompositeType: (value: boolean) => void;
}

const AttributeTypeForm = forwardRef(
    ({ onSubmit, attributeType, onChangeCompositeType }: Props, ref: any) => {
        const initialData = {
            name: attributeType?.name || "",
            notes: attributeType?.notes || "",
            display_name: attributeType?.display_name || "",
            multiple_choice: attributeType?.multiple_choice || false,
            composite_choice: attributeType?.composite_choice || false,
            is_active: attributeType?.is_active ?? true,
        };

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={AttributeTypeSchema}
                onSubmit={(values) => {
                    const copy = { ...values };
                    copy.name = copy.name.trim();
                    copy.notes = copy.notes.trim();
                    onSubmit(copy);
                }}
                validateOnMount
            >
                {({ touched, errors, handleChange, values }) => {
                    return (
                        <Form>
                            <AutoSizeTextField
                                name="name"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                required
                                helperText={touched.name ? errors.name : ""}
                                error={touched.name && Boolean(errors.name)}
                                label="Наименование"
                                value={values.name}
                                onChange={handleChange}
                                fullWidth
                                maxRows={3}
                            />

                            <TextField
                                name="display_name"
                                size="small"
                                label="Отображаемое имя"
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                helperText="Необязательно"
                                value={values.display_name}
                                margin="normal"
                            />

                            <AutoSizeTextField
                                name="notes"
                                size="small"
                                label="Комментарий"
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                helperText="Необязательно"
                                value={values.notes}
                                margin="normal"
                                maxRows={5}
                                minRows={2}
                                rows={2}
                            />

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.multiple_choice}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            name="multiple_choice"
                                            color="primary"
                                        />
                                    }
                                    label="Множественное значение"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.composite_choice}
                                            onChange={(e) => {
                                                handleChange(e);
                                                onChangeCompositeType(e.target.checked);
                                            }}
                                            name="composite_choice"
                                            color="primary"
                                        />
                                    }
                                    label="Составной тип"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.is_active}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            name="is_active"
                                            color="primary"
                                        />
                                    }
                                    label="Отображать на фронтенде"
                                />
                            </FormGroup>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default AttributeTypeForm;
