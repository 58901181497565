import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { GeneralSettingsForm } from "../../Forms/Settings";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { TableEvents, TableState } from "@store/table-module";
import {
    CreateGeneralSettingsDto,
    UpdateGeneralSettingsDto,
} from "@dto/SettingsDto/GeneralSettingsDto";
import { generalSettingsService } from "@services/Settings";
import { GeneralSettings } from "@interfaces/GeneralSettings";
import { ModalContentLoading } from "../../Common/Modal";
import { useAsync, useLoading } from "@hooks";

const GeneralSettingsDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data, loadingStatus } = useAsync<GeneralSettings>(
        generalSettingsService.getOneGeneralSettings,
        { functionArgs: [itemId], canLoad: !!itemId }
    );

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreateGeneralSettings = async (values) => {
        setSubmitLoadingStatus("loading");
        try {
            const dto = generalSettingsService.plainToClass(CreateGeneralSettingsDto, values);

            await generalSettingsService.createGeneralSettings(dto);
            enqueueSnackbar("Настройка успешно создана", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании настройки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleUpdateGeneralSettings = async (values) => {
        if (!itemId) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = generalSettingsService.plainToClass(UpdateGeneralSettingsDto, values);

            await generalSettingsService.updateGeneralSettings(itemId, dto);
            enqueueSnackbar("Настройка успешно обновлена", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении настройки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <GeneralSettingsForm
                        ref={formRef}
                        onSubmit={
                            itemId ? handleUpdateGeneralSettings : handleCreateGeneralSettings
                        }
                        data={itemId ? data : undefined}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default GeneralSettingsDialogContext;
