import { Tabs } from "@components/Views/Tabs";
import { useCombinedContentType } from "@hooks";
import { ContentTypesManager } from "@modules/ContentTypesManagers";
import { WithMultipleContentTypeProps } from "@modules/ContentTypesManagers";
import React from "react";
import { EnhancedContentLayout, EnhancedPageHead } from ".";

const EnhancedTabs = ({ contentType }: WithMultipleContentTypeProps<{}>) => {
    const type = useCombinedContentType(contentType);

    if (!type) return null;

    if (!(type?.content instanceof Array))
        throw new Error(`Content type '${contentType}' must be in array`);

    return (
        <Tabs
            tabs={type.content.map(
                (content) => ContentTypesManager.getContentType(content)?.name || ""
            )}
            panels={type.content.map((type) => (
                <>
                    <EnhancedPageHead contentType={type} />
                    <EnhancedContentLayout contentType={type} />
                </>
            ))}
            indicatorColor="primary"
            textColor="primary"
            marginTop={2}
        />
    );
};

export default EnhancedTabs;
