import { useCurrentPageInfo, useModal } from "@hooks";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { PermissionManager } from "@modules";
import { MODAL_TYPE } from "@modules/ModalManager";
import { fileService } from "@services";
import { ModalEvents, ModalState } from "@store/modal-module";
import { useSnackbar } from "notistack";
import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Add, Cached, Delete, Edit } from "@material-ui/icons";
import { useStoreon } from "storeon/react";

const useStyles = makeStyles(() => ({
    listIcon: {
        minWidth: 30,
    },
}));

const DirectoryContextMenu = ({
    itemId,
    directoryName,
    mouseX,
    mouseY,
    onClose,
    onUpdate,
    onResetSelectedDirectory,
}) => {
    const classes = useStyles();
    const { palette } = useTheme();
    const { dispatch } = useStoreon<ModalState, ModalEvents>();
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleCreateDirectoryClick = () => {
        dispatch("modal/set/type", MODAL_TYPE.ADD_DIRECTORY);
        dispatch("modal/set/props", { parentDirId: itemId, onUpdate });
        dispatch("modal/toggle", true);
        onClose();
    };

    const handleRenameDirectoryClick = () => {
        dispatch("modal/set/type", MODAL_TYPE.EDIT_DIRECTORY);
        dispatch("modal/set/props", { itemId, onUpdate });
        dispatch("modal/toggle", true);
        onClose();
    };

    const handleDeleteDirectoryClick = () => {
        if (directoryName === "root") { // Проверяем, равно ли имя директории "root"
            enqueueSnackbar("Нельзя удалить корневую директорию", { // Выводим сообщение об ошибке
                variant: "error",
            });
            onClose(); // Закрываем модальное окно
            return; // Прерываем выполнение функции
        }

        dispatch("modal/set/type", MODAL_TYPE.CONFIRMATION);
        dispatch("modal/set/props", {
            // content: "Вы действительно хотите удалить папку?",
            content: `Вы действительно хотите удалить папку ${directoryName}?`, //Vma 07-05-24 Добавляем имя папки в текст содержания
            onOk: async () => {
                try {
                    await fileService.deleteDirectories([itemId]);
                    enqueueSnackbar("Папка успешно удалена", {
                        variant: "success",
                    });
                    onResetSelectedDirectory();
                    await onUpdate();
                } catch {
                    enqueueSnackbar("Произошла ошибка при удалении папки", {
                        variant: "error",
                    });
                }
            },
        });
        dispatch("modal/toggle", true);
        onClose();
    };

    const handleCreateIndexingTaskClick = async () => {
        handleOpenModal("DIRECTORY_INDEXING", { itemId, titleProps: itemId });
        onClose();
    };

    const canEdit = PermissionManager.canEdit(permission);
    const canDelete = PermissionManager.canDelete(permission);

    return (
        <Menu
            keepMounted
            open={mouseY !== null}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={
                mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
            }
        >
            {canEdit && (
                <MenuItem onClick={handleCreateDirectoryClick} dense>
                    <ListItemIcon className={classes.listIcon}>
                        <Add fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Создать подпапку</ListItemText>
                </MenuItem>
            )}
            {canEdit && itemId !== 1 && (
                <MenuItem onClick={handleRenameDirectoryClick} dense>
                    <ListItemIcon className={classes.listIcon}>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Переименовать</ListItemText>
                </MenuItem>
            )}
            {canEdit && (
                <MenuItem onClick={handleCreateIndexingTaskClick} dense divider>
                    <ListItemIcon className={classes.listIcon}>
                        <Cached fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Индексация</ListItemText>
                </MenuItem>
            )}
            {canDelete && itemId !== 1 && (
                <MenuItem
                    onClick={handleDeleteDirectoryClick}
                    dense
                    style={{ color: palette.error.main }}
                >
                    <ListItemIcon className={classes.listIcon}>
                        <Delete fontSize="small" color="error" />
                    </ListItemIcon>
                    <ListItemText>Удалить папку</ListItemText>
                </MenuItem>
            )}
        </Menu>
    );
};

export default DirectoryContextMenu;
