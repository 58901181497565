import * as Yup from "yup";

export const CartItemSchema = Yup.object().shape({
    cart_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, выберите корзину"),
    excluded: Yup.boolean(),
    sku: Yup.string().required("Пожалуйста, укажите идентификатор товара"),
    quantity: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, укажите количество"),
});
