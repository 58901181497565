import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HourglassEmpty } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
    container: {
        height: `calc(100vh - 200px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    box: {
        textAlign: "center",
    },
    iconWrapper: {
        borderRadius: "50%",
        height: "4rem",
        width: "4rem",
        backgroundColor: theme.palette.secondary.light,
        margin: "1rem auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        color: theme.palette.common.white,
        fontSize: 40,
        animation: `$anim 2000ms ${theme.transitions.easing.easeInOut} infinite`,
    },
    "@keyframes anim": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
    loading: {
        "&::after": {
            content: '" ."',
            animation: "$dots 1.5s steps(5, end) infinite",
        },
    },
    "@keyframes dots": {
        "0%, 20%": {
            color: "rgba(0,0,0,0)",
            textShadow: ".25em 0 0 rgba(0,0,0,0) .5em 0 0 rgba(0,0,0,0);",
        },
        "40%": {
            color: theme.palette.common.black,
            textShadow: ".25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);",
        },
        "60%": {
            textShadow: ".25em 0 0 black,.5em 0 0 rgba(0,0,0,0);",
        },
        "80%, 100%": {
            textShadow: ".25em 0 0 black, .5em 0 0 black;",
        },
    },
}));

const Preloader = ({
    textPrimary = "Идет загрузка",
    textSecondary = "Получение данных с сервера",
    center = false,
}) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.box, { [classes.container]: center })}>
            <div className={classes.iconWrapper}>
                <HourglassEmpty className={classes.icon} />
            </div>

            <Typography variant="h6" className={classes.loading}>
                {textPrimary}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
                {textSecondary}
            </Typography>
        </Box>
    );
};

export default Preloader;
