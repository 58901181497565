import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { memo } from "react";
import { File } from "@interfaces/File";
import { isObjectsAreEqual, truncateText } from "@modules/Utils";
import { FilePreview } from "@components/Views/FilePreview";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    item: {
        textAlign: "center",
        "&:hover": {
            backgroundColor: theme.palette.action.selected,
            borderRadius: 10,
        },
    },
    name: {
        wordBreak: "break-all",
        padding: theme.spacing(0, 1),
    },
}));

type Props = {
    item: File;
    onClick(event: React.MouseEvent<HTMLElement>): void;
};

const FileItemCard = ({ item, onClick }: Props) => {
    const classes = useStyles();

    return (
        <Grid
            item
            xs={3}
            lg={2}
            onContextMenu={onClick}
            data-itemid={item.id}
            className={classes.item}
        >
            <Box height={80} my={2}>
                <FilePreview
                    file={item.path}
                    containerStyle={{
                        maxWidth: 80,
                        maxHeight: 80,
                        margin: "auto",
                    }}
                    imageStyle={{
                        top: "50%",
                        bottom: "50%",
                    }}
                />
            </Box>
            <Typography className={classes.name} variant="body2" gutterBottom>
                {truncateText(item.name, 30) || "Название не указано"}
            </Typography>
        </Grid>
    );
};

export const FileItemCardSkeleton = () => {
    return (
        <Grid item xs={4} md={3} lg={2}>
            <Skeleton height={150} width="100%" />
        </Grid>
    );
};

export default memo(FileItemCard, (prev, next) => isObjectsAreEqual(prev, next));
