import { TextFieldProps } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useAsync } from "@hooks";
import { fileService } from "@services";
import CustomAsyncAutocomplete from "./CustomAsyncAutocomplete";
import { File } from "@interfaces/File";

interface Props {
    textFieldProps: TextFieldProps;
    onChange: (name, value) => void;
    value: number | null;
    directoryId?: number | null;
    [key: string]: any;
}

const FileAutocomplete = ({ textFieldProps, onChange, value, directoryId, ...props }: Props) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [selectedValue, setSelectedValue] = useState<File | null>(null);

    const { data, loadingStatus } = useAsync(fileService.getFilesByDirectory, {
        functionArgs: [
            directoryId,
            {
                page: 1,
                page_size: 20,
                q: inputValue || undefined,
                id: value ? value : undefined,
            },
        ],
        canLoad: !!directoryId,
        setLoadingStatusOnUpdate: false,
    });

    useEffect(() => {
        if (loadingStatus === "loaded") {
            const item = data?.result?.items?.find((el) => value === el.id);

            setSelectedValue(item || null);
        }
    }, [value, loadingStatus, data]);

    const handleChangeValue = useCallback(
        (e, file: File) => {
            onChange(textFieldProps.name, file?.id || null);
        },
        [onChange, textFieldProps]
    );

    return (
        <CustomAsyncAutocomplete
            {...props}
            isLoading={loadingStatus === "loading"}
            value={selectedValue || null}
            items={data?.result?.items || []}
            textFieldProps={textFieldProps}
            optionField="name"
            secondaryField="description"
            onChange={handleChangeValue}
            onChangeInput={setInputValue}
        />
    );
};

export default FileAutocomplete;
