import React from "react";
import { useStoreon } from "storeon/react";
import { Add } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { ContentEvents, ContentState } from "@store/content-module";

const AddNewUserRoleButton = () => {
    const { dispatch } = useStoreon<ContentState, ContentEvents>();

    const handleAddRole = () => {
        dispatch("content/staff_roles/add/roles");
    };

    return (
        <Button color="primary" size="small" onClick={handleAddRole} startIcon={<Add />}>
            Добавить роль
        </Button>
    );
};

export default AddNewUserRoleButton;
