import { LoadingStatus } from "@hooks/useLoading";
import { StaffMember } from "@interfaces/StaffMember";
import { User } from "@interfaces/User";
import { Config } from "./Constants";

export const getUserFullName = (user: StaffMember | User, withMiddleName = true): string => {
    return `${user?.last_name || ""} ${user?.first_name || ""} ${
        withMiddleName && "middle_name" in user ? user.middle_name : ""
    }`;
};

export const getUserShortName = (user: StaffMember | User): string => {
    return `${user?.last_name || ""} ${user?.first_name[0].toUpperCase() || ""} ${
        "middle_name" in user ? user.middle_name[0].toUpperCase() : ""
    }`;
};

export const getGenderShortName = (gender: User["gender"]): string => {
    if (!gender) return "";
    return gender === "male" ? "М" : "Ж";
};

export const getFileUrl = (url: string): string => {
    return Config.API_URL + url;
};

export const getBlobType = (blob: Blob): string => {
    return blob.type.split("/")[0] || "";
};

export const flatten = <T extends Record<string, any>>(items: T[], key: string): T[] => {
    let flat = [] as T[];

    for (const item of items) {
        flat.push(item);
        if (item[key] instanceof Array) {
            flat = [...flat, ...flatten<T>(item[key], key)];
        }
    }

    return flat;
};

export const getFileFormat = (filePath: string, type: "remote" | "local" = "remote"): string => {
    if (type === "remote") {
        return filePath.split(".").pop() || "";
    } else {
        return filePath.split("/").pop() || "";
    }
};

export const arrayToSubArrays = <T extends Record<string, any>>(
    items: T[],
    elementsPerSubArray: number
): T[][] => {
    const arr: T[][] = [];

    for (let i = 0, k = -1; i < items.length; i++) {
        if (i % elementsPerSubArray === 0) {
            k++;
            arr.push([]);
        }

        arr[k].push(items[i]);
    }

    return arr;
};

export const truncateText = (text: string, maxLen: number) => {
    if (!text) return "";
    return text.length > maxLen ? `${text.substring(0, maxLen)}...` : text;
};

export const isObjectsAreEqual = (obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const compose =
    (...rest) =>
    (x) =>
        rest.reduceRight((y, f) => f(y), x);

export const combineLoadingStatuses = (...statuses: LoadingStatus[]): LoadingStatus => {
    let completeStatus: LoadingStatus = "inactivity";

    for (const status of statuses) {
        if (status === "inactivity") {
            completeStatus = "inactivity";
            continue;
        }
        if (status === "loading") {
            completeStatus = "loading";
            break;
        }
        if (status === "loaded") {
            completeStatus = "loaded";
            continue;
        }
    }

    return completeStatus;
};
