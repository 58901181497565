import CopyToClipboardButton from "@components/Controls/Buttons/CopyToClipboardButton";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorOutline, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import React, { ErrorInfo } from "react";
import { IconCircle } from "../IconCircle";

const useStyles = makeStyles((theme) => ({
    center: {
        justifyContent: "center",
    },
    box: {
        height: `calc(100vh - 100px)`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    accordion: {
        width: "auto",
        maxWidth: 600,
        marginTop: theme.spacing(2),
    },
    accordionDetail: {
        display: "flex",
        flexDirection: "column",
    },
    copyButton: {
        marginTop: theme.spacing(2),
    },
}));

export type ErrorProps = {
    error: Error;
    errorInfo?: ErrorInfo;
    center?: boolean;
    showStack?: boolean;
    textPrimary?: string;
    textSecondary?: string;
};

const Error = ({
    error,
    errorInfo,
    showStack = true,
    center = true,
    textPrimary = "Упс, что-то пошло не так :(",
    textSecondary = "Перезагрузите страницу",
}: ErrorProps) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.box, { [classes.center]: center })}>
            <IconCircle Icon={ErrorOutline} />
            <Typography variant="h5">{textPrimary}</Typography>
            <Typography color="textSecondary">{textSecondary}</Typography>
            {showStack && error.stack && (
                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Детали</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetail}>
                        <Typography
                            variant="body2"
                            component="pre"
                            color="textSecondary"
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            {error.stack}
                        </Typography>

                        <CopyToClipboardButton
                            copyText={error.stack}
                            color="primary"
                            variant="outlined"
                            className={classes.copyButton}
                        />
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
};

export default Error;
