import React, { useEffect } from "react";

const withCloseListener = (WrappedComponent) => (props) => {
    const unload = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };
    useEffect(() => {
        window.addEventListener("beforeunload", unload);

        return () => window.removeEventListener("beforeunload", unload);
    }, []);

    return <WrappedComponent {...props} />;
};

export default withCloseListener;
