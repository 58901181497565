import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { Constants } from "@modules";
import dayjs from "dayjs";
import React from "react";
import { UniversalInputProps } from "@interfaces/System/UniversalInputProps";

type UDatePickerProps = UniversalInputProps &
    Partial<{
        pickerProps: Partial<Omit<DatePickerProps, "variant" | "onChange">>;
    }>;

const UDatePicker = ({ pickerProps, ...props }: UDatePickerProps) => {
    const format = Constants.Format.DATE;

    const handleChange = (date) => {
        const formattedDate = dayjs.isDayjs(date) ? dayjs(date).format(format) : null;

        props.onChange(props.name, formattedDate);
    };

    const value = props.value ? dayjs(props.value, format) : null;
    return (
        <DatePicker
            clearable
            value={value}
            required={props.required}
            size={props.size}
            name={props.name}
            margin={props.margin}
            label={props.label || "Выберите дату"}
            fullWidth={props.fullWidth}
            onChange={handleChange}
            error={props.error}
            helperText={props.helperText}
            format="DD/MM/YYYY"
            inputVariant={props.variant}
            disabled={props.disabled}
            okLabel="Ок"
            clearLabel="Очистить"
            cancelLabel="Отмена"
            {...pickerProps}
        />
    );
};

export default UDatePicker;
