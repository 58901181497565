import { Cart } from "@interfaces/Cart";
import { UpdateFormProps } from "@interfaces/System/FormProps";
import { TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { UpdateCartSchema } from "@schema/Cart";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { User } from "@interfaces/User";
import { userService } from "@services";

const UpdateCartForm = forwardRef(({ onSubmit, data }: UpdateFormProps<Cart>, ref: any) => {
    const initialData = {
        number: data.number ?? "",
        user_id: data.user_id ?? null,
    } as Cart;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={UpdateCartSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, setFieldValue, values }) => {
                return (
                    <Form>
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            disabled
                            value={values.number || ""}
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="number"
                            helperText="Невозможно изменить"
                            label="Идентификатор корзины"
                        />

                        <AsyncAutocomplete<User>
                            fetchFn={userService.getUsers}
                            onChange={(name, value) => setFieldValue(name, value?.id || null)}
                            value={values.user_id}
                            optionField="email"
                            textFieldProps={{
                                required: true,
                                fullWidth: true,
                                name: "user_id",
                                variant: "outlined",
                                size: "small",
                                label: "Пользователь",
                                margin: "normal",
                                error: touched.user_id && !!errors.user_id,
                                helperText: touched.user_id ? errors.user_id : "",
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default UpdateCartForm;
