import { PaginatedQueryResult, QueryParams, QueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { CreateBrandDealerBindDto, UpdateBrandDealerBindDto } from "@dto/StaffOrderDto";
import { BrandDealerBind } from "@interfaces/BrandDealerBind";

class StaffBrandDealerBindService extends BaseService {
    public getBrandDealerBinds = async (
        params: QueryParams
    ): Promise<PaginatedQueryResult<BrandDealerBind>> => {
        const { data } = await this.instance.get("/brand_dealer_bind", { params });

        return data;
    };

    public getOneBrandDealerBind = async (id: number): Promise<BrandDealerBind> => {
        const { data } = await this.instance.get("/brand_dealer_bind", {
            params: { ids_list: id },
        });

        return data.result.items[0];
    };

    public createBrandDealerBind = async (
        createStaffOrderItemDto: CreateBrandDealerBindDto
    ): Promise<QueryResult<BrandDealerBind>> => {
        const { data } = await this.instance.post("/brand_dealer_bind", createStaffOrderItemDto);

        return data;
    };

    public updateBrandDealerBind = async (
        id: number,
        updateStaffOrderItemDto: UpdateBrandDealerBindDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/brand_dealer_bind", {
            id,
            data: updateStaffOrderItemDto,
        });

        return data;
    };

    public deleteBrandDealerBinds = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/brand_dealer_bind", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new StaffBrandDealerBindService(new HttpClient(Config.API_URL), AuthManager);
