import { EntityAttributeSchema } from "@interfaces/EntityAttributeSchema";
import { RestrictionsManager, TableFilterManager } from "@modules";
import { StoreonModule } from "storeon";
import { TableFilter } from "@interfaces/System/TableFilter";
import shortid from "shortid";

export interface TableState {
    ref: any;
    filters: TableFilter[];
}

export interface TableEvents {
    "table/set/ref": any;
    "table/update/data";
    "table/set/filters": EntityAttributeSchema[];
    "table/reset/filters";
    "table/update/filter": { key: string; value: any };
}

export const tableModule: StoreonModule<TableState, TableEvents> = (store) => {
    store.on("@init", () => ({
        ref: null,
        filters: [],
    }));

    store.on("table/set/ref", (state, value) => ({ ref: value }));
    store.on("table/update/data", async (state) => {
        if (!state.ref.current) return;

        const { page } = state.ref.current.state.query;

        if (page !== 0) {
            await state.ref.current.onPageChange(null, page);
            return;
        }

        await state.ref.current.onQueryChange();
    });
    store.on("table/set/filters", (state, attrSchema) => {
        const filters: TableFilter[] = attrSchema.map((el) => ({
            key: shortid.generate(),
            at_id: el.at_id,
            name: el.a_name,
            value: "",
            ptype: el.items[0].ptype,
            restrictions: RestrictionsManager.getRestrictionsArrayFromObject(
                el.items[0]?.restrictions instanceof Array ? el.items[0]?.restrictions[0] : {}
            ),
        }));

        const filteredFilters = TableFilterManager.exclude(filters, ["file"]);

        return {
            filters: filteredFilters,
        };
    });
    store.on("table/update/filter", (state, { key, value }) => {
        const copyArr = [...state.filters];
        const index = copyArr.findIndex((el) => el.key === key);
        copyArr[index] = { ...copyArr[index], value };

        return {
            filters: copyArr,
        };
    });
    store.on("table/reset/filters", (state) => ({
        filters: state.filters.map((filter) => ({ ...filter, value: null })),
    }));
};
