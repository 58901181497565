import { useCallback, useState } from "react";

type UsePaginationOptions = Partial<{
    initialPage: number;
    initialPageSize: number;
    scrollOnChange: boolean;
}>;

const usePagination = (options: UsePaginationOptions = {}) => {
    const { initialPage = 1, initialPageSize = 20, scrollOnChange = true } = options;

    const [page, setPage] = useState(initialPage);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const handleChangePageSize = useCallback(
        (value: number) => {
            setPageSize(value);
            setPage(1);
            if (scrollOnChange) window.scrollTo({ top: 0, behavior: "smooth" });
        },
        [scrollOnChange]
    );

    const handleChangePage = useCallback(
        (value: number) => {
            setPage(value);
            if (scrollOnChange) window.scrollTo({ top: 0, behavior: "smooth" });
        },
        [scrollOnChange]
    );

    return {
        handleChangePage,
        setPage,
        setPageSize,
        handleChangePageSize,
        page,
        pageSize,
    };
};

export default usePagination;
