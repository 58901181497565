import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { UpdateStaffMemberDto } from "@dto/StaffDto";
import { staffService } from "@services/Staff";
import { TableEvents, TableState } from "@store/table-module";
import { ModalContentLoading } from "../../Common/Modal";
import UpdateStaffMemberForm from "../../Forms/Staff/UpdateStaffMemberForm";
import StaffRolesList from "../../Content/StaffRoles/StaffRolesList";
import AddNewUserRoleButton from "../../Controls/Actions/UserRoles/AddNewUserRoleButton";
import { Subtitle } from "../../Views/Title";
import { ContentEvents, ContentState } from "@store/content-module";
import { useAsync, useLoading } from "@hooks";

const EditStaffMemberDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<any>(); // ref to formik
    const rolesRef = useRef<any>(); // ref to roles list
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch } = useStoreon<ContentState, ContentEvents>();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data: staffMember, loadingStatus } = useAsync(staffService.getOneStaffMember, {
        functionArgs: [itemId],
        canLoad: !!itemId,
        fetchCallback: (staffMember) => {
            if (!staffMember) return;
            dispatch("content/staff_roles/set/userid", staffMember.id);
            dispatch("content/staff_roles/set/roles", staffMember.memberof);
        },
    });

    useEffect(() => {
        return () => {
            dispatch("content/staff_roles/set/userid", null);
            dispatch("content/staff_roles/set/roles", []);
            dispatch("content/staff_roles/set/roles_to_revoke", []);
        };
    }, [dispatch]);

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleUpdateStaffMember = async (values) => {
        if (!staffMember) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = staffService.plainToClass(UpdateStaffMemberDto, values);
            await staffService.updateStaffMember(staffMember.id, dto);
            await rolesRef.current.handleSubmit();

            enqueueSnackbar("Сотрудник успешно обновлен", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении сотрудника", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {loadingStatus === "loaded" && staffMember && (
                    <>
                        <UpdateStaffMemberForm
                            onSubmit={handleUpdateStaffMember}
                            data={staffMember}
                            ref={formRef}
                        />

                        <Subtitle
                            title="Служебные роли"
                            bold
                            endAdornment={<AddNewUserRoleButton />}
                        />
                        <StaffRolesList ref={rolesRef} />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default EditStaffMemberDialogContext;
