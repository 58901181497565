import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

const ModalContentLoading = () => {
    return (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
        </Box>
    );
};

export default ModalContentLoading;
