import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { PaginatedQueryResult, QueryParams, QueryResultWithoutData } from ".";
import { CreateValueTypeDto, UpdateValueTypeDto } from "../dto/ValueTypeDto";
import { ValueType } from "../interfaces/ValueType";
import BaseService from "./BaseService";

class ValueTypesService extends BaseService {
    public getValueTypes = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<ValueType>> => {
        const { data } = await this.instance.get("/nsi_meta/vtype", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getValueType = async (valueTypeId: number): Promise<ValueType> => {
        const { data } = await this.instance.get("/nsi_meta/vtype", {
            params: { ids_list: valueTypeId },
        });

        return data.result.items[0];
    };

    public createValueType = async (vType: CreateValueTypeDto) => {
        const { data } = await this.instance.post("/nsi_meta/vtype", vType);

        return data;
    };

    public updateValueType = async (
        id: number,
        updateVTypeDto: UpdateValueTypeDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/nsi_meta/vtype", { id, data: updateVTypeDto });

        return data;
    };

    public deleteValueType = async (vTypeIds: number[]) => {
        const { data } = await this.instance.delete("/nsi_meta/vtype", {
            data: {
                ids_list: vTypeIds,
            },
        });

        return data;
    };

    public setValueTypeBlock = async (vTypeId: number) => {
        const { data } = await this.instance.post("/nsi_meta/vtype/block", {
            id: vTypeId,
        });

        return data;
    };

    public unsetValueTypeBlock = async (vTypeId: number) => {
        const { data } = await this.instance.delete("/nsi_meta/vtype/block", {
            data: {
                id: vTypeId,
            },
        });

        return data;
    };
}

export default new ValueTypesService(new HttpClient(Config.API_URL), AuthManager);
