import { PermissionSettings } from "@interfaces/PermissionSettings";
import { FormProps } from "@interfaces/System/FormProps";
import { TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { PermissionSettingsSchema } from "@schema/Settings";
import PermissionSlider from "@components/Controls/Sliders/PermissionSlider";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { StaffMember } from "@interfaces/StaffMember";
import { staffService } from "@services/Staff";

const PermissionSettingsForm = forwardRef(
    ({ onSubmit }: FormProps<PermissionSettings>, ref: any) => {
        const initialData = {
            username: "",
            table_name: "",
            permission: 1,
        } as PermissionSettings;

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={PermissionSettingsSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, setFieldValue, values }) => {
                    return (
                        <Form>
                            <AsyncAutocomplete<StaffMember>
                                fetchFn={staffService.getAllStaffMembers}
                                value={values.username}
                                onChange={(name, value) =>
                                    setFieldValue(name, value?.login || null)
                                }
                                optionField="login"
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                    size: "small",
                                    margin: "normal",
                                    variant: "outlined",
                                    name: "username",
                                    label: "Сотрудник",
                                    helperText: touched.username ? errors.username : "",
                                    error: touched.username && !!errors.username,
                                }}
                            />

                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.table_name}
                                required
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="table_name"
                                helperText={touched.table_name ? errors.table_name : ""}
                                error={touched.table_name && !!errors.table_name}
                                label="Таблица"
                            />

                            <PermissionSlider
                                name="permission"
                                value={values.permission}
                                onChange={(e, v) => setFieldValue("permission", v)}
                            />

                            <TextField
                                name="e_id"
                                type="number"
                                variant="outlined"
                                size="small"
                                label="ID сущности"
                                margin="normal"
                                helperText={touched.e_id ? errors.e_id : "Необязательно"}
                                error={touched.e_id && !!errors.e_id}
                                value={values.e_id}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default PermissionSettingsForm;
