import ContentLayout from "@components/Views/ContentLayout";
import { useAsync } from "@hooks";
import { Directory } from "@interfaces/Directory";
import { fileService } from "@services";
import { ContentEvents, ContentState } from "@store/content-module";
import React from "react";
import { useStoreon } from "storeon/react";
import { DirectoriesTree } from "./Directories";
import { FilesList } from "./Files";

const FileStorage: React.FC = () => {
    const { fileStorage } = useStoreon<ContentState, ContentEvents>("fileStorage");
    const selectedDir = fileStorage.selectedDirectory;

    const { data, loadingStatus, updateData } = useAsync<Directory>(
        fileService.getDirectoriesTree,
        {
            setLoadingStatusOnUpdate: false,
        }
    );

    return (
        <ContentLayout
            mainContent={
                <DirectoriesTree
                    data={data}
                    loadingStatus={loadingStatus}
                    updateData={updateData}
                />
            }
            mainContentGridProps={{ md: 4 }}
            sideContent={<FilesList directory={selectedDir} />}
            sideContentGridProps={{ md: 8 }}
        />
    );
};

export default FileStorage;
