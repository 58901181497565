import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { ModalContentLoading } from "@components/Common/Modal";
import React, { useRef } from "react";
import { eShopService } from "@services";
import { useStoreon } from "storeon/react";
import { TableEvents, TableState } from "@store/table-module";
import { useSnackbar } from "notistack";
import { useAsync } from "@hooks";
import { EShopForm } from "@components/Forms/EShop";
import { CreateEShopDto, UpdateEShopDto } from "@dto/EShopDto";

const EShopDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<HTMLFormElement>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();

    const { data, loadingStatus } = useAsync(eShopService.getOneEShop, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current!.handleSubmit();
    };

    const handleCreateEShop = async (values) => {
        try {
            const dto = eShopService.plainToClass(CreateEShopDto, values);
            await eShopService.createEShop(dto);
            enqueueSnackbar("Магазин успешно создан", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании магазина", {
                variant: "error",
            });
        }
    };

    const handleUpdateEShop = async (values) => {
        if (!data) return;

        try {
            const dto = eShopService.plainToClass(UpdateEShopDto, values);
            await eShopService.updateEShop(data.id, dto);
            enqueueSnackbar("Магазин успешно обновлен", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении магазина", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <EShopForm
                        ref={formRef}
                        data={data}
                        onSubmit={data ? handleUpdateEShop : handleCreateEShop}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button color="primary" variant="contained" onClick={invokeSubmit}>
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default EShopDialogContext;
