import { Container } from "@material-ui/core";
import React from "react";
import Breadcrumbs from "../components/Views/Breadcrumbs/Breadcrumbs";
import { ProtectedPathsManager } from "../modules";
import useTitle from "../hooks/useTitle";
import { useStoreon } from "storeon/react";
import { SectionCards } from "@components/Views/Lists";
import { CommonEvents, CommonState } from "@store/common-module";
import { WithPageProps } from "@modules/PageTypesManager";
import PageHead from "@components/Views/PageHead/PageHead";
import ContentLayout from "@components/Views/ContentLayout";
import SectionHelpCard from "@components/Views/Cards/SectionHelpCard";

const SectionPage = ({ url, icon, title, description }: WithPageProps<{}>) => {
    const { menu } = useStoreon<CommonState, CommonEvents>("menu");

    const sections = ProtectedPathsManager.getChildPaths(menu, url);

    useTitle(title);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs />
            <PageHead noTitle />

            <ContentLayout
                mainContent={<SectionCards sections={sections} />}
                sideContent={<SectionHelpCard />}
            />
        </Container>
    );
};

export default SectionPage;
