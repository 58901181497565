import { useAsync } from "@hooks";
import { List } from "@material-ui/core";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { entitiesService } from "@services";
import { EntityEvents, EntityState } from "@store/entity-module";
import { ModalContentLoading } from "@components/Common/Modal";
import EntityAttributeCard from "./EntityAttributeCard/EntityAttributeCard";
import { Entity } from "@interfaces/Entity";

type EntityAttributesListProps = { entityTypeId: number; entity?: Entity };

const EntityAttributesList = ({ entityTypeId, entity }: EntityAttributesListProps) => {
    const { attrs, schema, readOnly, dispatch } = useStoreon<EntityState, EntityEvents>(
        "attrs",
        "readOnly",
        "schema"
    );

    const { loadingStatus } = useAsync(entitiesService.getTypeSchema, {
        functionArgs: [entityTypeId],
        canLoad: !!entityTypeId,
        fetchCallback: (values) => {
            if (!values.length) return;

            dispatch("entity/set/schema", values);
            dispatch("entity/set/attrs", { schemas: values, entity });
        },
    });

    useEffect(() => {
        return () => {
            dispatch("entity/reset/attrs");
            dispatch("entity/set/schema", []);
        };
    }, [dispatch]);

    if (loadingStatus === "loading") return <ModalContentLoading />;

    return (
        <List>
            {loadingStatus === "loaded" &&
                attrs?.map((attr) => {
                    return (
                        <EntityAttributeCard
                            key={attr.atype_id}
                            attribute={attr}
                            readOnly={readOnly}
                            schema={schema || []}
                        />
                    );
                })}
        </List>
    );
};

export default EntityAttributesList;
