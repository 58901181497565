import ContentTypeSelect from "@components/Controls/Selects/ContentTypeSelect";
import MultipleContentTypeSelect from "@components/Controls/Selects/MultipleContentTypeSelect";
import PageTypeSelect from "@components/Controls/Selects/PageTypeSelect";
import { ProtectedPath } from "@interfaces/ProtectedPath";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { TextField } from "@material-ui/core";
import PageTypesManager, { PAGE_TYPE } from "@modules/PageTypesManager";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { StaffMenuItemSchema } from "@schema/Settings";
import { AutoSizeTextField } from "../../Controls/Inputs";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { staffMenuService } from "@services/Settings";

const ContentTypeInputs = {
    [PAGE_TYPE.CONTENT]: ContentTypeSelect,
    [PAGE_TYPE.MULTIPLE_CONTENT]: MultipleContentTypeSelect,
};

const StaffMenuItemForm = forwardRef(
    ({ onSubmit, data }: CommonFormProps<ProtectedPath>, ref: any) => {
        const initialData = {
            title: data?.title || "",
            url: data?.url || "",
            parent_id: data?.parent_id || null,
            page_type: data?.page_type || "",
            content_type: data?.content_type || "",
            icon: data?.icon || "",
            sort: data?.sort || 0,
            description: data?.description || "",
        } as ProtectedPath;

        // const getRestrictionsForParentPage = (pageType: PAGE_TYPE): PAGE_TYPE[] => {
        //     switch (pageType) {
        //         case PAGE_TYPE.SECTION: {
        //             return [PAGE_TYPE.DETAIL, PAGE_TYPE.SECTION];
        //         }
        //         case PAGE_TYPE.CONTENT:
        //         case PAGE_TYPE.MULTIPLE_CONTENT: {
        //             return [PAGE_TYPE.SECTION, PAGE_TYPE.CONTENT];
        //         }
        //         case PAGE_TYPE.DETAIL: {
        //             return [PAGE_TYPE.CONTENT];
        //         }
        //         default: {
        //             return [];
        //         }
        //     }
        // };

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={StaffMenuItemSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, setFieldValue, values }) => {
                    const ContentTypeInput = ContentTypeInputs[values.page_type];
                    return (
                        <Form>
                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.title}
                                required
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="title"
                                helperText={touched.title ? errors.title : ""}
                                error={touched.title && !!errors.title}
                                label="Название"
                            />

                            <AutoSizeTextField
                                name="description"
                                variant="outlined"
                                size="small"
                                label="Описание"
                                rows={2}
                                margin="normal"
                                helperText={
                                    touched.description ? errors.description : "Необязательно"
                                }
                                error={touched.description && !!errors.description}
                                value={values.description}
                                onChange={handleChange}
                                fullWidth
                            />

                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.url}
                                required
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="url"
                                helperText={
                                    touched.url
                                        ? errors.url
                                        : `Укажите полный путь к странице. Например "/products".
                                                 Если страница параметризованная, укажите наименование параметра. Например "/products/:product_id"`
                                }
                                error={touched.url && !!errors.url}
                                label="URL"
                            />

                            <PageTypeSelect
                                fullWidth
                                onChange={(e) => {
                                    setFieldValue("content_type", "");
                                    handleChange(e);
                                }}
                                value={values.page_type}
                                required
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="page_type"
                                helperText={touched.page_type ? errors.page_type : ""}
                                error={touched.page_type && !!errors.page_type}
                            />

                            {PageTypesManager.getPageType(values.page_type)?.hasContentType && (
                                <ContentTypeInput
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.content_type}
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    name="content_type"
                                    helperText={touched.content_type ? errors.content_type : ""}
                                    error={touched.content_type && !!errors.content_type}
                                />
                            )}

                            <AsyncAutocomplete<ProtectedPath>
                                fetchFn={staffMenuService.getStaffMenuItems}
                                value={values.parent_id}
                                onChange={(name, value) => setFieldValue(name, value?.id || null)}
                                // filter={[
                                //     "page_type",
                                //     getRestrictionsForParentPage(values.page_type)
                                //         .map((value) => value)
                                //         .join(", "),
                                // ]}
                                exludeValues={data?.id ? [data?.id] : []}
                                optionField="title"
                                textFieldProps={{
                                    name: "parent_id",
                                    fullWidth: true,
                                    variant: "outlined",
                                    margin: "normal",
                                    size: "small",
                                    label: "Родительская страница",
                                    helperText: touched.parent_id
                                        ? errors.parent_id
                                        : "Необязательно",
                                    error: touched.parent_id && !!errors.parent_id,
                                }}
                            />

                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.icon}
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="icon"
                                helperText={touched.icon ? errors.icon : "Необязательно"}
                                error={touched.icon && !!errors.icon}
                                label="Иконка"
                            />

                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.sort}
                                required
                                type="number"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="sort"
                                helperText={touched.sort ? errors.sort : ""}
                                error={touched.sort && !!errors.sort}
                                label="Значение сортировки"
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default StaffMenuItemForm;
