import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateContextHelpDto {
    @Expose()
    readonly html: string;

    @Expose()
    readonly staff_menu_item_id?: number;

    @Expose()
    readonly entity_types_id?: number;

    @Expose()
    readonly attribute_types_id?: number;

    @Expose()
    readonly value_types_id?: number;

    @Expose()
    readonly files_id?: number[];
}
