import * as React from "react";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CardActions, CardContent, CardMedia, Typography, Icon } from "@material-ui/core";
import BackgroundImage from "../../../images/auth-bg.png";

interface CardProps {
    url: string;
    title: string;
    description?: string;
    icon?: string;
}

const useStyles = makeStyles({
    root: {
        maxHeight: 300,
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    title: {
        fontSize: 14,
        fontWeight: 500,
    },
    media: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 80,
    },
    icon: {
        fontSize: 40,
    },
    content: {
        flexGrow: 1,
    },
    actions: {
        marginTop: 0,
    },
});

export const SectionCard = ({ title, url, description, icon }: CardProps) => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardMedia className={classes.media} image={BackgroundImage}>
                <Icon className={classes.icon} color="secondary">
                    {icon}
                </Icon>
            </CardMedia>
            <CardContent className={classes.content}>
                <Typography className={classes.title} gutterBottom={!!description}>
                    {title}
                </Typography>
                {description && (
                    <Typography variant="body2" color="textSecondary" component="p">
                        {description}
                    </Typography>
                )}
            </CardContent>
            <CardActions className={classes.actions}>
                <Button size="small" color="primary" onClick={() => history.push(url)}>
                    Перейти
                </Button>
            </CardActions>
        </Card>
    );
};
