import { CommonFormProps } from "@interfaces/System/FormProps";
import { TypeBind } from "@interfaces/TypeBind";
import { AutoSizeTextField } from "./../../Controls/Inputs";
import { Form, Formik } from "formik";
import * as React from "react";
import { TypeBindSchema } from "./../../../schema/Settings/index";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { EntityType } from "@interfaces/EntityType";
import { eTypesService } from "@services";

const TypeBindForm = React.forwardRef(({ onSubmit, data }: CommonFormProps<TypeBind>, ref: any) => {
    const initialData = {
        type_id: data?.type_id || null,
        type_name: data?.type_name || "",
    } as TypeBind;

    return (
        <Formik
            innerRef={ref}
            initialValues={initialData}
            validationSchema={TypeBindSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, setFieldValue, handleChange, values }) => {
                return (
                    <Form>
                        <AsyncAutocomplete<EntityType>
                            fetchFn={eTypesService.getEntityTypes}
                            value={values.type_id}
                            onChange={(name, value) => setFieldValue(name, value?.id || null)}
                            optionField="name"
                            textFieldProps={{
                                fullWidth: true,
                                required: true,
                                size: "small",
                                margin: "normal",
                                variant: "outlined",
                                name: "type_id",
                                label: "Тип сущности",
                                error: touched.type_id && !!errors.type_id,
                                helperText: touched.type_id ? errors.type_id : "",
                            }}
                        />

                        <AutoSizeTextField
                            fullWidth
                            onChange={handleChange}
                            value={values.type_name}
                            required
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="type_name"
                            label="Имя типа"
                            helperText={touched.type_name ? errors.type_name : ""}
                            error={touched.type_name && !!errors.type_name}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default TypeBindForm;
