import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { cartService } from "@services";
import { TableEvents, TableState } from "@store/table-module";
import { CreateCartItemDto, UpdateCartItemDto } from "@dto/CartItemDto";
import { useAsync } from "@hooks";
import { CartItem } from "@interfaces/CartItem";
import { ModalContentLoading } from "@components/Common/Modal";
import { CartItemForm } from "@components/Forms/Cart";

const CartItemDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<any>();

    const { enqueueSnackbar } = useSnackbar();
    const { dispatch } = useStoreon<TableState, TableEvents>();

    const { data: cartItem, loadingStatus } = useAsync<CartItem>(cartService.getOneCartItem, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreateCartItem = async (values) => {
        try {
            const dto = cartService.plainToClass(CreateCartItemDto, values);
            await cartService.createCartItem(dto);
            enqueueSnackbar("Элемент корзины успешно создан", {
                variant: "success",
            });
            onClose();
            dispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании элемента корзины", {
                variant: "error",
            });
        }
    };

    const handleUpdateCartItem = async (values) => {
        if (!itemId) return;

        try {
            const dto = cartService.plainToClass(UpdateCartItemDto, values);
            await cartService.updateCartItem(itemId, dto);

            enqueueSnackbar("Элемент корзины успешно обновлен", {
                variant: "success",
            });
            onClose();
            dispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении элемента корзины", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {loadingStatus === "inactivity" && (
                    <CartItemForm ref={formRef} onSubmit={handleCreateCartItem} />
                )}

                {loadingStatus === "loaded" && (
                    <CartItemForm ref={formRef} data={cartItem} onSubmit={handleUpdateCartItem} />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button color="primary" variant="contained" onClick={invokeSubmit}>
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default CartItemDialogContext;
