import { useCurrentPageInfo, useModal } from "@hooks";
import { Price } from "@interfaces/Price";
import React from "react";
import { storeService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { ServiceHelper } from "@modules";
import { useSnackbar } from "notistack";

const PricesTable = () => {
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_PRICE", { itemId: rowData.id, titleProps: rowData.id});
    };

    const handleDeleteClick = async (rowData: Price | Price[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await storeService.deletePrices(ids);
            enqueueSnackbar("Цены успешно удалены", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении цен", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<Price>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            align: "left",
            type: "numeric",
            width: 100,
        },
        {
            title: "SKU",
            field: "sku",
            width: "auto",
        },
        {
            title: "RRC цена",
            field: "rrc_price",
            width: "auto",
        },
        {
            title: "Цена",
            field: "sale_price",
            width: "auto",
        },
        {
            title: "Скидка",
            field: "discount_percent",
            width: "auto",
        },
        {
            title: "Цена со скидкой",
            field: "price_with_discount",
            width: "auto",
        },
        {
            title: "Дата обновления",
            filtering: false,
            field: "update_date",
            type: "datetime",
            hidden: true,
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={storeService.getStorePrices}
            columns={columns}
            displayField="sku"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            filtering
            sorting
            search
        />
    );
};

export default PricesTable;
