import { Container } from "@material-ui/core";
import React from "react";
import Breadcrumbs from "../components/Views/Breadcrumbs/Breadcrumbs";
import useTitle from "../hooks/useTitle";
import { EnhancedTabs } from "@components/Common/Content";
import { WithMultipleContentTypeProps } from "@modules/ContentTypesManagers";
import { WithPageProps } from "@modules/PageTypesManager";

export const MultipleContentPage = ({
    contentType,
    title,
    url,
    icon,
    description,
}: WithMultipleContentTypeProps<{}> & WithPageProps<{}>) => {
    useTitle(title);
    return (
        <Container maxWidth={false}>
            <Breadcrumbs />
            <EnhancedTabs contentType={contentType} />
        </Container>
    );
};

export default MultipleContentPage;
