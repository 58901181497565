import React, { useCallback, useState } from "react";
import { TreeItem } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Check, Folder } from "@material-ui/icons";
import { Directory } from "@interfaces/Directory";
import { useAsync } from "@hooks";
import { fileService } from "@services";
import { useStoreon } from "storeon/react";
import { ContentEvents, ContentState } from "@store/content-module";

const useStyles = makeStyles((theme) => ({
    labelRoot: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 0),
    },
    label: {
        fontWeight: "inherit",
        color: "inherit",
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: "inherit",
        flexGrow: 1,
    },
}));

type DirectoriesTreeItemProps = { node: Directory; onContextMenu(e): void };

const DirectoriesTreeItem = ({ node, onContextMenu }: DirectoriesTreeItemProps) => {
    const classes = useStyles();
    const { dispatch } = useStoreon<ContentState, ContentEvents>();

    const [isClicked, setClicked] = useState(false);

    const { data } = useAsync(fileService.getDirectories, {
        canLoad: isClicked && !node.childs?.length,
        setLoadingStatusOnUpdate: false,
        functionArgs: [{ parent_dir_id: node.id }],
    });

    const handleClick = useCallback(() => {
        setClicked(true);
        dispatch("content/file_storage/set/selected_directory", node);
    }, [node, dispatch]);

    const childs = !!node.childs?.length ? node.childs : data?.result.items;

    return (
        <TreeItem
            onContextMenu={onContextMenu}
            style={{ cursor: "context-menu" }}
            data-itemid={node.id}
            data-itemname={node.title} // Vma 07-05-24 Добавляем атрибут data-itemname с именем директории
            onLabelClick={handleClick}
            onIconClick={handleClick}
            endIcon={isClicked ? <Check /> : null}
            label={
                <div className={classes.labelRoot}>
                    <Folder color="action" className={classes.labelIcon} />

                    <Typography variant="body2" className={classes.labelText}>
                        {node.title}
                    </Typography>
                </div>
            }
            nodeId={node.id.toString()}
        >
            {childs
                ?.sort((a, b) => a.title.localeCompare(b.title))
                .map((child) => (
                    <DirectoriesTreeItem
                        key={child.id}
                        node={child}
                        onContextMenu={onContextMenu}
                    />
                ))}
        </TreeItem>
    );
};

export default DirectoriesTreeItem;
