import { IconButton, ListItem, Tooltip } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { memo } from "react";
import ServiceRoleSelect from "../../Controls/Selects/ServiceRoleSelect";

interface Props {
    onChange(key: string, value: string): void;
    id: string;
    value: string;
    onDelete(key: string): void;
}

const UserRoleItem = ({ id, value, onDelete, onChange }: Props) => {
    return (
        <ListItem disableGutters>
            <ServiceRoleSelect
                name="sub_role"
                variant="outlined"
                size="small"
                label="Роль"
                value={value}
                onChange={(e) => onChange(id, e.target.value)}
                fullWidth
            />
            <Tooltip title="Удалить" arrow>
                <IconButton style={{ marginLeft: 10 }} size="small" onClick={() => onDelete(id)}>
                    <Delete />
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};

export default memo(UserRoleItem);
