import { PAGE_TYPE } from "@modules/PageTypesManager";
import * as Yup from "yup";

export const StaffMenuItemSchema = Yup.object().shape({
    title: Yup.string().required("Пожалуйста, укажите название страницы"),

    url: Yup.string().required("Пожалуйста, укажите URL страницы"),
    page_type: Yup.string().required("Пожалуйста, укажите тип страницы"),
    content_type: Yup.string().when("page_type", {
        is: PAGE_TYPE.CONTENT || PAGE_TYPE.MULTIPLE_CONTENT,
        then: Yup.string().required("Пожалуйста, укажите тип контента"),
    }),
    sort: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, укажите значение сортировки"),
    description: Yup.string(),
});
