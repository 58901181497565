import { Exclude, Expose } from "class-transformer";

@Exclude()
class CreateBrandDealerBindDto {
    @Expose()
    readonly dealer_id: number;

    @Expose()
    readonly brand_id: number;

    @Expose()
    readonly price: number;
}

export default CreateBrandDealerBindDto;
