import { RestrictionsManager } from "@modules";
import { AppAttributeTypeItem } from "@interfaces/AttributeTypeItem";
import { Transform, Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateAttributeTypeDto {
    @Expose()
    readonly name: string;

    @Expose()
    readonly notes?: string;

    @Expose()
    readonly display_name?: string;

    @Expose()
    readonly multiple_choice: boolean;

    @Expose()
    readonly composite_choice: boolean;

    @Expose()
    @Transform(({ value }) =>
        value.map((el) => ({
            id: el.id,
            sorting: el.sorting,
            type_id: el.type_id,
            vtype_id: el.vtype_id,
            restrictions: RestrictionsManager.getRestrictionsObjectFromArray(el.restrictions),
        }))
    )
    readonly items?: AppAttributeTypeItem[];

    @Expose()
    readonly is_active: boolean;
}
