import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreatePermissionSettingsDto {
    @Expose()
    readonly table_name: string;

    @Expose({ name: "username" })
    readonly user: string;

    @Expose({ name: "e_id" })
    readonly id?: number;

    @Expose()
    readonly permission: number;
}
