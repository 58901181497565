import { useSnackbar } from "notistack";
import React from "react";
import { ServiceHelper } from "@modules";
import { rowBlockService } from "@services/Settings";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { RowBlock } from "@interfaces/RowBlock";
import { useCurrentPageInfo, useModal } from "@hooks";

const RowBlocksTable = () => {
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_ROW_BLOCK", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: RowBlock | RowBlock[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await rowBlockService.deleteRowBlocks(ids);
            enqueueSnackbar("Блокировка успешно удалена", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении блокировки", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<RowBlock>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Таблица",
            field: "table_name",
            width: "auto",
        },
        {
            title: "Пользователь",
            field: "username",
            width: "auto",
        },
        {
            title: "ID сущности",
            field: "e_id",
            width: "auto",
        },
        {
            title: "Дата создания",
            field: "created_at",
            type: "date",
            width: "auto",
        },
        {
            title: "Дата обновления",
            field: "updated_at",
            type: "date",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={rowBlockService.getRowBlocks}
            columns={columns}
            displayField="table_name"
            permission={permission}
            sorting
            selection
            search
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
        />
    );
};

export default RowBlocksTable;
