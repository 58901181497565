import { QueryParams, PaginatedQueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { CreateRowBlockDto, UpdateRowBlockDto } from "@dto/SettingsDto/RowBlockDto";
import { RowBlock } from "@interfaces/RowBlock";

class RowBlockService extends BaseService {
    public getRowBlocks = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<RowBlock>> => {
        const { data } = await this.instance.get("/settings/row_block", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneRowBlock = async (id: number): Promise<RowBlock> => {
        const { data } = await this.instance.get("/settings/row_block", {
            params: { id },
        });

        return data.result.items[0];
    };

    public createRowBlock = async (createRowBlockDto: CreateRowBlockDto): Promise<any> => {
        const { data } = await this.instance.post("/settings/row_block", {
            data: createRowBlockDto,
        });

        return data;
    };

    public updateRowBlock = async (
        id: number,
        updateRowBlockDto: UpdateRowBlockDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/settings/row_block", {
            id,
            data: updateRowBlockDto,
        });

        return data;
    };

    public deleteRowBlocks = async (ids: number[]): Promise<any> => {
        const { data } = await this.instance.delete("/settings/row_block", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new RowBlockService(new HttpClient(Config.API_URL), AuthManager);
