import React from "react";
import { Container } from "@material-ui/core";
import Profile from "../components/Views/Profile/Profile";
import useTitle from "../hooks/useTitle";
import Breadcrumbs from "../components/Views/Breadcrumbs/Breadcrumbs";
import PageHead from "../components/Views/PageHead/PageHead";
import { PathsManager } from "../modules";

const CabinetPage = ({ title = PathsManager.PATHS.CABINET.title }) => {
    useTitle(title);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs />
            <PageHead noTitle />
            <Profile />
        </Container>
    );
};

export default CabinetPage;
