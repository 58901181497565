import { TextField } from "@material-ui/core";
import React from "react";
import { UniversalInputProps } from "../../../interfaces/System/UniversalInputProps";

const UNumberField = ({ ...props }: UniversalInputProps) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        props.onChange(name, value !== "" ? +value : null);
    };

    return (
        <TextField
            {...props}
            onChange={handleChange}
            type="number"
            label={props.label || "Введите значение"}
        />
    );
};

export default UNumberField;
