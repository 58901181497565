import { ContentTypeNotFoundError } from "@modules/ContentTypesManagers";
import CombinedContentTypesManager, {
    CombinedContentType,
    CombinedType,
} from "@modules/ContentTypesManagers/CombinedContentTypesManager";
import { useEffect, useState } from "react";

const useCombinedContentType = (contentType?: CombinedType) => {
    const [type, setType] = useState<CombinedContentType | null>(null);

    useEffect(() => {
        if (!contentType) return;

        const type = CombinedContentTypesManager.getContentType(contentType);

        if (!type) throw new ContentTypeNotFoundError(contentType);

        setType(type);
    }, [contentType]);

    return type;
};

export default useCombinedContentType;
