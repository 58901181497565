import React from "react";
import { ModalType } from "@modules/ModalManager";
import AddNewButton from "../Buttons/AddNewButton";
import { ModalProps } from "@interfaces/System/ModalProps";
import { ButtonProps, SvgIconProps } from "@material-ui/core";
import { useModal } from "@hooks";

interface Props {
    buttonText: string;
    modalType: ModalType;
    modalProps?: ModalProps;
    buttonProps?: ButtonProps;
    iconProps?: SvgIconProps;
}

const CallModalNewActionButton = ({
    buttonText,
    modalType,
    modalProps,
    buttonProps,
    iconProps,
}: Props) => {
    const { handleOpenModal } = useModal();

    const handleCallModal = () => {
        handleOpenModal(modalType, modalProps);
    };

    return (
        <AddNewButton
            text={buttonText}
            onClick={handleCallModal}
            iconProps={iconProps}
            {...buttonProps}
        />
    );
};

export default CallModalNewActionButton;
