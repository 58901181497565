import { EntityAttributeSchema } from "@interfaces/EntityAttributeSchema";
import { EntityAttributesManager, RestrictionsManager, Utils } from "@modules";
import { StoreonModule } from "storeon";
import { Entity } from "@interfaces/Entity";
import { AppAttribute, AppAttributeValue } from "@interfaces/Internal/AppAttribute";
import { AttributeTypeVariant } from "@modules/EntityAttributesManager";
import shortid from "shortid";
import { REQUIRED_TYPES } from "@modules/ValidationManager";
import { arrayToSubArrays } from "@modules/Utils";

export interface EntityState {
    entity: Entity | null;
    schema: EntityAttributeSchema[] | null;
    entityId: number | null;
    entityTypeId: number | null;
    attrs: AppAttribute[] | null;
    readOnly: boolean;
    valuesToRemove: number[];
}

export interface EntityEvents {
    "entity/set/schema": EntityAttributeSchema[];
    "entity/set/attrs": { schemas: EntityAttributeSchema[]; entity?: Entity };
    "entity/reset/attrs";
    "entity/attr/add/value": {
        at_id: number;
    };
    /** remove value by key */
    "entity/attr/remove/value": {
        aTypeId: number;
        valueKeys: string[];
    };
    "entity/attr/update/value": {
        at_id: number;
        valueKey: string;
        value: any;
    };
    "entity/attr/update/sorting": {
        at_id: number;
        valueKeys: string[];
        sorting: number;
    };
    "entity/set/readonly": boolean;
    "entity/set/valuesToRemove": number[];
}

const defaultAttributeValue: Record<string, boolean | string | number> = {
    boolean: false,
};

const transformAttributeValue: (ptype: string, val: string) => string | boolean | number = (
    ptype,
    val
) => {
    if (ptype === "boolean") {
        return val === "true";
    }
    return val;
};

export const entityModule: StoreonModule<EntityState, EntityEvents> = (store) => {
    store.on("@init", () => ({
        schema: null,
        attrs: null,
        readOnly: false,
        valuesToRemove: [],
    }));

    store.on("entity/set/schema", (state, value) => ({
        schema: value,
    }));

    store.on("entity/set/attrs", (state, { schemas, entity }) => {
        const appAttributes = schemas.map((schema) => {
            const entityAttr = entity?.attrs.find((a) => a.atype_id === schema.at_id);

            const variant = EntityAttributesManager.getEntityAttributeVariant(schema);

            const values = Utils.arrayToSubArrays(entityAttr?.values || [], schema.items.length);
            const appAttribute: AppAttribute = {
                id: entityAttr?.attr_id,
                atype_id: schema.at_id,
                a_name: schema.a_name,
                required: schema.required,
                variant: variant,
                a_items_count: schema.items?.length ?? 0,
                items:
                    values
                        ?.map((item) => {
                            return item.map((value, i) => {
                                const schemaItem = schema.items?.[i];
                                const atRestrictions =
                                    schemaItem?.restrictions instanceof Array
                                        ? schemaItem.restrictions[0]
                                        : {};
                                const vtRestrictions =
                                    schemaItem?.vt_restrictions instanceof Array
                                        ? schemaItem.vt_restrictions[0]
                                        : {};

                                return {
                                    key: shortid.generate(),
                                    value_id: value.value_id,
                                    value: transformAttributeValue(schemaItem.ptype, value.value),
                                    vt_id: value.type_id,
                                    restrictions: RestrictionsManager.getAppRestrictions(
                                        atRestrictions,
                                        {
                                            vtRestrictions,
                                            required: schema.required === REQUIRED_TYPES.FULL.value,
                                        }
                                    ),
                                    edited: false,
                                    sorting: value.sorting,
                                };
                            });
                        })
                        .flat(1) || [],
            };

            if (appAttribute.items.length < appAttribute.a_items_count) {
                for (let i = 0; i < appAttribute.a_items_count; i++) {
                    const schemaItem = schema.items[i];
                    const atRestrictions =
                        schemaItem?.restrictions instanceof Array ? schemaItem.restrictions[0] : {};
                    const vtRestrictions =
                        schemaItem?.vt_restrictions instanceof Array
                            ? schemaItem.vt_restrictions[0]
                            : {};

                    if (!appAttribute.items[i]) {
                        appAttribute.items[i] = {
                            key: shortid.generate(),
                            value: null,
                            vt_id: schemaItem.vtype_id,
                            restrictions: RestrictionsManager.getAppRestrictions(atRestrictions, {
                                vtRestrictions,
                                required: schema.required === REQUIRED_TYPES.FULL.value,
                            }),
                            edited: false,
                            sorting: i + 1,
                        };
                    }
                }
            }

            return appAttribute;
        });

        return {
            attrs: appAttributes,
        };
    });

    store.on("entity/reset/attrs", () => ({ attrs: [] as AppAttribute[] }));

    store.on("entity/attr/add/value", (state, { at_id }) => {
        const schema = state.schema?.find((el) => el.at_id === at_id);
        if (!schema) throw new Error("Entity attribute schema not provided!");

        const copyArr = [...(state.attrs || [])];
        const index = copyArr.findIndex((el) => el.atype_id === at_id);

        const elem = copyArr[index];

        switch (elem.variant) {
            case AttributeTypeVariant.multiple: {
                const atRestrictions =
                    schema.items[0].restrictions instanceof Array
                        ? schema.items[0].restrictions[0]
                        : {};
                const vtRestrictions =
                    schema.items[0].vt_restrictions instanceof Array
                        ? schema.items[0].vt_restrictions[0]
                        : {};

                const newValue = {
                    key: shortid.generate(),
                    value: defaultAttributeValue[schema.items[0].ptype] ?? null,
                    vt_id: schema?.items[0].vtype_id,
                    restrictions: RestrictionsManager.getAppRestrictions(atRestrictions, {
                        required: elem.required === REQUIRED_TYPES.FULL.value,
                        vtRestrictions,
                    }),
                    edited: true,
                    sorting: elem.items.length + 1,
                };

                copyArr[index].items = [...(elem.items || []), newValue];

                break;
            }
            case AttributeTypeVariant.composite_multiple: {
                const newValues = schema.items.map((schema) => {
                    const atRestrictions =
                        schema.restrictions instanceof Array ? schema.restrictions[0] : {};
                    const vtRestrictions =
                        schema.vt_restrictions instanceof Array ? schema.vt_restrictions[0] : {};

                    return {
                        key: shortid.generate(),
                        value: defaultAttributeValue[schema.ptype] ?? null,
                        vt_id: schema.vtype_id,
                        restrictions: RestrictionsManager.getAppRestrictions(atRestrictions, {
                            required: elem.required === REQUIRED_TYPES.FULL.value,
                            vtRestrictions,
                        }),
                        edited: true,
                        sorting: arrayToSubArrays(elem.items, elem.a_items_count).length + 1,
                    };
                });

                copyArr[index].items = [...(elem.items || []), ...newValues];
                break;
            }
        }

        return {
            attrs: copyArr,
        };
    });

    store.on("entity/attr/update/value", (state, { at_id, valueKey, value }) => {
        const copyArr = [...(state.attrs || [])];
        const attrIndex = copyArr.findIndex((el) => el.atype_id === at_id);

        const elemIndex = copyArr[attrIndex].items.findIndex((el) => el.key === valueKey);

        const item = copyArr[attrIndex].items[elemIndex];
        item.value = value;
        item.edited = true;

        copyArr[attrIndex].items[elemIndex] = item;

        return {
            attrs: copyArr,
        };
    });

    store.on("entity/attr/update/sorting", (state, { at_id, valueKeys, sorting }) => {
        const copyArr: AppAttribute[] = [...(state.attrs || [])];
        const attrIndex: number = copyArr.findIndex((el) => el.atype_id === at_id);

        valueKeys.forEach((key) => {
            const elemIndex: number = copyArr[attrIndex].items.findIndex((el) => el.key === key);

            const item: AppAttributeValue = copyArr[attrIndex].items[elemIndex];
            item.sorting = sorting;
            item.edited = true;

            copyArr[attrIndex].items[elemIndex] = item;
        });

        return {
            attrs: copyArr,
        };
    });

    store.on("entity/attr/remove/value", (state, { aTypeId, valueKeys }) => {
        const copyArr = [...(state.attrs || [])];
        const attrIndex = copyArr.findIndex((el) => el.atype_id === aTypeId);

        const toRemove = [...state.valuesToRemove];

        valueKeys.forEach((key) => {
            const elemIndex = copyArr[attrIndex].items.findIndex((el) => el.key === key);

            const item = copyArr[attrIndex];

            const valueId = item.items[elemIndex]?.value_id;

            if (typeof valueId === "number") {
                toRemove.push(valueId);
            }

            copyArr[attrIndex].items.splice(elemIndex, 1);
        });

        return {
            valuesToRemove: toRemove,
            attrs: copyArr,
        };
    });

    store.on("entity/set/readonly", (state, value) => ({
        readOnly: value,
    }));

    store.on("entity/set/valuesToRemove", (state, value) => ({
        valuesToRemove: value,
    }));
};
