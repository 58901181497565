import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateFileInfoDto {
    @Expose()
    readonly name: string;

    @Expose()
    readonly description: string;
}
