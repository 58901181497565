import CallModalNewActionButton from "@components/Controls/Actions/CallModalNewActionButton";
import ImportDataButton from "@components/Controls/Buttons/ImportDataButton";
import {
    StaffMembersTable,
    StoresTable,
    PricesTable,
    EntityTypesTable,
    QuantitiesTable,
    ValueTypesTable,
    AttributeTypesTable,
    RowBlocksTable,
    StaffMenuItemsTable,
    ServiceRolesTable,
    TypeBindTable,
    GeneralSettingsTable,
    StaffMenuPermissionTable,
    BrandManagersTable,
    PermissionSettingsTable,
    CartTable,
    CartItemsTable,
    UsersTable,
    EShopsTable,
    ValueTypeBindsTable,
    LogsTable,
    OrdersTable,
    OrderItemsTable,
    StaffOrdersTable,
    StaffOrderItemsTable,
    BrandDealerBindsTable,
    AttributeTypeBindsTable,
    EntitiesTable,
    ObserverLogsTable,
    CsvLoadStatusesTable,
    PriceLoadStatusesTable,
    QuantitiesLoadStatusesTable,
    DiscountsTable,
    StoresLoadStatusesTable,
    QuantityReservesTable,
} from "@components/Tables";
import SectionHelpCard from "@components/Views/Cards/SectionHelpCard";
import React, { ReactNode } from "react";
import { IMPORT_DATA_TYPE } from "@hooks/useImportDataControls";
import { Alert, AlertTitle } from "@material-ui/lab";
import { PageHeadProps } from "@components/Views/PageHead/PageHead";
import { PageInfo } from "@hooks/useCurrentPageInfo";
import { Box } from "@material-ui/core";
import { ContextHelp } from "@interfaces/ContextHelp";
import { WithContentTypeProps } from ".";
import TableFilters from "@components/Views/TableFilters/TableFilters";
import { eTypesService } from "@services";
import EntitiesToolbar from "@components/Views/EntitiesToolbar/EntitiesToolbar";
import StaffCartButton from "@components/Controls/Actions/StaffCartButton";
import FileStorage from "@components/Content/FileStorage";
import ExportDataButton from "@components/Controls/Buttons/ExportDataButton";
import DiscountsLoadStatusesTable from "@components/Tables/DiscountsLoadStatusesTable";
import ReserveStoreBindingsTable from "@components/Tables/ReserveStoreBindingsTable";

export interface BaseContentType<T> {
    type: T;
    name: string;
    note?: string;
}

export type Content<T> = {
    name?: string;
    type?: T;
    getTitle?(params: any): string | Promise<string>;
    getMainContent(pageInfo?: PageInfo): ReactNode;
    getSideContent({ contentType }: WithContentTypeProps<{}>): ReactNode;
    getPageHeadChildren(pageInfo?: PageInfo): ReactNode;
    getPageHeadProps?(pageInfo?: PageInfo): PageHeadProps;
    getContextHelpOptions?(): ContextHelpOptions;
};

export type UniqueField = Extract<
    keyof ContextHelp,
    "staff_menu_item_id" | "entity_types_id" | "attribute_types_id" | "value_types_id"
>;

export type ContextHelpOptions = {
    uniqueField: UniqueField;
};

export interface ContentType extends BaseContentType<CONTENT_TYPE> {
    content: Content<CONTENT_TYPE>;
}

export enum CONTENT_TYPE {
    CATALOG = "catalog",
    CATALOGS = "catalogs",
    ENTITY = "entity",
    ENTITIES = "entities",
    STAFF_MEMBERS = "staff_members",
    BRAND_BIND = "brand_bind",
    PRICES = "prices",
    DISCOUNTS = "discounts",
    STORES = "stores",
    QUANTITIES = "quantities",
    VALUE_TYPES = "value_types",
    ATTRIBUTE_TYPES = "attribute_types",
    ENTITY_TYPES = "entity_types",
    GENERAL_SETTINGS = "general_settings",
    NS_TYPE_BINDING = "ns_type_binding",
    SERVICE_ROLES = "service_roles",
    STAFF_MENU = "staff_menu",
    STAFF_MENU_PERMISSIONS = "staff_menu_permissions",
    PERMISSION_SETTINGS = "permission_settings",
    ROW_BLOCK = "row_block",
    CART = "cart",
    CART_ITEMS = "cart_items",
    FILE_STORAGE = "file_storage",
    USERS = "users",
    E_SHOPS = "e_shops",
    NS_VTYPE_BIND = "ns_vtype_bind",
    LOGGING = "logging",
    ORDER = "order",
    ORDER_ITEMS = "order_items",
    STAFF_ORDERS = "staff_orders",
    STAFF_ORDER_ITEMS = "staff_order_items",
    BRAND_DEALER_BIND = "brand_dealer_bind",
    NS_ATYPE_BIND = "ns_atype_bind",
    OBSERVER_TABLE = "observer_table",
    CSV_LOAD_STATUSES = "csv_load_statuses",
    PRICE_LOAD_STATUSES = "price_load_statuses",
    QUANTITY_LOAD_STATUSES = "quantity_load_statuses",
    DISCOUNT_LOAD_STATUSES = "discount_load_statuses",
    STORE_LOAD_STATUSES = "store_load_statuses",
    QUANTITY_RESERVES = "quantity_reserves",
    RESERVE_STORE_BINDINGS = "reserve_store_bindings",
}

const onlyAdminNote = "Этот тип контента предназначен только для админа";

/** content types for page with type `content` */
class ContentTypesManager {
    static getContentType = (type: string) => {
        return ContentTypesManager.CONTENT_TYPES.find((content) => content.type === type);
    };

    static readonly CONTENT_TYPES: ContentType[] = [
        {
            type: CONTENT_TYPE.CATALOGS,
            name: "Справочники",
            note: "Список всех справочников",
            content: {
                getMainContent: () => (
                    <EntityTypesTable noDelete noEdit noCtxHelp title="Справочники" />
                ),
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
            },
        },
        {
            type: CONTENT_TYPE.CATALOG,
            name: "Справочник",
            note: "Используется для отображения деталки справочника. Используйте /:catalog_id",
            content: {
                getTitle: async (params) => {
                    const etype = await eTypesService.getEntityType(params);
                    return etype?.name || "";
                },
                getMainContent: (pageInfo) => (
                    <EntitiesTable typeName={pageInfo?.query.get("type")} />
                ),
                getSideContent: () => (
                    <>
                        <SectionHelpCard />
                        <TableFilters />
                    </>
                ),
                getPageHeadChildren: () => <EntitiesToolbar />,
                getPageHeadProps: () => ({
                    noTitle: false,
                }),
                getContextHelpOptions: () => ({
                    uniqueField: "entity_types_id",
                }),
            },
        },
        {
            type: CONTENT_TYPE.ENTITY,
            name: "Каталог сущностей",
            content: {
                getTitle: async (params) => {
                    if (!params) return "";
                    const etype = await eTypesService.getEntityType(params);
                    return etype?.name || "";
                },
                getMainContent: (pageInfo) => {
                    if (!pageInfo) return null;

                    const filterAttrParams = pageInfo.query.getAll("attrParam") || [];
                    const paramsArr = Object.values(pageInfo?.params || {});

                    const filter = filterAttrParams.reduce((res, item, index) => {
                        res[item] = paramsArr[index];
                        return res;
                    }, {});

                    return <EntitiesTable noDelete noEdit disableSelection filter={filter} />;
                },
                getSideContent: () => (
                    <>
                        <SectionHelpCard />
                        <TableFilters />
                    </>
                ),
                getPageHeadChildren: (pageInfo) => {
                    if (!pageInfo) return null;

                    const filterAttrParams = pageInfo.query.getAll("attrParam") || [];
                    const paramsArr = Object.values(pageInfo?.params || {});

                    const filter = filterAttrParams.reduce((res, item, index) => {
                        res[item] = paramsArr[index];
                        return res;
                    }, {});

                    return <StaffCartButton entityFilter={filter} eTypeId={pageInfo.param} />;
                },
                getPageHeadProps: () => ({
                    noTitle: false,
                }),
            },
        },
        {
            type: CONTENT_TYPE.ENTITIES,
            name: "Сущности",
            note: "Для фильтрации сущностей по привязке к типу используйте ?type=<type_name>",
            content: {
                getMainContent: (pageInfo) => {
                    const filterAttr = pageInfo?.query.get("attrParam");
                    const filter = filterAttr ? { [filterAttr]: pageInfo?.param } : {};

                    return (
                        <EntitiesTable
                            noDelete
                            noEdit
                            rowClick="child"
                            filter={filter}
                            typeName={pageInfo?.query.get("type")}
                        />
                    );
                },
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
            },
        },
        {
            type: CONTENT_TYPE.STAFF_MEMBERS,
            name: "Управление сотрудниками",
            content: {
                getMainContent: () => <StaffMembersTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить нового сотрудника"
                        modalType="ADD_NEW_STAFF_MEMBER"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.BRAND_BIND,
            name: "Привязка брендов",
            content: {
                getMainContent: () => <BrandManagersTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
            },
        },
        {
            type: CONTENT_TYPE.CART,
            name: "Управление корзиной",
            content: {
                getMainContent: () => <CartTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Создать новую корзину"
                        modalType="ADD_CART"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.CART_ITEMS,
            name: "Управление элементами корзины",
            content: {
                getMainContent: () => <CartItemsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Создать новый элемент корзины"
                        modalType="ADD_CART_ITEM"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.PRICES,
            name: "Управление ценами",
            content: {
                getMainContent: () => <PricesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <>
                        <ImportDataButton importType={IMPORT_DATA_TYPE.PRICES} />
                        <ExportDataButton exportDataType="price" style={{ marginLeft: 10 }} />
                        <CallModalNewActionButton
                            buttonText="Добавить новую цену"
                            modalType="ADD_PRICE"
                            buttonProps={{ style: { marginLeft: 10 } }}
                        />
                    </>
                ),
            },
        },
        {
            type: CONTENT_TYPE.STORES,
            name: "Управление складами",
            content: {
                getMainContent: () => <StoresTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <>
                        <ImportDataButton importType={IMPORT_DATA_TYPE.STORES} />
                        <ExportDataButton exportDataType="store" style={{ marginLeft: 10 }} />
                        <CallModalNewActionButton
                            buttonText="Добавить новый склад"
                            modalType="ADD_NEW_STORE"
                            buttonProps={{ style: { marginLeft: 10 } }}
                        />
                    </>
                ),
            },
        },
        {
            type: CONTENT_TYPE.QUANTITIES,
            name: "Управление остатками",
            content: {
                getMainContent: () => <QuantitiesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <>
                        <ImportDataButton importType={IMPORT_DATA_TYPE.QUANTITY} />
                        <ExportDataButton exportDataType="quantity" style={{ marginLeft: 10 }} />
                        <CallModalNewActionButton
                            buttonText="Добавить новый остаток"
                            modalType="ADD_QUANTITY"
                            buttonProps={{ style: { marginLeft: 10 } }}
                        />
                    </>
                ),
            },
        },
        {
            type: CONTENT_TYPE.VALUE_TYPES,
            name: "Управление типами значений",
            content: {
                getMainContent: () => <ValueTypesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новый тип значения"
                        modalType="ADD_NEW_VALUE_TYPE"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.ATTRIBUTE_TYPES,
            name: "Управление типами атрибутов",
            content: {
                getMainContent: () => <AttributeTypesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новый тип атрибута"
                        modalType="ADD_NEW_ATTRIBUTE_TYPE"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.ENTITY_TYPES,
            name: "Управление типами сущностей",
            content: {
                getMainContent: () => <EntityTypesTable noRowClick />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новый тип сущности"
                        modalType="ADD_NEW_ENTITY_TYPE"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.GENERAL_SETTINGS,
            name: "Общие настройки",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <GeneralSettingsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новую настройку"
                        modalType="ADD_NEW_GENERAL_SETTINGS"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.NS_TYPE_BINDING,
            name: "Управление привязками типов",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <TypeBindTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новую связь"
                        modalType="ADD_NEW_TYPE_BIND"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.SERVICE_ROLES,
            name: "Управление ролями",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <ServiceRolesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новую роль"
                        modalType="ADD_NEW_SERVICE_ROLE"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.STAFF_MENU,
            name: "Управление пунктами меню",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <StaffMenuItemsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новый пункт меню"
                        modalType="ADD_NEW_STAFF_MENU_ITEM"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.STAFF_MENU_PERMISSIONS,
            name: "Управление правами доступа к меню",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <StaffMenuPermissionTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новое право доступа"
                        modalType="ADD_NEW_STAFF_MENU_PERMISSION"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.PERMISSION_SETTINGS,
            name: "Управление правами пользователей",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <PermissionSettingsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новое право"
                        modalType="ADD_NEW_PERMISSION_SETTINGS"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.ROW_BLOCK,
            name: "Блокировка редактирования на уровне строк",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <RowBlocksTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
                // <CallModalNewActionButton
                //     buttonText="Добавить новую блокировку"
                //     modalType={MODAL_TYPE.ADD_NEW_ROW_BLOCK}
                // />
            },
        },
        {
            type: CONTENT_TYPE.FILE_STORAGE,
            name: "Файловое хранилище",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <FileStorage />,
                getSideContent: ({ contentType }) => (
                    <>
                        <Alert severity="info">
                            <AlertTitle>Подсказка</AlertTitle>
                            Используйте правую кнопку мыши для вызова контекстного меню
                        </Alert>

                        <Box my={1}>
                            <SectionHelpCard contentType={contentType} />
                        </Box>
                    </>
                ),
                getPageHeadChildren: () => null,
                // getPageHeadProps: (pageInfo) => ({
                //     title: pageInfo?.title,
                //     noTitle: false,
                // }),
            },
        },
        {
            type: CONTENT_TYPE.USERS,
            name: "Управление пользователями",
            content: {
                getMainContent: () => <UsersTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить нового пользователя"
                        modalType="ADD_USER"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.E_SHOPS,
            name: "Управление электронными магазинами",
            content: {
                getMainContent: () => <EShopsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новый магазин"
                        modalType="ADD_ESHOP"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.NS_VTYPE_BIND,
            name: "Привязка полей ввода",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <ValueTypeBindsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить новую привязку"
                        modalType="ADD_VTYPE_BIND"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.LOGGING,
            name: "Логирование",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <LogsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
            },
        },
        {
            type: CONTENT_TYPE.OBSERVER_TABLE,
            name: "Наблюдение таблиц",
            note: onlyAdminNote,
            content: {
                getMainContent: () => <ObserverLogsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Добавить нового наблюдателя"
                        modalType="ADD_OBSERVER_TABLE"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.ORDER,
            name: "Управление заказами",
            content: {
                getMainContent: () => <OrdersTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Создать новый заказ"
                        modalType="ADD_ORDER"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.ORDER_ITEMS,
            name: "Управление элементами заказов",
            content: {
                getMainContent: () => <OrderItemsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Создать новый элемент заказа"
                        modalType="ADD_ORDER_ITEM"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.STAFF_ORDERS,
            name: "Управление заказами (b2b)",
            content: {
                getMainContent: () => <StaffOrdersTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Создать новый заказ"
                        modalType="ADD_STAFF_ORDER"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.STAFF_ORDER_ITEMS,
            name: "Управление элементами заказов (b2b)",
            content: {
                getMainContent: () => <StaffOrderItemsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Создать новый элемент заказа"
                        modalType="ADD_STAFF_ORDER_ITEM"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.BRAND_DEALER_BIND,
            name: "Предложения (b2b)",
            content: {
                getMainContent: () => <BrandDealerBindsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Создать новое предложение"
                        modalType="ADD_BRAND_DEALER_BIND"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.NS_ATYPE_BIND,
            name: "Привязка типов атрибутов",
            content: {
                getMainContent: () => <AttributeTypeBindsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Привязать новый тип атрибута"
                        modalType="ADD_ATYPE_BIND"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.CSV_LOAD_STATUSES,
            name: "История загрузок CSV",
            content: {
                getTitle: async (params) => {
                    const etype = await eTypesService.getEntityType(params);
                    return `История загрузок «${etype?.name || ""}»`;
                },
                getMainContent: () => <CsvLoadStatusesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
                getContextHelpOptions: () => ({
                    uniqueField: "entity_types_id",
                }),
            },
        },
        {
            type: CONTENT_TYPE.PRICE_LOAD_STATUSES,
            name: "История загрузки цен",
            content: {
                getMainContent: () => <PriceLoadStatusesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
            },
        },
        {
            type: CONTENT_TYPE.QUANTITY_LOAD_STATUSES,
            name: "История загрузки остатков",
            content: {
                getMainContent: () => <QuantitiesLoadStatusesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
            },
        },
        {
            type: CONTENT_TYPE.DISCOUNT_LOAD_STATUSES,
            name: "История загрузки промокодов",
            content: {
                getMainContent: () => <DiscountsLoadStatusesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
            },
        },
        {
            type: CONTENT_TYPE.DISCOUNTS,
            name: "Управление промокодами",
            content: {
                getMainContent: () => <DiscountsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <>
                        <ImportDataButton importType={IMPORT_DATA_TYPE.DISCOUNTS} />
                        <ExportDataButton exportDataType="discount" style={{ marginLeft: 10 }} />
                        <CallModalNewActionButton
                            buttonText="Добавить новый промокод"
                            modalType="ADD_DISCOUNT"
                            buttonProps={{ style: { marginLeft: 10 } }}
                        />
                    </>
                ),
            },
        },
        {
            type: CONTENT_TYPE.STORE_LOAD_STATUSES,
            name: "История загрузки складов",
            content: {
                getMainContent: () => <StoresLoadStatusesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => null,
            },
        },
        {
            type: CONTENT_TYPE.QUANTITY_RESERVES,
            name: "Резервы остатков",
            content: {
                getMainContent: () => <QuantityReservesTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Создать новый резерв"
                        modalType="ADD_QUANTITY_RESERVE"
                    />
                ),
            },
        },
        {
            type: CONTENT_TYPE.RESERVE_STORE_BINDINGS,
            name: "Привязки магазин-резервы",
            content: {
                getMainContent: () => <ReserveStoreBindingsTable />,
                getSideContent: ({ contentType }) => <SectionHelpCard contentType={contentType} />,
                getPageHeadChildren: () => (
                    <CallModalNewActionButton
                        buttonText="Создать новую привязку"
                        modalType="ADD_RESERVE_STORE_BINDING"
                    />
                ),
            },
        },
    ];
}

export default ContentTypesManager;
