import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateAttributeTypeBindDto {
    @Expose()
    readonly type_id: number;

    @Expose()
    readonly attribute_type_name: string;
}
