import * as Yup from "yup";
import { Regex } from "@modules/Constants";

export const ChangeCurrentStaffMemberPasswordSchema = Yup.object().shape({
    old_password: Yup.string().required("Пожалуйста, укажите старый пароль"),
    password: Yup.string()
        .required("Пожалуйста, укажите новый пароль")
        .matches(Regex.PASSWORD, "Пароль не должен начинаться с цифр и спец. символов")
        .min(6, "Пароль должен содержать минимум 6 символов"),
    repeat_password: Yup.string()
        .required("Пожалуйста, повторите новый пароль")
        .matches(Regex.PASSWORD, "Пароль не должен начинаться с цифр и спец. символов")
        .oneOf([Yup.ref("password"), null], "Пароли не совпадают"),
});
