import { TableBody } from "@material-ui/core";
import React from "react";
import { arrayToSubArrays } from "@modules/Utils";
import { ValueType } from "@interfaces/ValueType";
import { AppAttribute } from "@interfaces/Internal/AppAttribute";
import EntityAttributeItemTableRow from "./EntityAttributeItemTableRow";

type EntityAttributeItemTableBodyProps = { vTypes: ValueType[]; attribute: AppAttribute };

const EntityAttributeItemTableBody = ({ vTypes, attribute }: EntityAttributeItemTableBodyProps) => {
    return (
        <TableBody>
            {arrayToSubArrays(attribute.items, attribute.a_items_count).map((row, index) => {
                return (
                    <EntityAttributeItemTableRow
                        key={index}
                        row={row}
                        vTypes={vTypes}
                        attribute={attribute}
                        index={index}
                    />
                );
            })}
        </TableBody>
    );
};

export default EntityAttributeItemTableBody;
