import { CommonEvents, CommonState } from "@store/common-module";
import { darkTheme, lightTheme } from "../theme";
import { useStoreon } from "storeon/react";
import { useMemo } from "react";

const useAppTheme = () => {
    const { theme: currentTheme } = useStoreon<CommonState, CommonEvents>("theme");

    const theme = useMemo(
        () => (currentTheme === "light" ? lightTheme() : darkTheme()),
        [currentTheme]
    );

    return theme;
};

export default useAppTheme;
