import { TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { QuantityReserveSchema } from "@schema/Quantity";
import { QuantityReserve } from "@interfaces/QuantityReserve";

const QuantityReserveForm = forwardRef(
    ({ onSubmit, data }: CommonFormProps<QuantityReserve>, ref: any) => {
        const initialData = {
            sku: data?.sku ?? "",
            count: data?.count ?? 0,
        } as QuantityReserve;

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={QuantityReserveSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, values }) => {
                    return (
                        <Form>
                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.sku}
                                required
                                autoFocus
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="sku"
                                helperText={touched.sku ? errors.sku : ""}
                                error={touched.sku && !!errors.sku}
                                label="SKU"
                            />

                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.count}
                                required
                                type="number"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="count"
                                helperText={touched.count ? errors.count : ""}
                                error={touched.count && !!errors.count}
                                label="Количество"
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default QuantityReserveForm;
