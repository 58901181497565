import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateGeneralSettingsDto {
    @Expose()
    readonly name: string;

    @Expose()
    readonly description: string;

    @Expose()
    readonly key: string;

    @Expose()
    readonly value: string;
}
