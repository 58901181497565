import { PaginatedQueryResult, QueryParams, QueryResult, QueryResultWithoutData } from ".";
import { CreateEntityDto, UpdateEntityDto } from "@dto/EntityDto";
import { Entity } from "@interfaces/Entity";
import { EntityAttributeSchema } from "@interfaces/EntityAttributeSchema";
import BaseService from "./BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";

class EntitiesService extends BaseService {
    public getEntities = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<Entity>> => {
        const { data } = await this.instance.get("/nsi_entity/entity", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getEntity = async (entityId: number, entityTypeId: number): Promise<Entity | null> => {
        const { data } = await this.instance.get("/nsi_entity/entity", {
            params: { ids_list: entityId, et_id: entityTypeId },
        });

        return this.getFirstItemOrNull<Entity>(
            this.toPaginatedUniversalOutput<Entity>(data).result.items
        );
    };

    public createEntity = async (entity: CreateEntityDto): Promise<QueryResult<Entity>> => {
        const { data } = await this.instance.post("/nsi_entity/entity", { data: entity });

        return data;
    };

    public updateEntity = async (entity: UpdateEntityDto): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/nsi_entity/entity", [...entity.items]);

        return data;
    };

    public deleteEntity = async (entitiesIds: number[]): Promise<QueryResult<Entity>> => {
        const { data } = await this.instance.delete("/nsi_entity/entity", {
            data: {
                ids_list: entitiesIds,
            },
        });

        return data;
    };

    public getTypeSchema = async (eTypeId: number): Promise<EntityAttributeSchema[]> => {
        const { data } = await this.instance.get("/nsi_meta/type_schema", {
            params: { id: eTypeId },
        });

        return this.toArray<EntityAttributeSchema>(data.result);
    };

    public setEntityBlock = async (entityId: number) => {
        const { data } = await this.instance.post("/nsi_entity/entity/block", {
            id: entityId,
        });

        return data;
    };

    public unsetEntityBlock = async (entityId: number) => {
        const { data } = await this.instance.delete("/nsi_entity/entity/block", {
            data: {
                id: entityId,
            },
        });

        return data;
    };

    /** use this if you want to remove value from attribute */
    public deleteEntityAttributeValues = async (
        valueIds: number[]
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/nsi_entity/value", {
            data: {
                ids_list: valueIds,
            },
        });

        return data;
    };
}

export default new EntitiesService(new HttpClient(Config.API_URL), AuthManager);
