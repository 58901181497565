import { Box, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Lock } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router-dom";
import { AuthManager, PathsManager } from "../../../modules";
import { authService } from "@services";
import LoginForm from "../../Forms/LoginForm";
import { store } from "@store";
import { useLoading } from "@hooks";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    title: {
        textAlign: "center",
        fontWeight: "bold",
        margin: ".5rem",
        marginTop: "2rem",
    },
    iconWrapper: {
        position: "absolute",
        top: -50,
        left: "-50%",
        right: "-50%",
        borderRadius: "50%",
        height: "4rem",
        width: "4rem",
        backgroundColor: theme.palette.secondary.light,
        margin: "1rem auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        color: theme.palette.common.white,
        fontSize: 40,
    },
}));

const AuthForm = () => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const { loadingStatus, setLoadingStatus } = useLoading("inactivity");

    const handleSubmit = async (values) => {
        try {
            setLoadingStatus("loading");
            const { data } = await authService.login({ ...values });
            AuthManager.authenticate(data.token, store);
            if (AuthManager.isRememberMeActive()) {
                AuthManager.setSavedUsername(values.user);
            }
            history.push(PathsManager.PATHS.HOME.url);
        } catch (err) {
            setLoadingStatus("error");
            enqueueSnackbar("Неправильные данные для входа", {
                variant: "error",
            });
        }
    };

    return (
        <Box p={3} component={Paper} className={classes.wrapper} borderRadius={20}>
            <div className={classes.iconWrapper}>
                <Lock color="action" className={classes.icon} />
            </div>
            <Typography variant="h5" className={classes.title}>
                Авторизация
            </Typography>

            <LoginForm onSubmit={handleSubmit} isLoading={loadingStatus === "loading"} />
        </Box>
    );
};

export default AuthForm;
