import { Container } from "@material-ui/core";
import Breadcrumbs from "@components/Views/Breadcrumbs/Breadcrumbs";
import React from "react";
import PageHead from "@components/Views/PageHead/PageHead";
import { PathsManager } from "@modules";
import { useAuth, useTitle } from "@hooks";
import { HomeContent } from "@components/Views/HomeContent";
import { getUserFullName } from "@modules/Utils";

const HomePage = ({ title = PathsManager.PATHS.HOME.title }) => {
    const { user } = useAuth();

    useTitle(title);

    const username = user ? getUserFullName(user, false) : "";

    return (
        <Container maxWidth={false}>
            <Breadcrumbs />
            <PageHead title={`Добро пожаловать, ${username}`} truncateTitle={false} />
            <HomeContent />
        </Container>
    );
};

export default HomePage;
