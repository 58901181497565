import { useCurrentPageInfo } from "@hooks";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Delete, Edit, Visibility } from "@material-ui/icons";
import { PermissionManager } from "@modules";
import { MODAL_TYPE } from "@modules/ModalManager";
import { getFileUrl } from "@modules/Utils";
import { fileService } from "@services";
import { ModalEvents, ModalState } from "@store/modal-module";
import { useSnackbar } from "notistack";
import React from "react";
import { useStoreon } from "storeon/react";

const useStyles = makeStyles(() => ({
    listIcon: {
        minWidth: 30,
    },
}));

const FileItemMenu = ({ anchorEl, onClose, item, onUpdate }) => {
    const classes = useStyles();
    const { palette } = useTheme();
    const { permission } = useCurrentPageInfo();
    const { dispatch } = useStoreon<ModalState, ModalEvents>();
    const { enqueueSnackbar } = useSnackbar();

    const handleEditFile = () => {
        dispatch("modal/set/type", MODAL_TYPE.EDIT_FILE);
        dispatch("modal/set/props", { itemId: item.id, onUpdate });
        dispatch("modal/toggle", true);
        onClose();
    };

    const handleDeleteFileClick = () => {
        dispatch("modal/set/type", MODAL_TYPE.CONFIRMATION);
        dispatch("modal/set/props", {
            // content: "Вы действительно хотите удалить файл?",
            content: `Вы действительно хотите удалить файл ${item.name}?`, // Добавляем имя файла в текст содержания
            onOk: async () => {
                try {
                    await fileService.deleteFiles([item.id]);
                    enqueueSnackbar("Файл успешно удален", {
                        variant: "success",
                    });
                    await onUpdate();
                } catch {
                    enqueueSnackbar("Произошла ошибка при удалении файла", {
                        variant: "error",
                    });
                }
            },
        });
        dispatch("modal/toggle", true);
        onClose();
    };

    const handleViewFile = () => {
        window.open(getFileUrl(item.path), "_blank");
        onClose();
    };

    const canEdit = PermissionManager.canEdit(permission);
    const canDelete = PermissionManager.canDelete(permission);

    return (
        <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={onClose}>
            <MenuItem onClick={handleViewFile} dense>
                <ListItemIcon className={classes.listIcon}>
                    <Visibility fontSize="small" />
                </ListItemIcon>
                <ListItemText>Посмотреть</ListItemText>
            </MenuItem>
            {canEdit && (
                <MenuItem onClick={handleEditFile} dense divider>
                    <ListItemIcon className={classes.listIcon}>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Редактировать</ListItemText>
                </MenuItem>
            )}
            {canDelete && (
                <MenuItem
                    onClick={handleDeleteFileClick}
                    dense
                    style={{ color: palette.error.main }}
                >
                    <ListItemIcon className={classes.listIcon}>
                        <Delete fontSize="small" color="error" />
                    </ListItemIcon>
                    <ListItemText>Удалить</ListItemText>
                </MenuItem>
            )}
        </Menu>
    );
};

export default FileItemMenu;
