import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateCartDto {
    @Expose()
    readonly user_id: number;

    @Expose()
    readonly shop_id: number;
}
