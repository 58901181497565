import React from "react";
import { UniversalInputProps } from "../../../interfaces/System/UniversalInputProps";
import { AutoSizeTextField } from "../Inputs";

const UTextAutoSizeField = ({ ...props }: UniversalInputProps) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        props.onChange(name, value || null);
    };

    return (
        <AutoSizeTextField
            {...props}
            onChange={handleChange}
            label={props.label || "Введите значение"}
        />
    );
};

export default UTextAutoSizeField;
