import { useAsync } from "@hooks";
import { EntityAttributeSchema } from "@interfaces/EntityAttributeSchema";
import { AppAttribute } from "@interfaces/Internal/AppAttribute";
import { Box, Table } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { vTypesService } from "@services";
import React from "react";
import EntityAttributeItemTableBody from "./EntityAttributeItemTableBody";
import EntityAttributeItemTableHead from "./EntityAttributeItemTableHead";

interface EntityAttributeItemTableProps {
    attribute: AppAttribute;
    schema: EntityAttributeSchema[];
}

const EntityAttributeItemTable = ({ attribute, schema }: EntityAttributeItemTableProps) => {
    const { data, loadingStatus } = useAsync(vTypesService.getValueTypes, {
        functionArgs: [{ ids_list: [...new Set(attribute.items.map((v) => v?.vt_id))].join(",") }],
        canLoad: !!attribute.items?.length,
        setLoadingStatusOnUpdate: false,
    });

    if (loadingStatus === "loading") return <Skeleton width="100%" height={60} />;

    return (
        <Box mt={2}>
            <Table size="small">
                <EntityAttributeItemTableHead
                    attribute={attribute}
                    vTypes={data?.result.items || []}
                    aTypeId={attribute.atype_id}
                    schema={schema}
                />
                <EntityAttributeItemTableBody
                    attribute={attribute}
                    vTypes={data?.result.items || []}
                />
            </Table>
        </Box>
    );
};

export default EntityAttributeItemTable;
