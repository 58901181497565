import { DatePickerProps, DateTimePicker } from "@material-ui/pickers";
import { Constants } from "@modules";
import dayjs from "dayjs";
import React from "react";
import { UniversalInputProps } from "@interfaces/System/UniversalInputProps";

type UDatePickerProps = UniversalInputProps &
    Partial<{
        pickerProps: Partial<Omit<DatePickerProps, "variant" | "onChange">>;
    }>;

const UDateTimePicker = ({ pickerProps, ...props }: UDatePickerProps) => {
    const format = Constants.Format.DATE_TIME;

    const handleChange = (date) => {
        const formattedDateTime = dayjs.isDayjs(date) ? dayjs(date).format(format) : null;
        props.onChange(props.name, formattedDateTime);
    };

    const value = props.value ? dayjs(props.value, format) : null;

    return (
        <DateTimePicker
            ampm={false}
            label={props.label || "Выберите дату и время"}
            size={props.size}
            margin={props.margin}
            fullWidth={props.fullWidth}
            clearable
            inputVariant={props.variant}
            value={value}
            error={props.error}
            helperText={props.helperText}
            format="DD/MM/YYYY HH:mm"
            onChange={handleChange}
            disabled={props.disabled}
            okLabel="Ок"
            clearLabel="Очистить"
            cancelLabel="Отмена"
            required={props.required}
            {...pickerProps}
        />
    );
};

export default UDateTimePicker;
