import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateServiceRoleDto {
    @Expose()
    readonly rolname: string;

    @Expose()
    readonly shown_name: string;

    @Expose()
    readonly description: string;
}
