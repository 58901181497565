import * as Yup from "yup";

export const CreateCartSchema = Yup.object().shape({
    user_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, выберите пользователя"),
    shop_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, выберите магазин"),
});
