import { Exclude, Expose } from "class-transformer";

@Exclude()
class UpdateStaffOrderDto {
    @Expose()
    readonly comment: string;

    @Expose()
    readonly rejected: boolean;

    @Expose()
    readonly status: string;

    @Expose()
    readonly brand_manager_checked: boolean;

    @Expose()
    readonly content_manager_checked: boolean;

    @Expose()
    readonly commodity_expert_checked: boolean;

    @Expose()
    readonly dealer_checked: boolean;

    @Expose()
    readonly brand_manager_id: number;
}

export default UpdateStaffOrderDto;
