import { FormHelperText, Grid, IconButton } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef, useState } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { Order } from "@interfaces/Order";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { CreateOrderSchema, UpdateOrderSchema } from "@schema/Order";
import AddNewButton from "@components/Controls/Buttons/AddNewButton";
import { OrderStatusSelect } from "@components/Controls/Selects";
import { Delete } from "@material-ui/icons";
import { User } from "@interfaces/User";
import { cartService, userService } from "@services";
import { Cart } from "@interfaces/Cart";
import { CartItem } from "@interfaces/CartItem";

const OrderForm = forwardRef(({ onSubmit, data }: CommonFormProps<Order>, ref: any) => {
    const [cartId, setCardId] = useState<number | null>(null);

    const initialData = {
        user_id: data?.user_id ?? null,
        cart_number: data?.number ?? "",
        cart_item_ids: [null],
        status: data?.status ?? "",
    } as Order;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={data ? UpdateOrderSchema : CreateOrderSchema}
            onSubmit={(values, helpers) => {
                const modify = { ...values };
                modify.cart_item_ids.filter((el) => el !== null);
                onSubmit(modify, helpers);
            }}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, setFieldValue }) => {
                return (
                    <Form>
                        <AsyncAutocomplete<User>
                            fetchFn={userService.getUsers}
                            value={values.user_id}
                            onChange={(name, value) => {
                                setFieldValue("cart_number", "");
                                setFieldValue(name, value?.id || null);
                            }}
                            optionField="email"
                            textFieldProps={{
                                required: true,
                                fullWidth: true,
                                name: "user_id",
                                variant: "outlined",
                                size: "small",
                                label: "Пользователь",
                                margin: "normal",
                                error: touched.user_id && !!errors.user_id,
                                helperText: touched.user_id ? errors.user_id : "",
                            }}
                        />

                        {!data && (
                            <AsyncAutocomplete<Cart>
                                fetchFn={cartService.getCarts}
                                onChange={(name, value) => {
                                    setFieldValue(name, value?.number || "");
                                    setCardId(value?.id || null);
                                }}
                                value={values.cart_number}
                                findBy="number"
                                filter={["user_id", values.user_id]}
                                optionField="number"
                                textFieldProps={{
                                    disabled: !values.user_id,
                                    required: true,
                                    fullWidth: true,
                                    name: "cart_number",
                                    variant: "outlined",
                                    size: "small",
                                    label: "Корзина",
                                    margin: "normal",
                                    error: touched.cart_number && !!errors.cart_number,
                                    helperText: touched.cart_number ? errors.cart_number : "",
                                }}
                            />
                        )}

                        {!data && (
                            <>
                                <Grid container justifyContent="flex-end">
                                    <AddNewButton
                                        text="Добавить элемент корзины"
                                        onClick={() => {
                                            const items = [...values.cart_item_ids];
                                            items.push(null);
                                            setFieldValue("cart_item_ids", items);
                                        }}
                                        variant="text"
                                    />
                                </Grid>

                                {values.cart_item_ids.map((el, i) => (
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs>
                                            <AsyncAutocomplete<CartItem>
                                                key={i}
                                                fetchFn={cartService.getCartItems}
                                                onChange={(name, value) => {
                                                    const id = value?.id;
                                                    const copy = [...values.cart_item_ids];

                                                    copy[i] = id || null;

                                                    setFieldValue(name, copy);
                                                }}
                                                filter={["cart_id", cartId]}
                                                value={el}
                                                disabledOptions={values.cart_item_ids.filter(
                                                    (x): x is number => x !== null
                                                )}
                                                optionField="sku"
                                                getOptionFieldLabel={(field) => `SKU: ${field}`}
                                                secondaryField="quantity"
                                                getSecondaryFieldLabel={(field) =>
                                                    `Количество: ${field}`
                                                }
                                                textFieldProps={{
                                                    name: "cart_item_ids",
                                                    disabled: !values.cart_number,
                                                    fullWidth: true,
                                                    variant: "outlined",
                                                    size: "small",
                                                    margin: "dense",
                                                    label: "Элемент корзины",
                                                    error:
                                                        touched.cart_item_ids &&
                                                        !!errors.cart_item_ids,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                size="small"
                                                color="secondary"
                                                disabled={values.cart_item_ids.length <= 1}
                                                onClick={() => {
                                                    const copy = [...values.cart_item_ids];

                                                    copy.splice(i, 1);
                                                    setFieldValue("cart_item_ids", copy);
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                <FormHelperText error>
                                    {touched.cart_item_ids ? errors.cart_item_ids : ""}
                                </FormHelperText>
                            </>
                        )}

                        {data && (
                            <OrderStatusSelect
                                name="status"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                helperText={touched.status ? errors.status : ""}
                                error={touched.status && !!errors.status}
                                label="Статус"
                                value={values.status}
                                onChange={handleChange}
                                fullWidth
                            />
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
});

export default OrderForm;
