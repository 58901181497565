import ContentTypesManager, { CONTENT_TYPE } from "./ContentTypesManager";
import MultipleContentTypesManager, { MULTIPLE_CONTENT_TYPE } from "./MultipleContentTypesManager";
import CombinedContentTypesManager, { CombinedType } from "./CombinedContentTypesManager";

export type WithContentTypeProps<T> = T & Partial<{ contentType: CONTENT_TYPE }>;

export type WithMultipleContentTypeProps<T> = T & Partial<{ contentType: MULTIPLE_CONTENT_TYPE }>;

export type WithCombinedContentTypeProps<T> = T & Partial<{ contentType: CombinedType }>;

export class ContentTypeNotFoundError extends Error {
    constructor(public contentType: string) {
        super(contentType);
        this.name = "ContentTypeError";
        this.message = `Content type "${contentType}" could not be found`;
    }
}

export class MultipleContentTypeNotSupportedError extends Error {
    constructor(public contentType: string) {
        super(contentType);
        this.name = "MultipleContentTypeNotSupportedError";
        this.message = `Content type "${contentType}" must not be in Array!`;
    }
}

export { ContentTypesManager, MultipleContentTypesManager, CombinedContentTypesManager };
