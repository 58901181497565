import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { TableEvents, TableState } from "@store/table-module";
import { permissionSettingsService } from "@services/Settings";
import { CreatePermissionSettingsDto } from "@dto/SettingsDto/PermissionSettingsDto";
import PermissionSettingsForm from "@components/Forms/Settings/PermissionSettingsForm";
import { useLoading } from "@hooks";

const PermissionSettingsDialogContext = ({ onClose }: DialogContextProps) => {
    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreatePermissionSettings = async (values) => {
        setSubmitLoadingStatus("loading");
        try {
            const dto = permissionSettingsService.plainToClass(CreatePermissionSettingsDto, values);

            await permissionSettingsService.createPermissionSettings(dto);
            enqueueSnackbar("Право успешно создано", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании права", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                <PermissionSettingsForm ref={formRef} onSubmit={handleCreatePermissionSettings} />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default PermissionSettingsDialogContext;
