import { StoreonModule } from "storeon";
import { ModalProps } from "../interfaces/System/ModalProps";
import { ModalType } from "../modules/ModalManager";

export interface ModalState {
    open: boolean;
    type: ModalType | null;
    props: ModalProps | null;
}

export interface ModalEvents {
    "modal/toggle": boolean;
    "modal/set/type": ModalType | null;
    "modal/set/props": ModalProps | null;
}

export const modalModule: StoreonModule<ModalState, ModalEvents> = (store) => {
    store.on("@init", () => ({
        open: false,
        type: null,
        props: null,
    }));

    store.on("modal/toggle", (state, value) => ({ open: value }));
    store.on("modal/set/type", (state, value) => ({ type: value }));
    store.on("modal/set/props", (state, value) => ({ props: value }));
};
