import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdatePriceDto {
    @Expose()
    readonly sku: number;

    @Expose()
    readonly rrc_price: number;

    @Expose()
    readonly sale_price: number;

    @Expose()
    readonly discount_percent: number;

    @Expose()
    readonly price_with_discount: number;
}
