import { useSnackbar } from "notistack";
import React from "react";
import { ServiceHelper } from "@modules";
import { attributeTypeBindService } from "@services/Settings";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo, useModal } from "@hooks";
import { AttributeTypeBind } from "@interfaces/AttributeTypeBind";

const AttributeTypeBindsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_ATYPE_BIND", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: AttributeTypeBind | AttributeTypeBind[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await attributeTypeBindService.deleteATypeBinds(ids);
            enqueueSnackbar("Привязка к атрибуту успешно удалена", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении привязки к атрибуту", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<AttributeTypeBind>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Тип атрибута",
            field: "type_id",
            width: "auto",
        },
        {
            title: "Название",
            field: "attribute_type_name",
            width: "auto",
        },
    ];

    return (
        <DataTable<AttributeTypeBind>
            fetchFn={attributeTypeBindService.getATypeBinds}
            columns={columns}
            displayField="attribute_type_name"
            permission={permission}
            sorting
            filtering
            search
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
        />
    );
};

export default AttributeTypeBindsTable;
