import { useSnackbar } from "notistack";
import React from "react";
import { ServiceHelper } from "@modules";
import { staffBrandDealerBindService } from "@services/Staff";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo, useModal } from "@hooks";
import { BrandDealerBind } from "@interfaces/BrandDealerBind";

const BrandDealerBindsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_BRAND_DEALER_BIND", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: BrandDealerBind | BrandDealerBind[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await staffBrandDealerBindService.deleteBrandDealerBinds(ids);
            enqueueSnackbar("Предложение успешно удален", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении предложения", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<BrandDealerBind>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Бренд",
            field: "brand_id",
            width: "auto",
        },
        {
            title: "Дилер",
            field: "dealer_id",
            width: "auto",
        },
        {
            title: "Цена",
            field: "price",
            width: "auto",
        },
    ];

    return (
        <DataTable<BrandDealerBind>
            fetchFn={staffBrandDealerBindService.getBrandDealerBinds}
            columns={columns}
            displayField="id"
            permission={permission}
            sorting
            filtering
            search
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
        />
    );
};

export default BrandDealerBindsTable;
