import { FunctionComponent } from "react";
import SunEditor from "suneditor-react";
import { SunEditorReactProps } from "suneditor-react/dist/types/SunEditorReactProps";

import "suneditor/dist/css/suneditor.min.css";

type RichTextEditorProps = SunEditorReactProps & {
    value: string;
};

const RichTextEditor: FunctionComponent<RichTextEditorProps> = ({
    name,
    value,
    onChange,
    height = "300px",
    ...props
}) => {
    const handleChange: (content: string) => void = (content) => {
        onChange?.(content);
    };

    return (
        <SunEditor
            lang="ru"
            name={name}
            width="100%"
            height={height}
            defaultValue={value}
            onChange={handleChange}
            setOptions={{
                buttonList: [
                    [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "fontColor",
                        "hiliteColor",
                        "textStyle",
                        "removeFormat",
                        "outdent",
                        "indent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "video",
                        "audio",
                        "imageGallery",
                        "fullScreen",
                        "showBlocks",
                        "codeView",
                        "preview",
                        "print",
                        "save",
                    ],
                ],
            }}
            {...props}
        />
    );
};

export default RichTextEditor;
