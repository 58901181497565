import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateContextHelpDto {
    @Expose()
    readonly html: string;

    @Expose()
    readonly files_id?: number[];
}
