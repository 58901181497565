import * as Yup from "yup";
import { Regex } from "@modules/Constants";

export const UpdateCurrentStaffMemberSchema = Yup.object().shape({
    first_name: Yup.string().required("Пожалуйста, введите имя"),
    middle_name: Yup.string().required("Пожалуйста, введите отчество"),
    last_name: Yup.string().required("Пожалуйста, введите фамилию"),
    email: Yup.string()
        .required("Пожалуйста, укажите адрес эл. почты")
        .email("Пожалуйста, укажите корректный адрес эл. почты"),
    phone: Yup.string().matches(Regex.PHONE, "Номер указан неверно"),
    birthday: Yup.string().nullable().required("Пожалуйста, укажите дату рождения"),
    work_phone: Yup.string().matches(Regex.PHONE, "Номер указан неверно"),
    additional_phone: Yup.string().matches(Regex.PHONE, "Номер указан неверно"),
});
