import * as Yup from "yup";

export const OrderItemSchema = Yup.object().shape({
    order_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, укажите идентификатор заказа"),
    sku: Yup.string().required("Пожалуйста, укажите SKU"),
    quantity: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, укажите количество"),
});
