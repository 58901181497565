import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { fileService } from "@services";
import { useAsync, useLoading } from "@hooks";
import { ModalContentLoading } from "@components/Common/Modal";
import { CheckCircle, PlayArrowSharp, Error, Update } from "@material-ui/icons";
import dayjs from "dayjs";

const DirectoryIndexingDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const { data, loadingStatus, updateData } = useAsync(fileService.getIndexingStatuses, {
        functionArgs: [{ dir_id: itemId, sorting: "-id" }],
        canLoad: !!itemId,
    });

    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const handleCreateIndexingTask = async () => {
        if (!itemId) return;

        setSubmitLoadingStatus("loading");
        try {
            await fileService.createIndexingTask(itemId);
            enqueueSnackbar("Задача на индексирование успешно создана", {
                variant: "success",
            });
            updateData();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании задачи индексирования", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const isEmpty = !!(data && !data.result.count);

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {loadingStatus === "loaded" && isEmpty && (
                    <Typography variant="subtitle1">
                        Данных по данной директории не найдено
                    </Typography>
                )}

                {loadingStatus === "loaded" && !isEmpty && (
                    <List>
                        {data?.result.items.map((item, i) => (
                            <ListItem
                                disableGutters
                                divider={data.result.count - 1 !== i}
                                key={item.id}
                            >
                                <ListItemText
                                    primary={dayjs(item.created_at).toString()}
                                    secondary={item.message || "Задача обрабатывается"}
                                />
                                <ListItemSecondaryAction>
                                    {item.status && <CheckCircle color="primary" />}
                                    {!item.status && item.message && <Error color="error" />}
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Button
                        color="primary"
                        onClick={updateData}
                        variant="outlined"
                        startIcon={<Update />}
                    >
                        Обновить
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleCreateIndexingTask}
                        variant="outlined"
                        startIcon={<PlayArrowSharp />}
                        style={{ marginLeft: 10 }}
                        disabled={submitLoadingStatus === "loading"}
                    >
                        Индексация
                    </Button>
                </Grid>

                <Button color="default" onClick={onClose}>
                    Закрыть
                </Button>
            </DialogActions>
        </>
    );
};

export default DirectoryIndexingDialogContext;
