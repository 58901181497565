import { List } from "@material-ui/core";
import React, { forwardRef, useCallback, useImperativeHandle } from "react";
import StaffRoleItem from "./StaffRoleItem";
import { staffRoleService } from "@services/Staff";
import { StaffRoleDto } from "@dto/StaffRoleDto";
import { useStoreon } from "storeon/react";
import { ContentEvents, ContentState } from "@store/content-module";

const StaffRolesList = forwardRef((props, ref: any) => {
    const {
        staffRoles: { userId, roles, rolesToRevoke },
        dispatch,
    } = useStoreon<ContentState, ContentEvents>("staffRoles");

    useImperativeHandle(ref, () => ({
        handleSubmit: async () => {
            if (typeof userId !== "number") return;

            const rolesToGrant = roles
                .filter((el) => (el.action === "add" || el.action === "update") && el.value !== "")
                .map((el) => new StaffRoleDto(userId, el.value));

            for (const role of rolesToGrant) {
                await staffRoleService.grantStaffRole(role);
            }
            for (const rolename of rolesToRevoke) {
                await staffRoleService.revokeStaffRole(new StaffRoleDto(userId, rolename));
            }
        },
    }));

    const handleChangeValue = useCallback(
        (key: string, value: string) => {
            dispatch("content/staff_roles/update/roles", { key, value });
        },
        [dispatch]
    );

    const handleDeleteRole = useCallback(
        (key: string) => {
            dispatch("content/staff_roles/remove/roles", key);
        },
        [dispatch]
    );

    return (
        <List>
            {roles.map((role) => {
                return (
                    <StaffRoleItem
                        key={role.key}
                        id={role.key}
                        value={role.value}
                        onChange={handleChangeValue}
                        onDelete={handleDeleteRole}
                    />
                );
            })}
        </List>
    );
});

export default StaffRolesList;
