import { Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Preloader from "./Preloader";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        zIndex: 5,
        alignItems: "center",
        position: "absolute",
    },
}));

const PreloaderScreen = ({ loading = true }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Fade in={loading}>
                <Preloader />
            </Fade>
        </div>
    );
};

export default PreloaderScreen;
