import { REQUIRED_TYPE } from "@modules/ValidationManager";
import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateEntityTypeItemDto {
    @Expose()
    readonly type_id: number;

    @Expose()
    readonly atype_id: number;

    @Expose()
    readonly sorting: number;

    @Expose()
    readonly required: REQUIRED_TYPE;
}
