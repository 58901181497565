import { IconButton, TableCell, TableRow, TextField, Tooltip, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import React, { FC, useEffect } from "react";
import { useStoreon } from "storeon/react";
import { RestrictionsManager, ValidationManager } from "@modules";
import { EntityEvents, EntityState } from "@store/entity-module";
import { UniversalRestrictionChecker } from "@components/Common";
import UniversalInputField from "@components/Common/UniversalInputField";
import { ValueType } from "@interfaces/ValueType";
import { AppAttribute, AppAttributeValue } from "@interfaces/Internal/AppAttribute";

type EntityAttributeItemTableRowProps = {
    row: AppAttributeValue[];
    vTypes: ValueType[];
    attribute: AppAttribute;
    index: number;
};

const EntityAttributeItemTableRow: FC<EntityAttributeItemTableRowProps> = ({
    row,
    vTypes,
    attribute,
    index,
}: EntityAttributeItemTableRowProps) => {
    const { readOnly, dispatch } = useStoreon<EntityState, EntityEvents>("readOnly");
    const { enableValidation, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("enableValidation");

    const handleDeleteRow: (valueKeys: string[]) => void = (valueKeys) => {
        dispatch("entity/attr/remove/value", { aTypeId: attribute.atype_id, valueKeys });
    };

    const handleChangeValue: (key: string, value: any) => void = (key, value) => {
        dispatch("entity/attr/update/value", {
            at_id: attribute.atype_id,
            valueKey: key,
            value,
        });
    };

    const handleChangeSort: (keys: string[], sorting: number) => void = (keys, sorting) => {
        dispatch("entity/attr/update/sorting", {
            at_id: attribute.atype_id,
            valueKeys: keys,
            sorting,
        });
    };

    const { valid } = ValidationManager.validateAppAttributeValueGroup(attribute.items);

    useEffect(() => {
        validationDispatch("validation/add/validations", { [attribute.atype_id]: valid });
    }, [validationDispatch, attribute, valid]);

    return (
        <TableRow>
            <TableCell>{index + 1}</TableCell>
            {row.map((column) => {
                const vType = vTypes.find((el) => el.id === column.vt_id);

                const { valid, messages } = ValidationManager.validateEntityAttributeValue(
                    column.value,
                    column.restrictions
                );

                return (
                    <TableCell key={column.key}>
                        <UniversalRestrictionChecker
                            restrictions={column.restrictions}
                            render={(inputTypeName, { entityRelationTypeId }) => {
                                return (
                                    <UniversalInputField
                                        vTypeId={column.vt_id}
                                        inputTypeName={inputTypeName}
                                        findBy={column.edited ? "id" : "attrValue"}
                                        responseValue="id"
                                        value={column.value || ""}
                                        size="small"
                                        label={vType?.name || ""}
                                        margin="normal"
                                        disabled={readOnly}
                                        fullWidth
                                        filterByEntityTypeId={entityRelationTypeId}
                                        name={vType?.name || ""}
                                        onChange={(name, val) => {
                                            handleChangeValue(column.key, val);
                                        }}
                                        error={enableValidation && !valid}
                                        helperText={
                                            <>
                                                {column.restrictions.map((rest) => {
                                                    const errorMessage =
                                                        enableValidation && messages[rest.code];

                                                    return (
                                                        <Typography
                                                            key={rest.key}
                                                            variant="caption"
                                                            display="block"
                                                            color={
                                                                errorMessage
                                                                    ? "error"
                                                                    : "textSecondary"
                                                            }
                                                        >
                                                            {errorMessage ||
                                                                RestrictionsManager.getRestrictionItemByCode(
                                                                    rest.code
                                                                )?.getHelpText(rest.value)}
                                                        </Typography>
                                                    );
                                                })}
                                            </>
                                        }
                                    />
                                );
                            }}
                        />
                    </TableCell>
                );
            })}
            <TableCell>
                <TextField
                    label="Сортировка"
                    value={row[0]?.sorting ?? ""}
                    type="number"
                    fullWidth
                    onChange={({ target }) =>
                        handleChangeSort(
                            row.map((el) => el.key),
                            Number(target.value) ?? null
                        )
                    }
                />
            </TableCell>

            {!readOnly && (
                <TableCell>
                    <Tooltip arrow title="Удалить запись">
                        <IconButton
                            size="small"
                            color="secondary"
                            onClick={() => handleDeleteRow(row.map((c) => c.key))}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            )}
        </TableRow>
    );
};

export default EntityAttributeItemTableRow;
