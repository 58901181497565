import { QueryResult, QueryParams, PaginatedQueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { CreateTypeBindDto, UpdateTypeBindDto } from "@dto/SettingsDto/TypeBindDto";
import { ValueTypeBind } from "@interfaces/ValueTypeBind";

class ValueTypeBindService extends BaseService {
    public getVTypeBinds = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<ValueTypeBind>> => {
        const { data } = await this.instance.get("/settings/ns_vtype_bind", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneVTypeBind = async (
        vTypeBindId?: number,
        params?: QueryParams
    ): Promise<ValueTypeBind> => {
        const { data } = await this.instance.get("/settings/ns_vtype_bind", {
            params: { id: vTypeBindId, ...params },
        });

        return data.result.items[0];
    };

    public createVTypeBind = async (
        createValueTypeBindDto: CreateTypeBindDto
    ): Promise<QueryResult<ValueTypeBind>> => {
        const { data } = await this.instance.post("/settings/ns_vtype_bind", {
            data: createValueTypeBindDto,
        });

        return data;
    };

    public updateVTypeBind = async (
        id: number,
        updateValueTypeBindDto: UpdateTypeBindDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/settings/ns_vtype_bind", {
            id,
            data: updateValueTypeBindDto,
        });

        return data;
    };

    public deleteVTypeBinds = async (ids: number[]): Promise<QueryResult<ValueTypeBind>> => {
        const { data } = await this.instance.delete("/settings/ns_vtype_bind", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new ValueTypeBindService(new HttpClient(Config.API_URL), AuthManager);
