import { Chip, Grid, TextField, TextFieldProps, Typography } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import React from "react";

export interface CustomAutocompleteProps<T> {
    textFieldProps: TextFieldProps;
    onChange: (e, value) => void;
    onChangeInput?: (value) => void;
    optionField: keyof T;
    getOptionFieldLabel?(option: string): string;
    secondaryField?: keyof T;
    getSecondaryFieldLabel?(option: string): string;
    items: T[];
    value: T | T[] | null;
    isLoading: boolean;
    disabledOptions?: any[];
    multiple?: boolean;
}

const CustomAsyncAutocomplete = <T extends object>(
    props: CustomAutocompleteProps<T>
): React.ReactElement => {
    const {
        textFieldProps,
        onChange,
        onChangeInput,
        optionField,
        secondaryField,
        getOptionFieldLabel,
        getSecondaryFieldLabel,
        items,
        value,
        disabledOptions,
        isLoading,
        multiple,
    } = props;

    if (isLoading) return <Skeleton width="100%" height={50} />;

    const handleChangeInputValue = (e, inputValue, reason) => {
        if (onChangeInput) {
            if (reason === "clear") {
                onChangeInput("");
                return;
            }

            if (value) return;

            onChangeInput(inputValue || "");
        }
    };

    const handleBlur = () => {
        if (onChangeInput && !value) {
            onChangeInput("");
        }
    };

    return (
        <Autocomplete
            multiple={multiple}
            options={items}
            disabled={textFieldProps.disabled}
            onBlur={handleBlur}
            value={value}
            loading={isLoading}
            fullWidth={textFieldProps.fullWidth}
            onChange={onChange}
            onInputChange={handleChangeInputValue}
            getOptionDisabled={(option) => disabledOptions?.includes(option["id"]) || false}
            getOptionLabel={(option) => option[optionField as string]?.toString() || ""}
            renderOption={(option) => (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography color="textPrimary">
                            {getOptionFieldLabel
                                ? getOptionFieldLabel(option[optionField as string])
                                : option[optionField]}
                        </Typography>
                    </Grid>
                    {secondaryField && (
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                                {getSecondaryFieldLabel
                                    ? getSecondaryFieldLabel(option[secondaryField as string])
                                    : option[secondaryField]}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
            getOptionSelected={(option, value) => option[optionField] === value[optionField]}
            renderInput={(params) => <TextField {...params} {...textFieldProps} />}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip label={option[optionField]} {...getTagProps({ index })} />
                ))
            }
        />
    );
};

export default CustomAsyncAutocomplete;
