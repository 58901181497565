import { CommonFormProps } from "@interfaces/System/FormProps";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CreateObserverTableSchema, UpdateObserverTableSchema } from "@schema/Settings";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    TextField,
} from "@material-ui/core";
import { ObserverTable } from "@interfaces/ObserverTable";

const ObserverTableForm = forwardRef(
    ({ onSubmit, data }: CommonFormProps<ObserverTable>, ref: any) => {
        const initialData = {
            table_name: data?.table_name ?? "",
            insert: data?.insert ?? false,
            update: data?.update ?? false,
            delete: data?.delete ?? false,
        } as ObserverTable;

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={data ? UpdateObserverTableSchema : CreateObserverTableSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, values }) => {
                    return (
                        <Form>
                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.table_name}
                                required
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="table_name"
                                helperText={touched.table_name ? errors.table_name : ""}
                                error={touched.table_name && !!errors.table_name}
                                label="Название таблицы"
                                disabled={!!data}
                            />

                            <FormControl component="fieldset" margin="normal">
                                <FormLabel component="legend">Параметры наблюдения</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.insert}
                                                onChange={handleChange}
                                                name="insert"
                                                color="primary"
                                            />
                                        }
                                        label="Создание"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.update}
                                                onChange={handleChange}
                                                name="update"
                                                color="primary"
                                            />
                                        }
                                        label="Обновление"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.delete}
                                                onChange={handleChange}
                                                name="delete"
                                                color="primary"
                                            />
                                        }
                                        label="Удаление"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default ObserverTableForm;
