import { DataTableColumn, RowAction } from "../Common/DataTable/DataTable";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router-dom";
import { EntityType } from "@interfaces/EntityType";
import { ProtectedPathsManager, ServiceHelper } from "@modules";
import { eTypesService } from "@services";
import { DataTable } from "../Common";
import { useCurrentPageInfo, useAppPaths, useModal } from "@hooks";
import { CONTENT_TYPE } from "@modules/ContentTypesManagers/ContentTypesManager";
import useContextHelp from "@hooks/useContextHelp";

const EntityTypesTable = ({
    title = "Типы сущностей",
    noDelete = false,
    noEdit = false,
    noRowClick = false,
    noCtxHelp = false,
}) => {
    const { onContextClickHandler } = useContextHelp();
    const { permission } = useCurrentPageInfo();
    const { menu } = useAppPaths();
    const history = useHistory();
    const { handleOpenModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();

    const handleEditClick = async (rowData) => {
        try {
            await eTypesService.setEntityTypeBlock(rowData.id);
            handleOpenModal("EDIT_ENTITY_TYPE", { itemId: rowData.id });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message, {
                variant: "info",
            });
        }
    };

    const handleDeleteClick = async (rowData: EntityType | EntityType[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await eTypesService.deleteEntityType(ids);
            enqueueSnackbar("Тип сущности успешно удален", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении типа сущности", {
                variant: "error",
            });
        }
    };

    const handleRowClick = (e, rowData?: EntityType) => {
        const page = ProtectedPathsManager.getPath(menu, CONTENT_TYPE.CATALOGS, "content_type");
        if (!page) return;
        history.push(`${page.url}/${rowData?.id}`);
    };

    const handleContextHelpClick = (e, rowData) => {
        onContextClickHandler(e, "entity_types_id", rowData.id);
    };

    const columns: DataTableColumn<EntityType>[] = [
        {
            title: "#",
            field: "id",
            width: 50,
            defaultSort: "asc",
        },
        {
            title: "Наименование",
            field: "name",
            width: "auto",
        },
        {
            title: "Комментарий",
            field: "notes",
            width: "auto",
        },
    ];

    const actions: RowAction<EntityType>[] = [];

    if (!noCtxHelp) {
        actions.push({
            name: "view_context_help",
            icon: "help_outline",
            iconProps: {
                fontSize: "small",
            },
            position: "row",
            tooltip: "Контекстная справка",
            onClick: handleContextHelpClick,
        });
    }

    return (
        <DataTable
            title={title}
            fetchFn={eTypesService.getEntityTypes}
            columns={columns}
            displayField="name"
            permission={permission}
            search
            sorting
            selection={!noDelete}
            onEditClick={(!noEdit && handleEditClick) || undefined}
            onDeleteClick={(!noDelete && handleDeleteClick) || undefined}
            onRowClick={(!noRowClick && handleRowClick) || undefined}
            onMassDeleteClick={(!noDelete && handleDeleteClick) || undefined}
            customActions={actions}
        />
    );
};

export default EntityTypesTable;
