import { Box, Button, DialogActions, DialogContent, Divider, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { CheckCircle, Error } from "@material-ui/icons";
import { useImportDataControls } from "@hooks";
import { IMPORT_DATA_TYPE } from "@hooks/useImportDataControls";
import React from "react";
import { useStoreon } from "storeon/react";
import { DropzoneField } from "../Controls/Inputs";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { TableEvents, TableState } from "@store/table-module";

type importDataModalProps = { entityTypeId: number };

const ImportDataDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { entityTypeId = null } = { ...modalProps } as importDataModalProps;

    const { dispatch } = useStoreon<TableState, TableEvents>();

    const { handleSubmit, handleChangeFile, error, success } = useImportDataControls(
        modalProps.importType as IMPORT_DATA_TYPE,
        {
            entityTypeId: Number(entityTypeId),
            submitCallback: () => dispatch("table/update/data"),
        }
    );

    return (
        <>
            <DialogContent>
                <DropzoneField
                    onSubmit={handleSubmit}
                    maxFiles={1}
                    onDrop={handleChangeFile}
                    dropzoneOptions={{ accept: ".csv" }}
                    text={
                        <>
                            Разрешенные форматы: <b>.csv</b>
                        </>
                    }
                />

                {(error || success) && (
                    <>
                        <Divider />
                        <Box py={1}>
                            <Typography
                                gutterBottom
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {success && (
                                    <>
                                        <CheckCircle
                                            style={{
                                                marginRight: 10,
                                                color: green[500],
                                            }}
                                        />
                                        Успешно!
                                    </>
                                )}
                                {error && (
                                    <>
                                        <Error style={{ marginRight: 10 }} color="error" />
                                        Ошибка!
                                    </>
                                )}
                            </Typography>
                            <Typography variant="body2">{success || error}</Typography>
                        </Box>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Закрыть</Button>
            </DialogActions>
        </>
    );
};

export default ImportDataDialogContext;
