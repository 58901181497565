import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorOutline } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
    iconWrapper: {
        margin: "1rem auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        color: theme.palette.secondary.main,
        fontSize: 60,
    },
}));

const NotFound = ({ title }) => {
    const classes = useStyles();

    return (
        <Box py={1}>
            <div className={classes.iconWrapper}>
                <ErrorOutline className={classes.icon} />
            </div>
            <Typography
                variant="h5"
                style={{
                    textAlign: "center",
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default NotFound;
