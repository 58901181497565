import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useSnackbar } from "notistack";
import React from "react";
import { ServiceHelper } from "@modules";
import { valueTypeBindService } from "@services/Settings";
import { DataTable } from "../Common";
import { useCurrentPageInfo, useModal } from "@hooks";
import { ValueTypeBind } from "@interfaces/ValueTypeBind";

const ValueTypeBindsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();

    const handleEditClick = async (rowData) => {
        handleOpenModal("EDIT_VTYPE_BIND", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: ValueTypeBind | ValueTypeBind[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await valueTypeBindService.deleteVTypeBinds(ids);
            enqueueSnackbar("Привязка успешно удалена", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении привязок", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<ValueTypeBind>[] = [
        {
            title: "#",
            field: "id",
            width: 50,
            defaultSort: "asc",
        },
        {
            title: "Тип значения",
            field: "type_id",
            width: "auto",
        },
        {
            title: "Тип поля ввода",
            field: "type_name",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={valueTypeBindService.getVTypeBinds}
            columns={columns}
            displayField="id"
            selection
            sorting
            permission={permission}
            search
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
        />
    );
};

export default ValueTypeBindsTable;
