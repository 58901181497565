import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import React from "react";
import { useStoreon } from "storeon/react";
import { ETypeEvents, ETypeState } from "../../../../store/etype-module";

interface Props {
    itemIndex: number;
    itemsLength: number;
}

const EntityTypeSorting = ({ itemIndex, itemsLength }: Props) => {
    const { dispatch } = useStoreon<ETypeState, ETypeEvents>();

    const handleSwapItem = (position: "up" | "down") => {
        dispatch("etype/swap/item", {
            itemIndex: itemIndex,
            pos: position,
        });
    };

    return (
        <Grid item>
            <Tooltip title="Переместить вверх" arrow>
                <span>
                    <IconButton
                        size="small"
                        disabled={itemIndex === 0}
                        onClick={() => handleSwapItem("up")}
                    >
                        <ArrowUpward />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Переместить вниз" arrow>
                <span>
                    <IconButton
                        size="small"
                        disabled={itemsLength - 1 === itemIndex}
                        onClick={() => handleSwapItem("down")}
                    >
                        <ArrowDownward />
                    </IconButton>
                </span>
            </Tooltip>
        </Grid>
    );
};

export default EntityTypeSorting;
