import { FilePreview } from "@components/Views/FilePreview";
import React, { useState } from "react";
import { UniversalInputProps } from "@interfaces/System/UniversalInputProps";
import { DirectoryTreeAutocomplete, FileAutocomplete } from "../Autocomplete";
import { useAsync } from "@hooks";
import { fileService } from "@services";
import { Button, Grid, Link, Tooltip } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { getFileUrl } from "@modules/Utils";
import { Skeleton } from "@material-ui/lab";

const UTreeFileAutocomplete = ({ ...props }: UniversalInputProps) => {
    const {
        name,
        value,
        onChange,
        label,
        variant,
        margin,
        size,
        disabled,
        error,
        helperText,
        fullWidth,
    } = props;
    const [dirId, setDirId] = useState<number | null>(null);

    const { data: file, loadingStatus } = useAsync(fileService.getOneFile, {
        functionArgs: [value],
        setLoadingStatusOnUpdate: false,
        canLoad: !!value,
        fetchCallback: (value) => value && setDirId(value.dir_id),
    });

    const handleChangeDirectory = (dirName, dirValue) => {
        onChange(name, null);
        setDirId(dirValue);
    };

    if (loadingStatus === "loading") return <Skeleton width="100%" height={50} />;

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                <Grid item xs>
                    <DirectoryTreeAutocomplete
                        value={dirId}
                        onChange={handleChangeDirectory}
                        textFieldProps={{
                            name: "dir_id",
                            size,
                            disabled,
                            error,
                            helperText,
                            label: "Директория",
                            margin,
                            fullWidth,
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <FileAutocomplete
                        value={+value || null}
                        directoryId={dirId}
                        onChange={onChange}
                        textFieldProps={{
                            name,
                            variant,
                            size,
                            disabled: !dirId || disabled,
                            error,
                            helperText,
                            label,
                            margin,
                            fullWidth,
                        }}
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Посмотреть файл" arrow>
                        <span>
                            <Button
                                component={Link}
                                color="primary"
                                disabled={loadingStatus !== "loaded" || !!!value}
                                target="_blank"
                                href={getFileUrl(file?.path!)}
                            >
                                <Visibility />
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>

            {loadingStatus === "loaded" && value && (
                <FilePreview
                    file={file?.path ?? ""}
                    containerStyle={{
                        maxWidth: 200,
                        maxHeight: 200,
                    }}
                    imageStyle={{
                        top: "50%",
                        bottom: "50%",
                    }}
                />
            )}
        </>
    );
};

export default UTreeFileAutocomplete;
