import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    List,
    ListItem,
    TextField,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { StaffOrderSchema } from "@schema/StaffOrder";
import { AutoSizeTextField } from "@components/Controls/Inputs";
import { AsyncAutocomplete, EntityAutocomplete } from "@components/Controls/Autocomplete";
import { BrandDealerBind } from "@interfaces/BrandDealerBind";
import { staffBrandDealerBindService, staffService } from "@services/Staff";
import { StaffMember } from "@interfaces/StaffMember";
import { StaffOrderDetail } from "@interfaces/StaffOrderDetail";
import { OrderStatusSelect } from "@components/Controls/Selects";
import { UDateTimePicker } from "@components/Controls/Universal";
import { Subtitle } from "@components/Views/Title";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: "bold",
    },
}));

const StaffOrderDetailForm = forwardRef(
    ({ onSubmit, data, readOnly }: CommonFormProps<StaffOrderDetail>, ref: any) => {
        const classes = useStyles();

        const initialData = {
            collection_id: data?.collection_id ?? null,
            brand_dealer_binding_id: data?.brand_dealer_binding_id ?? null,
            comment: data?.comment ?? "",
            rejected: data?.rejected ?? false,
            brand_manager_checked: data?.brand_manager_checked ?? false,
            content_manager_checked: data?.content_manager_checked ?? false,
            commodity_expert_checked: data?.commodity_expert_checked ?? false,
            dealer_checked: data?.dealer_checked ?? false,
            brand_manager_id: data?.brand_manager_id ?? null,
        } as StaffOrderDetail;

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={StaffOrderSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, values, setFieldValue }) => {
                    return (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <Subtitle title="Общая иформация" bold />
                                    <TextField
                                        value={data?.number}
                                        disabled={readOnly}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        name="number"
                                        label="Номер"
                                        margin="normal"
                                    />

                                    <UDateTimePicker
                                        value={data?.deadline}
                                        onChange={() => {}}
                                        disabled={readOnly}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        name="number"
                                        label="Дедлайн"
                                        margin="normal"
                                    />

                                    <OrderStatusSelect
                                        value={data?.status}
                                        disabled={readOnly}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        name="status"
                                        label="Статус"
                                        margin="normal"
                                    />

                                    <TextField
                                        value={data?.offer_cost}
                                        disabled={readOnly}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        name="offer_cost"
                                        label="Предложенная цена"
                                        margin="normal"
                                    />

                                    <TextField
                                        value={data?.total_price}
                                        disabled={readOnly}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        name="total_price"
                                        label="Итоговая цена"
                                        margin="normal"
                                    />

                                    <EntityAutocomplete
                                        value={values.collection_id}
                                        onChange={(name, value) =>
                                            setFieldValue(name, value?.id || null)
                                        }
                                        typeBindName="collection"
                                        textFieldProps={{
                                            fullWidth: true,
                                            size: "small",
                                            margin: "normal",
                                            variant: "outlined",
                                            name: "collection_id",
                                            label: "Коллекция",
                                            helperText: touched.collection_id
                                                ? errors.collection_id
                                                : "",
                                            error: touched.collection_id && !!errors.collection_id,
                                            disabled: readOnly,
                                        }}
                                    />

                                    <AsyncAutocomplete<BrandDealerBind>
                                        fetchFn={staffBrandDealerBindService.getBrandDealerBinds}
                                        value={values.brand_dealer_binding_id}
                                        onChange={(name, value) =>
                                            setFieldValue(name, value?.id || null)
                                        }
                                        optionField="id"
                                        getOptionFieldLabel={(field) => `Идентификатор: ${field}`}
                                        secondaryField="price"
                                        getSecondaryFieldLabel={(field) => `Цена: ${field}`}
                                        textFieldProps={{
                                            fullWidth: true,
                                            size: "small",
                                            margin: "normal",
                                            variant: "outlined",
                                            name: "brand_dealer_binding_id",
                                            label: "Предложение",
                                            helperText: touched.brand_dealer_binding_id
                                                ? errors.brand_dealer_binding_id
                                                : "",
                                            error:
                                                touched.brand_dealer_binding_id &&
                                                !!errors.brand_dealer_binding_id,
                                            disabled: readOnly,
                                        }}
                                    />

                                    {data && (
                                        <AsyncAutocomplete<StaffMember>
                                            fetchFn={staffService.getAllStaffMembers}
                                            value={values.brand_manager_id}
                                            onChange={(name, value) =>
                                                setFieldValue(name, value?.id || null)
                                            }
                                            optionField="email"
                                            filter={["memberof", "@>{brand_manager}"]}
                                            textFieldProps={{
                                                fullWidth: true,
                                                size: "small",
                                                margin: "normal",
                                                variant: "outlined",
                                                name: "brand_manager_id",
                                                label: "Бренд-менеджер",
                                                helperText: touched.brand_manager_id
                                                    ? errors.brand_manager_id
                                                    : "",
                                                error:
                                                    touched.brand_manager_id &&
                                                    !!errors.brand_manager_id,
                                                disabled: readOnly,
                                            }}
                                        />
                                    )}

                                    <AutoSizeTextField
                                        name="comment"
                                        onChange={handleChange}
                                        value={values.comment || ""}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        margin="normal"
                                        label="Комментарий"
                                        minRows={2}
                                        disabled={readOnly}
                                    />

                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.rejected}
                                                    onChange={handleChange}
                                                    name="rejected"
                                                    color="primary"
                                                    disabled={readOnly}
                                                />
                                            }
                                            label="Отклонено"
                                        />
                                    </FormGroup>

                                    <FormControl
                                        component="fieldset"
                                        margin="normal"
                                        disabled={readOnly}
                                    >
                                        <FormLabel component="legend">Контроль</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.brand_manager_checked}
                                                        onChange={handleChange}
                                                        name="brand_manager_checked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Проверено бренд-менеджером"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.content_manager_checked}
                                                        onChange={handleChange}
                                                        name="content_manager_checked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Проверено контент-менеджером"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.commodity_expert_checked}
                                                        onChange={handleChange}
                                                        name="commodity_expert_checked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Проверено товароведом"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.dealer_checked}
                                                        onChange={handleChange}
                                                        name="dealer_checked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Проверено дилером"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                    <Subtitle title="Детали заказа" bold />

                                    <List>
                                        {data?.order_items.map((orderItem) => {
                                            return (
                                                <ListItem
                                                    component="div"
                                                    divider
                                                    style={{ display: "block" }}
                                                    disableGutters
                                                >
                                                    <Typography variant="subtitle1">
                                                        Цена:{" "}
                                                        <Typography
                                                            component="span"
                                                            className={classes.bold}
                                                        >
                                                            {orderItem.static_price}
                                                        </Typography>
                                                    </Typography>
                                                    <Typography variant="subtitle1">
                                                        SKU:{" "}
                                                        <Typography
                                                            component="span"
                                                            className={classes.bold}
                                                        >
                                                            {orderItem.sku}
                                                        </Typography>
                                                    </Typography>
                                                    <EntityAutocomplete
                                                        value={orderItem.entity_id}
                                                        onChange={(name, value) =>
                                                            setFieldValue(name, value || null)
                                                        }
                                                        textFieldProps={{
                                                            fullWidth: true,
                                                            size: "small",
                                                            margin: "normal",
                                                            variant: "standard",
                                                            label: "Товар",
                                                            name: "order_item",
                                                            disabled: readOnly,
                                                        }}
                                                    />
                                                    {orderItem.update_date && (
                                                        <Typography variant="subtitle1">
                                                            Дата обновления:{" "}
                                                            <Typography
                                                                component="span"
                                                                className={classes.bold}
                                                            >
                                                                {dayjs(
                                                                    orderItem.update_date
                                                                ).format("DD.MM.YYYY hh:mm:ss")}
                                                            </Typography>
                                                        </Typography>
                                                    )}
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default StaffOrderDetailForm;
