import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { vTypesService } from "@services";
import { ModalContentLoading } from "../../Common/Modal";
import ValueTypeForm from "../../Forms/ValueTypeForm";
import { UpdateValueTypeDto } from "@dto/ValueTypeDto";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import withCloseListener from "@hoc/withCloseListener";
import { useAsync, useLoading, useTable } from "@hooks";

const EditValueTypeDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { updateData } = useTable();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data, loadingStatus } = useAsync(vTypesService.getValueType, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    useEffect(() => {
        return () => {
            vTypesService.unsetValueTypeBlock(Number(modalProps.itemId));
        };
    }, [modalProps]);

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleUpdateValueType = async (values) => {
        if (!itemId) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = vTypesService.plainToClass(UpdateValueTypeDto, values);
            await vTypesService.updateValueType(itemId, dto);
            enqueueSnackbar("Тип значения успешно обновлен", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            setSubmitLoadingStatus("loaded");
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении типа значения", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" ? (
                    <ModalContentLoading />
                ) : (
                    <ValueTypeForm onSubmit={handleUpdateValueType} data={data} ref={formRef} />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default withCloseListener(EditValueTypeDialogContext);
