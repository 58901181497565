import { StoreonModule } from "storeon";
import { StaffMember } from "../interfaces/StaffMember";
import { AuthManager } from "../modules";

export interface AuthState {
    isLoggedIn: boolean;
    user: StaffMember | null;
    token: string | null;
}

export interface AuthEvents {
    "auth/set/is_logged_in": boolean;
    "auth/set/user": StaffMember;
    "auth/set/token": string;
    "auth/remove/user";
    "auth/remove/token";
    "auth/remove/is_logged_in";
}

export const authModule: StoreonModule<AuthState, AuthEvents> = (store) => {
    store.on("@init", () => ({
        isLoggedIn: AuthManager.isUserAuthenticated(),
        user: null,
        token: AuthManager.getToken(),
    }));

    store.on("auth/set/is_logged_in", (state, value) => ({
        isLoggedIn: value,
    }));
    store.on("auth/set/user", (state, value) => ({ user: value }));
    store.on("auth/set/token", (state, value) => ({ token: value }));
    store.on("auth/remove/user", () => ({ user: null }));
    store.on("auth/remove/token", () => ({ token: null }));
    store.on("auth/remove/is_logged_in", () => ({ isLoggedIn: false }));
};
