import axios, { AxiosInstance, AxiosResponse } from "axios";

export type HttpResponse<T = unknown> = AxiosResponse<T>;

export default class HttpClient {
    public instance: AxiosInstance;

    constructor(private readonly baseUrl?: string) {
        this.instance = axios.create({
            baseURL: this.baseUrl,
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
}
