import { Checkbox, FormControlLabel } from "@material-ui/core";
import { AuthManager } from "@modules";
import React, { useState } from "react";

const RememberMe = () => {
    const [checked, setChecked] = useState(!!AuthManager.getSavedUsername());

    const handleChange = (e) => {
        const { checked } = e.target;

        setChecked(checked);
        if (checked) {
            AuthManager.setRememberMe();
        } else {
            AuthManager.removeRememberMe();
            AuthManager.removeSavedUsername();
        }
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name="remember-me"
                    color="primary"
                />
            }
            label="Запомнить логин"
        />
    );
};

export default RememberMe;
