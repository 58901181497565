import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateOrderItemDto {
    @Expose()
    readonly order_id: number;

    @Expose()
    readonly sku: string;

    @Expose()
    readonly quantity: number;

    @Expose()
    readonly rrc_price: number;

    @Expose()
    readonly sale_price: number;

    @Expose()
    readonly discount_money: number;

    @Expose()
    readonly discount_percent: number;

    @Expose()
    readonly excluded: boolean;
}
