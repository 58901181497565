import { useSnackbar } from "notistack";
import React from "react";
import { ServiceHelper } from "@modules";
import { staffOrderService } from "@services/Staff";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo, useModal } from "@hooks";
import { StaffOrder } from "@interfaces/StaffOrder";

const StaffOrdersTable = () => {
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_STAFF_ORDER", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: StaffOrder | StaffOrder[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await staffOrderService.deleteStaffOrders(ids);
            enqueueSnackbar("Заказ успешно удален", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении заказа", {
                variant: "error",
            });
        }
    };

    const handleRowClick = (e, rowData?: StaffOrder) => {
        handleOpenModal("VIEW_STAFF_ORDER_DETAIL", { itemId: rowData?.id });
    };

    const columns: DataTableColumn<StaffOrder>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Номер",
            field: "number",
            width: "auto",
        },
        {
            title: "Дедлайн",
            field: "deadline",
            type: "datetime",
            width: "auto",
        },
        {
            title: "Статус",
            field: "status",
            width: "auto",
        },
        {
            title: "Комментарий",
            field: "comment",
            width: "auto",
        },
        {
            title: "Отклонено",
            field: "rejected",
            width: "auto",
            type: "boolean",
        },
        {
            title: "Проверено бренд-менеджером",
            field: "brand_manager_checked",
            width: "auto",
            hidden: true,
            type: "boolean",
        },
        {
            title: "Проверено контент-менеджером",
            field: "content_manager_checked",
            width: "auto",
            hidden: true,
            type: "boolean",
        },
        {
            title: "Проверено товароведом",
            field: "commodity_expert_checked",
            width: "auto",
            hidden: true,
            type: "boolean",
        },
        {
            title: "Проверено дилером",
            field: "dealer_checked",
            width: "auto",
            hidden: true,
            type: "boolean",
        },
        {
            title: "Дата создания",
            field: "created_at",
            width: "auto",
            hidden: true,
            type: "datetime",
        },
        {
            title: "Дата обновления",
            field: "updated_at",
            width: "auto",
            hidden: true,
            type: "datetime",
        },
    ];

    return (
        <DataTable<StaffOrder>
            fetchFn={staffOrderService.getStaffOrders}
            columns={columns}
            displayField="number"
            permission={permission}
            sorting
            search
            onRowClick={handleRowClick}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
        />
    );
};

export default StaffOrdersTable;
