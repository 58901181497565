import { Button, ButtonProps } from "@material-ui/core";
import { Brightness4, Brightness7 } from "@material-ui/icons";
import { CommonEvents, CommonState } from "@store/common-module";
import { useStoreon } from "storeon/react";

const ThemeSwitcher = ({ ...props }: ButtonProps = {}) => {
    const { theme, dispatch } = useStoreon<CommonState, CommonEvents>("theme");

    const handleChangeTheme = () => {
        const nextTheme = theme === "light" ? "dark" : "light";
        dispatch("common/set/theme", nextTheme);
        localStorage.setItem("theme", nextTheme);
    };

    return (
        <Button
            startIcon={
                theme === "light" ? (
                    <Brightness4 color="primary" style={{ marginRight: 10 }} />
                ) : (
                    <Brightness7 color="primary" style={{ marginRight: 10 }} />
                )
            }
            onClick={handleChangeTheme}
            color="inherit"
            {...props}
        >
            Переключить тему
        </Button>
    );
};

export default ThemeSwitcher;
