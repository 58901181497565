import { AttributeTypeVariant } from "@modules/EntityAttributesManager";
import { arrayToSubArrays } from "@modules/Utils";
import { Transform, Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateEntityDto {
    @Expose({ name: "entityTypeId" })
    readonly type_id: number;

    @Expose()
    @Transform(({ value }) =>
        value.map((attr) => {
            let value: any = null;

            switch (attr.variant) {
                case AttributeTypeVariant.single: {
                    value = attr.items[0].value;
                    break;
                }
                case AttributeTypeVariant.multiple: {
                    value = attr.items.map((el) => el.value);
                    break;
                }
                case AttributeTypeVariant.composite: {
                    value = [attr.items.map((el) => el.value)];
                    break;
                }
                case AttributeTypeVariant.composite_multiple: {
                    value = arrayToSubArrays(attr.items, attr.a_items_count).map((v) =>
                        v.map((e) => e.value)
                    );
                    break;
                }
                default:
                    value = null;
            }

            return {
                atype_id: attr.atype_id,
                value,
            };
        })
    )
    readonly attrs: { atype_id: number; value: any }[];
}
