import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import React from "react";

type Props = {
    name: string;
    value?: "male" | "female";
    onChange(name, value): void;
    formControlProps?: any;
};

const GenderSelect = ({ name, value, onChange, formControlProps }: Props) => {
    const handleChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    return (
        <FormControl component="fieldset" {...formControlProps}>
            <FormLabel component="legend">Пол</FormLabel>
            <RadioGroup aria-label="gender" name={name} value={value} onChange={handleChange}>
                <FormControlLabel value="male" control={<Radio />} label="Мужской" />
                <FormControlLabel value="female" control={<Radio />} label="Женский" />
            </RadioGroup>
        </FormControl>
    );
};

export default GenderSelect;
