import { Exclude, Expose } from "class-transformer";

@Exclude()
export class AttributeValueBindDto implements Readonly<AttributeValueBindDto> {
    @Expose()
    at_id: number;

    @Expose()
    a_value: string;
}
