import { StoreDialogContext } from "../components/DialogContexts/StoreDialogContexts";
import {
    ConfirmationDialogContext,
    ViewContentDialogContext,
    ImportDataDialogContext,
} from "../components/DialogContexts/";
import {
    CreateAttributeTypeDialogContext,
    EditAttributeTypeDialogContext,
} from "../components/DialogContexts/AttributeTypeDialogContexts";
import {
    CreateEntityByEntityTypeDialogContext,
    EditEntityByEntityTypeDialogContext,
    ViewEntityByEntityTypeDialogContext,
} from "../components/DialogContexts/EntityByEntityTypeDialogContexts";
import {
    CreateEntityTypeDialogContext,
    EditEntityTypeDialogContext,
} from "../components/DialogContexts/EntityTypeDialogContexts";
import {
    CreateStaffMemberDialogContext,
    EditStaffMemberDialogContext,
    BindStaffMemberBrandsDialogContext,
    ChangeStaffMemberPasswordDialogContext,
    ChangeCurrentStaffMemberPasswordDialogContext,
} from "../components/DialogContexts/StaffMemberDialogContexts";
import {
    CreateValueTypeDialogContext,
    EditValueTypeDialogContext,
} from "../components/DialogContexts/ValueTypeDialogContexts";
import { Modal } from "../interfaces/System/Modal";
import { eTypesService, fileService, storeService } from "@services";
import {
    ContextHelpDialogContext,
    GeneralSettingsDialogContext,
    PermissionSettingsDialogContext,
    ServiceRoleDialogContext,
    TypeBindDialogContext,
    StaffMenuItemDialogContext,
    RowBlockDialogContext,
    StaffMenuPermissionDialogContext,
    AttributeTypeBindDialogContext,
    ValueTypeBindDialogContext,
    ObserverTableDialogContext,
} from "../components/DialogContexts/SettingsDialogContexts";
import {
    CartDialogContext,
    CartItemDialogContext,
} from "@components/DialogContexts/CartDialogContexts";
import {
    DirectoryDialogContext,
    DirectoryIndexingDialogContext,
} from "@components/DialogContexts/FileStorageDialogContexts";
import FileUploadDialogContext from "@components/DialogContexts/FileUploadDialogContext";
import FileDialogContext from "@components/DialogContexts/FileStorageDialogContexts/FileDialogContext";
import { UserDialogContext } from "@components/DialogContexts/UserDialogContexts";
import { EShopDialogContext } from "@components/DialogContexts/EShopDialogContexts";
import {
    OrderDialogContext,
    OrderItemDialogContext,
} from "@components/DialogContexts/OrderDialogContexts";
import {
    BrandDealerBindDialogContext,
    StaffOrderDetailDialogContext,
    StaffOrderDialogContext,
    StaffOrderItemDialogContext,
} from "@components/DialogContexts/StaffOrderDialogContexts";
import { StaffCartDialogContext } from "@components/DialogContexts/StaffCartDialogContexts";
import { PriceDialogContext } from "@components/DialogContexts/PriceDialogContexts";
import {
    QuantityDialogContext,
    QuantityReserveDialogContext,
    ReserveStoreBindingDialogContext,
} from "@components/DialogContexts/QuantityDialogContexts";
import { DiscountDialogContext } from "@components/DialogContexts/DiscountDialogContexts";

export enum MODAL_TYPE {
    CONFIRMATION = "CONFIRMATION",
    ADD_NEW_VALUE_TYPE = "ADD_NEW_VALUE_TYPE",
    EDIT_VALUE_TYPE = "EDIT_VALUE_TYPE",
    ADD_NEW_ATTRIBUTE_TYPE = "ADD_NEW_ATTRIBUTE_TYPE",
    EDIT_ATTRIBUTE_TYPE = "EDIT_ATTRIBUTE_TYPE",
    ADD_NEW_ENTITY_TYPE = "ADD_NEW_ENTITY_TYPE",
    EDIT_ENTITY_TYPE = "EDIT_ENTITY_TYPE",
    ADD_NEW_ENTITY_BY_ENTITY_TYPE = "ADD_NEW_ENTITY_BY_ENTITY_TYPE",
    EDIT_ENTITY_BY_ENTITY_TYPE = "EDIT_ENTITY_BY_ENTITY_TYPE",
    SHOW_ENTITY_BY_ENTITY_TYPE = "SHOW_ENTITY_BY_ENTITY_TYPE",
    IN_DEVELOPMENT = "IN_DEVELOPMENT",
    ADD_NEW_STAFF_MEMBER = "ADD_NEW_STAFF_MEMBER",
    EDIT_STAFF_MEMBER = "EDIT_STAFF_MEMBER",
    IMPORT_DATA = "IMPORT_DATA",
    CHANGE_STAFF_MEMBER_PASSWORD = "CHANGE_STAFF_MEMBER_PASSWORD",
    CHANGE_CURRENT_STAFF_MEMBER_PASSWORD = "CHANGE_CURRENT_STAFF_MEMBER_PASSWORD",
    BIND_STAFF_MEMBER_BRANDS = "BIND_STAFF_MEMBER_BRANDS",
    ADD_NEW_STORE = "ADD_NEW_STORE",
    EDIT_STORE = "EDIT_STORE",
    ADD_NEW_GENERAL_SETTINGS = "ADD_NEW_GENERAL_SETTINGS",
    EDIT_GENERAL_SETTINGS = "EDIT_GENERAL_SETTINGS",
    ADD_NEW_SERVICE_ROLE = "ADD_NEW_SERVICE_ROLE",
    EDIT_SERVICE_ROLE = "EDIT_SERVICE_ROLE",
    ADD_NEW_TYPE_BIND = "ADD_NEW_TYPE_BIND",
    EDIT_TYPE_BIND = "EDIT_TYPE_BIND",
    ADD_NEW_STAFF_MENU_ITEM = "ADD_NEW_STAFF_MENU_ITEM",
    EDIT_STAFF_MENU_ITEM = "EDIT_STAFF_MENU_ITEM",
    ADD_NEW_ROW_BLOCK = "ADD_NEW_ROW_BLOCK",
    EDIT_ROW_BLOCK = "EDIT_ROW_BLOCK",
    ADD_NEW_STAFF_MENU_PERMISSION = "ADD_NEW_STAFF_MENU_PERMISSION",
    EDIT_STAFF_MENU_PERMISSION = "EDIT_STAFF_MENU_PERMISSION",
    ADD_NEW_PERMISSION_SETTINGS = "ADD_NEW_PERMISSION_SETTINGS",
    EDIT_PERMISSION_SETTINGS = "EDIT_PERMISSION_SETTINGS",
    ADD_CART = "ADD_CART",
    EDIT_CART = "EDIT_CART",
    ADD_CART_ITEM = "ADD_CART_ITEM",
    EDIT_CART_ITEM = "EDIT_CART_ITEM",
    ADD_DIRECTORY = "ADD_DIRECTORY",
    EDIT_DIRECTORY = "EDIT_DIRECTORY",
    MASS_FILE_UPLOAD = "MASS_FILE_UPLOAD",
    EDIT_FILE = "EDIT_FILE",
    CONTEXT_HELP = "CONTEXT_HELP",
    ADD_USER = "ADD_USER",
    EDIT_USER = "EDIT_USER",
    ADD_ESHOP = "ADD_ESHOP",
    EDIT_ESHOP = "EDIT_ESHOP",
    ADD_VTYPE_BIND = "ADD_VTYPE_BIND",
    EDIT_VTYPE_BIND = "EDIT_VTYPE_BIND",
    ADD_ORDER = "ADD_ORDER",
    EDIT_ORDER = "EDIT_ORDER",
    ADD_ORDER_ITEM = "ADD_ORDER_ITEM",
    EDIT_ORDER_ITEM = "EDIT_ORDER_ITEM",
    ADD_STAFF_ORDER = "ADD_STAFF_ORDER",
    EDIT_STAFF_ORDER = "EDIT_STAFF_ORDER",
    ADD_STAFF_ORDER_ITEM = "ADD_STAFF_ORDER_ITEM",
    EDIT_STAFF_ORDER_ITEM = "EDIT_STAFF_ORDER_ITEM",
    VIEW_STAFF_ORDER_DETAIL = "VIEW_STAFF_ORDER_DETAIL",
    ADD_BRAND_DEALER_BIND = "ADD_BRAND_DEALER_BIND",
    EDIT_BRAND_DEALER_BIND = "EDIT_BRAND_DEALER_BIND",
    ADD_ATYPE_BIND = "ADD_ATYPE_BIND",
    EDIT_ATYPE_BIND = "EDIT_ATYPE_BIND",
    EDIT_STAFF_CART = "EDIT_STAFF_CART",
    ADD_OBSERVER_TABLE = "ADD_OBSERVER_TABLE",
    EDIT_OBSERVER_TABLE = "EDIT_OBSERVER_TABLE",
    DIRECTORY_INDEXING = "DIRECTORY_INDEXING",
    ADD_PRICE = "ADD_PRICE",
    EDIT_PRICE = "EDIT_PRICE",
    ADD_QUANTITY = "ADD_QUANTITY",
    EDIT_QUANTITY = "EDIT_QUANTITY",
    ADD_DISCOUNT = "ADD_DISCOUNT",
    EDIT_DISCOUNT = "EDIT_DISCOUNT",
    ADD_QUANTITY_RESERVE = "ADD_QUANTITY_RESERVE",
    EDIT_QUANTITY_RESERVE = "EDIT_QUANTITY_RESERVE",
    ADD_RESERVE_STORE_BINDING = "ADD_RESERVE_STORE_BINDING",
    EDIT_RESERVE_STORE_BINDING = "EDIT_RESERVE_STORE_BINDING",
}

export type ModalType = keyof typeof MODAL_TYPE;

class ModalManager {
    static getModal = (modalType: ModalType) => {
        return ModalManager.MODALS.find((el) => el.type === modalType);
    };

    static readonly MODALS: Modal[] = [
        {
            type: MODAL_TYPE.CONFIRMATION,
            title: "Подтвердите действие",
            content: ConfirmationDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_VALUE_TYPE,
            title: "Добавить новый тип значения",
            content: CreateValueTypeDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_VALUE_TYPE,
            title: "Изменить тип значения",
            content: EditValueTypeDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_ATTRIBUTE_TYPE,
            title: "Добавить новый тип атрибута",
            content: CreateAttributeTypeDialogContext,
            width: "lg",
        },
        {
            type: MODAL_TYPE.EDIT_ATTRIBUTE_TYPE,
            title: "Изменить тип атрибута",
            content: EditAttributeTypeDialogContext,
            width: "lg",
        },
        {
            type: MODAL_TYPE.ADD_NEW_ENTITY_TYPE,
            title: "Добавить новый тип сущности",
            content: CreateEntityTypeDialogContext,
            width: "lg",
        },
        {
            type: MODAL_TYPE.EDIT_ENTITY_TYPE,
            title: "Изменить тип сущности",
            content: EditEntityTypeDialogContext,
            width: "lg",
        },
        {
            type: MODAL_TYPE.ADD_NEW_ENTITY_BY_ENTITY_TYPE,
            title: "Добавить новую сущность",
            getTitle: async (eTypeId: number) => {
                const etype = await eTypesService.getEntityType(eTypeId);
                return `Добавить новую сущность «${etype?.name || ""}»`;
            },
            content: CreateEntityByEntityTypeDialogContext,
            width: "lg",
        },
        {
            type: MODAL_TYPE.EDIT_ENTITY_BY_ENTITY_TYPE,
            title: "Изменить сущность",
            getTitle: async (eTypeId: number) => {
                const etype = await eTypesService.getEntityType(eTypeId);
                return `Изменить сущность «${etype?.name}»`;
            },
            content: EditEntityByEntityTypeDialogContext,
            width: "lg",
        },
        {
            type: MODAL_TYPE.SHOW_ENTITY_BY_ENTITY_TYPE,
            title: "Просмотр сущности",
            getTitle: async (eTypeId: number) => {
                const etype = await eTypesService.getEntityType(eTypeId);
                return `Просмотр сущности «${etype?.name}»`;
            },
            content: ViewEntityByEntityTypeDialogContext,
            width: "lg",
        },
        {
            type: MODAL_TYPE.IN_DEVELOPMENT,
            title: "В разработке",
            content: ViewContentDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_STAFF_MEMBER,
            title: "Добавить нового сотрудника",
            content: CreateStaffMemberDialogContext,
            width: "md",
        },
        {
            type: MODAL_TYPE.EDIT_STAFF_MEMBER,
            title: "Редактировать сотрудника",
            content: EditStaffMemberDialogContext,
            width: "md",
        },
        {
            type: MODAL_TYPE.IMPORT_DATA,
            title: "Импорт данных",
            content: ImportDataDialogContext,
        },
        {
            type: MODAL_TYPE.CHANGE_STAFF_MEMBER_PASSWORD,
            title: "Изменить пароль сотрудника",
            content: ChangeStaffMemberPasswordDialogContext,
        },
        {
            type: MODAL_TYPE.CHANGE_CURRENT_STAFF_MEMBER_PASSWORD,
            title: "Изменить пароль",
            content: ChangeCurrentStaffMemberPasswordDialogContext,
        },
        {
            type: MODAL_TYPE.BIND_STAFF_MEMBER_BRANDS,
            title: "Назначить бренды",
            content: BindStaffMemberBrandsDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_STORE,
            title: "Добавить новый склад",
            content: StoreDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_STORE,
            title: "Редактировать склад",
            getTitle: async (storeId: number) => {
                const { store_name } = await storeService.getOneStore(storeId);
                return `Редактировать склад «${store_name}»`;
            },
            content: StoreDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_GENERAL_SETTINGS,
            title: "Добавить новую настройку",
            content: GeneralSettingsDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_GENERAL_SETTINGS,
            title: "Редактировать настройку",
            content: GeneralSettingsDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_SERVICE_ROLE,
            title: "Добавить новую роль",
            content: ServiceRoleDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_SERVICE_ROLE,
            title: "Редактировать роль",
            content: ServiceRoleDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_TYPE_BIND,
            title: "Привязать тип",
            content: TypeBindDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_TYPE_BIND,
            title: "Редактировать привязку типа",
            content: TypeBindDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_STAFF_MENU_ITEM,
            title: "Добавить новый пункт меню",
            content: StaffMenuItemDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_STAFF_MENU_ITEM,
            title: "Редактировать пункт меню",
            content: StaffMenuItemDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_ROW_BLOCK,
            title: "Добавить блокировку",
            content: RowBlockDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_ROW_BLOCK,
            title: "Редактировать блокировку",
            content: RowBlockDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_STAFF_MENU_PERMISSION,
            title: "Добавить новое право доступа",
            content: StaffMenuPermissionDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_STAFF_MENU_PERMISSION,
            title: "Редактировать право доступа",
            content: StaffMenuPermissionDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_NEW_PERMISSION_SETTINGS,
            title: "Добавить новое право",
            content: PermissionSettingsDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_PERMISSION_SETTINGS,
            title: "Редактировать право",
            content: PermissionSettingsDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_CART,
            title: "Создать новую корзину",
            content: CartDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_CART,
            title: "Редактировать корзину",
            content: CartDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_CART_ITEM,
            title: "Создать новый элемент корзины",
            content: CartItemDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_CART_ITEM,
            title: "Редактировать элемент корзины",
            content: CartItemDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_DIRECTORY,
            title: "Создать новую папку",
            content: DirectoryDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_DIRECTORY,
            title: "Редактировать папку",
            content: DirectoryDialogContext,
        },
        {
            type: MODAL_TYPE.MASS_FILE_UPLOAD,
            title: "Загрузка файлов",
            content: FileUploadDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_FILE,
            title: "Редактировать файл",
            content: FileDialogContext,
        },
        {
            type: MODAL_TYPE.CONTEXT_HELP,
            title: "Контекстная справка",
            content: ContextHelpDialogContext,
            width: "lg",
        },
        {
            type: MODAL_TYPE.ADD_USER,
            title: "Создание пользователя",
            content: UserDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_USER,
            title: "Редактирование пользователя",
            content: UserDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_ESHOP,
            title: "Создание магазина",
            content: EShopDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_ESHOP,
            title: "Редактирование магазина",
            content: EShopDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_VTYPE_BIND,
            title: "Создание привязки",
            content: ValueTypeBindDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_VTYPE_BIND,
            title: "Редактирование привязки",
            content: ValueTypeBindDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_ORDER,
            title: "Создание заказа",
            content: OrderDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_ORDER,
            title: "Редактирование заказа",
            content: OrderDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_ORDER_ITEM,
            title: "Создание элемента заказа",
            content: OrderItemDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_ORDER_ITEM,
            title: "Редактирование элемента заказа",
            content: OrderItemDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_STAFF_ORDER,
            title: "Создание заказа (b2b)",
            content: StaffOrderDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_STAFF_ORDER,
            title: "Изменение заказа (b2b)",
            content: StaffOrderDialogContext,
        },
        {
            type: MODAL_TYPE.VIEW_STAFF_ORDER_DETAIL,
            title: "Просмотр деталей заказа (b2b)",
            width: "lg",
            content: StaffOrderDetailDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_STAFF_ORDER_ITEM,
            title: "Создание элемента заказа (b2b)",
            content: StaffOrderItemDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_STAFF_ORDER_ITEM,
            title: "Изменение элемента заказа (b2b)",
            content: StaffOrderItemDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_BRAND_DEALER_BIND,
            title: "Создание предложения (b2b)",
            content: BrandDealerBindDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_BRAND_DEALER_BIND,
            title: "Изменение предложения (b2b)",
            content: BrandDealerBindDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_ATYPE_BIND,
            title: "Создание привязки для типа атрибута",
            content: AttributeTypeBindDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_ATYPE_BIND,
            title: "Изменение привязки для типа атрибута",
            content: AttributeTypeBindDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_STAFF_CART,
            title: "Изменение корзины",
            content: StaffCartDialogContext,
        },
        {
            type: MODAL_TYPE.ADD_OBSERVER_TABLE,
            title: "Создание наблюдателя таблицы",
            content: ObserverTableDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_OBSERVER_TABLE,
            title: "Изменение наблюдателя таблицы",
            content: ObserverTableDialogContext,
        },
        {
            type: MODAL_TYPE.DIRECTORY_INDEXING,
            title: "Индексация директории",
            content: DirectoryIndexingDialogContext,
            getTitle: async (directoryId: number) => {
                const directory = await fileService.getOneDirectory(directoryId);
                return `Индексация директории «${directory.title || ""}»`;
            },
        },
        {
            type: MODAL_TYPE.ADD_PRICE,
            title: "Добавить новую цену",
            content: PriceDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_PRICE,
            title: "Редактировать цену",
            content: PriceDialogContext,
            getTitle: async (priceId: number) => {
                const price = await storeService.getOneStorePrice(priceId);
                return `Редактировать цену для «${price?.sku || ""}»`;
            },
        },
        {
            type: MODAL_TYPE.ADD_QUANTITY,
            title: "Добавить новый остаток",
            content: QuantityDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_QUANTITY,
            title: "Редактировать остаток",
            content: QuantityDialogContext,
            getTitle: async (quantityId: number) => {
                const quantity = await storeService.getOneStoreQuantity(quantityId);
                return `Редактировать остаток для «${quantity?.sku || ""}»`;
            },
        },
        {
            type: MODAL_TYPE.ADD_DISCOUNT,
            title: "Добавить новый промокод",
            content: DiscountDialogContext,
            width: "md",
        },
        {
            type: MODAL_TYPE.EDIT_DISCOUNT,
            title: "Редактировать промокод",
            content: DiscountDialogContext,
            width: "md",
            getTitle: async (quantityId: number) => {
                const discount = await storeService.getOneDiscount(quantityId);
                return `Редактировать промокод «${discount?.title || ""}»`;
            },
        },
        {
            type: MODAL_TYPE.ADD_QUANTITY_RESERVE,
            title: "Добавить новый резерв",
            content: QuantityReserveDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_QUANTITY_RESERVE,
            title: "Редактировать резерв",
            content: QuantityReserveDialogContext,
            getTitle: async (quantityReserveId: number) => {
                const quantityReserve = await storeService.getOneQuantityReserve(quantityReserveId);
                return `Редактировать резерв для SKU «${quantityReserve?.sku || ""}»`;
            },
        },
        {
            type: MODAL_TYPE.ADD_RESERVE_STORE_BINDING,
            title: "Добавить новую привязку магазин-резерв",
            content: ReserveStoreBindingDialogContext,
        },
        {
            type: MODAL_TYPE.EDIT_RESERVE_STORE_BINDING,
            title: "Редактировать привязку",
            content: ReserveStoreBindingDialogContext,
            getTitle: async (storeId: number) => {
                const store = await storeService.getOneStore(storeId);
                return `Редактировать привязку для магазина «${store.store_name || ""}»`;
            },
        },
    ];
}

export default ModalManager;
