import * as Yup from "yup";

export const DiscountSchema = Yup.object().shape({
    title: Yup.string().required("Пожалуйста, укажите название"),
    max_percent: Yup.number()
        .min(0, "Значение не может быть меньше 0")
        .required("Пожалуйста, укажите максимальную скидку в %"),
    money: Yup.number().min(0, "Значение не может быть меньше 0"),
    percent: Yup.number().min(0, "Значение не может быть меньше 0"),
    sku: Yup.array().of(Yup.string().required("Пожалуйста, укажите значение")),
    discount_attribute_value_bindings: Yup.array().of(
        Yup.object().shape({
            at_id: Yup.number().nullable().required("Пожалуйста, выберите тип атрибута"),
            a_value: Yup.string().required("Пожалуйста, укажите значение"),
        })
    ),
});
