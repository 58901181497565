import { RowBlock } from "@interfaces/RowBlock";

export class CreateRowBlockDto {
    readonly table_name: string;
    readonly username: string;
    readonly e_id: number;

    constructor(rowBlock: RowBlock) {
        this.table_name = rowBlock.table_name;
        this.username = rowBlock.username;
        this.e_id = rowBlock.e_id;
    }
}
