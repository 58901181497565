import { Exclude, Expose } from "class-transformer";

@Exclude()
class CreateStaffOrderItemDto {
    @Expose()
    readonly price_id: number;

    @Expose()
    readonly staff_order_id: number;

    @Expose()
    readonly quantity: number;
}

export default CreateStaffOrderItemDto;
