import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateOrderDto {
    @Expose()
    readonly user_id: number;

    @Expose()
    readonly cart_item_ids: number[];

    @Expose()
    readonly cart_number: string;
}
