import { Button, ButtonProps } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";

type Props = { copyText: string };

const CopyToClipboardButton = ({ copyText, ...props }: ButtonProps & Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleCopy = () => {
        navigator.clipboard.writeText(copyText);
        document.execCommand("copy");

        enqueueSnackbar("Скопировано!", { variant: "info" });
    };

    return (
        <Button onClick={handleCopy} {...props}>
            Скопировать
        </Button>
    );
};

export default CopyToClipboardButton;
