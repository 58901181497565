import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { Entity } from "@interfaces/Entity";
import { entitiesService } from "@services";
import { typeBindService } from "@services/Settings";
import { DataTable } from "../Common";
import { PermissionManager, ProtectedPathsManager, ServiceHelper } from "@modules";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { Typography } from "@material-ui/core";
import { truncateText } from "@modules/Utils";
import { Common } from "@modules/Constants";
import { useAppPaths, useAsync, useCurrentPageInfo, useModal } from "@hooks";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { staffService } from "@services/Staff";

type EntitiesTableProps = Partial<{
    noDelete: boolean;
    noEdit: boolean;
    noRowClick: boolean;
    rowClick: "detail" | "child";
    /** filter by type_name from ns_type_binding */
    typeName: string | null;
    /** key: attribute name */
    filter: Record<string, string>;
    disableSelection?: boolean;
}>;

const EntitiesTable = ({
    noDelete = false,
    noEdit = false,
    noRowClick = false,
    rowClick = "detail",
    typeName,
    filter,
    disableSelection = false,
}: EntitiesTableProps) => {
    const history = useHistory();
    const { permission, param, url } = useCurrentPageInfo();
    const { menu } = useAppPaths();
    const { handleOpenModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();

    const { data: typeBind, loadingStatus } = useAsync(typeBindService.getOneTypeBind, {
        functionArgs: [undefined, { type_name: typeName }],
        canLoad: !!typeName,
        setLoadingStatusOnUpdate: false,
    });

    const fetchData = useCallback(
        async ({ ...params }) => {
            const etId = typeBind?.type_id || param;
            const schema = await entitiesService.getTypeSchema(etId);

            const res = await entitiesService.getEntities({
                et_id: etId,
                ...filter,
                ...params,
            });

            const permissionIds: string = res.result.items.map((item) => item.id).join(",");
            const permissions = permissionIds.length
                ? await staffService.getStaffEntityPermission({
                      ids_list: permissionIds,
                  })
                : null;

            const columns = [] as DataTableColumn<Entity>[];

            const data = res.result.items?.map((entity, i) => {
                if (permissions) {
                    entity.permissions = Array.isArray(permissions.result)
                        ? permissions.result[i]
                        : permissions.result;
                }
                entity.attrs.forEach((item) => {
                    entity[item.attr_name] = item.values;
                });
                return entity;
            });

            res.result.items = data || [];

            if (schema.length) {
                columns.push({
                    title: "#",
                    field: "id",
                    width: "auto",
                    defaultSort: "asc",
                });

                schema.forEach((el, i) => {
                    const column: DataTableColumn<Entity> = {
                        title: el.a_name,
                        field: el.a_name,
                        width: "auto",
                        hidden: i >= 4,
                        render: (rowData) => {
                            return (
                                <>
                                    {rowData[el.a_name]?.map((item) => {
                                        const value = item.display || item.value;

                                        return (
                                            <Typography key={item.value_id} variant="body2">
                                                {typeof value === "string"
                                                    ? truncateText(
                                                          value,
                                                          Common.TABLE_TEXT_TRUNCATE_LEN
                                                      )
                                                    : value}
                                            </Typography>
                                        );
                                    })}
                                </>
                            );
                        },
                    };

                    columns.push(column);
                });
            }

            return { data: res, columns };
        },
        [param, typeBind, filter]
    );

    const handleEditClick = async (rowData) => {
        try {
            await entitiesService.setEntityBlock(rowData.id);
            handleOpenModal("EDIT_ENTITY_BY_ENTITY_TYPE", {
                itemId: rowData.id,
                entityTypeId: rowData.et_id,
                titleProps: rowData.et_id,
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Сущность недоступна для редактирования", {
                variant: "info",
            });
        }
    };

    const handleDeleteClick = async (rowData: Entity | Entity[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await entitiesService.deleteEntity(ids);
            enqueueSnackbar("Сущность успешно удалена", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении сущности", {
                variant: "error",
            });
        }
    };

    const handleOpenDetailClick = (rowData) => {
        handleOpenModal("SHOW_ENTITY_BY_ENTITY_TYPE", {
            itemId: rowData.id,
            entityTypeId: rowData.et_id,
            titleProps: rowData.et_id,
            readOnly: true,
        });
    };

    const handleGoToChildPathClick = (rowData) => {
        const pages = ProtectedPathsManager.getChildPaths(menu, url);
        if (!pages.length) return;

        const child = pages[0];

        const childParam = child.url.split(":");
        const search = childParam.slice(-1)[0].split("?")[1] || "";

        if (childParam[1]) {
            history.push(`${history.location.pathname}/${rowData.id}?${search}`);
            return;
        }

        history.push(pages[0].url);
    };

    const getRowClickHandler = (e, rowData) => {
        switch (rowClick) {
            case "child": {
                return handleGoToChildPathClick(rowData);
            }
            case "detail": {
                return handleOpenDetailClick(rowData);
            }
        }
    };

    if (loadingStatus === "loading") return <Skeleton height={80} width="100%" />;

    return (
        <DataTable<Entity>
            // title={title}
            fetchFn={fetchData}
            dynamicColumns
            selection={!disableSelection}
            sorting
            search
            permission={permission}
            onEditClick={(!noEdit && handleEditClick) || undefined}
            onDeleteClick={(!noDelete && handleDeleteClick) || undefined}
            onRowClick={(!noRowClick && getRowClickHandler) || undefined}
            onMassDeleteClick={(!noDelete && handleDeleteClick) || undefined}
            isEditable={(rowData) => PermissionManager.canEdit(rowData.permissions)}
            isDeletable={(rowData) => PermissionManager.canDelete(rowData.permissions)}
            options={{
                selectionProps: (rowData) => ({
                    disabled: !PermissionManager.canDelete(rowData.permissions),
                }),
            }}
        />
    );
};

export default EntitiesTable;
