import { useState } from "react";

const useMousePosition = () => {
    const [state, setState] = useState<{
        mouseX: number | null;
        mouseY: number | null;
    }>({
        mouseX: null,
        mouseY: null,
    });

    return {
        mouseX: state.mouseX,
        mouseY: state.mouseY,
        setMousePosition: setState,
    };
};

export default useMousePosition;
