import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { ModalContentLoading } from "@components/Common/Modal";
import React, { useRef } from "react";
import { staffOrderService } from "@services/Staff";
import { useAsync } from "@hooks";
import { StaffOrderDetailForm } from "@components/Forms/StaffOrder";

const StaffOrderDetailDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = modalProps;

    const formRef = useRef<HTMLFormElement>();

    const { data, loadingStatus } = useAsync(staffOrderService.getOneStaffOrderDetail, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <StaffOrderDetailForm
                        ref={formRef}
                        data={data}
                        readOnly
                        onSubmit={async () => {}}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    Закрыть
                </Button>
            </DialogActions>
        </>
    );
};

export default StaffOrderDetailDialogContext;
