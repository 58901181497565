import { QueryParams, PaginatedQueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { CreateTypeBindDto, UpdateTypeBindDto } from "@dto/SettingsDto/TypeBindDto";
import { TypeBind } from "@interfaces/TypeBind";

class TypeBindService extends BaseService {
    public getTypeBind = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<TypeBind>> => {
        const { data } = await this.instance.get("/settings/ns_type_bind", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneTypeBind = async (id: number, params?: QueryParams): Promise<TypeBind> => {
        const { data } = await this.instance.get("/settings/ns_type_bind", {
            params: { id, ...params },
        });

        return data.result.items[0];
    };

    public createTypeBind = async (createTypeBindDto: CreateTypeBindDto): Promise<any> => {
        const { data } = await this.instance.post("/settings/ns_type_bind", {
            data: createTypeBindDto,
        });

        return data;
    };

    public updateTypeBind = async (
        id: number,
        updateTypeBindDto: UpdateTypeBindDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/settings/ns_type_bind", {
            id,
            data: updateTypeBindDto,
        });

        return data;
    };

    public deleteTypeBind = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/settings/ns_type_bind", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new TypeBindService(new HttpClient(Config.API_URL), AuthManager);
