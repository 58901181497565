import { EntityAutocomplete } from "@components/Controls/Autocomplete";
import { AppStaffCartItem } from "@interfaces/Internal/AppStaffCartItem";
import { Grid, IconButton, ListItem, TextField, Tooltip } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { getAttributeFromEntity } from "@modules/EntityAttributesManager";
import React from "react";

interface Props {
    onChange(key: string, field: string, value: any): void;
    item: AppStaffCartItem;
    onDelete(key: string): void;
    attributeSkuId: number;
    attributeTypeSkuName: string;
    entityFilter: Record<string, string>;
    eTypeId: number;
}

const StaffCartListItem = ({
    item,
    onDelete,
    onChange,
    attributeSkuId,
    eTypeId,
    entityFilter,
    attributeTypeSkuName,
}: Props) => {
    return (
        <ListItem disableGutters>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={7}>
                    <EntityAutocomplete
                        value={item.sku}
                        filter={entityFilter}
                        filterByEntityTypeId={eTypeId}
                        findBy={attributeTypeSkuName}
                        onChange={(name, entity) => {
                            const attribute = getAttributeFromEntity(
                                entity,
                                "atype_id",
                                attributeSkuId
                            );

                            onChange(item.key, name, attribute?.values[0].value || null);
                        }}
                        textFieldProps={{
                            fullWidth: true,
                            required: true,
                            size: "small",
                            margin: "normal",
                            variant: "outlined",
                            name: "sku",
                            label: "Товар",
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        size="small"
                        name="quantity"
                        value={item.quantity}
                        type="number"
                        onChange={(e) => onChange(item.key, e.target.name, +e.target.value || null)}
                        onBlur={(e) => onChange(item.key, e.target.name, +e.target.value || 1)}
                        defaultValue={1}
                        inputProps={{ min: 1 }}
                        variant="outlined"
                        margin="normal"
                        label="Количество"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Удалить" arrow>
                        <IconButton
                            style={{ marginLeft: 10 }}
                            size="small"
                            onClick={() => onDelete(item.key)}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default StaffCartListItem;
