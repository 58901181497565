import { eTypesService } from "@services";
import React from "react";
import { UniversalInputProps } from "@interfaces/System/UniversalInputProps";
import { AsyncAutocomplete } from "../Autocomplete";
import { EntityType } from "@interfaces/EntityType";

const UEntityTypeAutocomplete = (props: UniversalInputProps) => {
    const handleChange = (name: string, value: EntityType | null) => {
        props.onChange(name, value?.id || null);
    };

    return (
        <AsyncAutocomplete
            fetchFn={eTypesService.getEntityTypes}
            value={+props.value}
            onChange={handleChange}
            optionField="name"
            secondaryField="notes"
            textFieldProps={{
                disabled: props.disabled,
                label: props.label || "Выберите значение",
                margin: props.margin,
                fullWidth: props.fullWidth,
                error: props.error,
                helperText: props.helperText,
                variant: props.variant,
                size: props.size,
            }}
        />
    );
};

export default UEntityTypeAutocomplete;
