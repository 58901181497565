import { AttributeType } from "@interfaces/AttributeType";
import { Entity } from "@interfaces/Entity";
import { EntityAttribute } from "@interfaces/EntityAttribute";
import { EntityAttributeSchema } from "@interfaces/EntityAttributeSchema";

export enum AttributeTypeVariant {
    single = "single",
    multiple = "multiple",
    composite = "composite",
    composite_multiple = "composite_multiple",
}

export const getEntityAttributeVariant = (
    attr?: EntityAttribute | AttributeType | EntityAttributeSchema
): AttributeTypeVariant => {
    const defaultValue = AttributeTypeVariant.single;
    if (!attr) return defaultValue;

    if (!attr.composite_choice && !attr.multiple_choice) return AttributeTypeVariant.single;
    if (attr.multiple_choice && !attr.composite_choice) return AttributeTypeVariant.multiple;
    if (attr.composite_choice && !attr.multiple_choice) return AttributeTypeVariant.composite;
    if (attr.composite_choice && attr.multiple_choice)
        return AttributeTypeVariant.composite_multiple;

    return defaultValue;
};

export const getAttributeFromEntity = (
    entity: Entity,
    byField: keyof EntityAttribute,
    fieldValue: any
) => {
    if (!entity) return null;
    return entity.attrs.find((attr) => attr[byField] === fieldValue);
};
