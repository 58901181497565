import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        "@global": {
            "*": {
                scrollbarColor: "inherit",
                scrollbarWidth: "thin",
            },
            "html, body": {
                height: "100%",
                width: "100%",
            },
            "#root": {
                height: "100%",
            },
            "*::-webkit-scrollbar": {
                width: "0.7em",
                height: "0.7em",
            },
            "::-webkit-scrollbar-corner": {
                background: "rgba(0,0,0,0)",
            },
            "*::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.grey[600],
                borderRadius: 10,
                "&:hover": {
                    backgroundColor: theme.palette.grey[700],
                },
            },

            "::-ms-reveal, ::-ms-clear": {
                display: "none",
            },
            ".sun-editor": {
                borderColor: theme.palette.divider,
                "& .sun-editor-common": {
                    borderColor: theme.palette.divider,
                    "& .se-dialog-content": {
                        "& div": {
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            "& button": {
                                backgroundColor: theme.palette.background.default,
                                color: theme.palette.text.primary,
                                "&:enabled.active": {
                                    backgroundColor: theme.palette.primary.main,
                                },
                            },
                        },
                        "& form label": {
                            color: theme.palette.text.primary,
                        },
                    },
                    "& .se-submenu": {
                        backgroundColor: theme.palette.background.default,
                        "& .se-list-basic": {
                            "& li": {
                                "& .se-btn-list": {
                                    backgroundColor: theme.palette.background.default,
                                    color: theme.palette.text.primary,
                                    "&:enabled.active": {
                                        backgroundColor: theme.palette.primary.main,
                                    },
                                },
                            },
                        },
                    },
                    "& .se-file-browser-content": {
                        backgroundColor: theme.palette.background.default,
                    },
                },

                "& .se-toolbar, & .se-container, & .se-btn-group": {
                    backgroundColor: theme.palette.background.default,
                    "& .se-btn": {
                        color: theme.palette.text.primary,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.getContrastText(theme.palette.primary.main),
                        },
                        "&:enabled.active:hover": {
                            backgroundColor: theme.palette.action.selected,
                        },
                    },
                },
                "& .se-wrapper-inner, & .se-resizing-bar": {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    "& .se-navigation": {
                        color: theme.palette.text.primary,
                    },
                },
                "& .se-wrapper": {
                    "& .se-wrapper-wysiwyg": {
                        backgroundColor: theme.palette.background.default,
                    },
                },
            },
        },
    })
);

const GlobalStyles = () => {
    useStyles();

    return null;
};

export default GlobalStyles;
