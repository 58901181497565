import * as Yup from "yup";

export const StaffOrderItemSchema = Yup.object().shape({
    staff_order_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, выберите заказ"),
    price_id: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, выберите цену"),
    rejected: Yup.boolean(),
    quantity: Yup.number(),
});
