import { TextFieldProps } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useAsync } from "@hooks";
import { fileService } from "@services";
import { Directory } from "@interfaces/Directory";
import TreeSelect, { BranchNode, defaultInput, ValueNode } from "mui-tree-select";
import { Skeleton } from "@material-ui/lab";

type Value = { value: number; label: string };
type Branch = { branch: number; label: string; childs: Directory[] };

interface State {
    value: ValueNode<Value, Branch> | null;
    options: Array<BranchNode<Branch> | Value>;
    branch: BranchNode<Branch> | null;
    inputValue: string;
    hasInitialValue: boolean;
}

interface DirectoryTreeAutocompleteProps {
    textFieldProps: Omit<TextFieldProps, "variant">;
    onChange: (name, value) => void;
    value: number | null;
}

const DirectoryTreeAutocomplete = ({
    textFieldProps,
    onChange,
    value,
}: DirectoryTreeAutocompleteProps) => {
    const { name, disabled, size } = textFieldProps;

    const [state, setState] = useState<State>({
        value: null,
        options: [],
        branch: null,
        inputValue: "",
        hasInitialValue: !!value,
    });

    const generateOptions = (parentBranch: BranchNode<Branch>, items: Directory[]) => {
        const options: Array<BranchNode<Branch> | Value> = [];

        for (const item of items) {
            const { id, title } = item;

            if (id === 1) {
                options.push({
                    value: id,
                    label: title,
                });
                continue;
            }

            options.push(new BranchNode({ branch: id, label: title, childs: [] }, parentBranch), {
                value: id,
                label: title,
            });
        }

        return options;
    };

    const { data, loadingStatus } = useAsync(fileService.getDirectories, {
        setLoadingStatusOnUpdate: false,
        functionArgs: [
            {
                page: 1,
                page_size: 20,
                parent_dir_id: value === 1 ? undefined : state.branch?.valueOf().branch,
                ids_list: state.hasInitialValue ? value : undefined,
                q: state.inputValue || undefined,
            },
        ],

        fetchCallback: ({ result: { items } }) =>
            setState((prev) => ({
                ...prev,
                options: generateOptions(
                    new BranchNode({
                        branch: prev.branch?.valueOf().branch || 1,
                        label: prev.branch?.valueOf().label || "root",
                        childs: prev.branch?.valueOf().childs || [],
                    }),
                    items
                ),
            })),
    });

    useEffect(() => {
        const item = data?.result.items.find((el) => value === el?.id);

        setState((prev) => ({
            ...prev,
            value: item ? new ValueNode({ value: item.id, label: item.title }) : null,
        }));
    }, [value, data]);

    const getOptionLabel = useCallback(
        (option: BranchNode<Branch> | ValueNode<Value, Branch>) =>
            option instanceof BranchNode
                ? option.valueOf().label || ""
                : option.valueOf().label || "",
        []
    );

    const handleChangeInputValue = (e, inputValue, reason) => {
        if (reason === "clear") {
            setState((prev) => ({ ...prev, inputValue: "", hasInitialValue: false }));

            return;
        }

        if (value) return;

        if (reason === "input") {
            setState((prev) => ({ ...prev, inputValue }));
        }
    };

    if (loadingStatus === "loading") return <Skeleton width="100%" height={50} />;

    return (
        <TreeSelect<Value, Branch, false, false, false>
            branch={state.branch}
            onBranchChange={(_, branch) => {
                setState((prev) => ({
                    ...prev,
                    branch: branch,
                }));
            }}
            getOptionSelected={(option, value) => option.valueOf().value === value.valueOf().value}
            onInputChange={handleChangeInputValue}
            options={state.options}
            getOptionLabel={getOptionLabel}
            disabled={disabled}
            size={size}
            renderInput={(params) =>
                defaultInput({
                    ...params,
                    ...textFieldProps,
                    variant: "outlined",
                })
            }
            value={state.value}
            onChange={(_, value) => {
                setState((prev) => ({
                    ...prev,
                    value,
                }));
                onChange(name, value?.valueOf().value);
            }}
        />
    );
};

export default DirectoryTreeAutocomplete;
