import { GeneralSettings } from "@interfaces/GeneralSettings";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { GeneralSettingsSchema } from "@schema/Settings";
import { AutoSizeTextField } from "../../Controls/Inputs";
import { TextField } from "@material-ui/core";

const GeneralSettingsForm = forwardRef(
    ({ onSubmit, data }: CommonFormProps<GeneralSettings>, ref: any) => {
        const initialData = {
            name: data?.name || "",
            description: data?.description || "",
            key: data?.key || "",
            value: data?.value || "",
        } as GeneralSettings;

        return (
            <Formik
                initialValues={initialData}
                innerRef={ref}
                validationSchema={GeneralSettingsSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, values }) => {
                    return (
                        <Form>
                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.key}
                                required
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="key"
                                helperText={touched.key ? errors.key : ""}
                                error={touched.key && !!errors.key}
                                label="Ключ"
                            />

                            <AutoSizeTextField
                                fullWidth
                                onChange={handleChange}
                                value={values.name}
                                required
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="name"
                                helperText={touched.name ? errors.name : ""}
                                error={touched.name && !!errors.name}
                                label="Название"
                            />

                            <AutoSizeTextField
                                fullWidth
                                onChange={handleChange}
                                value={values.description}
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="description"
                                helperText="Необязательно"
                                label="Описание"
                                rows={2}
                            />

                            <TextField
                                fullWidth
                                onChange={handleChange}
                                value={values.value}
                                required
                                variant="outlined"
                                size="small"
                                margin="normal"
                                name="value"
                                helperText={touched.value ? errors.value : ""}
                                error={touched.value && !!errors.value}
                                label="Значение"
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default GeneralSettingsForm;
