import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { entitiesService } from "@services";
import { EntityEvents, EntityState } from "@store/entity-module";
import { ModalContentLoading } from "../../Common/Modal";
import EntityAttributesList from "../../Views/EntityAttributesList/EntityAttributesList";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { useAsync } from "@hooks";

const useStyles = makeStyles(() => ({
    content: {
        height: "80vh",
    },
}));

const ViewEntityByEntityTypeDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId, entityTypeId } = modalProps;

    const classes = useStyles();

    const { dispatch } = useStoreon<EntityState, EntityEvents>();

    const { data: entity, loadingStatus } = useAsync(entitiesService.getEntity, {
        functionArgs: [itemId, entityTypeId],
        canLoad: !!itemId && !!entityTypeId,
        fetchCallback: (value) => {
            if (!value) return;

            dispatch("entity/set/readonly", true);
        },
    });

    useEffect(() => {
        return () => {
            dispatch("entity/reset/attrs");
            dispatch("entity/set/readonly", false);
        };
    }, [dispatch]);

    return (
        <>
            <DialogContent className={classes.content}>
                {loadingStatus === "loading" && <ModalContentLoading />}
                {loadingStatus === "loaded" && entity && (
                    <EntityAttributesList entityTypeId={entityTypeId} entity={entity} />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose} variant="contained">
                    Закрыть
                </Button>
            </DialogActions>
        </>
    );
};

export default ViewEntityByEntityTypeDialogContext;
