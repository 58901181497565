import React from "react";
import { logService } from "@services/Settings";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo } from "@hooks";
import { Log } from "@interfaces/Log";
import { Typography } from "@material-ui/core";
import { truncateText } from "@modules/Utils";

const LogsTable = () => {
    const { permission } = useCurrentPageInfo();

    const columns: DataTableColumn<Log>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "desc",
            width: 100,
        },
        {
            title: "Пользователь",
            field: "username",
            width: "auto",
        },
        {
            title: "Роль",
            field: "role",
            width: "auto",
        },
        {
            title: "Таблица",
            field: "table_name",
            width: "auto",
        },
        {
            title: "Тип операции",
            field: "operation",
            width: "auto",
        },
        {
            title: "Текст",
            field: "text",
            width: "auto",
            render: (rowData) => (
                <Typography variant="body2" style={{ wordBreak: "break-word" }}>
                    {truncateText(rowData.text, 600)}
                </Typography>
            ),
        },
        {
            title: "Дата",
            field: "created_at",
            type: "datetime",
            filtering: false,
            width: 120,
        },
    ];

    return (
        <DataTable
            fetchFn={logService.getLogs}
            columns={columns}
            displayField="id"
            permission={permission}
            filtering
            sorting
            search
        />
    );
};

export default LogsTable;
