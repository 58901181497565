import { Box, Button, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { UpdateEntityTypeDto } from "@dto/EntityTypeDto";
import { CreateEntityTypeItemDto } from "@dto/EntityTypeItemDto";
import { ServiceHelper, ValidationManager } from "@modules";
import { eTypesService } from "@services";
import { ETypeEvents, ETypeState } from "@store/etype-module";
import { ModalContentLoading } from "../../Common/Modal";
import EntityTypeForm from "../../Forms/EntityTypeForm";
import EntityItemsActions from "../../Views/EntityTypeItems/EntityItemsActions";
import EntityItemsList from "../../Views/EntityTypeItems/EntityItemsList";
import withCloseListener from "../../../hoc/withCloseListener";
import { AppEntityTypeItem } from "@interfaces/Internal/AppEntityTypeItem";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import { useAsync, useLoading, useTable } from "@hooks";
import { Subtitle } from "@components/Views/Title";

const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
        height: "100vh",
        [breakpoints.up("md")]: {
            overflowY: "hidden",
        },
    },
    content: {
        height: "100%",
    },
    list: {
        height: "100%",
        overflow: "auto",
        padding: "0 .1rem",
        paddingBottom: "1rem",
    },
}));

const EditEntityTypeDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };
    const classes = useStyles();
    const formRef = useRef<any>();
    const { updateData } = useTable();
    const { validations, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("validations");
    const { itemsToRemove, attrsCount, items, dispatch } = useStoreon<ETypeState, ETypeEvents>(
        "itemsToRemove",
        "items"
    );
    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data: eType, loadingStatus } = useAsync(eTypesService.getEntityType, {
        functionArgs: [itemId],
        canLoad: !!itemId,
        fetchCallback: (value) => {
            if (!value) return;
            dispatch("etype/set/etypeid", value.id);
        },
    });

    const invokeSubmit = () => {
        validationDispatch("validation/toggle/validation", true);
        formRef.current.handleSubmit();
    };

    useEffect(() => {
        return () => {
            eTypesService.unsetEntityTypeBlock(Number(itemId));
            dispatch("etype/set/attrs_count", 0);
            dispatch("etype/reset/items");
            dispatch("etype/reset/items_to_remove");
            dispatch("etype/set/etypeid", null);
            validationDispatch("validation/toggle/validation", false);
            validationDispatch("validation/set/validations", {});
        };
    }, [itemId, dispatch, validationDispatch]);

    const handleUpdateEntityType = async (values) => {
        if (!itemId) return;

        if (!ValidationManager.isFinalObjectValid(validations)) return;

        setSubmitLoadingStatus("loading");
        try {
            if (itemsToRemove.length) {
                await eTypesService.deleteEntityTypeItems(itemsToRemove);
            }

            const itemsToCreate = ServiceHelper.getItemsForCreate<any>(items || []);
            if (itemsToCreate.length) {
                const itemsToCreateDto = itemsToCreate.map((el) =>
                    eTypesService.plainToClass(CreateEntityTypeItemDto, el)
                );

                await eTypesService.createEntityTypeItems(itemsToCreateDto);
            }

            const dto = eTypesService.plainToClass(UpdateEntityTypeDto, {
                ...values,
                items: items?.filter((el) => el.id) as AppEntityTypeItem[],
            });

            await eTypesService.updateEntityType(itemId, dto);
            enqueueSnackbar("Тип сущности успешно обновлен", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            setSubmitLoadingStatus("loaded");
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении типа сущности", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent className={classes.root}>
                {loadingStatus === "loading" && <ModalContentLoading />}
                {loadingStatus === "loaded" && eType && (
                    <Grid container spacing={2} className={classes.content}>
                        <Grid item xs={12} md={4}>
                            <Subtitle title="Общее" titleVariant="h6" bold />
                            <EntityTypeForm
                                onSubmit={handleUpdateEntityType}
                                entityType={eType}
                                ref={formRef}
                            />
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.content}>
                            <Subtitle
                                title="Типы атрибутов"
                                titleVariant="h6"
                                bold
                                endAdornment={
                                    <EntityItemsActions
                                        itemsCount={items?.length ?? 0}
                                        attrsCount={attrsCount}
                                    />
                                }
                            />
                            <Box className={classes.list}>
                                <EntityItemsList etypeId={modalProps?.itemId} />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default withCloseListener(EditEntityTypeDialogContext);
