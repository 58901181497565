import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ValidationManager } from "@modules";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { CreateEntityDto } from "@dto/EntityDto";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { entitiesService } from "@services";
import { EntityEvents, EntityState } from "@store/entity-module";
import { TableEvents, TableState } from "@store/table-module";
import EntityAttributesList from "@components/Views/EntityAttributesList/EntityAttributesList";
import { useLoading } from "@hooks";

const useStyles = makeStyles(() => ({
    content: {
        height: "80vh",
    },
}));

const CreateEntityByEntityTypeDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { entityTypeId } = modalProps;
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const { attrs, dispatch } = useStoreon<EntityState, EntityEvents>("attrs");
    const { validations, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("validations");
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    useEffect(() => {
        return () => {
            dispatch("entity/reset/attrs");
            validationDispatch("validation/toggle/validation", false);
            validationDispatch("validation/set/validations", {});
        };
    }, [dispatch, validationDispatch]);

    const handleCreateEntity = async () => {
        validationDispatch("validation/toggle/validation", true);

        if (!ValidationManager.isFinalObjectValid(validations)) {
            enqueueSnackbar(
                "Не все поля заполнены корректно. Проверьте правильность ввода данных",
                {
                    variant: "error",
                }
            );
            return;
        }

        setSubmitLoadingStatus("loading");
        try {
            const dto = entitiesService.plainToClass(CreateEntityDto, { entityTypeId, attrs });
            await entitiesService.createEntity(dto);
            enqueueSnackbar("Сущность успешно создана", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;

            enqueueSnackbar(message || "Произошла ошибка при создании сущности", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent className={classes.content}>
                <EntityAttributesList entityTypeId={entityTypeId} />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose} variant="text">
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleCreateEntity}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateEntityByEntityTypeDialogContext;
