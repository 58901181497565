import { PreloaderScreen } from "../components/Views/Preloader";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useStoreon } from "storeon/react";
import useAsync from "../hooks/useAsync";
import { AuthManager } from "@modules";
import { authService } from "@services";
import { AuthEvents, AuthState } from "@store/auth-module";
import { store } from "@store";

const withAuth = (WrappedComponent, redirectPath: string) => (props) => {
    const { token } = useStoreon<AuthState, AuthEvents>("token");
    const { data, loadingStatus } = useAsync(authService.checkAuthToken, {
        canLoad: !!token,
    });

    useEffect(() => {
        if (loadingStatus === "loaded" && !data?.status) {
            AuthManager.logout(store);
        }
    }, [loadingStatus, data]);

    if (loadingStatus === "loading") return <PreloaderScreen />;

    return token && data?.status ? <WrappedComponent {...props} /> : <Redirect to={redirectPath} />;
};

export default withAuth;
