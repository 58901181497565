import { QueryResult, QueryParams, PaginatedQueryResult, QueryResultWithoutData } from ".";
import BaseService from "./BaseService";
import { File as AppFile } from "@interfaces/File";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { Directory } from "@interfaces/Directory";
import { CreateDirectoryDto, UpdateDirectoryDto } from "@dto/DirectoryDto";
import { UpdateFileInfoDto } from "@dto/FileDto";
import { IndexingStatus } from "@interfaces/IndexingStatus";

class FileService extends BaseService {
    public getFiles = async (params: QueryParams = {}): Promise<QueryResult<AppFile[]>> => {
        const { data } = await this.instance.get("/file_storage/files", {
            params,
        });

        return data;
    };

    public getOneFile = async (id: number): Promise<AppFile> => {
        const { data } = await this.instance.get("/file_storage/files", {
            params: { id },
        });

        return data.result.items[0];
    };

    /** returns id of the uploaded file */
    public uploadFile = async (
        file: File | File[],
        options: {
            /** @deprecated */
            name?: string;
            /** @deprecated */
            description?: string;
            directoryId?: number;
        } = {}
    ): Promise<number> => {
        const formData = new FormData();

        formData.append("dir_id", options.directoryId?.toString() || "");

        if (file instanceof Array) {
            file.forEach((el) => {
                formData.append("file", el);
            });
        } else {
            formData.append("file", file);
        }

        const { data } = await this.instance.post("/file_storage/files", formData);

        return data;
    };

    public updateFileInfo = async (
        id: number,
        updateFileInfoDto: UpdateFileInfoDto
    ): Promise<QueryResult<File>> => {
        const { data } = await this.instance.patch("/file_storage/files", {
            id,
            data: updateFileInfoDto,
        });

        return data;
    };

    public deleteFiles = async (fileIds: number[]): Promise<QueryResult<File>> => {
        const { data } = await this.instance.delete("/file_storage/files", {
            data: { ids_list: fileIds },
        });

        return data;
    };

    /** File Storage */

    public getDirectoriesTree = async ({ ...params }: QueryParams = {}): Promise<Directory> => {
        const { data } = await this.instance.get("/file_storage/directory_tree", {
            params,
        });

        return data.result;
    };

    public getDirectories = async ({ ...params }: QueryParams = {}): Promise<
        PaginatedQueryResult<Directory>
    > => {
        const { data } = await this.instance.get("/file_storage/directory", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneDirectory = async (directoryId: number): Promise<Directory> => {
        const { data } = await this.instance.get("/file_storage/directory_tree", {
            params: { id: directoryId },
        });

        return data.result;
    };

    public createDirectory = async (
        createDirectoryDto: CreateDirectoryDto
    ): Promise<QueryResult<Directory>> => {
        const { data } = await this.instance.post("/file_storage/directory_tree", {
            data: createDirectoryDto,
        });

        return data;
    };

    public updateDirectory = async (
        id: number,
        updateDirectoryDto: UpdateDirectoryDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/file_storage/directory_tree", {
            id,
            data: updateDirectoryDto,
        });

        return data;
    };

    public deleteDirectories = async (
        directoriesIds: number[]
    ): Promise<QueryResult<Directory>> => {
        const { data } = await this.instance.delete("/file_storage/directory_tree", {
            data: { ids_list: directoriesIds },
        });

        return data;
    };

    public getFilesByDirectory = async (
        directoryId: number,
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<AppFile>> => {
        const { data } = await this.instance.get("/file_storage/files", {
            params: { dir_id: directoryId, ...params },
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getIndexingStatuses = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<IndexingStatus>> => {
        const { data } = await this.instance.get("/file_storage/indexing", {
            params: { ...params },
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public createIndexingTask = async (directoryId: number): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.post("/file_storage/indexing", {
            dir_id: directoryId,
        });

        return data;
    };

    /** End File Storage */
}

export default new FileService(new HttpClient(Config.API_URL), AuthManager);
