import { AppAttribute } from "@interfaces/Internal/AppAttribute";
import { Typography } from "@material-ui/core";
import { ValidationEvents, ValidationState } from "@store/validation-module";
import React, { useEffect } from "react";
import { useStoreon } from "storeon/react";
import { RestrictionsManager, ValidationManager } from "@modules";
import { EntityEvents, EntityState } from "@store/entity-module";
import { UniversalRestrictionChecker } from "@components/Common";
import UniversalInputField from "@components/Common/UniversalInputField";
import { useAsync } from "@hooks";
import { vTypesService } from "@services";
import EntityAttributeItemSkeleton from "../EntityAttributeItemSkeleton";

interface EntityAttributeItemSingleProps {
    attribute: AppAttribute;
}

const EntityAttributeItemSingle = ({ attribute }: EntityAttributeItemSingleProps) => {
    const { readOnly, dispatch } = useStoreon<EntityState, EntityEvents>("readOnly");
    const { enableValidation, dispatch: validationDispatch } =
        useStoreon<ValidationState, ValidationEvents>("enableValidation");

    const item = attribute.items[0];

    const { data: vType, loadingStatus } = useAsync(vTypesService.getValueType, {
        canLoad: !!item?.vt_id,
        functionArgs: [item.vt_id],
    });

    const handleChangeAttributeValue = (name: string, value: any) => {
        dispatch("entity/attr/update/value", {
            at_id: attribute.atype_id,
            valueKey: item?.key || "",
            value,
        });
    };

    const { valid, messages } = ValidationManager.validateEntityAttributeValue(
        item?.value,
        item?.restrictions
    );

    const useValidation = ValidationManager.isValidationRequired(attribute.required, item);

    useEffect(() => {
        validationDispatch("validation/add/validations", {
            [attribute.atype_id]: useValidation ? valid : true,
        });
    }, [validationDispatch, attribute, useValidation, valid]);

    if (loadingStatus === "loading") return <EntityAttributeItemSkeleton />;

    return (
        <UniversalRestrictionChecker
            key={attribute.atype_id}
            restrictions={item?.restrictions}
            render={(inputTypeName, { entityRelationTypeId }) => {
                return (
                    <UniversalInputField
                        vTypeId={item?.vt_id}
                        findBy={item.edited ? "id" : "attrValue"}
                        responseValue="id"
                        inputTypeName={inputTypeName}
                        label={vType?.name}
                        name={vType?.name || ""}
                        value={item?.value || ""}
                        fullWidth
                        margin="normal"
                        disabled={readOnly}
                        onChange={handleChangeAttributeValue}
                        variant="outlined"
                        size="small"
                        filterByEntityTypeId={entityRelationTypeId}
                        error={enableValidation && useValidation && !valid}
                        helperText={
                            <>
                                {item?.restrictions.map((rest) => {
                                    const errorMessage =
                                        enableValidation && useValidation && messages[rest.code];

                                    return (
                                        <Typography
                                            key={rest.key}
                                            variant="caption"
                                            display="block"
                                            color={errorMessage ? "error" : "textSecondary"}
                                        >
                                            {errorMessage ||
                                                RestrictionsManager.getRestrictionItemByCode(
                                                    rest.code
                                                )?.getHelpText(rest.value)}
                                        </Typography>
                                    );
                                })}
                            </>
                        }
                    />
                );
            }}
        />
    );
};

export default EntityAttributeItemSingle;
