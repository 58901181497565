import { ModalProps } from "@interfaces/System/ModalProps";
import { ModalType } from "@modules/ModalManager";
import { ModalEvents, ModalState } from "@store/modal-module";
import { useCallback } from "react";
import { useStoreon } from "storeon/react";

type UseModalControls = { handleOpenModal(type: ModalType, props?: ModalProps): void };

const useModal = (): UseModalControls => {
    const { dispatch } = useStoreon<ModalState, ModalEvents>();

    const handleOpenModal = useCallback(
        (type: ModalType, props: ModalProps = {}) => {
            dispatch("modal/set/props", props);
            dispatch("modal/set/type", type);
            dispatch("modal/toggle", true);
        },
        [dispatch]
    );

    return { handleOpenModal };
};

export default useModal;
