import { PaginatedQueryResult, QueryParams, QueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { CreateStaffOrderItemDto, UpdateStaffOrderItemDto } from "@dto/StaffOrderItemDto";
import { StaffOrderItem } from "@interfaces/StaffOrderItem";
import { StaffOrderDetail } from "@interfaces/StaffOrderDetail";
import { CreateStaffOrderDto, UpdateStaffOrderDto } from "@dto/StaffOrderDto";
import { StaffOrder } from "@interfaces/StaffOrder";

class StaffOrderService extends BaseService {
    public getStaffOrders = async (
        params: QueryParams
    ): Promise<PaginatedQueryResult<StaffOrder>> => {
        const { data } = await this.instance.get("/staff_order", { params });

        return data;
    };

    public getOneStaffOrder = async (id: number): Promise<StaffOrder> => {
        const { data } = await this.instance.get("/staff_order", { params: { ids_list: id } });

        return data.result.items[0];
    };

    public createStaffOrder = async (
        createStaffOrderDto: CreateStaffOrderDto
    ): Promise<QueryResult<StaffOrder>> => {
        const { data } = await this.instance.post("/staff_order", createStaffOrderDto);

        return data;
    };

    public updateStaffOrder = async (
        id: number,
        updateStaffOrderDto: UpdateStaffOrderDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/staff_order", {
            id,
            data: updateStaffOrderDto,
        });

        return data;
    };

    public deleteStaffOrders = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/staff_order", { data: { ids_list: ids } });

        return data;
    };

    public getOneStaffOrderDetail = async (id: number): Promise<StaffOrderDetail> => {
        const { data } = await this.instance.get("/staff_order/details", {
            params: { ids_list: id },
        });

        return data.result.items[0];
    };

    public getStaffOrderItems = async (
        params: QueryParams
    ): Promise<PaginatedQueryResult<StaffOrderItem>> => {
        const { data } = await this.instance.get("/staff_order_item", { params });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneStaffOrderItem = async (id: number): Promise<StaffOrderItem> => {
        const { data } = await this.instance.get("/staff_order_item", { params: { ids_list: id } });

        return data.result.items[0];
    };

    public createStaffOrderItem = async (
        createStaffOrderItemDto: CreateStaffOrderItemDto
    ): Promise<QueryResult<StaffOrderItem>> => {
        const { data } = await this.instance.post("/staff_order_item", createStaffOrderItemDto);

        return data;
    };

    public updateStaffOrderItem = async (
        id: number,
        updateStaffOrderItemDto: UpdateStaffOrderItemDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/staff_order_item", {
            id,
            data: updateStaffOrderItemDto,
        });

        return data;
    };

    public deleteStaffOrderItems = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/staff_order_item", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new StaffOrderService(new HttpClient(Config.API_URL), AuthManager);
