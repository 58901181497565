import { StoreonModule } from "storeon";

export interface ValidationState {
    enableValidation: boolean;
    validations: { [key: string]: boolean };
}

export interface ValidationEvents {
    "validation/toggle/validation": boolean;
    "validation/add/validations": { [key: string]: boolean };
    "validation/set/validations": { [key: string]: boolean };
    "validation/remove/validation": string;
}

export const validationModule: StoreonModule<ValidationState, ValidationEvents> = (store) => {
    store.on("@init", () => ({
        enableValidation: false,
        validations: {} as any,
    }));

    store.on("validation/toggle/validation", (state, value) => ({
        enableValidation: value,
    }));

    store.on("validation/set/validations", (state, value) => ({
        validations: value,
    }));

    store.on("validation/add/validations", (state, value) => {
        const copy = { ...state.validations, ...value };
        return {
            validations: copy,
        };
    });

    store.on("validation/remove/validation", (state, key) => {
        const copy = { ...state.validations };

        delete copy[key];

        return {
            validations: copy,
        };
    });
};
