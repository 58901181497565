import React from "react";
import { UniversalInputProps } from "../../../interfaces/System/UniversalInputProps";
import EntityAutocomplete from "../Autocomplete/EntityAutocomplete";

export type UEntityAutocompleteExtraProps = Partial<{
    findBy: "id" | "attrValue";
    responseValue: "id" | "attrValue";
    filterByEntityTypeId: number;
}>;

type UEntityAutocompleteProps = UEntityAutocompleteExtraProps;

const UEntityAutocomplete = ({
    filterByEntityTypeId,
    findBy = "id",
    responseValue = "id",
    ...props
}: UEntityAutocompleteProps & UniversalInputProps) => {
    const handleChange = (name: string, value: any) => {
        props.onChange(name, responseValue === "id" ? value?.id : value?.primaryField);
    };

    return (
        <EntityAutocomplete
            value={props.value}
            onChange={handleChange}
            filterByEntityTypeId={filterByEntityTypeId}
            findBy={findBy === "id" ? "ids_list" : "q"}
            textFieldProps={{
                disabled: props.disabled,
                label: props.label || "Выберите значение",
                margin: props.margin,
                fullWidth: props.fullWidth,
                error: props.error,
                helperText: props.helperText,
                variant: props.variant,
                size: props.size,
            }}
        />
    );
};

export default UEntityAutocomplete;
