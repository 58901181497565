import {
    UCheckbox,
    UDatePicker,
    UTimePicker,
    UNumberField,
    UTextAutoSizeField,
    UTextField,
    UDateTimePicker,
    UEntityAutocomplete,
    URichTextEditor,
    UEntityTypeAutocomplete,
    UTreeFileAutocomplete,
    UFileAutocomplete,
} from "@components/Controls/Universal";

export enum InputType {
    TEXT = "text",
    AUTOSIZE_TEXT = "autosize_text",
    NUMBER = "number",
    CHECKBOX = "checkbox",
    DATE_PICKER = "date_picker",
    TIME_PICKER = "time_picker",
    DATE_TIME_PICKER = "date_time_picker",
    FILE = "file",
    TREE_FILE = "tree_file",
    RTE = "rte",
    ENTITY_AUTOCOMPLETE = "et_id",
    ENTITY_TYPE_AUTOCOMPLETE = "etype_autocomplete",
}

export interface InputTypeItem {
    type: InputType;
    name: string;
    /** hide field on select available input fields */
    private?: boolean;
    description?: string;
    component: any;
}

class InputTypesManager {
    static getInputTypeByCode = (type: InputType) => {
        return InputTypesManager.INPUT_TYPES.find((el) => el.type === type);
    };

    static readonly INPUT_TYPES: InputTypeItem[] = [
        {
            type: InputType.TEXT,
            name: "Текстовое поле",
            component: UTextField,
        },
        {
            type: InputType.AUTOSIZE_TEXT,
            name: "Авторасширяемое текстовое поле",
            component: UTextAutoSizeField,
        },
        {
            type: InputType.NUMBER,
            name: "Числовое поле",
            component: UNumberField,
        },
        {
            type: InputType.DATE_PICKER,
            name: "Поле для выбора даты",
            component: UDatePicker,
        },
        {
            type: InputType.TIME_PICKER,
            name: "Поле для выбора времени",
            component: UTimePicker,
        },
        {
            type: InputType.DATE_TIME_PICKER,
            name: "Поле для выбора даты и времени",
            component: UDateTimePicker,
        },
        {
            type: InputType.FILE,
            name: "Файл",
            component: UFileAutocomplete,
        },
        {
            type: InputType.TREE_FILE,
            name: "Файл (древовидное)",
            component: UTreeFileAutocomplete,
        },
        {
            type: InputType.CHECKBOX,
            name: "Чекбокс",
            component: UCheckbox,
        },
        {
            type: InputType.RTE,
            name: "Текстовый редактор",
            component: URichTextEditor,
        },
        {
            type: InputType.ENTITY_AUTOCOMPLETE,
            private: true,
            name: "Ссылка на сущность",
            component: UEntityAutocomplete,
        },
        {
            type: InputType.ENTITY_TYPE_AUTOCOMPLETE,
            name: "Ссылка на тип сущности",
            component: UEntityTypeAutocomplete,
        },
    ];
}

export default InputTypesManager;
