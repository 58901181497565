import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateInnBindDto {
    @Expose()
    readonly staff_id: number;

    @Expose()
    readonly inn: string;
}
