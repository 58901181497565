import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import AuthForm from "../components/Views/Auth/AuthForm";
import useTitle from "../hooks/useTitle";
import { PathsManager } from "../modules";

const LoginPage = ({ title = PathsManager.PATHS.LOGIN.title }) => {
    useTitle(title);

    return (
        <Container>
            <Box display="flex" justifyContent="center">
                <Grid item xs={12} sm={8} md={5} lg={4}>
                    <AuthForm />
                </Grid>
            </Box>
        </Container>
    );
};

export default LoginPage;
