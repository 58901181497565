import {
    useCurrentPageInfo,
    useAsync,
    useCombinedContentType,
    useAppPaths,
    useModal,
} from "@hooks";
import ContentTypesManager, {
    CONTENT_TYPE,
    ContextHelpOptions,
    UniqueField,
} from "@modules/ContentTypesManagers/ContentTypesManager";
import { useCallback, useEffect, useState } from "react";
import { staffContextHelpService } from "@services/Staff";
import { CombinedType } from "@modules/ContentTypesManagers/CombinedContentTypesManager";
import { PermissionManager, ProtectedPathsManager } from "@modules";
import { LoadingStatus } from "./useLoading";
import { ProtectedPath } from "@interfaces/ProtectedPath";

type UseContextHelpState = {
    canModify: boolean;
    options: ContextHelpOptions;
};

const defaultOptions: ContextHelpOptions = {
    uniqueField: "staff_menu_item_id",
};

export type UseContextHelpResult = {
    uniqueField: string;
    html?: string;
    onContextClickHandler(
        e: React.MouseEvent<HTMLElement>,
        overrideUniqueField?: UniqueField,
        overrideUniqueFieldValue?: any
    ): void;
    loadingStatus: LoadingStatus;
    canModify: boolean;
};

type UseContextHelpConfig = Partial<{
    overrideContentType: CombinedType;
    autoLoad: boolean;
}>;

const useContextHelp = ({ overrideContentType, autoLoad = false }: UseContextHelpConfig = {}) => {
    const { handleOpenModal } = useModal();
    const { menu } = useAppPaths();
    const { content_type, page_id, param } = useCurrentPageInfo();
    const contentType = overrideContentType ?? content_type;
    const type = useCombinedContentType(contentType);
    const [state, setState] = useState<UseContextHelpState>({
        canModify: false,
        options: defaultOptions,
    });

    const uniqueField = state.options.uniqueField;

    const getValueByUniqueField = useCallback(
        (uniqueField): number => {
            const path = ProtectedPathsManager.getPath(
                menu,
                contentType,
                "content_type"
            ) as ProtectedPath;

            switch (uniqueField) {
                case "entity_types_id":
                    return param;
                case "staff_menu_item_id":
                    return page_id ?? path?.id;
                default:
                    return page_id ?? path?.id;
            }
        },
        [menu, contentType, page_id, param]
    );

    const { data, loadingStatus, updateData } = useAsync(
        staffContextHelpService.getOneContextHelp,
        {
            functionArgs: [
                {
                    [uniqueField]: getValueByUniqueField(uniqueField) || undefined,
                },
            ],
            canLoad: !!uniqueField && autoLoad,
        }
    );

    useEffect(() => {
        let options = defaultOptions;

        if (type) {
            const content =
                type.content instanceof Array
                    ? ContentTypesManager.getContentType(type.content[0])?.content
                    : type.content;
            if (!content) return;

            options =
                (content.getContextHelpOptions && content.getContextHelpOptions()) ||
                defaultOptions;
        }

        const ctxHelpPermission = PermissionManager.getPermissionFromProtectedPaths(
            menu,
            CONTENT_TYPE.STAFF_MENU,
            "content_type"
        );

        const canModify = PermissionManager.canCreate(ctxHelpPermission);

        setState({ canModify, options });
    }, [type, menu]);

    const onContextClickHandler = useCallback(
        (
            e: React.MouseEvent<HTMLElement>,
            overrideUniqueField?: UniqueField,
            overrideUniqueFieldValue?: any
        ) => {
            handleOpenModal("CONTEXT_HELP", {
                uniqueField: overrideUniqueField ?? uniqueField,
                uniqueFieldValue: overrideUniqueFieldValue ?? getValueByUniqueField(uniqueField),
                type: state.canModify ? "create" : "view",
                onUpdate: updateData,
            });
        },
        [handleOpenModal, updateData, state.canModify, uniqueField, getValueByUniqueField]
    );

    const contextHelpResult: UseContextHelpResult = {
        uniqueField,
        html: data?.result?.html,
        onContextClickHandler,
        loadingStatus,
        canModify: state.canModify,
    };

    return contextHelpResult;
};

export default useContextHelp;
