import { Store } from "@interfaces/Store";
import React from "react";
import { storeService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo, useModal } from "@hooks";

const StoresTable = () => {
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_STORE", {
            itemId: rowData.id,
            titleProps: rowData.id,
        });
    };

    const columns: DataTableColumn<Store>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            align: "left",
            type: "numeric",
            width: 100,
        },
        {
            title: "ID Магазина",
            field: "store_id",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "Сортировка",
            field: "sorting",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "Является магазином",
            field: "is_shop",
            type: "boolean",
            width: 10,
        },
        {
            title: "Учитывать остатки",
            field: "is_countable",
            type: "boolean",
            width: 10,
        },
        {
            title: "Название",
            field: "store_name",
            width: "auto",
        },
        {
            title: "Отображаемое название",
            field: "shown_name",
            width: "auto",
        },
        {
            title: "Комментарий",
            field: "comment",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={storeService.getStores}
            columns={columns}
            displayField="store_name"
            permission={permission}
            onEditClick={handleEditClick}
            filtering
            sorting
            search
        />
    );
};

export default StoresTable;
