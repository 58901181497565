import { StaffMember } from "@interfaces/StaffMember";
import { Typography } from "@material-ui/core";
import React from "react";
import { useCurrentPageInfo, useModal } from "@hooks";
import { PermissionManager, Utils } from "@modules";
import { staffService } from "@services/Staff";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import StatusSymbol from "../Views/StatusSymbol/StatusSymbol";

const StaffMembersTable = () => {
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_STAFF_MEMBER", { itemId: rowData.id });
    };

    const handleChangePasswordClick = (e, rowData) => {
        handleOpenModal("CHANGE_STAFF_MEMBER_PASSWORD", { itemId: rowData.id });
    };

    const columns: DataTableColumn<StaffMember>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Логин",
            field: "login",
            width: "auto",
        },
        {
            title: "Email",
            field: "email",
            width: "auto",
        },
        {
            title: "ФИО",
            field: "fullname",
            sorting: false,
            render: (rowData) => {
                return <Typography variant="body2">{Utils.getUserFullName(rowData)}</Typography>;
            },
            width: "auto",
        },
        {
            title: "Роль",
            field: "memberof",
            render: (rowData) => {
                return (
                    <>
                        {rowData.memberof.map((el) => (
                            <Typography variant="body2" key={el}>
                                {el}
                            </Typography>
                        ))}
                    </>
                );
            },
            width: "auto",
        },
        {
            title: "Статус",
            field: "is_active",
            render: (rowData) => {
                return <StatusSymbol isActive={rowData.is_active} />;
            },
            width: "auto",
        },
        {
            title: "Дата создания",
            field: "created_at",
            type: "date",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={staffService.getAllStaffMembers}
            columns={columns}
            displayField="email"
            permission={permission}
            onEditClick={handleEditClick}
            sorting
            search
            customActions={[
                {
                    name: "change_password",
                    tooltip: "Изменить пароль",
                    icon: "security",
                    iconProps: {
                        fontSize: "small",
                    },
                    onClick: handleChangePasswordClick,
                    disabled: !PermissionManager.canEdit(permission),
                },
            ]}
        />
    );
};

export default StaffMembersTable;
