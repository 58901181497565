import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { PasswordField, PhoneNumberInput } from "../../Controls/Inputs";
import { UDatePicker } from "../../Controls/Universal";
import { CreateUserSchema, UpdateUserSchema } from "@schema/User";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { User } from "@interfaces/User";
import { GenderSelect } from "@components/Controls/Radio";

const UserForm = forwardRef(({ onSubmit, data }: CommonFormProps<User>, ref: any) => {
    const initialData = {
        username: data?.username ?? "",
        email: data?.email ?? "",
        password: data ? undefined : "",
        first_name: data?.first_name ?? "",
        last_name: data?.last_name ?? "",
        gender: data?.gender ?? "male",
        loyalty_card: data?.loyalty_card ?? "",
        phone: data?.phone ?? "",
        birthday: data?.birthday ?? "",
        is_active: data?.is_active,
    } as User;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={data ? UpdateUserSchema : CreateUserSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, setFieldValue }) => {
                return (
                    <Form>
                        <TextField
                            name="username"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            helperText={touched.username ? errors.username : ""}
                            error={touched.username && !!errors.username}
                            label="Имя пользователя"
                            value={values.username}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="email"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            helperText={touched.email ? errors.email : ""}
                            error={touched.email && !!errors.email}
                            label="Электронная почта"
                            value={values.email}
                            onChange={handleChange}
                            fullWidth
                        />

                        {data && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.is_active}
                                        onChange={handleChange}
                                        name="is_active"
                                        color="primary"
                                    />
                                }
                                label="Активен"
                            />
                        )}

                        {!data && (
                            <PasswordField
                                name="password"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                required
                                helperText={touched.password ? errors.password : ""}
                                error={touched.password && !!errors.password}
                                label="Пароль"
                                value={values.password}
                                onChange={handleChange}
                                fullWidth
                            />
                        )}

                        <TextField
                            name="last_name"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            helperText={touched.last_name ? errors.last_name : ""}
                            error={touched.last_name && !!errors.last_name}
                            label="Фамилия"
                            value={values.last_name}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="first_name"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            helperText={touched.first_name ? errors.first_name : ""}
                            error={touched.first_name && !!errors.first_name}
                            label="Имя"
                            value={values.first_name}
                            onChange={handleChange}
                            fullWidth
                        />

                        <GenderSelect
                            name="gender"
                            value={values.gender}
                            onChange={setFieldValue}
                            formControlProps={{
                                margin: "normal",
                            }}
                        />

                        <UDatePicker
                            name="birthday"
                            size="small"
                            required
                            variant="outlined"
                            margin="normal"
                            helperText={touched.birthday ? errors.birthday : ""}
                            error={touched.birthday && !!errors.birthday}
                            label="Дата рождения"
                            pickerProps={{ maxDate: new Date() }}
                            value={values.birthday}
                            onChange={setFieldValue}
                            fullWidth
                        />

                        <PhoneNumberInput
                            name="phone"
                            variant="outlined"
                            required
                            size="small"
                            margin="normal"
                            helperText={touched.phone ? errors.phone : ""}
                            error={touched.phone && !!errors.phone}
                            label="Номер телефона"
                            value={values.phone}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="loyalty_card"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            helperText={touched.loyalty_card ? errors.loyalty_card : ""}
                            error={touched.loyalty_card && !!errors.loyalty_card}
                            label="Карта лояльности"
                            value={values.loyalty_card}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default UserForm;
