import React, { useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { common } from "@material-ui/core/colors";
import { CloudDownload } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up("sm")]: {
            minWidth: "344px !important",
        },
    },
    card: {
        backgroundColor: theme.palette.primary.main,
        width: "100%",
    },
    typography: {
        fontWeight: "bold",
        color: common.white,
    },
    actionRoot: {
        padding: "8px 8px 8px 16px",
        display: "flex",
        justifyContent: "space-between",
    },
    icons: {
        marginLeft: "auto",
    },
    expand: {
        color: common.white,
        padding: "8px 8px",
        transform: "rotate(0deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    collapse: {
        padding: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    checkIcon: {
        fontSize: 20,
        color: "#b3b3b3",
        paddingRight: 4,
    },
    button: {
        textTransform: "none",
    },
}));

interface Props {
    onDownload: () => void;
    id: number | string;
    message: any;
}

const DownloadSnackbar = React.forwardRef((props: Props, ref: any) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDismiss = () => {
        closeSnackbar(props.id);
    };

    return (
        <div ref={ref} className={classes.root}>
            <Card className={classes.card}>
                <CardActions classes={{ root: classes.actionRoot }}>
                    <Typography variant="subtitle2" className={classes.typography} color="inherit">
                        {props.message}
                    </Typography>
                    <div className={classes.icons}>
                        <IconButton
                            aria-label="Show more"
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                        <IconButton className={classes.expand} onClick={handleDismiss}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Paper className={classes.collapse}>
                        <Typography gutterBottom>Готово к скачиванию</Typography>
                        <Button
                            size="small"
                            color="primary"
                            className={classes.button}
                            onClick={props.onDownload}
                        >
                            <CloudDownload className={classes.checkIcon} />
                            Скачать
                        </Button>
                    </Paper>
                </Collapse>
            </Card>
        </div>
    );
});

export default DownloadSnackbar;
