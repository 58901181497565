import { TextField, TextFieldProps, MenuItem } from "@material-ui/core";
import React from "react";
import useAsync from "../../../hooks/useAsync";
import { dataTypesService } from "../../../services";

const DataTypeSelect = ({ ...props }: TextFieldProps) => {
    const { data } = useAsync(dataTypesService.getPhysTypes);

    const value = data?.result?.items?.find((el) => props?.value === el.id)?.id;

    return (
        <TextField {...props} select label="Тип данных" fullWidth value={value || ""}>
            <MenuItem value="" disabled>
                Выберите тип
            </MenuItem>
            {data?.result?.items?.map((item) => {
                return (
                    <MenuItem key={item.id} value={item.id}>
                        {item.phys_type}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default DataTypeSelect;
