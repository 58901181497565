import { IconButton, ListItem, Tooltip } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { memo } from "react";
import BrandAutocomplete from "../../Controls/Autocomplete/BrandAutocomplete";

interface Props {
    selectedBrands: number[];
    onChange(key: string, value: number): void;
    id: string;
    value: number | null;
    onDelete(key: string): void;
}

const StaffBrandItem = ({ id, value, onDelete, onChange, selectedBrands }: Props) => {
    return (
        <ListItem disableGutters>
            <BrandAutocomplete
                fullWidth
                disabledOptions={selectedBrands}
                value={value}
                onChange={(name, value) => onChange(id, value)}
                textFieldProps={{
                    size: "small",
                    variant: "outlined",
                    label: "Бренд",
                    name: "brand_id",
                    fullWidth: true,
                }}
            />
            <Tooltip title="Удалить" arrow>
                <IconButton style={{ marginLeft: 10 }} size="small" onClick={() => onDelete(id)}>
                    <Delete />
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};

export default memo(StaffBrandItem);
