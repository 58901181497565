import {
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { Form, Formik, FieldArray } from "formik";
import React, { forwardRef, FocusEventHandler } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { Discount } from "@interfaces/Discount";
import { UDateTimePicker } from "@components/Controls/Universal";
import { DiscountSchema } from "@schema/Discount";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { aTypesService } from "@services";
import { AttributeType } from "@interfaces/AttributeType";
import DiscountTypeSelect from "@components/Controls/Selects/DIscountTypeSelect";
import { Subtitle } from "@components/Views/Title";
import AddNewButton from "@components/Controls/Buttons/AddNewButton";
import { Delete } from "@material-ui/icons";

const DiscountForm = forwardRef(({ onSubmit, data }: CommonFormProps<Discount>, ref: any) => {
    const initialData = {
        title: data?.title ?? "",
        sku: data?.sku ?? [],
        key: data?.key ?? "",
        money: data?.money ?? 0,
        percent: data?.percent ?? 0,
        max_percent: data?.max_percent ?? 0,
        start_date: data?.start_date ?? new Date(),
        end_date: data?.end_date ?? new Date(),
        type: data?.type ?? "add",
        discount_attribute_value_bindings: data?.discount_attribute_value_bindings ?? [],
    } as Discount;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={DiscountSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur
        >
            {({ touched, errors, handleChange, setFieldValue, handleBlur, values }) => {
                const transformToNumber: FocusEventHandler<HTMLInputElement> = (e) => {
                    const { name, value } = e.target;
                    if (value) return;
                    const val = Number(e.target.value);
                    setFieldValue(name, val);
                };

                return (
                    <Form>
                        <Subtitle title="Общее" bold />
                        <Grid container spacing={2}>
                            <Grid item xs md={6}>
                                <TextField
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.title}
                                    onBlur={handleBlur}
                                    required
                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    name="title"
                                    helperText={touched.title ? errors.title : ""}
                                    error={touched.title && !!errors.title}
                                    label="Наименование"
                                />

                                <TextField
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.key}
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    name="key"
                                    helperText={touched.key ? errors.key : ""}
                                    error={touched.key && !!errors.key}
                                    label="Ключ"
                                />

                                <DiscountTypeSelect
                                    fullWidth
                                    onChange={handleChange}
                                    value={values.type}
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    name="type"
                                    helperText={touched.type ? errors.type : ""}
                                    error={touched.type && !!errors.type}
                                    label="Тип промокода"
                                />
                            </Grid>

                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={transformToNumber}
                                    value={values.max_percent}
                                    required
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    name="max_percent"
                                    helperText={touched.max_percent ? errors.max_percent : ""}
                                    error={touched.max_percent && !!errors.max_percent}
                                    label="Макс. скидка в %"
                                />

                                <TextField
                                    type="number"
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={transformToNumber}
                                    value={values.money}
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    name="money"
                                    helperText={touched.money ? errors.money : ""}
                                    error={touched.money && !!errors.money}
                                    label="Скидка в Д.Е"
                                />

                                <TextField
                                    type="number"
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={transformToNumber}
                                    value={values.percent}
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    name="percent"
                                    helperText={touched.percent ? errors.percent : ""}
                                    error={touched.percent && !!errors.percent}
                                    label="Скидка в %"
                                />

                                <UDateTimePicker
                                    name="start_date"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    label="Дата начала"
                                    pickerProps={{ minDate: new Date() }}
                                    value={values.start_date}
                                    onChange={setFieldValue}
                                    fullWidth
                                />

                                <UDateTimePicker
                                    name="end_date"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    label="Дата окончания"
                                    pickerProps={{ minDate: new Date() }}
                                    value={values.end_date}
                                    onChange={setFieldValue}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        <FieldArray name="sku">
                            {({ handlePush, handleRemove }) => (
                                <>
                                    <Subtitle
                                        title="Набор SKU"
                                        bold
                                        endAdornment={
                                            <AddNewButton
                                                color="secondary"
                                                variant="text"
                                                size="small"
                                                onClick={handlePush("")}
                                            />
                                        }
                                    />

                                    <List disablePadding>
                                        {!values.sku.length ? (
                                            <Box padding="1rem 0">
                                                <Typography>SKU отсутствуют</Typography>
                                            </Box>
                                        ) : null}
                                        {values.sku.map((sku, i) => {
                                            const baseName: string = `sku`;
                                            const skuName: string = `${baseName}.${i}`;

                                            const skuTouched: boolean = touched[baseName]?.[i];

                                            const skuErrors: string = errors[baseName]?.[i];

                                            return (
                                                <ListItem disableGutters key={i}>
                                                    <TextField
                                                        fullWidth
                                                        onChange={handleChange}
                                                        value={sku}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="dense"
                                                        name={skuName}
                                                        helperText={skuTouched ? skuErrors : ""}
                                                        error={skuTouched && !!skuErrors}
                                                        label="SKU"
                                                    />

                                                    <Tooltip title="Удалить" arrow>
                                                        <IconButton
                                                            style={{ marginLeft: 10 }}
                                                            size="small"
                                                            onClick={handleRemove(i)}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </>
                            )}
                        </FieldArray>

                        <FieldArray name="discount_attribute_value_bindings">
                            {({ handlePush, handleRemove }) => (
                                <>
                                    <Subtitle
                                        title="Привязанные атрибуты"
                                        bold
                                        endAdornment={
                                            <AddNewButton
                                                color="secondary"
                                                variant="text"
                                                size="small"
                                                onClick={handlePush({
                                                    at_id: null,
                                                    a_value: "",
                                                })}
                                            />
                                        }
                                    />

                                    <List disablePadding>
                                        {!values.discount_attribute_value_bindings.length ? (
                                            <Box padding="1rem 0">
                                                <Typography>
                                                    Привязанные атрибуты отсутствуют
                                                </Typography>
                                            </Box>
                                        ) : null}
                                        {values.discount_attribute_value_bindings.map((davb, i) => {
                                            const baseName: string = `discount_attribute_value_bindings`;
                                            const aTypeIdName: string = `${baseName}.${i}.at_id`;
                                            const aValueName: string = `${baseName}.${i}.a_value`;

                                            const aTypeIdTouched: boolean =
                                                touched[baseName]?.[i]?.at_id;
                                            const aValueTouched: boolean =
                                                touched[baseName]?.[i]?.a_value;

                                            const aTypeIdErrors: string =
                                                errors[baseName]?.[i]?.at_id;
                                            const aValueErrors: string =
                                                errors[baseName]?.[i]?.a_value;

                                            return (
                                                <ListItem disableGutters key={i}>
                                                    <AsyncAutocomplete<AttributeType>
                                                        fetchFn={aTypesService.getAttributeTypes}
                                                        value={davb.at_id}
                                                        onChange={(name, value) => {
                                                            return setFieldValue(
                                                                name,
                                                                value?.id || null
                                                            );
                                                        }}
                                                        optionField="name"
                                                        textFieldProps={{
                                                            required: true,
                                                            fullWidth: true,
                                                            onBlur: handleBlur,
                                                            size: "small",
                                                            margin: "dense",
                                                            variant: "outlined",
                                                            name: aTypeIdName,
                                                            label: "Тип атрибута",
                                                            helperText: aTypeIdTouched
                                                                ? aTypeIdErrors
                                                                : "",
                                                            error:
                                                                aTypeIdTouched && !!aTypeIdErrors,
                                                        }}
                                                    />
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={davb.a_value}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="dense"
                                                        name={aValueName}
                                                        helperText={
                                                            aValueTouched ? aValueErrors : ""
                                                        }
                                                        error={aValueTouched && !!aValueErrors}
                                                        label="Значение атрибута"
                                                        style={{ marginLeft: 10 }}
                                                    />

                                                    <Tooltip title="Удалить" arrow>
                                                        <IconButton
                                                            style={{ marginLeft: 10 }}
                                                            size="small"
                                                            onClick={handleRemove(i)}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </>
                            )}
                        </FieldArray>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default DiscountForm;
