import React from "react";
import { Box, Divider, Icon, Typography, TypographyVariant } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    box: {
        display: "flex",
        alignItems: "center",
    },
    withAction: {
        justifyContent: "space-between",
    },
    title: {
        display: "flex",
        alignContent: "center",
    },
    bold: {
        fontWeight: 600,
    },
    icon: {
        margin: "auto 0",
        marginRight: 10,
    },
}));

interface Props {
    title: string;
    bold?: boolean;
    endAdornment?: JSX.Element;
    icon?: string;
    disableDivider?: boolean;
    titleVariant?: TypographyVariant;
}

const Subtitle: React.FC<Props> = ({
    title,
    bold,
    endAdornment,
    icon,
    disableDivider,
    titleVariant = "subtitle1",
}: Props) => {
    const classes = useStyles();

    return (
        <>
            <Box
                mt={1}
                className={clsx(classes.box, {
                    [classes.withAction]: !!endAdornment,
                })}
            >
                <Typography
                    variant={titleVariant}
                    className={clsx(classes.title, { [classes.bold]: bold })}
                    gutterBottom
                >
                    {icon && <Icon className={classes.icon}>{icon}</Icon>}
                    {title}
                </Typography>
                {endAdornment && <div>{endAdornment}</div>}
            </Box>
            {!disableDivider && <Divider />}
        </>
    );
};

export default Subtitle;
