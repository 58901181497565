import { Exclude, Expose } from "class-transformer";

@Exclude()
class UpdateStaffOrderItemDto {
    @Expose()
    readonly staff_order_id: number;

    @Expose()
    readonly price_id: number;

    @Expose()
    readonly quantity: number;

    @Expose()
    readonly rejected: boolean;
}

export default UpdateStaffOrderItemDto;
