import { List } from "@material-ui/core";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { staffBrandBindService } from "@services/Staff";
import StaffBrandItem from "./StaffBrandItem";
import { StaffBrandDto } from "@dto/StaffDto";
import { useStoreon } from "storeon/react";
import { ContentEvents, ContentState } from "@store/content-module";
import { useAsync } from "@hooks";
import { Skeleton } from "@material-ui/lab";

const StaffBrandsList = forwardRef((props, ref: any) => {
    const {
        staffRoles: { userId },
        staffBrands: { brands, brandsToUnbind },
        dispatch,
    } = useStoreon<ContentState, ContentEvents>("staffRoles", "staffBrands");

    const { loadingStatus } = useAsync(staffBrandBindService.getStaffBrands, {
        functionArgs: [userId],
        canLoad: !!userId,
        fetchCallback: (brands) => {
            if (brands.length && userId) {
                dispatch("content/staff_brands/set/brands", { brands, userId });
                dispatch("content/staff_brands/set/brandstounbind", {
                    brandsToUnbind: brands,
                    userId,
                });
            }
        },
    });

    useEffect(() => {
        return () => {
            dispatch("content/staff_brands/set/brands", {
                brands: [],
                userId: 0,
            });
            dispatch("content/staff_brands/set/brandstounbind", {
                brandsToUnbind: [],
                userId: 0,
            });
        };
    }, [dispatch]);

    useImperativeHandle(ref, () => ({
        handleSubmit: async () => {
            if (typeof userId !== "number") return;

            const brandsToBind = brands
                .filter((brand) => typeof brand.brandId === "number")
                .map((appBrand) => new StaffBrandDto(userId, Number(appBrand.brandId)));

            for (const brand of brandsToUnbind) {
                if (!brand.brandId) continue;
                await staffBrandBindService.unbindStaffBrand(
                    new StaffBrandDto(userId, brand.brandId)
                );
            }

            for (const brand of brandsToBind) {
                await staffBrandBindService.bindStaffBrand(brand);
            }
        },
    }));

    const handleChangeValue = useCallback(
        (key: string, value: number | null) => {
            dispatch("content/staff_brands/update/brands", { key, value });
        },
        [dispatch]
    );

    const handleDeleteRole = useCallback(
        (key: string) => {
            dispatch("content/staff_brands/remove/brands", key);
        },
        [dispatch]
    );

    const selectedBrands = brands.filter((b) => b.brandId !== null).map((b) => b.brandId as number);

    if (loadingStatus === "loading") {
        return <Skeleton width="100%" height={50} />;
    }

    return (
        <List>
            {brands.map((brand) => {
                return (
                    <StaffBrandItem
                        selectedBrands={selectedBrands}
                        key={brand.key}
                        id={brand.key}
                        value={brand.brandId}
                        onChange={handleChangeValue}
                        onDelete={handleDeleteRole}
                    />
                );
            })}
        </List>
    );
});

export default StaffBrandsList;
