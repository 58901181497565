import { QueryParams, PaginatedQueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { ServiceRole } from "@interfaces/ServiceRole";
import { CreateServiceRoleDto, UpdateServiceRoleDto } from "@dto/SettingsDto/ServiceRolesDto";

class ServiceRoleService extends BaseService {
    public getServiceRoles = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<ServiceRole>> => {
        const { data } = await this.instance.get("/settings/service_role", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneServiceRole = async (id: number): Promise<ServiceRole> => {
        const { data } = await this.instance.get("/settings/service_role", {
            params: { id },
        });

        return data.result.items[0];
    };

    public createServiceRole = async (createServiceRoleDto: CreateServiceRoleDto): Promise<any> => {
        const { data } = await this.instance.post("/settings/service_role", {
            data: createServiceRoleDto,
        });

        return data;
    };

    public updateServiceRole = async (
        id: number,
        updateServiceRoleDto: UpdateServiceRoleDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/settings/service_role", {
            id,
            data: updateServiceRoleDto,
        });

        return data;
    };

    public deleteServiceRoles = async (ids: number[]): Promise<void> => {
        const { data } = await this.instance.delete("/settings/service_role", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new ServiceRoleService(new HttpClient(Config.API_URL), AuthManager);
