import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { ChangeStaffMemberPassword } from "../../../interfaces/Internal/ChangeStaffMemberPassword";
import { ChangeStaffMemberPasswordSchema } from "../../../schema/Staff";
import { PasswordField } from "../../Controls/Inputs";

interface Props {
    onSubmit: (values: ChangeStaffMemberPassword) => Promise<void>;
}

const ChangeStaffMemberPasswordForm = forwardRef(({ onSubmit }: Props, ref: any) => {
    const initialData: ChangeStaffMemberPassword = {
        password: "",
        repeat_password: "",
    };

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={ChangeStaffMemberPasswordSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values }) => {
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <PasswordField
                                    name="password"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    label="Новый пароль"
                                    required
                                    helperText={touched.password ? errors.password : ""}
                                    error={touched.password && Boolean(errors.password)}
                                    value={values.password}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PasswordField
                                    name="repeat_password"
                                    variant="outlined"
                                    size="small"
                                    label="Повторите новый пароль"
                                    margin="normal"
                                    required
                                    helperText={
                                        touched.repeat_password ? errors.repeat_password : ""
                                    }
                                    error={
                                        touched.repeat_password && Boolean(errors.repeat_password)
                                    }
                                    value={values.repeat_password}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default ChangeStaffMemberPasswordForm;
