import React from "react";
import { useCurrentPageInfo, useModal } from "@hooks";
import { cartService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { CartItem } from "@interfaces/CartItem";

const CartItemsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_CART_ITEM", { itemId: rowData.id });
    };

    // const handleDeleteClick = async (rowData: CartItem | CartItem[]) => {
    //     try {
    //         const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
    //         await cartService.deleteCartItems(ids);
    //         enqueueSnackbar("Элемент корзины успешно удален", {
    //             variant: "success",
    //         });
    //     } catch (err) {
    //         enqueueSnackbar("Произошла ошибка при удалении элемента корзины", {
    //             variant: "error",
    //         });
    //     }
    // };

    const columns: DataTableColumn<CartItem>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            align: "left",
            type: "numeric",
            width: 100,
        },
        {
            title: "ID корзины",
            field: "cart_id",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "Исключено из корзины",
            field: "excluded",
            type: "boolean",
            width: "auto",
        },
        {
            title: "SKU",
            field: "sku",
            width: "auto",
        },
        {
            title: "Количество",
            field: "quantity",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "Скидка",
            field: "discount_money",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "Скидка в %",
            field: "discount_percent",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "RRC цена",
            field: "rrc_price",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "Итоговая цена",
            field: "sale_price",
            align: "left",
            type: "numeric",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={cartService.getCartItems}
            columns={columns}
            displayField="id"
            permission={permission}
            onEditClick={handleEditClick}
            // onDeleteClick={handleDeleteClick}
            // onMassDeleteClick={handleDeleteClick}
            // selection
            filtering
            sorting
            search
        />
    );
};

export default CartItemsTable;
