import { QueryParams, PaginatedQueryResult, QueryResultWithoutData } from ".";
import BaseService from "./BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { Log } from "@interfaces/Log";
import { ObserverTable } from "@interfaces/ObserverTable";
import { CreateObserverTableDto, UpdateObserverTableDto } from "@dto/ObserverTableDto";

class LogService extends BaseService {
    public getLogs = async (params: QueryParams = {}): Promise<PaginatedQueryResult<Log>> => {
        const { data } = await this.instance.get("/log", { params });

        return this.toPaginatedUniversalOutput(data);
    };

    public getObserverTable = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<ObserverTable>> => {
        const { data } = await this.instance.get("/observer_table", { params });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneObserverTable = async (id: number): Promise<ObserverTable | null> => {
        const { data } = await this.instance.get("/observer_table", { params: { ids_list: id } });

        return this.getFirstItemOrNull<ObserverTable>(data.result.items);
    };

    public createObserverTable = async (
        createObserverTableDto: CreateObserverTableDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.post("/observer_table", createObserverTableDto);

        return data;
    };

    public updateObserverTable = async (
        id: number,
        updateObserverTableDto: UpdateObserverTableDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/observer_table", {
            id,
            data: updateObserverTableDto,
        });

        return data;
    };

    public deleteObserverTable = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/observer_table", { data: { ids_list: ids } });

        return data;
    };
}

export default new LogService(new HttpClient(Config.API_URL), AuthManager);
