import { RestrictionsManager } from "@modules";
import { Transform, Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateValueTypeDto {
    @Expose()
    readonly name: string;

    @Expose()
    readonly notes?: string;

    @Expose()
    readonly pt_id: number;

    @Expose()
    @Transform(({ value }) => RestrictionsManager.getRestrictionsObjectFromArray(value || []))
    readonly restrictions: object;
}
