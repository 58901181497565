import { useAsync, useCurrentPageInfo } from "@hooks";
import { Button, Card, CardActions, CardContent, Grid, List, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { useStoreon } from "storeon/react";
import { entitiesService } from "@services";
import { TableEvents, TableState } from "@store/table-module";
import TableFilterItem from "./TableFilterItem";
import TableFilterItemSkeleton from "./TableFilterItemSkeleton";

const useStyles = makeStyles({
    marginTop: {
        marginTop: 20,
    },
    title: {
        display: "flex",
    },
});

const TableFilters = ({ defaultFiltersCount = 5, disableMargin = false }) => {
    const classes = useStyles();
    const { param } = useCurrentPageInfo();

    const { filters, dispatch } = useStoreon<TableState, TableEvents>("filters");

    const [state, setState] = useState({
        expanded: false,
    });

    const activeFiltersCount = filters?.filter((el) => el.value).length;

    const { loadingStatus } = useAsync(entitiesService.getTypeSchema, {
        functionArgs: [+param],
        canLoad: !!param,
        fetchCallback: (value) => dispatch("table/set/filters", value),
    });

    const handleResetFilters = () => {
        dispatch("table/reset/filters");
        dispatch("table/update/data");
    };

    const handleApplyFilters = () => {
        dispatch("table/update/data");
    };

    return (
        <Card className={clsx({ [classes.marginTop]: !disableMargin })}>
            <CardContent>
                <Grid container justifyContent="space-between">
                    <Typography color="textSecondary" gutterBottom className={classes.title}>
                        <FilterList style={{ marginRight: 10 }} />
                        Фильтры
                    </Typography>
                    {filters.length > 0 && (
                        <Typography>
                            Выбрано: <b>{activeFiltersCount}</b>
                        </Typography>
                    )}
                </Grid>

                {loadingStatus === "loading" &&
                    [...new Array(defaultFiltersCount)].map((el, index) => (
                        <TableFilterItemSkeleton key={index} />
                    ))}

                {!filters.length && loadingStatus === "loaded" && (
                    <Typography variant="subtitle1">Нет доступных фильтров</Typography>
                )}

                {filters.length > 0 && loadingStatus === "loaded" && (
                    <List>
                        {filters
                            ?.slice(0, state.expanded ? filters.length : defaultFiltersCount)
                            .map((filter) => {
                                return <TableFilterItem key={filter.at_id} filter={filter} />;
                            })}
                    </List>
                )}

                {filters?.length > defaultFiltersCount && (
                    <Button
                        fullWidth
                        onClick={() =>
                            setState((prevState) => ({
                                ...prevState,
                                expanded: !state.expanded,
                            }))
                        }
                    >
                        {state.expanded ? "Скрыть" : "Показать еще"}
                    </Button>
                )}
            </CardContent>
            {filters.length > 0 && (
                <CardActions>
                    <Button style={{ marginRight: "auto" }} onClick={handleResetFilters}>
                        Сбросить
                    </Button>

                    <Button
                        color="primary"
                        onClick={handleApplyFilters}
                        disabled={!activeFiltersCount}
                    >
                        Применить
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

export default TableFilters;
