import React from "react";
import { useCurrentPageInfo, useModal } from "@hooks";
import { ServiceHelper } from "@modules";
import { cartService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { Cart } from "@interfaces/Cart";
import { useSnackbar } from "notistack";

const CartTable = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_CART", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: Cart | Cart[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await cartService.deleteCart(ids);
            enqueueSnackbar("Корзина успешно удалена", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении корзины", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<Cart>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            align: "left",
            type: "numeric",
            width: 100,
        },
        {
            title: "Идентификатор корзины",
            field: "number",
            width: "auto",
        },
        {
            title: "Пользователь",
            field: "user_id",
            align: "left",
            type: "numeric",
            width: 200,
        },
        {
            title: "Дата создания",
            field: "created_date",
            filtering: false,
            type: "datetime",
            width: "auto",
        },
        {
            title: "Дата обновления",
            field: "updated_date",
            filtering: false,
            type: "datetime",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={cartService.getCarts}
            columns={columns}
            displayField="number"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            selection
            filtering
            sorting
            search
        />
    );
};

export default CartTable;
