import DownloadSnackbar from "@components/Views/Snackbars/DownloadSnackbar";
import { TableFilter } from "@interfaces/System/TableFilter";
import { Button, Fade, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown, GetApp } from "@material-ui/icons";
import { ServiceHelper } from "@modules";
import { nsiService } from "@services";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

type Props = { entityName: string; entityTypeId: number; entityFilters?: TableFilter[] };

const ExportEntitiesMenu = ({ entityName, entityTypeId, entityFilters = [] }: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDownloadTemplateClick = async () => {
        if (!entityTypeId) return;

        handleMenuClose();
        const response = await nsiService.downloadCSVtemplate(entityTypeId, {
            deferredLoading: true,
        });

        const filename = entityName
            ? `${entityName}.csv`
            : nsiService.getFileNameFromHeaders(response);

        enqueueSnackbar("Шаблон сгенерирован", {
            persist: true,
            content: (id, message) => (
                <DownloadSnackbar
                    id={id}
                    message={message}
                    onDownload={() =>
                        nsiService.download(response, filename, {
                            blobType: "text/csv",
                        })
                    }
                />
            ),
        });
    };

    const handleDownloadEntitiesClick = async (
        e: React.MouseEvent<HTMLLIElement>,
        withFilters?: boolean
    ) => {
        if (!entityTypeId) return;

        handleMenuClose();
        const response = await nsiService.downloadEntityShapshot(entityTypeId, {
            deferredLoading: true,
            filters: withFilters ? ServiceHelper.getSchemaBasedFiltersObject(entityFilters) : {},
        });

        const filename = entityName
            ? `${entityName}.csv`
            : nsiService.getFileNameFromHeaders(response);

        enqueueSnackbar("Сущности успешно выгружены", {
            persist: true,
            content: (id, message) => (
                <DownloadSnackbar
                    id={id}
                    message={message}
                    onDownload={() =>
                        nsiService.download(response, filename, {
                            blobType: "text/csv",
                        })
                    }
                />
            ),
        });
    };

    return (
        <>
            <Button
                component="span"
                onClick={handleMenuOpen}
                startIcon={<GetApp />}
                color="secondary"
                endIcon={<ArrowDropDown />}
            >
                Выгрузить данные
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleMenuClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={handleDownloadTemplateClick}>Сгенерировать шаблон</MenuItem>
                <MenuItem onClick={handleDownloadEntitiesClick}>Выгрузить все сущности</MenuItem>
                <MenuItem onClick={(e) => handleDownloadEntitiesClick(e, true)}>
                    Выгрузить все сущности (с учетом фильтров)
                </MenuItem>
            </Menu>
        </>
    );
};

export default ExportEntitiesMenu;
