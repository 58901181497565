import { Container } from "@material-ui/core";
import React from "react";
import Breadcrumbs from "@components/Views/Breadcrumbs/Breadcrumbs";
import useTitle from "@hooks/useTitle";
import { EnhancedPageHead } from "@components/Common/Content";
import EnhancedContentLayout from "@components/Common/Content/EnhancedContentLayout";
import { WithContentTypeProps } from "@modules/ContentTypesManagers";
import { WithPageProps } from "@modules/PageTypesManager";

export const ContentPage = ({
    contentType,
    title,
    url,
    icon,
    description,
}: WithContentTypeProps<{}> & WithPageProps<{}>) => {
    useTitle(title);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs />

            <EnhancedPageHead contentType={contentType} />

            <EnhancedContentLayout contentType={contentType} />
        </Container>
    );
};

export default ContentPage;
