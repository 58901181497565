import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import { UniversalInputProps } from "../../../interfaces/System/UniversalInputProps";

const UCheckbox = ({ ...props }: UniversalInputProps) => {
    const handleChange = (e) => {
        props.onChange(e.target.name, e.target.checked);
    };
    return (
        <FormControlLabel
            control={<Checkbox {...props} checked={props.value} onChange={handleChange} />}
            label={props.label}
        />
    );
};

export default UCheckbox;
