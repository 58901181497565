import { useCurrentPageInfo, useModal } from "@hooks";
import React from "react";
import { storeService } from "@services";
import DataTable, { DataTableColumn, DataTableProps } from "../Common/DataTable/DataTable";
import { ServiceHelper } from "@modules";
import { useSnackbar } from "notistack";
import { Discount } from "@interfaces/Discount";
import { Typography } from "@material-ui/core";

const DiscountsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();

    const handleEditClick: DataTableProps<Discount>["onEditClick"] = (rowData) => {
        if (rowData instanceof Array) return;
        handleOpenModal("EDIT_DISCOUNT", { itemId: rowData.id, titleProps: rowData.id });
    };

    const handleDeleteClick = async (rowData) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await storeService.deleteDiscounts(ids);
            enqueueSnackbar("Промокоды успешно удалены", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении промокодов", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<Discount>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            align: "left",
            type: "numeric",
            width: 100,
        },
        {
            title: "Наименование",
            field: "title",
            width: "auto",
        },
        {
            title: "SKU",
            field: "sku",
            width: "auto",
            render: (rowData) => (
                <>
                    {rowData.sku?.map((sku) => (
                        <Typography key={sku} variant="body2">
                            {sku}
                        </Typography>
                    ))}
                </>
            ),
        },
        {
            title: "Ключ",
            field: "key",
            width: "auto",
        },
        {
            title: "Атрибуты",
            field: "discount_attribute_value_bindings",
            width: "auto",
            render: (rowData) => (
                <>
                    {rowData.discount_attribute_value_bindings?.map((davb) => (
                        <Typography key={davb.at_id} variant="body2">
                            {davb.at_id} - {davb.a_value}
                        </Typography>
                    ))}
                </>
            ),
        },
        {
            title: "Тип промокода",
            field: "type",
            width: "auto",
        },
        {
            title: "Макс. скидка в %",
            field: "max_percent",
            width: "auto",
        },
        {
            title: "Скидка в %",
            field: "percent",
            width: "auto",
        },
        {
            title: "Скидка в Д.Е",
            field: "money",
            width: "auto",
        },
        {
            title: "Публичный",
            field: "public",
            type: "boolean",
            hidden: true,
            width: "auto",
        },
        {
            title: "Дата начала",
            filtering: false,
            field: "start_date",
            type: "datetime",
            hidden: true,
            width: "auto",
        },
        {
            title: "Дата окончания",
            filtering: false,
            field: "end_date",
            type: "datetime",
            hidden: true,
            width: "auto",
        },
        {
            title: "Дата создания",
            filtering: false,
            field: "created_at",
            type: "datetime",
            hidden: true,
            width: "auto",
        },
        {
            title: "Дата обновления",
            filtering: false,
            field: "updated_at",
            type: "datetime",
            hidden: true,
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={storeService.getDiscounts}
            columns={columns}
            displayField="title"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            filtering
            sorting
            search
        />
    );
};

export default DiscountsTable;
