import * as Yup from "yup";

export const ReserveStoreBindingSchema = Yup.object().shape({
    store_id: Yup.number()
        .required("Пожалуйста, выберите магазин")
        .transform((value) => (isNaN(value) ? undefined : value)),
    reserve_id: Yup.number()
        .required("Пожалуйста, выберите резерв")
        .transform((value) => (isNaN(value) ? undefined : value)),
    count: Yup.number()
        .required("Пожалуйста, укажите количество")
        .min(1, "Значение должно быть больше 0")
        .transform((value) => (isNaN(value) ? undefined : value)),
});
