import { useCurrentPageInfo, useModal } from "@hooks";
import React from "react";
import { orderService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { ServiceHelper } from "@modules";
import { useSnackbar } from "notistack";
import { OrderItem } from "@interfaces/OrderItem";

const OrderItemsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_ORDER_ITEM", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: OrderItem | OrderItem[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await orderService.deleteOrderItems(ids);
            enqueueSnackbar(
                rowData instanceof Array
                    ? "Элементы заказов успешно удалены"
                    : "Элемент заказа успешно удален",
                {
                    variant: "success",
                }
            );
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(
                message || rowData instanceof Array
                    ? "Произошла ошибка при удалении элементов заказа"
                    : "Произошла ошибка при удалении элемента заказа",
                {
                    variant: "error",
                }
            );
        }
    };

    const columns: DataTableColumn<OrderItem>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            align: "left",
            type: "numeric",
            width: 100,
        },
        {
            title: "Номер заказа",
            field: "order_id",
            width: "auto",
        },
        {
            title: "SKU",
            field: "sku",
            width: "auto",
        },
        {
            title: "Количество",
            field: "quantity",
            width: "auto",
        },
        {
            title: "Исключено из заказа",
            field: "excluded",
            type: "boolean",
            width: "auto",
        },
        {
            title: "SKU",
            field: "sku",
            width: "auto",
        },
        {
            title: "Количество",
            field: "quantity",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "Скидка",
            field: "discount_money",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "Скидка в %",
            field: "discount_percent",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "RRC цена",
            field: "rrc_price",
            align: "left",
            type: "numeric",
            width: "auto",
        },
        {
            title: "Цена продажи",
            field: "sale_price",
            align: "left",
            type: "numeric",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={orderService.getOrderItems}
            columns={columns}
            displayField="id"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            filtering
            sorting
            search
        />
    );
};

export default OrderItemsTable;
