import { TextField, TextFieldProps, MenuItem } from "@material-ui/core";
import React from "react";
import RestrictionsManager from "../../../modules/RestrictionsManager";

const RestrictionTypeSelect = ({ disabledOptions, ...props }: any & TextFieldProps) => {
    return (
        <TextField {...props} select label="Тип ограничения" fullWidth value={props.value || ""}>
            <MenuItem value="" disabled>
                Выберите тип
            </MenuItem>
            {RestrictionsManager.getRestrictionsArrayWithoutCustom().map((item) => {
                return (
                    <MenuItem
                        key={item.code}
                        value={item.code}
                        disabled={disabledOptions?.includes(item.code)}
                    >
                        {item.title}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default RestrictionTypeSelect;
