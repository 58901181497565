import { MutableItem } from "@interfaces/System/MutableItem";
import { TableFilter } from "@interfaces/System/TableFilter";
import { Filter } from "@material-table/core";

export const getIdsListFromRowDataObject = (data): number[] => {
    return Array.isArray(data) ? [...data.map((el) => el.id)] : [data.id];
};

export const getSchemaBasedFiltersObject = (filtersArr: TableFilter[]) => {
    return filtersArr.reduce((obj, filter) => {
        if (filter.value) {
            obj[filter.at_id] = filter.value;
        }
        return obj;
    }, {});
};

export const getFiltersObjectFromTable = <T extends {}>(filtersArr: Filter<T>[]) => {
    return filtersArr.reduce((obj, filter) => {
        const field = filter.column.field as string;
        const type = filter.column.type;

        if (!field) return obj;

        if (type === "boolean") {
            obj[field] = filter.value === "checked" ? true : false;
            return obj;
        }

        obj[field] = filter.value;

        return obj;
    }, {});
};

export const getSortingParam = (orderBy: string, orderDirection: "asc" | "desc") => {
    if (!orderBy || !orderDirection) return;

    let sorting = "";

    sorting = orderDirection === "asc" ? sorting : sorting + "-";
    sorting += orderBy;

    return sorting;
};

export const getItemsForCreate = <T extends MutableItem>(items: T[]): T[] => {
    return items.filter((item) => item.action === "add");
};

export const getItemsForUpdate = <T extends MutableItem>(items: T[]): T[] => {
    return items.filter((item) => !item.action);
};

export const getItemsForDelete = <T extends MutableItem>(items: T[]): T[] => {
    return items.filter((item) => item.action === "remove");
};
