import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateStaffProfileDto {
    @Expose()
    readonly first_name: string;

    @Expose()
    readonly last_name: string;

    @Expose()
    readonly middle_name: string;

    @Expose()
    readonly phone?: string;

    @Expose()
    readonly email: string;

    @Expose()
    readonly birthday?: string;

    @Expose()
    readonly work_phone?: string;

    @Expose()
    readonly additional_phone?: string;
}
