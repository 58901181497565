import { BaseContentType, CONTENT_TYPE } from "./ContentTypesManager";

export interface MultipleContentType extends BaseContentType<MULTIPLE_CONTENT_TYPE> {
    content: CONTENT_TYPE[];
}

export enum MULTIPLE_CONTENT_TYPE {
    STAFF_MEMBERS_AND_BRANDS = "staff_members_and_brands",
    CART_AND_CART_ITEMS = "cart_and_cart_items",
    ORDER_AND_ORDER_ITEMS = "order_and_order_items",
    STAFF_ORDERS_AND_STAFF_ORDER_ITEMS = "staff_orders_and_staff_order_items",
    LOGGING_AND_OBSERVER_TABLE = "logging_and_observer_table",
    CATALOG_AND_CSV_LOAD_STATUSES = "catalog_and_csv_load_statuses",
    PRICES_AND_PRICE_LOAD_STATUSES = "prices_and_price_load_statuses",
    QUANTITIES_AND_QUANTITY_LOAD_STATUSES = "quantities_and_quantity_load_statuses",
    DISCOUNTS_AND_DISCOUNT_LOAD_STATUSES = "discounts_and_discount_load_statuses",
    STORES_AND_STORE_LOAD_STATUSES = "stores_and_store_load_statuses",
}

/** content types for page with type `multiple_content` */
class MultipleContentTypesManager {
    static getMultipleContentType = (type: string) => {
        return MultipleContentTypesManager.MULTIPLE_CONTENT_TYPES.find(
            (content) => content.type === type
        );
    };

    static readonly MULTIPLE_CONTENT_TYPES: MultipleContentType[] = [
        {
            type: MULTIPLE_CONTENT_TYPE.STAFF_MEMBERS_AND_BRANDS,
            name: "Управление сотрудниками и привязками к брендам",
            content: [CONTENT_TYPE.STAFF_MEMBERS, CONTENT_TYPE.BRAND_BIND],
        },
        {
            type: MULTIPLE_CONTENT_TYPE.CART_AND_CART_ITEMS,
            name: "Управление корзиной и ее элементами",
            content: [CONTENT_TYPE.CART, CONTENT_TYPE.CART_ITEMS],
        },
        {
            type: MULTIPLE_CONTENT_TYPE.ORDER_AND_ORDER_ITEMS,
            name: "Управление заказами и их элементами",
            content: [CONTENT_TYPE.ORDER, CONTENT_TYPE.ORDER_ITEMS],
        },
        {
            type: MULTIPLE_CONTENT_TYPE.STAFF_ORDERS_AND_STAFF_ORDER_ITEMS,
            name: "Управление заказами и их элементами (b2b)",
            content: [
                CONTENT_TYPE.BRAND_DEALER_BIND,
                CONTENT_TYPE.STAFF_ORDERS,
                CONTENT_TYPE.STAFF_ORDER_ITEMS,
            ],
        },
        {
            type: MULTIPLE_CONTENT_TYPE.LOGGING_AND_OBSERVER_TABLE,
            name: "Управление логами и наблюдателями таблиц",
            content: [CONTENT_TYPE.LOGGING, CONTENT_TYPE.OBSERVER_TABLE],
        },
        {
            type: MULTIPLE_CONTENT_TYPE.CATALOG_AND_CSV_LOAD_STATUSES,
            name: "Справочник и история загрузок CSV",
            content: [CONTENT_TYPE.CATALOG, CONTENT_TYPE.CSV_LOAD_STATUSES],
        },
        {
            type: MULTIPLE_CONTENT_TYPE.PRICES_AND_PRICE_LOAD_STATUSES,
            name: "Цены и история загрузки цен",
            content: [CONTENT_TYPE.PRICES, CONTENT_TYPE.PRICE_LOAD_STATUSES],
        },
        {
            type: MULTIPLE_CONTENT_TYPE.QUANTITIES_AND_QUANTITY_LOAD_STATUSES,
            name: "Остатки, история загрузки остатков, резервы и привязки магазин-резервы",
            content: [
                CONTENT_TYPE.QUANTITIES,
                CONTENT_TYPE.QUANTITY_LOAD_STATUSES,
                CONTENT_TYPE.QUANTITY_RESERVES,
                CONTENT_TYPE.RESERVE_STORE_BINDINGS,
            ],
        },
        {
            type: MULTIPLE_CONTENT_TYPE.DISCOUNTS_AND_DISCOUNT_LOAD_STATUSES,
            name: "Промокоды и история загрузки промокодов",
            content: [CONTENT_TYPE.DISCOUNTS, CONTENT_TYPE.DISCOUNT_LOAD_STATUSES],
        },
        {
            type: MULTIPLE_CONTENT_TYPE.STORES_AND_STORE_LOAD_STATUSES,
            name: "Склады и история загрузки складов",
            content: [CONTENT_TYPE.STORES, CONTENT_TYPE.STORE_LOAD_STATUSES],
        },
    ];
}

export default MultipleContentTypesManager;
