import { TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { Price } from "@interfaces/Price";
import { PriceSchema } from "@schema/Price";

const PriceForm = forwardRef(({ onSubmit, data }: CommonFormProps<Price>, ref: any) => {
    const initialData = {
        sku: data?.sku ?? "",
        rrc_price: data?.rrc_price ?? 0,
        sale_price: data?.sale_price ?? 0,
        discount_percent: data?.discount_percent ?? null,
        price_with_discount: data?.price_with_discount ?? null,
    } as Price;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={PriceSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values }) => {
                return (
                    <Form>
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.sku}
                            required
                            autoFocus
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="sku"
                            helperText={touched.sku ? errors.sku : ""}
                            error={touched.sku && !!errors.sku}
                            label="SKU"
                        />

                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.rrc_price}
                            type="number"
                            required
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="rrc_price"
                            helperText={touched.rrc_price ? errors.rrc_price : ""}
                            error={touched.rrc_price && !!errors.rrc_price}
                            label="RRC цена"
                        />

                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.sale_price}
                            type="number"
                            required
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="sale_price"
                            helperText={touched.sale_price ? errors.sale_price : ""}
                            error={touched.sale_price && !!errors.sale_price}
                            label="Цена продажи"
                        />

                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.discount_percent}
                            type="number"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="discount_percent"
                            helperText={touched.discount_percent ? errors.discount_percent : ""}
                            error={touched.discount_percent && !!errors.discount_percent}
                            label="Скидка в %"
                        />

                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.price_with_discount}
                            type="number"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="price_with_discount"
                            helperText={
                                touched.price_with_discount ? errors.price_with_discount : ""
                            }
                            error={touched.price_with_discount && !!errors.price_with_discount}
                            label="Цена со скидкой"
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default PriceForm;
