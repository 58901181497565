import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React, { useRef } from "react";
import { useStoreon } from "storeon/react";
import { useSnackbar } from "notistack";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { TableEvents, TableState } from "@store/table-module";
import { CreateTypeBindDto } from "@dto/SettingsDto/TypeBindDto/CreateTypeBindDto";
import { UpdateTypeBindDto } from "@dto/SettingsDto/TypeBindDto/UpdateTypeBindDto";
import { ModalContentLoading } from "@components/Common/Modal";
import { valueTypeBindService } from "@services/Settings";
import { ValueTypeBindForm } from "@components/Forms/Settings";
import { useAsync, useLoading } from "@hooks";

const ValueTypeBindDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data, loadingStatus } = useAsync(valueTypeBindService.getOneVTypeBind, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreateValueTypeBind = async (values) => {
        setSubmitLoadingStatus("loading");
        try {
            const dto = valueTypeBindService.plainToClass(CreateTypeBindDto, values);

            await valueTypeBindService.createVTypeBind(dto);
            enqueueSnackbar("Привязка успешно создана", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании привязки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleUpdateValueTypeBind = async (values) => {
        if (!itemId) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = valueTypeBindService.plainToClass(UpdateTypeBindDto, values);

            await valueTypeBindService.updateVTypeBind(itemId, dto);
            enqueueSnackbar("Привязка успешно обновлена", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении привязки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <ValueTypeBindForm
                        ref={formRef}
                        onSubmit={itemId ? handleUpdateValueTypeBind : handleCreateValueTypeBind}
                        data={itemId ? data : undefined}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default ValueTypeBindDialogContext;
