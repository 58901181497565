import { User } from "@interfaces/User";
import React from "react";
import { useCurrentPageInfo, useModal } from "@hooks";
import { ServiceHelper } from "@modules";
import { userService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import StatusSymbol from "../Views/StatusSymbol/StatusSymbol";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import { getGenderShortName } from "@modules/Utils";

const UsersTable = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_USER", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: User | User[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await userService.deleteUsers(ids);
            enqueueSnackbar(
                rowData instanceof Array
                    ? "Пользователи успешно удалены"
                    : "Пользователь успешно удален",
                {
                    variant: "success",
                }
            );
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(
                message || rowData instanceof Array
                    ? "Произошла ошибка при удалении пользователей"
                    : "Произошла ошибка при удалении пользователя",
                {
                    variant: "error",
                }
            );
        }
    };

    const columns: DataTableColumn<User>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Логин",
            field: "username",
            width: "auto",
        },
        {
            title: "Email",
            field: "email",
            width: "auto",
        },
        {
            title: "Фамилия",
            field: "last_name",
            width: "auto",
        },
        {
            title: "Имя",
            field: "first_name",
            width: "auto",
        },
        {
            title: "Пол",
            field: "gender",
            width: "auto",
            render: (rowData) => <Typography>{getGenderShortName(rowData.gender)}</Typography>,
        },
        {
            title: "Дата рождения",
            field: "birthday",
            type: "date",
            width: "auto",
        },
        {
            title: "Номер телефона",
            field: "phone",
            width: "auto",
        },
        {
            title: "Карта лояльности",
            field: "loyalty_card",
            width: "auto",
        },
        {
            title: "Статус",
            field: "is_active",
            render: (rowData) => {
                return <StatusSymbol isActive={rowData.is_active} />;
            },
            width: "auto",
        },
        {
            title: "Дата создания",
            field: "created_at",
            type: "date",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={userService.getUsers}
            columns={columns}
            displayField="email"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            selection
            sorting
            search
        />
    );
};

export default UsersTable;
