import { Quantity } from "@interfaces/Quantity";
import React from "react";
import { storeService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo, useModal } from "@hooks";
import { useSnackbar } from "notistack";
import { ServiceHelper } from "@modules";

const QuantitiesTable = () => {
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_QUANTITY", { itemId: rowData.id, titleProps: rowData.id });
    };

    const handleDeleteClick = async (rowData: Quantity | Quantity[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await storeService.deleteStoreQuantities(ids);
            enqueueSnackbar("Остатки успешно удалены", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении остатков", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<Quantity>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 100,
        },
        {
            title: "SKU",
            field: "sku",
            width: "auto",
        },
        {
            title: "ID Магазина",
            field: "store_id",
            width: "auto",
        },
        {
            title: "Количество",
            field: "quantity",
            width: "auto",
        },
        {
            title: "Дата обновления",
            filtering: false,
            field: "updated_at",
            type: "datetime",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={storeService.getStoreQuantities}
            columns={columns}
            displayField="sku"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            filtering
            sorting
            search
        />
    );
};

export default QuantitiesTable;
