import { isObjectsAreEqual } from "@modules/Utils";
import { AxiosResponse } from "axios";
import { useState, useCallback, useEffect } from "react";
import useLoading, { LoadingStatus } from "./useLoading";
import { useDebouncedFn } from "beautiful-react-hooks";

export type UseAsyncResult<T> = {
    data: T | null;
    error: AxiosResponse | null;
    loadingStatus: LoadingStatus;
    updateData(): Promise<T | void>;
};

type UseAsyncOptions<T> = {
    functionArgs?: any[];
    canLoad?: boolean;
    setLoadingStatusOnUpdate?: boolean;
    fetchCallback?(value: T): void | Promise<void>;
    debounceInterval?: number;
    /** Watch this function and return boolean for immediate call fetch function. */
    debounceImmediate?(prevArgs: any[], nextArgs: any[]): boolean;
};

type UseAsyncState<T> = {
    args: any[];
    canLoadData: boolean;
    immediate: boolean;
    data: T | null;
    error: AxiosResponse | null;
};

const useAsync = <T>(
    fetchFunction: (...args: any) => Promise<T>,
    options: UseAsyncOptions<T> = {}
) => {
    const {
        functionArgs = [],
        canLoad = true,
        setLoadingStatusOnUpdate = true,
        fetchCallback,
        debounceInterval = 0,
        debounceImmediate = () => false,
    } = options;

    const { loadingStatus, setLoadingStatus } = useLoading(canLoad ? "loading" : "inactivity");

    const [state, setState] = useState<UseAsyncState<T>>({
        args: functionArgs,
        canLoadData: canLoad,
        immediate: debounceImmediate(functionArgs, functionArgs),
        data: null,
        error: null,
    });

    const getDebounceImmediate = useCallback(
        (prev, next) => debounceImmediate(prev, next),
        [debounceImmediate]
    );

    useEffect(() => {
        if (canLoad !== state.canLoadData) {
            setState((prevState) => ({
                ...prevState,
                canLoadData: canLoad,
            }));
        }

        if (functionArgs && !isObjectsAreEqual(state.args, functionArgs)) {
            const nextImmediate = getDebounceImmediate(state.args, functionArgs);

            setState((prevState) => ({
                ...prevState,
                immediate: nextImmediate,
                args: functionArgs,
            }));
        }
    }, [
        state.args,
        functionArgs,
        canLoad,
        state.canLoadData,
        getDebounceImmediate,
        debounceInterval,
        state.immediate,
    ]);

    /* eslint-disable */
    const cb = useCallback((value) => {
        if (fetchCallback) fetchCallback(value);
    }, []);

    const fetch = useCallback(
        async () => fetchFunction(...state.args),
        [fetchFunction, state.args]
    );

    const fetchData = useCallback(async () => {
        if (setLoadingStatusOnUpdate) setLoadingStatus("loading");

        try {
            const data = await fetch();
            setState((prevState) => ({
                ...prevState,
                data,
            }));
            if (fetchCallback) cb(data);
            setLoadingStatus("loaded");
            return data;
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                error,
            }));
            setLoadingStatus("error");
        }
    }, [fetch, setLoadingStatus, setLoadingStatusOnUpdate, cb]);

    const debouncedFetchData = useDebouncedFn(
        fetchData,
        debounceInterval,
        {
            leading: state.immediate,
        },
        [fetch]
    );

    useEffect(() => {
        if (state.canLoadData) {
            debouncedFetchData();
        }

        return () => debouncedFetchData.cancel();
    }, [debouncedFetchData, state.canLoadData]);

    const useAsyncResult: UseAsyncResult<T> = {
        data: state.data,
        error: state.error,
        updateData: fetchData,
        loadingStatus: loadingStatus,
    };

    return useAsyncResult;
};

export default useAsync;
