import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { staffContextHelpService } from "@services/Staff";
import { ModalContentLoading } from "../../Common/Modal";
import { useAsync, useLoading } from "@hooks";
import { CreateContextHelpDto, UpdateContextHelpDto } from "@dto/SettingsDto/ContextHelpDto";
import { RichTextEditor } from "@components/Controls/Inputs";
import { ContextHelp } from "@interfaces/ContextHelp";
import { ModalProps } from "@interfaces/System/ModalProps";
import { UniqueField } from "@modules/ContentTypesManagers/ContentTypesManager";

type ContextHelpModalProps = ModalProps & {
    uniqueField: UniqueField;
    uniqueFieldValue: any;
    onUpdate(): Promise<void>;
};

const ContextHelpDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { uniqueField, uniqueFieldValue, itemId, type, onUpdate } = {
        ...modalProps,
    } as ContextHelpModalProps;

    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data, loadingStatus } = useAsync(staffContextHelpService.getOneContextHelp, {
        functionArgs: [{ [uniqueField]: uniqueFieldValue, id: itemId }],
        canLoad: !!uniqueField && !!uniqueFieldValue,
        fetchCallback: (value) => setContent(value?.result?.html),
    });

    const [content, setContent] = useState("");

    const handleCreateContextHelp = async () => {
        if (!uniqueFieldValue || !uniqueField) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = staffContextHelpService.plainToClass(CreateContextHelpDto, {
                html: content,
                [uniqueField]: uniqueFieldValue,
            });

            await staffContextHelpService.createContextHelp(dto);
            enqueueSnackbar("Справка успешно создана", {
                variant: "success",
            });
            onClose();
            await onUpdate();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании справки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleUpdateContextHelp = async () => {
        if (!data?.result || !uniqueFieldValue || !uniqueField) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = staffContextHelpService.plainToClass(UpdateContextHelpDto, {
                html: content,
                [uniqueField]: uniqueFieldValue,
            } as ContextHelp);

            await staffContextHelpService.updateContextHelp(data.result.id, dto);
            enqueueSnackbar("Справка успешно обновлена", {
                variant: "success",
            });
            onClose();
            await onUpdate();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении справки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleDeleteContextHelp = async () => {
        if (!data?.result) return;

        setSubmitLoadingStatus("loading");
        try {
            await staffContextHelpService.deleteContextHelp(data.result.id);
            enqueueSnackbar("Справка успешно удалена", {
                variant: "success",
            });
            onClose();
            await onUpdate();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении справки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const action = data?.result ? handleUpdateContextHelp : handleCreateContextHelp;

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <RichTextEditor
                        name="context_help"
                        height="600px"
                        disable={type === "view"}
                        hideToolbar={type === "view"}
                        value={data?.result?.html ?? ""}
                        onChange={setContent}
                    />
                )}
            </DialogContent>
            <DialogActions>
                {type === "create" && (
                    <Button
                        color="secondary"
                        disabled={!!!data?.result || submitLoadingStatus === "loading"}
                        onClick={handleDeleteContextHelp}
                        variant="outlined"
                        style={{ marginRight: "auto" }}
                    >
                        Удалить справку
                    </Button>
                )}

                <Button color={type === "view" ? "primary" : "default"} onClick={onClose}>
                    Отмена
                </Button>

                {type === "create" && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={action}
                        disabled={submitLoadingStatus === "loading"}
                    >
                        Сохранить
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default ContextHelpDialogContext;
