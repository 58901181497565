import React from "react";
import { logService } from "@services/Settings";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo, useModal } from "@hooks";
import { ObserverTable } from "@interfaces/ObserverTable";
import { ServiceHelper } from "@modules";
import { useSnackbar } from "notistack";

const ObserverLogsTable = () => {
    const { permission } = useCurrentPageInfo();
    const { enqueueSnackbar } = useSnackbar();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_OBSERVER_TABLE", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: ObserverTable | ObserverTable[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await logService.deleteObserverTable(ids);
            enqueueSnackbar("Наблюдатель успешно удален", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении наблюдателя", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<ObserverTable>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 100,
        },
        {
            title: "Таблица",
            field: "table_name",
            width: "auto",
        },
        {
            title: "Создание",
            field: "insert",
            type: "boolean",
            width: "auto",
        },
        {
            title: "Обновление",
            field: "update",
            type: "boolean",
            width: "auto",
        },
        {
            title: "Удаление",
            field: "delete",
            type: "boolean",
            width: "auto",
        },
        {
            title: "Дата",
            field: "created_at",
            type: "datetime",
            filtering: false,
            width: 120,
        },
    ];

    return (
        <DataTable<ObserverTable>
            fetchFn={logService.getObserverTable}
            columns={columns}
            displayField="table_name"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            selection
            filtering
            sorting
            search
        />
    );
};

export default ObserverLogsTable;
