import { nsiService, storeService } from "@services";
import { useState } from "react";

export enum IMPORT_DATA_TYPE {
    DISCOUNTS = "discounts",
    STORES = "stores",
    PRICES = "prices",
    QUANTITY = "quantity",
    NEW_ENTITIES = "new_entities",
    EXIST_ENTITIES = "exist_entities",
}

const useImportDataControls = (
    importType: IMPORT_DATA_TYPE,
    { entityTypeId = 0, submitCallback = () => {} }
) => {
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const defaultSuccessMessage = "Данные загружены";
    const defaultErrorMessage = "Произошла ошибка при загрузке данных из файла";

    const handleChangeFile = (files: File[]) => {
        setSuccess("");
        setError("");
    };

    const handleSubmit = async (files: File[]) => {
        setSuccess("");
        setError("");

        switch (importType) {
            case IMPORT_DATA_TYPE.EXIST_ENTITIES: {
                await uploadFileToEntitiesService(files, "exist");
                break;
            }
            case IMPORT_DATA_TYPE.NEW_ENTITIES: {
                await uploadFileToEntitiesService(files, "new");
                break;
            }
            case IMPORT_DATA_TYPE.PRICES: {
                await uploadFileToStorePriceService(files);
                break;
            }
            case IMPORT_DATA_TYPE.QUANTITY: {
                await uploadFileToStoreQuantityService(files);
                break;
            }
            case IMPORT_DATA_TYPE.STORES: {
                await uploadFileToStoreService(files);
                break;
            }
            case IMPORT_DATA_TYPE.DISCOUNTS: {
                await uploadFileToStoreDiscountsService(files);
                break;
            }
        }
    };

    const uploadFileToEntitiesService = async (files: File[], type: "new" | "exist") => {
        if (!files || !importType || !entityTypeId) return;

        try {
            let msg: string = "";
            switch (type) {
                case "new": {
                    msg = await nsiService.uploadCSVdata(files[0], entityTypeId);
                    break;
                }
                case "exist": {
                    msg = await nsiService.uploadEntityShapshot(files[0], entityTypeId);
                }
            }

            setSuccess(msg);
            submitCallback();
        } catch (err) {
            const message = err.response?.data.result || defaultErrorMessage;
            setError(message);
        }
    };

    const uploadFileToStorePriceService = async (files: File[]) => {
        if (!files) return;

        try {
            const { result } = await storeService.uploadPriceCSVdata(files[0]);
            setSuccess(result || defaultSuccessMessage);
            submitCallback();
        } catch (err) {
            const message = err.response?.data.result || defaultErrorMessage;
            setError(message);
        }
    };

    const uploadFileToStoreDiscountsService = async (files: File[]) => {
        if (!files) return;
        try {
            const { result } = await storeService.uploadDiscountCSVdata(files[0]);
            setSuccess(result || defaultSuccessMessage);
            submitCallback();
        } catch (err) {
            const message = err.response?.data.result || defaultErrorMessage;
            setError(message);
        }
    };

    const uploadFileToStoreQuantityService = async (files: File[]) => {
        if (!files) return;

        try {
            const { result } = await storeService.uploadQuantityCSVdata(files[0]);
            setSuccess(result || defaultSuccessMessage);
            submitCallback();
        } catch (err) {
            const message = err.response?.data.result || defaultErrorMessage;
            setError(message);
        }
    };

    const uploadFileToStoreService = async (files: File[]) => {
        if (!files.length) return;

        try {
            const { result } = await storeService.uploadCSVStoreFile(files[0]);
            setSuccess(result ?? defaultSuccessMessage);
            submitCallback();
        } catch (err) {
            const message = err.response?.data.result || defaultErrorMessage;
            setError(message);
        }
    };

    return {
        handleChangeFile,
        handleSubmit,
        success,
        error,
    };
};

export default useImportDataControls;
