import React, { ComponentType, useCallback, useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import { ProtectedPathsManager, RouteManager } from "@modules";
import { staffProfileService } from "@services/Staff";
import { AuthEvents, AuthState } from "@store/auth-module";
import { LayoutRoute } from "react-base-routing";
import { CommonEvents, CommonState } from "@store/common-module";
import { useAsync, useLoading } from "@hooks";
import { PreloaderScreen } from "@components/Views/Preloader";
import { ProtectedPath } from "@interfaces/ProtectedPath";

export type WithProtectedPathsProps<T> = T & {
    paths: string[];
    routes: LayoutRoute[];
};

const withProtectedPaths =
    (WrappedComponent: ComponentType, { fallback = true } = {}) =>
    (props) => {
        const { loadingStatus, setLoadingStatus } = useLoading();

        const [paths, setPaths] = useState<string[]>([]);
        const [routes, setRoutes] = useState<LayoutRoute[]>([]);
        const { isLoggedIn } = useStoreon<AuthState, AuthEvents>("isLoggedIn");
        const { dispatch: commonDispatch } = useStoreon<CommonState, CommonEvents>("routes");

        const {
            data,
            loadingStatus: asyncLoadingStatus,
            updateData,
        } = useAsync(staffProfileService.getProtectedPaths, {
            canLoad: isLoggedIn,
        });

        const setupAppRoutes = useCallback(
            (data: ProtectedPath[]) => {
                setLoadingStatus("loading");

                const protectedPaths = ProtectedPathsManager.getAllAccessiblePaths(data);

                const protectedRoutes = RouteManager.getProtectedRoutes(data);

                const routes = RouteManager.insertRoutes(
                    RouteManager.getStaticRoutes(),
                    protectedRoutes
                );

                setRoutes(routes);
                setPaths(protectedPaths);
                commonDispatch("common/set/menu", data);
                commonDispatch("common/set/routes", routes);
                commonDispatch("common/set/update_menu_and_routes", updateData);
                setLoadingStatus("loaded");
            },
            [commonDispatch, setLoadingStatus, updateData]
        );

        useEffect(() => {
            if (asyncLoadingStatus === "loaded" && data) {
                setupAppRoutes(isLoggedIn ? data : []);
            }

            if (asyncLoadingStatus === "inactivity") {
                setupAppRoutes([]);
            }
        }, [isLoggedIn, data, setupAppRoutes, asyncLoadingStatus]);

        if (loadingStatus !== "loaded" || asyncLoadingStatus === "loading") {
            return fallback ? <PreloaderScreen /> : null;
        }

        return <WrappedComponent {...props} paths={paths} routes={routes} />;
    };

export default withProtectedPaths;
