import { ProtectedPath } from "@interfaces/ProtectedPath";
import { Path } from "@interfaces/System/Path";
import { PathsManager, PermissionManager, ProtectedPathsManager } from "@modules";
import { PAGE_TYPE, WithPageProps } from "@modules/PageTypesManager";
import { PermissionWithNoValue } from "@modules/PermissionManager";
import { CommonEvents, CommonState } from "@store/common-module";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { useStoreon } from "storeon/react";
import { useEffect, useState } from "react";
import { CombinedType } from "@modules/ContentTypesManagers/CombinedContentTypesManager";

type RouteParams = { [key: string]: string };

export type PageInfo = WithPageProps<{}> & {
    /** param from current page */
    param?: any;
    /** all params from url */
    params: Record<string, string>;
    query: URLSearchParams;
    permission: PermissionWithNoValue;
    parentId?: number;
    page_type?: PAGE_TYPE;
    content_type?: CombinedType;
    key: string;
    page_id: number;
};

interface UseCurrentPageInfoState {
    permission: PermissionWithNoValue;
    page: Path | ProtectedPath | null;
    key: string;
}

const useCurrentPageInfo = () => {
    const params: RouteParams = useParams();

    const { path } = useRouteMatch();
    const { search } = useLocation();
    const { menu } = useStoreon<CommonState, CommonEvents>("menu");

    const [state, setState] = useState<UseCurrentPageInfoState>({
        permission: -1,
        page: null,
        key: "",
    });

    useEffect(() => {
        const permission = PermissionManager.getPermissionFromProtectedPaths(menu, path);

        const page = ProtectedPathsManager.getPath(menu, path);
        const key = PathsManager.getPathKey(path);

        setState((prev) => ({
            ...prev,
            permission,
            page: page ?? null,
            key,
        }));
    }, [path, menu]);

    const { permission, page, key } = state;

    const pageInfo: PageInfo = {
        permission,
        param: Object.values(params).slice(-1)[0],
        params: params,
        query: new URLSearchParams(search),
        url: ProtectedPathsManager.excludeQueryParams(page?.url ?? ""),
        icon: page?.icon,
        title: page?.title ?? "",
        description: page?.description,
        parentId: (page as ProtectedPath)?.parent_id || undefined,
        page_type: (page as ProtectedPath)?.page_type as PAGE_TYPE,
        content_type: (page as ProtectedPath)?.content_type as CombinedType,
        key: key,
        page_id: (page as ProtectedPath)?.id,
    };

    return pageInfo;
};

export default useCurrentPageInfo;
