import React, { useState } from "react";
import { Box, Tab, Tabs as MuiTabs, TabsProps } from "@material-ui/core";

import { TabPanel } from "../../Common";

interface Props {
    tabs: string[];
    panels: JSX.Element[];
    marginTop?: number;
}

const Tabs = ({ tabs, panels, marginTop, ...props }: Props & TabsProps) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box mt={marginTop || 0}>
            <MuiTabs onChange={handleChangeTab} value={selectedTab} {...props}>
                {tabs.map((tab, index) => (
                    <Tab key={tab} label={tab} value={index} fullWidth />
                ))}
            </MuiTabs>
            {panels.map((panel, index) => (
                <TabPanel key={index} value={selectedTab} index={index}>
                    {panel}
                </TabPanel>
            ))}
        </Box>
    );
};

export default Tabs;
