import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { vTypesService } from "../../../services";
import ValueTypeForm from "../../Forms/ValueTypeForm";
import { CreateValueTypeDto } from "../../../dto/ValueTypeDto";
import { DialogContextProps } from "../../../interfaces/System/DialogContextProps";
import { useLoading, useTable } from "@hooks";

const CreateValueTypeDialogContext = ({ onClose }: DialogContextProps) => {
    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { updateData } = useTable();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreateValueType = async (values) => {
        setSubmitLoadingStatus("loading");
        try {
            const dto = vTypesService.plainToClass(CreateValueTypeDto, values);
            await vTypesService.createValueType(dto);
            enqueueSnackbar("Тип значения успешно создан", {
                variant: "success",
            });
            onClose();
            updateData();
        } catch (err) {
            setSubmitLoadingStatus("loaded");
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании типа значения", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent>
                <ValueTypeForm onSubmit={handleCreateValueType} ref={formRef} />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateValueTypeDialogContext;
