import { AppValueType } from "@interfaces/Internal/AppValueType";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { ValueType } from "@interfaces/ValueType";
import RestrictionsManager from "@modules/RestrictionsManager";
import { ValueTypeSchema } from "@schema/ValueTypeSchema";
import AutoSizeTextField from "../Controls/Inputs/AutoSizeTextField";
import DataTypeSelect from "../Controls/Selects/DataTypeSelect";
import ValueTypeRestrictions from "../Views/Restrictions/ValueTypeRestrictions";

const ValueTypeForm = forwardRef(({ onSubmit, data }: CommonFormProps<ValueType>, ref: any) => {
    const initialData = {
        name: data?.name || "",
        notes: data?.notes || "",
        pt_id: data?.pt_id || null,
        useRestrictions: !!RestrictionsManager.getRestrictionsArrayFromObject(
            data?.restrictions || {}
        ),
        restrictions: data
            ? RestrictionsManager.getRestrictionsArrayFromObject(data?.restrictions || {})
            : [],
    } as AppValueType & ValueType;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={ValueTypeSchema}
            onSubmit={(values, helpers) => {
                const copy = { ...values };
                copy.name = copy.name.trim();
                copy.notes = copy.notes.trim();
                onSubmit(copy, helpers as any);
            }}
            validateOnMount
        >
            {({ touched, errors, handleChange, setFieldValue, values }) => {
                return (
                    <Form>
                        <AutoSizeTextField
                            name="name"
                            variant="outlined"
                            margin="normal"
                            size="small"
                            required
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            label="Наименование"
                            value={values.name}
                            onChange={handleChange}
                            fullWidth
                            maxRows={3}
                        />

                        <AutoSizeTextField
                            name="notes"
                            label="Комментарий"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={handleChange}
                            helperText="Необязательно"
                            value={values.notes}
                            margin="normal"
                            maxRows={5}
                            rows={2}
                        />

                        <DataTypeSelect
                            name="pt_id"
                            variant="outlined"
                            fullWidth
                            size="small"
                            required
                            onChange={handleChange}
                            helperText={touched.pt_id ? errors.pt_id : "Выберите одно из значений"}
                            error={touched.pt_id && Boolean(errors.pt_id)}
                            value={values.pt_id}
                            margin="normal"
                        />

                        <ValueTypeRestrictions
                            restrictions={values.restrictions || []}
                            onChangeUseRestrictions={(value) =>
                                setFieldValue("useRestrictions", value)
                            }
                            onChange={(values) => setFieldValue("restrictions", values)}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default ValueTypeForm;
