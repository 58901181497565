import { Tooltip, Typography } from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { REQUIRED_TYPE, REQUIRED_TYPES } from "@modules/ValidationManager";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(() => ({
    hidden: {
        display: "none",
    },
    red: {
        color: red[500],
    },
    grey: {
        color: grey[500],
    },
}));

interface Props {
    type: REQUIRED_TYPE;
}

const RequiredTypeSymbol = ({ type }: Props) => {
    const classes = useStyles();

    const rType = Object.values(REQUIRED_TYPES).find((el) => el.value === type);
    return (
        <Tooltip title={rType?.description || ""} arrow>
            <Typography
                variant="h6"
                display="inline"
                style={{ marginRight: 10 }}
                className={clsx(
                    { [classes.hidden]: type === REQUIRED_TYPES.NOT.value },
                    { [classes.red]: type === REQUIRED_TYPES.FULL.value },
                    { [classes.grey]: type === REQUIRED_TYPES.PART.value }
                )}
            >
                *
            </Typography>
        </Tooltip>
    );
};

export default RequiredTypeSymbol;
