import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { fileService } from "@services";
import { useAsync, useLoading } from "@hooks";
import { ModalContentLoading } from "@components/Common/Modal";
import { FileForm } from "@components/Forms/FileStorage";
import { UpdateFileInfoDto } from "@dto/FileDto";
import { FilePreview } from "@components/Views/FilePreview";

const FileDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { onUpdate = () => {}, itemId } = { ...modalProps };

    const { data, loadingStatus } = useAsync(fileService.getOneFile, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleUpdateFile = async (values) => {
        if (!itemId) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = fileService.plainToClass(UpdateFileInfoDto, values);

            await fileService.updateFileInfo(itemId, dto);
            enqueueSnackbar("Файл успешно обновлен", {
                variant: "success",
            });
            onClose();
            await onUpdate();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении файла", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {loadingStatus === "loaded" && data && (
                    <>
                        <FilePreview
                            file={data.path}
                            containerStyle={{
                                height: 400,
                                margin: "auto",
                            }}
                            imageStyle={{
                                top: "50%",
                                bottom: "50%",
                            }}
                        />
                        <FileForm onSubmit={handleUpdateFile} ref={formRef} data={data} />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default FileDialogContext;
