import { ModalContentLoading } from "@components/Common/Modal";
import StaffMenuPermissionForm from "@components/Forms/Settings/StaffMenuPermissionForm";
import {
    CreateStaffMenuPermissionDto,
    UpdateStaffMenuPermissionDto,
} from "@dto/SettingsDto/StaffMenuPermissionDto";
import { useAsync, useLoading } from "@hooks";
import { StaffMenuPermission } from "@interfaces/StaffMenuPermission";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { staffMenuPermissionService } from "@services/Settings";
import { TableEvents, TableState } from "@store/table-module";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useStoreon } from "storeon/react";

const StaffMenuPermissionDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data, loadingStatus } = useAsync<StaffMenuPermission>(
        staffMenuPermissionService.getOnePermission,
        { functionArgs: [itemId], canLoad: !!itemId }
    );

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreatePermission = async (values) => {
        setSubmitLoadingStatus("loading");
        try {
            const dto = staffMenuPermissionService.plainToClass(
                CreateStaffMenuPermissionDto,
                values
            );

            await staffMenuPermissionService.createPermission(dto);
            enqueueSnackbar("Настройка успешно создана", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании настройки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleUpdatePermission = async (values) => {
        if (!itemId) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = staffMenuPermissionService.plainToClass(
                UpdateStaffMenuPermissionDto,
                values
            );

            await staffMenuPermissionService.updatePermission(itemId, dto);
            enqueueSnackbar("Настройка успешно обновлена", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении настройки", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <StaffMenuPermissionForm
                        ref={formRef}
                        onSubmit={itemId ? handleUpdatePermission : handleCreatePermission}
                        data={itemId ? data : undefined}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default StaffMenuPermissionDialogContext;
