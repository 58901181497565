import { IconButton, TextField, TextFieldProps } from "@material-ui/core";
import { Clear, Search } from "@material-ui/icons";
import React, { ChangeEvent, memo } from "react";

type Props = { onChangeInput(value: string): void };

const SearchInput = ({ onChangeInput, ...props }: Props & TextFieldProps) => {
    const { value } = props;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        onChangeInput(value);
    };

    const handleClear = () => {
        onChangeInput("");
    };

    return (
        <TextField
            value={value}
            onChange={handleChange}
            placeholder="Поиск"
            size="small"
            InputProps={{
                startAdornment: <Search style={{ marginRight: 10 }} />,
                endAdornment: (
                    <IconButton size="small" onClick={handleClear} disabled={!value}>
                        <Clear />
                    </IconButton>
                ),
            }}
            {...props}
        />
    );
};

export default memo(SearchInput);
