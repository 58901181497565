import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { EShop } from "@interfaces/EShop";
import { CreateEShopSchema, UpdateEShopSchema } from "@schema/Eshop";

const EShopForm = forwardRef(({ onSubmit, data }: CommonFormProps<EShop>, ref: any) => {
    const initialData = {
        title: data?.title ?? "",
        url: data?.url ?? "",
        last_number: data?.last_number ?? null,
        cart_number_prefix: data?.cart_number_prefix ?? "",
        is_active: data?.is_active,
    } as EShop;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={data ? UpdateEShopSchema : CreateEShopSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, setFieldValue }) => {
                return (
                    <Form>
                        <TextField
                            name="title"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            helperText={touched.title ? errors.title : ""}
                            error={touched.title && !!errors.title}
                            label="Название магазина"
                            value={values.title}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="url"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            helperText={touched.url ? errors.url : ""}
                            error={touched.url && !!errors.url}
                            label="URL адрес"
                            value={values.url}
                            onChange={handleChange}
                            fullWidth
                        />

                        {data && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.is_active}
                                        onChange={handleChange}
                                        name="is_active"
                                        color="primary"
                                    />
                                }
                                label="Активен"
                            />
                        )}

                        <TextField
                            name="last_number"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            helperText={touched.last_number ? errors.last_number : ""}
                            error={touched.last_number && !!errors.last_number}
                            label="Последняя цифра"
                            value={values.last_number}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="cart_number_prefix"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            helperText={touched.cart_number_prefix ? errors.cart_number_prefix : ""}
                            error={touched.cart_number_prefix && !!errors.cart_number_prefix}
                            label="Префикс корзины"
                            value={values.cart_number_prefix}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default EShopForm;
