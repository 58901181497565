import PageHead from "@components/Views/PageHead/PageHead";
import { PermissionManager } from "@modules";
import React from "react";
import { useContentTitle, useCurrentPageInfo, useTitle } from "@hooks";
import {
    CombinedContentTypesManager,
    ContentTypeNotFoundError,
    WithContentTypeProps,
} from "@modules/ContentTypesManagers";
import { ContentType } from "@modules/ContentTypesManagers/ContentTypesManager";

const EnhancedPageHead = ({ contentType }: WithContentTypeProps<unknown>) => {
    const pageInfo = useCurrentPageInfo();
    const { contentTitle, loadingStatus } = useContentTitle(contentType, pageInfo.param);
    useTitle(loadingStatus === "loading" ? "Загрука..." : contentTitle);

    if (!contentType) return null;
    const type = CombinedContentTypesManager.getContentType(contentType) as ContentType;
    if (!type) throw new ContentTypeNotFoundError(contentType);

    const content = type.content;

    const Children = content.getPageHeadChildren(pageInfo);
    const props = (content.getPageHeadProps && content.getPageHeadProps(pageInfo)) || {};

    return (
        <PageHead noTitle title={contentTitle} isLoading={loadingStatus === "loading"} {...props}>
            {PermissionManager.canCreate(pageInfo.permission) && Children}
        </PageHead>
    );
};

export default EnhancedPageHead;
