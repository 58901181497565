import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { OrderItemSchema } from "@schema/Order";
import { OrderItem } from "@interfaces/OrderItem";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { Order } from "@interfaces/Order";
import { orderService } from "@services";

const OrderItemForm = forwardRef(({ onSubmit, data }: CommonFormProps<OrderItem>, ref: any) => {
    const initialData = {
        order_id: data?.order_id ?? null,
        sku: data?.sku ?? "",
        quantity: data?.quantity ?? null,
        excluded: data?.excluded ?? false,
        rrc_price: data?.rrc_price ?? null,
        sale_price: data?.sale_price ?? null,
        discount_money: data?.discount_money ?? null,
        discount_percent: data?.discount_percent ?? null,
    } as OrderItem;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={OrderItemSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, setFieldValue }) => {
                return (
                    <Form>
                        <AsyncAutocomplete<Order>
                            fetchFn={orderService.getOrders}
                            onChange={(name, value) => setFieldValue(name, value?.id || null)}
                            value={values.order_id}
                            optionField="number"
                            textFieldProps={{
                                required: true,
                                fullWidth: true,
                                name: "order_id",
                                variant: "outlined",
                                size: "small",
                                label: "Корзина",
                                margin: "normal",
                                error: touched.order_id && !!errors.order_id,
                                helperText: touched.order_id ? errors.order_id : "",
                            }}
                        />

                        {data && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.excluded}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        name="excluded"
                                        color="primary"
                                    />
                                }
                                label="Исключено из заказа"
                            />
                        )}

                        <TextField
                            name="sku"
                            variant="outlined"
                            size="small"
                            required
                            margin="normal"
                            helperText={touched.sku ? errors.sku : ""}
                            error={touched.sku && !!errors.sku}
                            label="SKU"
                            value={values.sku}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="quantity"
                            variant="outlined"
                            size="small"
                            required
                            margin="normal"
                            type="number"
                            helperText={touched.quantity ? errors.quantity : ""}
                            error={touched.quantity && !!errors.quantity}
                            label="Количество"
                            value={values.quantity}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="rrc_price"
                            variant="outlined"
                            type="number"
                            size="small"
                            margin="normal"
                            helperText={touched.rrc_price ? errors.rrc_price : ""}
                            error={touched.rrc_price && !!errors.rrc_price}
                            label="RRC Цена"
                            value={values.rrc_price}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="sale_price"
                            variant="outlined"
                            type="number"
                            size="small"
                            margin="normal"
                            helperText={touched.sale_price ? errors.sale_price : ""}
                            error={touched.sale_price && !!errors.sale_price}
                            label="Цена продажи"
                            value={values.sale_price}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="discount_percent"
                            variant="outlined"
                            type="number"
                            size="small"
                            margin="normal"
                            helperText={touched.discount_percent ? errors.discount_percent : ""}
                            error={touched.discount_percent && !!errors.discount_percent}
                            label="Скидка в %"
                            value={values.discount_percent}
                            onChange={handleChange}
                            fullWidth
                        />

                        <TextField
                            name="discount_money"
                            variant="outlined"
                            type="number"
                            size="small"
                            margin="normal"
                            helperText={touched.discount_money ? errors.discount_money : ""}
                            error={touched.discount_money && !!errors.discount_money}
                            label="Скидка"
                            value={values.discount_money}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default OrderItemForm;
