import { FilePreview } from "@components/Views/FilePreview";
import useFileIdentity from "@hooks/useFileIdentity";
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    SvgIconProps,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import clsx from "clsx";
import React, { memo, useCallback, useState } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
    dropzone: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: theme.palette.divider,
        borderStyle: "dashed",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
        outline: "none",
        transition: "border .24s ease-in-out",
        cursor: "pointer",
    },
    activeStyle: {
        borderColor: theme.palette.primary.main,
    },
    acceptStyle: {
        borderColor: theme.palette.success.main,
    },
    rejectStyle: {
        borderColor: theme.palette.error.main,
    },
    icon: {
        width: 30,
        height: 30,
    },
    marginTopBottom: {
        marginTop: 10,
        marginBottom: 10,
    },
}));

type Props = Partial<{
    onDrop: (files: File[]) => void;
    onSubmit(files: File[]): Promise<void>;
    onClear(): void;
    maxFiles: number;
    preview: boolean;
    dropzoneOptions: DropzoneOptions;
    text: React.ReactNode;
}>;

const DropzoneField = ({
    onDrop,
    onClear,
    onSubmit,
    maxFiles = 3,
    preview = false,
    dropzoneOptions = {},
    text,
}: Props) => {
    const classes = useStyles();

    const [files, setFiles] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const maxFilesCountErrorMessage = "Выбрано максимальное количество файлов!";

    const handleDrop = useCallback(
        (acceptedFiles, rejected, e) => {
            if (acceptedFiles.length + files.length > maxFiles) {
                setError(maxFilesCountErrorMessage);
                return;
            }

            if (rejected.length + files.length > maxFiles) {
                setError(maxFilesCountErrorMessage);
                return;
            }

            setFiles([...files, ...acceptedFiles]);
            onDrop && onDrop(acceptedFiles);
        },
        [files, onDrop, maxFiles]
    );

    const handleSubmit = async () => {
        setLoading(true);
        onSubmit && (await onSubmit(files));
        handleClear();
        setLoading(false);
    };

    const handleDeleteFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setError("");

        setFiles(updatedFiles);
        onDrop && onDrop(updatedFiles);
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        maxFiles,
        onDrop: handleDrop,
        ...dropzoneOptions,
    });

    const handleClear = () => {
        setError("");
        setFiles([]);
        onClear && onClear();
    };

    return (
        <>
            <div
                {...getRootProps()}
                className={clsx(classes.dropzone, {
                    [classes.activeStyle]: isDragActive,
                    [classes.acceptStyle]: isDragAccept,
                    [classes.rejectStyle]: isDragReject || !!error,
                })}
            >
                <input {...getInputProps()} />
                <Typography variant="subtitle1">
                    Перетащите сюда файлы или нажмите что-бы выбрать их
                </Typography>
                <Typography variant="body2" gutterBottom>
                    ( Максимум файлов для загрузки: <b>{maxFiles}</b> )
                </Typography>
                <Typography variant="body2">{text}</Typography>
                {error && (
                    <Typography variant="body2" color="error">
                        {error}
                    </Typography>
                )}
            </div>

            {preview && (
                <Grid container spacing={1}>
                    {files.map((file, i) => (
                        <Grid item key={i}>
                            <FilePreview
                                file={file}
                                containerStyle={{
                                    maxHeight: 104,
                                    width: 104,
                                }}
                                imageStyle={{
                                    top: "50%",
                                    bottom: "50%",
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}

            {loading && <LinearProgress className={classes.marginTopBottom} />}

            <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ marginTop: 10, marginBottom: 10 }}
            >
                <Typography>Выбранные файлы</Typography>
                <Button
                    onClick={handleClear}
                    color="secondary"
                    size="small"
                    disabled={!files.length}
                >
                    Очистить
                </Button>
            </Grid>

            <List disablePadding dense>
                {!files?.length && (
                    <Typography variant="body2" color="textSecondary">
                        Файлы не выбраны
                    </Typography>
                )}
                {files?.map((file, index) => (
                    <DropzoneFileItem
                        key={index}
                        index={index}
                        file={file}
                        onDelete={handleDeleteFile}
                    />
                ))}
            </List>

            {onSubmit && (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!files.length || loading}
                    fullWidth
                    className={classes.marginTopBottom}
                >
                    {loading ? "Загрузка..." : "Загрузить"}
                </Button>
            )}
        </>
    );
};

const DropzoneFileItem = memo(({ file, index, onDelete }: any) => {
    const classes = useStyles();

    const { getFileIcon } = useFileIdentity(file);

    const Icon = getFileIcon();
    const iconProps: SvgIconProps = { fontSize: "small" };

    return (
        <ListItem disableGutters>
            <ListItemAvatar>
                <Avatar className={classes.icon}>
                    <Icon {...iconProps} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={file.name} />

            <ListItemSecondaryAction>
                <Tooltip arrow title="Удалить">
                    <IconButton edge="end" size="small" onClick={() => onDelete(index)}>
                        <Delete fontSize="small" />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
});

export default DropzoneField;
