import { Tooltip } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(() => ({
    circle: {
        height: 15,
        width: 15,
        borderRadius: "50%",
    },
    red: {
        backgroundColor: red[500],
    },
    green: {
        backgroundColor: green[500],
    },
}));

type Props = {
    isActive: boolean;
    text?: Partial<{ active: string; inactive: string }>;
};

const StatusSymbol = ({ isActive, text = {} }: Props) => {
    const classes = useStyles();
    const { active = "Активен", inactive = "Не активен" } = text;

    return (
        <Tooltip title={isActive ? active : inactive} arrow>
            <div
                className={clsx(
                    classes.circle,
                    { [classes.green]: isActive },
                    { [classes.red]: !isActive }
                )}
            />
        </Tooltip>
    );
};

export default StatusSymbol;
