import { QueryResult, QueryParams, PaginatedQueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { AttributeTypeBind } from "@interfaces/AttributeTypeBind";
import { CreateAttributeTypeBindDto, UpdateAttributeTypeBindDto } from "@dto/AttributeTypeBindDto";

class AttributeTypeBindService extends BaseService {
    public getATypeBinds = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<AttributeTypeBind>> => {
        const { data } = await this.instance.get("/settings/ns_atype_bind", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneATypeBind = async (
        aTypeBindId: number,
        params: QueryParams = {}
    ): Promise<AttributeTypeBind> => {
        const { data } = await this.instance.get("/settings/ns_atype_bind", {
            params: { id: aTypeBindId, ...params },
        });

        return data.result.items[0];
    };

    public createATypeBind = async (
        createAttributeTypeBindDto: CreateAttributeTypeBindDto
    ): Promise<QueryResult<AttributeTypeBind>> => {
        const { data } = await this.instance.post("/settings/ns_atype_bind", {
            data: createAttributeTypeBindDto,
        });

        return data;
    };

    public updateATypeBind = async (
        id: number,
        updateAttributeTypeBindDto: UpdateAttributeTypeBindDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/settings/ns_atype_bind", {
            id,
            data: updateAttributeTypeBindDto,
        });

        return data;
    };

    public deleteATypeBinds = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/settings/ns_atype_bind", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new AttributeTypeBindService(new HttpClient(Config.API_URL), AuthManager);
