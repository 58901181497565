import { AuthEvents, AuthState } from "@store/auth-module";
import { useStoreon } from "storeon/react";

const useAuth = () => {
    const { user, token, isLoggedIn } = useStoreon<AuthState, AuthEvents>(
        "user",
        "token",
        "isLoggedIn"
    );

    return { user, token, isLoggedIn };
};

export default useAuth;
