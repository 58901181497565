import { Box } from "@material-ui/core";
import React from "react";
import GoBackButton from "../components/Controls/Buttons/GoBackButton";
import NotFound from "../components/Views/NotFound/NotFound";

const Error404Page = ({ title = "Страница не найдена" }) => {
    return (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <NotFound title={title} />
            <GoBackButton />
        </Box>
    );
};

export default Error404Page;
