import { TextFieldProps } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "@hooks";
import { fileService } from "@services";
import CustomAsyncAutocomplete from "./CustomAsyncAutocomplete";
import { Directory } from "@interfaces/Directory";

interface Props {
    textFieldProps: TextFieldProps;
    onChange: (name, value) => void;
    value: number | null;
    [key: string]: any;
}

const DirectoryAutocomplete = ({ textFieldProps, onChange, value, ...props }: Props) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [selectedValue, setSelectedValue] = useState<Directory | null>(null);

    const { data, loadingStatus } = useAsync(fileService.getDirectories, {
        setLoadingStatusOnUpdate: false,
        functionArgs: [
            {
                page: 1,
                page_size: 20,
                ids_list: !inputValue ? value : undefined,
                q: inputValue || undefined,
            },
        ],
    });

    const dirs = useMemo(
        () => (loadingStatus === "loaded" && data ? data.result.items : []),
        [data, loadingStatus]
    );

    useEffect(() => {
        const item = dirs.find((el) => value === el?.id);

        setSelectedValue(item || null);
    }, [value, dirs]);

    const handleChangeValue = useCallback(
        (e, directory: Directory) => {
            onChange(textFieldProps.name, directory?.id || null);
        },
        [onChange, textFieldProps]
    );

    return (
        <CustomAsyncAutocomplete
            {...props}
            isLoading={loadingStatus === "loading"}
            value={selectedValue}
            items={dirs || []}
            textFieldProps={textFieldProps}
            optionField="title"
            onChange={handleChangeValue}
            onChangeInput={setInputValue}
        />
    );
};

export default DirectoryAutocomplete;
