import React, { useCallback, useEffect, useState } from "react";
import { useAsync } from "@hooks";
import { PaginatedQueryResult, QueryParams } from "@services";
import CustomAsyncAutocomplete, { CustomAutocompleteProps } from "./CustomAsyncAutocomplete";

type AsyncAutocompleteProps<D> = Omit<
    CustomAutocompleteProps<D>,
    "items" | "isLoading" | "onChange" | "value"
> & {
    fetchFn(params: QueryParams): Promise<PaginatedQueryResult<D>>;
    onChange: (name: string, value: D | null) => void;
    value: any;
    findBy?: keyof D | "id";
    /** [field, value] */
    filter?: [string, any];
    exludeValues?: number[];
};

const AsyncAutocomplete = <D extends { id: number }>(props: AsyncAutocompleteProps<D>) => {
    const {
        fetchFn,
        textFieldProps,
        onChange,
        value,
        findBy = "id",
        filter = ["", null],
        exludeValues,
        ...other
    } = props;

    const [inputValue, setInputValue] = useState<string>("");
    const [selectedValue, setSelectedValue] = useState<D | null>(null);

    const { data, loadingStatus } = useAsync(fetchFn, {
        functionArgs: [
            {
                page_size: 20,
                page: 1,
                q: inputValue || undefined,
                [findBy]: (!inputValue && value) || undefined,
                [filter[0]]: filter[1] ?? undefined,
            },
        ],
        setLoadingStatusOnUpdate: false,
    });

    useEffect(() => {
        if (loadingStatus === "loaded") {
            const item = data?.result.items?.find((el) => value === el[findBy as string]);
            setSelectedValue(item || null);
        }
    }, [value, loadingStatus, data, findBy]);

    const handleChangeValue = useCallback(
        (e, item: D | null) => {
            onChange(textFieldProps.name ?? "", item || null);
            setInputValue("");
        },
        [onChange, textFieldProps]
    );

    const items =
        loadingStatus === "loaded" && data
            ? data?.result.items.filter((i) => !exludeValues?.includes(i.id))
            : [];

    return (
        <CustomAsyncAutocomplete<D>
            {...other}
            isLoading={loadingStatus === "loading"}
            value={selectedValue || null}
            items={items}
            onChangeInput={setInputValue}
            textFieldProps={textFieldProps}
            onChange={handleChangeValue}
        />
    );
};

export default AsyncAutocomplete;
