import { TextField, TextFieldProps, MenuItem } from "@material-ui/core";
import { REQUIRED_TYPES } from "@modules/ValidationManager";
import React from "react";

const RequiredTypeSelect = ({ ...props }: TextFieldProps) => {
    return (
        <TextField
            {...props}
            value={props.value || REQUIRED_TYPES.NOT.value}
            select
            label="Параметр обязательности"
        >
            {Object.values(REQUIRED_TYPES).map((type) => {
                return (
                    <MenuItem key={type.value} value={type.value}>
                        {type.title}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default RequiredTypeSelect;
