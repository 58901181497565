import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { ModalContentLoading } from "@components/Common/Modal";
import React, { useRef } from "react";
import { orderService } from "@services";
import { useStoreon } from "storeon/react";
import { TableEvents, TableState } from "@store/table-module";
import { useSnackbar } from "notistack";
import { useAsync } from "@hooks";
import { CreateOrderItemDto, UpdateOrderItemDto } from "@dto/OrderDto";
import { OrderItemForm } from "@components/Forms/Order";

const OrderItemDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = modalProps;

    const formRef = useRef<HTMLFormElement>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();

    const { data, loadingStatus } = useAsync(orderService.getOneOrderItem, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current!.handleSubmit();
    };

    const handleCreateOrderItem = async (values) => {
        try {
            const dto = orderService.plainToClass(CreateOrderItemDto, values);
            await orderService.createOrderItem(dto);
            enqueueSnackbar("Элемент заказа успешно создан", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании элемента заказа", {
                variant: "error",
            });
        }
    };

    const handleUpdateOrderItem = async (values) => {
        if (!data) return;

        try {
            const dto = orderService.plainToClass(UpdateOrderItemDto, values);
            await orderService.updateOrderItem(data.id, dto);
            enqueueSnackbar("Элемент заказа успешно обновлен", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении элемента заказа", {
                variant: "error",
            });
        }
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <OrderItemForm
                        ref={formRef}
                        data={data}
                        onSubmit={data ? handleUpdateOrderItem : handleCreateOrderItem}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button color="primary" variant="contained" onClick={invokeSubmit}>
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default OrderItemDialogContext;
