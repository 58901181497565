import { Exclude, Expose } from "class-transformer";

@Exclude()
export class UpdateObserverTableDto {
    @Expose()
    readonly insert: boolean;

    @Expose()
    readonly delete: boolean;

    @Expose()
    readonly update: boolean;
}
