import * as Yup from "yup";

export const BaseEShopSchema = Yup.object().shape({
    title: Yup.string().required("Пожалуйста, укажите название магазина"),
    url: Yup.string().required("Пожалуйста, укажите URL адрес магазина"),
    cart_number_prefix: Yup.string().required(
        "Пожалуйста, укажите префикс для наименования корзины"
    ),
    last_number: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Пожалуйста, последнюю цифру магазина"),
});
