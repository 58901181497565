import { AttributeValueBind } from "@interfaces/AttributeValueBind";
import { DiscountType } from "@interfaces/Discount";
import { Exclude, Expose } from "class-transformer";

@Exclude()
export abstract class BaseDiscountDto implements Readonly<BaseDiscountDto> {
    @Expose()
    title: string;

    @Expose()
    key: string;

    @Expose()
    sku: string[];

    @Expose()
    percent: number;

    @Expose()
    max_percent: number;

    @Expose()
    money: number;

    @Expose()
    start_date: string;

    @Expose()
    end_date: string;

    @Expose()
    at_id: number;

    @Expose()
    attribute_value: string;

    @Expose()
    type: DiscountType;
}

@Exclude()
export class CreateDiscountDto extends BaseDiscountDto implements Readonly<CreateDiscountDto> {
    @Expose()
    discount_attribute_value_bindings: AttributeValueBind[];
}
