import { TableFilter } from "@interfaces/System/TableFilter";
import { TableEvents, TableState } from "@store/table-module";
import { useCallback } from "react";
import { useStoreon } from "storeon/react";

type UseTable = { updateData(): void; filters: TableFilter[] };

const useTable = (): UseTable => {
    const { dispatch, filters } = useStoreon<TableState, TableEvents>("filters");

    const updateData = useCallback(() => {
        dispatch("table/update/data");
    }, [dispatch]);

    return { updateData, filters };
};

export default useTable;
