import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useStoreon } from "storeon/react";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { TableEvents, TableState } from "@store/table-module";
import { logService } from "@services/Settings";
import { ObserverTableForm } from "@components/Forms/Settings";
import { useAsync, useLoading } from "@hooks";
import { ObserverTable } from "@interfaces/ObserverTable";
import { ModalContentLoading } from "@components/Common/Modal";
import { CreateObserverTableDto, UpdateObserverTableDto } from "@dto/ObserverTableDto";

const ObserverTableDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = modalProps;

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch: tableDispatch } = useStoreon<TableState, TableEvents>();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data, loadingStatus } = useAsync<ObserverTable | null>(logService.getOneObserverTable, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleCreateObserverTable = async (values) => {
        setSubmitLoadingStatus("loading");
        try {
            const dto = logService.plainToClass(CreateObserverTableDto, values);

            await logService.createObserverTable(dto);
            enqueueSnackbar("Наблюдатель успешно создан", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при создании наблюдателя", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    const handleUpdateObserverTable = async (values) => {
        if (!itemId) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = logService.plainToClass(UpdateObserverTableDto, values);

            await logService.updateObserverTable(itemId, dto);
            enqueueSnackbar("Наблюдатель успешно обновлен", {
                variant: "success",
            });
            onClose();
            tableDispatch("table/update/data");
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при обновлении наблюдателя", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {(loadingStatus === "loaded" || loadingStatus === "inactivity") && (
                    <ObserverTableForm
                        ref={formRef}
                        onSubmit={itemId ? handleUpdateObserverTable : handleCreateObserverTable}
                        data={itemId ? data : undefined}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default ObserverTableDialogContext;
