import { Exclude, Expose } from "class-transformer";

@Exclude()
export class ChangeStaffMemberPasswordDto {
    @Expose()
    readonly id: number;

    @Expose()
    readonly password: string;

    @Expose()
    readonly password2: string;
}
