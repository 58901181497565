import { Box } from "@material-ui/core";
import React from "react";
import { UniversalInputProps } from "../../../interfaces/System/UniversalInputProps";
import { RichTextEditor } from "../Inputs";

const URichTextEditor = ({ ...props }: UniversalInputProps) => {
    const handleChange = (content) => {
        props.onChange(props.name, content);
    };

    return (
        <Box mt={2}>
            <RichTextEditor
                {...props}
                onChange={handleChange}
                disable={props.disabled}
                hideToolbar={props.disabled}
            />
        </Box>
    );
};

export default URichTextEditor;
