import { QueryParams, PaginatedQueryResult, QueryResultWithoutData } from "..";
import BaseService from "../BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { GeneralSettings } from "@interfaces/GeneralSettings";
import {
    CreateGeneralSettingsDto,
    UpdateGeneralSettingsDto,
} from "@dto/SettingsDto/GeneralSettingsDto";

class GeneralSettingsService extends BaseService {
    public getGeneralSettings = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<GeneralSettings>> => {
        const { data } = await this.instance.get("/settings/general", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneGeneralSettings = async (id: number): Promise<GeneralSettings> => {
        const { data } = await this.instance.get("/settings/general", {
            params: { id },
        });

        return data.result.items[0];
    };

    public createGeneralSettings = async (
        createGeneralSettings: CreateGeneralSettingsDto
    ): Promise<any> => {
        const { data } = await this.instance.post("/settings/general", {
            data: createGeneralSettings,
        });

        return data;
    };

    public updateGeneralSettings = async (
        id: number,
        updateGeneralSettings: UpdateGeneralSettingsDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/settings/general", {
            id,
            data: updateGeneralSettings,
        });

        return data;
    };

    public deleteGeneralSettings = async (ids: number[]): Promise<void> => {
        const { data } = await this.instance.delete("/settings/general", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new GeneralSettingsService(new HttpClient(Config.API_URL), AuthManager);
