import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { EntityType } from "@interfaces/EntityType";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Tooltip,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { eTypesService } from "@services";
import React, { memo } from "react";
import { AppRestriction } from "@interfaces/Internal/AppRestriction";
import RestrictionsManager from "@modules/RestrictionsManager";
import RestrictionTypeSelect from "../../Controls/Selects/RestrictionTypeSelect";

interface Props {
    disabledRestrictions: string[];
    restItem: AppRestriction;
    onChangeType(value: string, restKey: string): void;
    onChangeValue(code: string, value: any, restKey: string): void;
    onDelete(restKey: string): void;
}

const RestrictionItem = ({
    disabledRestrictions,
    restItem,
    onChangeType,
    onChangeValue,
    onDelete,
}: Props) => {
    const exclude = [
        RestrictionsManager.RESTRICTIONS.ENTITY_TYPE.code,
        RestrictionsManager.RESTRICTIONS.NULLABLE.code,
    ];

    return (
        <Box py={1}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={5}>
                    <RestrictionTypeSelect
                        disabledOptions={disabledRestrictions}
                        fullWidth
                        variant="outlined"
                        value={restItem?.code || ""}
                        size="small"
                        onChange={(e) => onChangeType(e.target.value, restItem.key || "")}
                    />
                </Grid>
                <Grid item xs={6}>
                    {restItem.code === RestrictionsManager.RESTRICTIONS.ENTITY_TYPE.code && (
                        <AsyncAutocomplete<EntityType>
                            fetchFn={eTypesService.getEntityTypes}
                            value={restItem?.value}
                            onChange={(name, value) =>
                                onChangeValue(restItem.code, value?.id, restItem.key || "")
                            }
                            optionField="name"
                            textFieldProps={{
                                name: "et_id",
                                label: "Тип сущности",
                                variant: "outlined",
                                size: "small",
                            }}
                        />
                    )}

                    {restItem.code === RestrictionsManager.RESTRICTIONS.NULLABLE.code && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={restItem?.value}
                                    onChange={(e) => {
                                        onChangeValue(
                                            restItem.code,
                                            e.target.checked,
                                            restItem.key
                                        );
                                    }}
                                    name="nullable"
                                    color="primary"
                                />
                            }
                            label="Может быть пустым"
                        />
                    )}

                    {!exclude.includes(restItem.code) && (
                        <TextField
                            label="Значение"
                            fullWidth
                            value={restItem?.value ?? ""}
                            variant="outlined"
                            disabled={!restItem.code}
                            size="small"
                            onChange={(e) =>
                                onChangeValue(restItem.code, e.target.value, restItem.key || "")
                            }
                        />
                    )}
                </Grid>
                <Grid item>
                    <Tooltip title="Удалить ограничение" arrow>
                        <IconButton size="small" onClick={() => onDelete(restItem.key || "")}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
    );
};

export default memo(RestrictionItem);
