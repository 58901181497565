import { AppAttributeValue } from "../interfaces/Internal/AppAttribute";
import { AppRestriction } from "../interfaces/Internal/AppRestriction";
import RestrictionsManager from "./RestrictionsManager";

export const REQUIRED_TYPES = {
    NOT: {
        title: "Нет",
        description: "",
        value: "not",
    },
    PART: {
        title: "Частичная",
        description: "Необязательно для заполнения",
        value: "part",
    },
    FULL: {
        title: "Полная",
        description: "Обязательно для заполнения",
        value: "full",
    },
};

export type REQUIRED_TYPE = "not" | "full" | "part";

class ValidationManager {
    /** returns `true` if object is valid */
    static isFinalObjectValid = (validations: Record<string, boolean>): boolean => {
        return Object.values(validations).findIndex((val) => !val) === -1;
    };

    /** returns `true` if value is valid */
    static validateEntityAttributeValue = (value: any, restrictions: AppRestriction[]) => {
        let valid = true;
        let messages = {} as Record<string, string>;

        restrictions.forEach((rest) => {
            const restrictionItem = RestrictionsManager.getRestrictionItemByCode(rest.code);

            let localValid = true;
            if (!restrictionItem) return;

            localValid = restrictionItem.validate(value, rest.value);
            if (!localValid) {
                valid = false;
                messages[rest.code] = restrictionItem.getErrorValidationMessage(rest.value);
            }
        });

        return { valid, messages };
    };

    /** returns `true` if value is valid */
    static validateAppAttributeValueGroup = (values: AppAttributeValue[]) => {
        let valid = true;

        if (!values) return { valid: false };

        values?.forEach((value) => {
            value.restrictions?.forEach((rest) => {
                const restrictionItem = RestrictionsManager.getRestrictionItemByCode(rest.code);
                if (!restrictionItem) return;

                let localValid = true;

                localValid = restrictionItem.validate(value.value, rest.value);

                if (!localValid) {
                    valid = false;
                    return;
                }
            });
        });

        return { valid };
    };

    static isValidationRequired(
        requiredType: REQUIRED_TYPE,
        values: AppAttributeValue | AppAttributeValue[]
    ): boolean {
        if (requiredType === REQUIRED_TYPES.FULL.value) return true;

        if (Array.isArray(values)) {
            const hasValue = values.findIndex(
                (el) => el.value !== null && el.value !== "" && el.value !== undefined
            );

            if (hasValue !== -1) return true;
        } else {
            if (values.value !== null && values.value !== "" && values.value !== undefined)
                return true;
        }

        return false;
    }
}

export default ValidationManager;
