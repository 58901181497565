import { RowBlock } from "@interfaces/RowBlock";
import { CommonFormProps } from "@interfaces/System/FormProps";
import { Form, Formik } from "formik";
import React, { forwardRef } from "react";
import { RowBlockSchema } from "@schema/Settings";
import { TextField } from "@material-ui/core";
import { AsyncAutocomplete } from "@components/Controls/Autocomplete";
import { StaffMember } from "@interfaces/StaffMember";
import { staffService } from "@services/Staff";

const RowBlockForm = forwardRef(({ onSubmit, data }: CommonFormProps<RowBlock>, ref: any) => {
    const initialData = {
        table_name: data?.table_name || "",
        username: data?.username || "",
        e_id: data?.e_id || null,
    } as RowBlock;

    return (
        <Formik
            initialValues={initialData}
            innerRef={ref}
            validationSchema={RowBlockSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, setFieldValue, values }) => {
                return (
                    <Form>
                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.table_name}
                            required
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="table_name"
                            helperText={touched.table_name ? errors.table_name : ""}
                            error={touched.table_name && !!errors.table_name}
                            label="Название таблицы"
                        />

                        <AsyncAutocomplete<StaffMember>
                            fetchFn={staffService.getAllStaffMembers}
                            value={values.username}
                            onChange={(name, value) => setFieldValue(name, value?.login || null)}
                            optionField="login"
                            textFieldProps={{
                                fullWidth: true,
                                required: true,
                                size: "small",
                                margin: "normal",
                                variant: "outlined",
                                name: "username",
                                label: "Сотрудник",
                                helperText: touched.username ? errors.username : "",
                                error: touched.username && !!errors.username,
                            }}
                        />

                        <TextField
                            fullWidth
                            onChange={handleChange}
                            value={values.e_id || ""}
                            required
                            type="number"
                            variant="outlined"
                            size="small"
                            margin="normal"
                            name="e_id"
                            helperText={touched.e_id ? errors.e_id : ""}
                            error={touched.e_id && !!errors.e_id}
                            label="ID Сущности"
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});

export default RowBlockForm;
