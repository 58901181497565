import { CommonState, CommonEvents } from "@store/common-module";
import { useStoreon } from "storeon/react";

const useAppPaths = () => {
    const { routes, menu } = useStoreon<CommonState, CommonEvents>("routes", "menu");

    return { routes, menu };
};

export default useAppPaths;
