import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { PaginatedQueryResult, QueryParams, QueryResult } from ".";
import { CreateEntityTypeDto, UpdateEntityTypeDto } from "../dto/EntityTypeDto";
import { CreateEntityTypeItemDto } from "../dto/EntityTypeItemDto";
import { EntityType } from "../interfaces/EntityType";
import { EntityTypeItem } from "../interfaces/EntityTypeItem";
import BaseService from "./BaseService";

class EntityTypesService extends BaseService {
    public getEntityTypes = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<EntityType>> => {
        const { data } = await this.instance.get("/nsi_meta/etype", {
            params,
        });

        return this.toPaginatedUniversalOutput(data);
    };

    public getEntityType = async (eTypeId: number): Promise<EntityType | null> => {
        const { data } = await this.instance.get("/nsi_meta/etype", {
            params: { ids_list: eTypeId },
        });

        return this.getFirstItemOrNull<EntityType>(
            this.toPaginatedUniversalOutput<EntityType>(data).result.items
        );
    };

    public getEntityTypesItems = async (
        params: QueryParams = {}
    ): Promise<PaginatedQueryResult<EntityTypeItem>> => {
        const { data } = await this.instance.get("/nsi_meta/etype_item", {
            params,
        });

        return data;
    };

    public createEntityType = async (
        eType: CreateEntityTypeDto
    ): Promise<QueryResult<EntityType>> => {
        const { data } = await this.instance.post("/nsi_meta/etype", { data: eType });

        return data;
    };

    public createEntityTypeItems = async (
        eTypeItems: CreateEntityTypeItemDto[]
    ): Promise<QueryResult<EntityTypeItem>> => {
        const { data } = await this.instance.post("/nsi_meta/etype_item", { data: eTypeItems });

        return data;
    };

    public updateEntityType = async (
        id: number,
        eType: UpdateEntityTypeDto
    ): Promise<QueryResult<EntityType>> => {
        const { data } = await this.instance.patch("/nsi_meta/etype", { id, data: eType });

        return data;
    };

    public deleteEntityType = async (eTypeIds: number[]): Promise<QueryResult<EntityType>> => {
        const { data } = await this.instance.delete("/nsi_meta/etype", {
            data: {
                ids_list: eTypeIds,
            },
        });

        return data;
    };

    public deleteEntityTypeItems = async (
        eTypeItemsIds: number[]
    ): Promise<QueryResult<EntityType>> => {
        const { data } = await this.instance.delete("/nsi_meta/etype_item", {
            data: {
                ids_list: eTypeItemsIds,
            },
        });

        return data;
    };

    public setEntityTypeBlock = async (eTypeId: number) => {
        const { data } = await this.instance.post("/nsi_meta/etype/block", {
            id: eTypeId,
        });

        return data;
    };

    public unsetEntityTypeBlock = async (eTypeId: number) => {
        const { data } = await this.instance.delete("/nsi_meta/etype/block", {
            data: {
                id: eTypeId,
            },
        });

        return data;
    };
}

export default new EntityTypesService(new HttpClient(Config.API_URL), AuthManager);
