import { PaginatedQueryResult, QueryParams, QueryResultWithoutData, QueryResult } from "@services";
import BaseService from "./BaseService";
import AuthManager from "@modules/AuthManager";
import { Config } from "@modules/Constants";
import HttpClient from "@modules/HttpClient";
import { CreateEShopDto, UpdateEShopDto } from "@dto/EShopDto";
import { EShop } from "@interfaces/EShop";

class EShopService extends BaseService {
    public getEShops = async (params: QueryParams = {}): Promise<PaginatedQueryResult<EShop>> => {
        const { data } = await this.instance.get("/eshop", { params });

        return this.toPaginatedUniversalOutput(data);
    };

    public getOneEShop = async (userId: number): Promise<EShop> => {
        const { data } = await this.instance.get("/eshop", {
            params: { id: userId },
        });

        return data.result.items[0];
    };

    public createEShop = async (createEShopDto: CreateEShopDto): Promise<QueryResult<EShop>> => {
        const { data } = await this.instance.post("/eshop", { data: createEShopDto });

        return data;
    };

    public updateEShop = async (
        id: number,
        updateEShopDto: UpdateEShopDto
    ): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.patch("/eshop", { id, data: updateEShopDto });

        return data;
    };

    public deleteEShops = async (ids: number[]): Promise<QueryResultWithoutData> => {
        const { data } = await this.instance.delete("/eshop", {
            data: { ids_list: ids },
        });

        return data;
    };
}

export default new EShopService(new HttpClient(Config.API_URL), AuthManager);
