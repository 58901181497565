import * as Yup from "yup";
import { Regex } from "@modules/Constants";

export const CreateStaffMemberSchema = Yup.object().shape({
    login: Yup.string()
        .required("Пожалуйста, укажите логин")
        .matches(Regex.LOGIN, "Логин не должен начинаться с цифр и спец. символов")
        .min(6, "Логин должен содержать минимум 6 символов"),
    email: Yup.string()
        .email("Пожалуйста, укажите корректный адрес эл. почты")
        .required("Пожалуйста, укажите адрес эл. почты"),
    password: Yup.string()
        .required("Пожалуйста, укажите пароль")
        .matches(Regex.PASSWORD, "Пароль не должен начинаться с цифр и спец. символов")
        .min(6, "Пароль должен содержать минимум 6 символов"),
    repeat_password: Yup.string()
        .required("Пожалуйста, укажите пароль")
        .matches(Regex.PASSWORD, "Пароль не должен начинаться с цифр и спец. символов")
        .oneOf([Yup.ref("password"), null], "Пароли не совпадают"),
    first_name: Yup.string().trim().required("Пожалуйста, введите имя"),
    last_name: Yup.string().trim().required("Пожалуйста, введите фамилию"),
    middle_name: Yup.string().trim().required("Пожалуйста, введите отчество"),
    birthday: Yup.string().nullable().required("Пожалуйста, укажите дату рождения"),
    phone: Yup.string().matches(Regex.PHONE, "Номер указан неверно"), //.required("Пожалуйста, укажите номер телефона"),
    occupation: Yup.string().trim(),
    department: Yup.string().trim(),
    work_phone: Yup.string().matches(Regex.PHONE, "Номер указан неверно"),
    additional_phone: Yup.string().matches(Regex.PHONE, "Номер указан неверно"),
    join_date: Yup.string().nullable(),
    dismiss_date: Yup.string().nullable(),
    sub_role: Yup.string().required("Пожалуйста, выберите роль"),
});
