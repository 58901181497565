import { useSnackbar } from "notistack";
import React from "react";
import { ServiceHelper } from "@modules";
import { permissionSettingsService } from "@services/Settings";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import { useCurrentPageInfo } from "../../hooks";
import { PermissionSettings } from "@interfaces/PermissionSettings";

const PermissionSettingsTable = () => {
    const { permission } = useCurrentPageInfo();

    const { enqueueSnackbar } = useSnackbar();

    const handleDeleteClick = async (rowData: PermissionSettings | PermissionSettings[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await permissionSettingsService.deletePermissionSettings(ids);
            enqueueSnackbar("Право успешно удалено", {
                variant: "success",
            });
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при удалении прав", {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<PermissionSettings>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Пользователь",
            field: "username",
            width: "auto",
        },
        {
            title: "Таблица",
            field: "table_name",
            width: "auto",
        },
        {
            title: "Уровень доступа",
            field: "permission",
            width: "auto",
        },
        {
            title: "Сущность",
            field: "e_id",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={permissionSettingsService.getPermissionsSettings}
            columns={columns}
            displayField="username"
            permission={permission}
            sorting
            selection
            search
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
        />
    );
};

export default PermissionSettingsTable;
