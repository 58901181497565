import { Button, DialogActions, DialogContent, Divider, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { ChangeStaffMemberPasswordDto } from "@dto/ChangePasswordDto";
import { StaffMember } from "@interfaces/StaffMember";
import { staffService } from "@services/Staff";
import { ModalContentLoading } from "../../Common/Modal";
import ChangeStaffMemberPasswordForm from "../../Forms/Staff/ChangeStaffMemberPasswordForm";
import { DialogContextProps } from "@interfaces/System/DialogContextProps";
import { useAsync, useLoading } from "@hooks";

const ChangeStaffMemberPasswordDialogContext = ({ onClose, modalProps }: DialogContextProps) => {
    const { itemId } = { ...modalProps };

    const formRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus: submitLoadingStatus, setLoadingStatus: setSubmitLoadingStatus } =
        useLoading("inactivity");

    const { data: user, loadingStatus } = useAsync<StaffMember>(staffService.getOneStaffMember, {
        functionArgs: [itemId],
        canLoad: !!itemId,
    });

    const invokeSubmit = () => {
        formRef.current.handleSubmit();
    };

    const handleSubmit = async (values) => {
        if (!user) return;

        setSubmitLoadingStatus("loading");
        try {
            const dto = staffService.plainToClass(ChangeStaffMemberPasswordDto, {
                id: user.id,
                password: values.password,
                password2: values.repeat_password,
            });
            await staffService.changeStaffMemberPassword(dto);
            enqueueSnackbar("Пароль успешно изменен", {
                variant: "success",
            });
            onClose();
        } catch (err) {
            const message = err.response?.data?.error_tooltip;
            enqueueSnackbar(message || "Произошла ошибка при изменении пароля", {
                variant: "error",
            });
        }
        setSubmitLoadingStatus("loaded");
    };

    return (
        <>
            <DialogContent>
                {loadingStatus === "loading" && <ModalContentLoading />}

                {loadingStatus === "loaded" && user && (
                    <>
                        <Typography gutterBottom>
                            Изменить пароль для пользователя «<b>{user.login}</b>»
                        </Typography>
                        <Divider />
                        <ChangeStaffMemberPasswordForm onSubmit={handleSubmit} ref={formRef} />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={invokeSubmit}
                    disabled={submitLoadingStatus === "loading"}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </>
    );
};

export default ChangeStaffMemberPasswordDialogContext;
