import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateStaffMenuPermissionDto {
    @Expose()
    readonly permission: number;

    @Expose()
    readonly rolname: string;

    @Expose()
    readonly menu_item: number;
}
