import { useMousePosition } from "@hooks";
import { UseAsyncResult } from "@hooks/useAsync";
import { Directory } from "@interfaces/Directory";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import { Skeleton, TreeView } from "@material-ui/lab";
import { ContentEvents, ContentState, defaultDirectory } from "@store/content-module";
import React, { useCallback, useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import DirectoriesHeader from "./DirectoriesHeader";
import TreeItem from "./DirectoriesTreeItem";
import DirectoryContextMenu from "./DirectoryContextMenu";

const useStyles = makeStyles(() => ({
    tree: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflowY: "scroll",
    },
}));

type DirectoriesTreeProps = Pick<
    UseAsyncResult<Directory>,
    "data" | "loadingStatus" | "updateData"
>;

const DirectoriesTree = ({ data, loadingStatus, updateData }: DirectoriesTreeProps) => {
    const styles = useStyles();

    const { mouseX, mouseY, setMousePosition } = useMousePosition();
    const { dispatch } = useStoreon<ContentState, ContentEvents>();
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [selectedItemName, setSelectedItemName] = useState<string | null>(null); //Vma 07-05-24 Добавляем состояние для хранения имени выбранной директории

    const resetSeletedDirectory = useCallback(() => {
        dispatch("content/file_storage/set/selected_directory", defaultDirectory);
    }, [dispatch]);

    useEffect(() => {
        return () => resetSeletedDirectory();
    }, [resetSeletedDirectory]);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLLIElement>) => {
            event.stopPropagation();
            event.preventDefault();
            const itemId = event.currentTarget.getAttribute("data-itemid") || 1;
            const itemName = event.currentTarget.getAttribute("data-itemname"); // Vma 07-05-24 Получаем имя выбранной директории


            setMousePosition({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            });
            setSelectedItemId(+itemId);
            setSelectedItemName(itemName); //Vma 07-05-24 Устанавливаем имя выбранной директории
        },
        [setMousePosition]
    );

    const onCloseMenu = useCallback(() => {
        setMousePosition({
            mouseX: null,
            mouseY: null,
        });
    }, [setMousePosition]);

    return (
        <>
            <DirectoriesHeader onUpdate={updateData} />
            {loadingStatus === "loading" && (
                <>
                    {[...new Array(5)].map((e) => (
                        <Skeleton key={e} height={30} />
                    ))}
                </>
            )}

            {loadingStatus === "loaded" && (
                <TreeView
                    className={styles.tree}
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    defaultExpanded={(data && [data.id.toString()]) || []}
                >
                    {loadingStatus === "loaded" && data && (
                        <TreeItem key={data.id} node={data} onContextMenu={handleClick} />
                    )}
                </TreeView>
            )}

            <DirectoryContextMenu
                itemId={selectedItemId}
                directoryName={selectedItemName} //Vma 07-05-24 Передаем имя директории в DirectoryContextMenu
                mouseX={mouseX}
                mouseY={mouseY}
                onClose={onCloseMenu}
                onUpdate={updateData}
                onResetSelectedDirectory={resetSeletedDirectory}
            />
        </>
    );
};

export default DirectoriesTree;
