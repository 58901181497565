import { AppEntityTypeItem } from "@interfaces/Internal/AppEntityTypeItem";
import { Exclude, Transform, Expose } from "class-transformer";

@Exclude()
export class CreateEntityTypeDto {
    @Expose()
    readonly name: string;

    @Expose()
    readonly notes?: string;

    @Expose()
    @Transform(({ value }) =>
        value.map((item) => ({
            atype_id: item.atype_id,
            sorting: item.sorting,
            required: item.required,
        }))
    )
    readonly items?: AppEntityTypeItem[];
}
