import * as Yup from "yup";

export const StoreSchema = Yup.object().shape({
    store_id: Yup.number().nullable().required("Пожалуйста, укажите идентификатор магазина"),
    is_shop: Yup.boolean(),
    is_countable: Yup.boolean(),
    store_name: Yup.string().required("Пожалуйста, укажите название"),
    shown_name: Yup.string(),
    comment: Yup.string(),
    sorting: Yup.number().nullable(),
});
