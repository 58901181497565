import React from "react";
import { useCurrentPageInfo, useModal } from "@hooks";
import { ServiceHelper } from "@modules";
import { eShopService } from "@services";
import { DataTable } from "../Common";
import { DataTableColumn } from "../Common/DataTable/DataTable";
import StatusSymbol from "../Views/StatusSymbol/StatusSymbol";
import { useSnackbar } from "notistack";
import { EShop } from "@interfaces/EShop";

const EShopsTable = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { permission } = useCurrentPageInfo();
    const { handleOpenModal } = useModal();

    const handleEditClick = (rowData) => {
        handleOpenModal("EDIT_ESHOP", { itemId: rowData.id });
    };

    const handleDeleteClick = async (rowData: EShop | EShop[]) => {
        try {
            const ids = ServiceHelper.getIdsListFromRowDataObject(rowData);
            await eShopService.deleteEShops(ids);
            enqueueSnackbar(
                rowData instanceof Array ? "Магазины успешно удалены" : "Магазин успешно удален",
                {
                    variant: "success",
                }
            );
        } catch (err) {
            const message = err.response.data.error_tooltip;
            enqueueSnackbar(
                message || rowData instanceof Array
                    ? "Произошла ошибка при удалении магазинов"
                    : "Произошла ошибка при удалении магазина",
                {
                    variant: "error",
                }
            );
        }
    };

    const columns: DataTableColumn<EShop>[] = [
        {
            title: "#",
            field: "id",
            defaultSort: "asc",
            width: 50,
        },
        {
            title: "Название",
            field: "title",
            width: "auto",
        },
        {
            title: "Адрес",
            field: "url",
            width: "auto",
        },
        {
            title: "Префикс корзины",
            field: "cart_number_prefix",
            width: "auto",
        },
        {
            title: "Последняя цифра",
            field: "last_number",
            width: "auto",
        },
        {
            title: "Статус",
            field: "is_active",
            render: (rowData) => {
                return <StatusSymbol isActive={rowData.is_active} />;
            },
            width: "auto",
        },
        {
            title: "Дата создания",
            field: "created_at",
            type: "date",
            width: "auto",
        },
    ];

    return (
        <DataTable
            fetchFn={eShopService.getEShops}
            columns={columns}
            displayField="title"
            permission={permission}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onMassDeleteClick={handleDeleteClick}
            selection
            sorting
            search
        />
    );
};

export default EShopsTable;
