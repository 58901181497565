import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import React from "react";

interface Props<T> {
    data: T[];
    displayField: string;
}

const ConfirmationContent = <T extends {}>({ data, displayField }: Props<T>) => {
    return (
        <Box>
            <Typography variant="subtitle1">Вы действительно хотите удалить эти записи?</Typography>
            <List dense>
                {data.map((el, index) => {
                    return (
                        <ListItem key={index} disableGutters dense>
                            <ListItemIcon>{index + 1}</ListItemIcon>
                            <ListItemText primary={el[displayField]} />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default ConfirmationContent;
