import { AppAttribute } from "@interfaces/Internal/AppAttribute";
import { Button, ButtonProps } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";
import { useStoreon } from "storeon/react";
import { EntityEvents, EntityState } from "@store/entity-module";

interface Props {
    attribute: AppAttribute;
}

const AddNewEntityAttributeValueButton = ({ attribute, ...props }: Props & ButtonProps) => {
    const { dispatch } = useStoreon<EntityState, EntityEvents>();

    const handleClick = () => {
        dispatch("entity/attr/add/value", {
            at_id: attribute.atype_id,
        });
    };

    return (
        <Button {...props} onClick={handleClick} startIcon={<Add />}>
            Новое значение
        </Button>
    );
};

export default AddNewEntityAttributeValueButton;
