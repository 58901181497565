import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { Common } from "@modules/Constants";
import { truncateText } from "@modules/Utils";
import clsx from "clsx";
import React, { memo } from "react";
import { TitleTooltip } from "../Title";

const useStyles = makeStyles(() => ({
    paddings: {
        padding: "1rem 0",
    },
}));

export interface PageHeadProps {
    title?: string;
    noTitle?: boolean;
    truncateTitle?: boolean;
    children?: any;
    disablePaddings?: boolean;
    isLoading?: boolean;
}

const PageHead = ({
    title,
    noTitle,
    children,
    disablePaddings,
    isLoading,
    truncateTitle = true,
}: PageHeadProps) => {
    const classes = useStyles();

    return (
        <Grid
            container
            justifyContent={noTitle ? "flex-end" : "space-between"}
            alignItems="center"
            className={clsx({ [classes.paddings]: !disablePaddings })}
        >
            {isLoading ? (
                <Skeleton height={33} width={300} />
            ) : (
                !noTitle && title && <Title title={title} truncate={truncateTitle} />
            )}
            {isLoading && children ? <Skeleton height={33} width={400} /> : children}
        </Grid>
    );
};

const Title = memo(({ title, truncate }: { title: string; truncate: boolean }) => {
    const maxLen = Common.TITLE_TRUNCATE_LEN;
    const truncatedTitle = truncateText(title, maxLen);

    if (!truncate) return <Typography variant="h5">{title}</Typography>;

    return (
        <TitleTooltip title={title} maxLen={maxLen}>
            <Typography variant="h5">{truncatedTitle}</Typography>
        </TitleTooltip>
    );
});

export default memo(PageHead);
