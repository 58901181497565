import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { PropsWithChildren, useEffect } from "react";
import { Modal } from "../components/Common/Modal";
import Appbar from "../components/Views/Appbar/Appbar";
import Sidebar from "../components/Views/Sidebar/Sidebar";
import { AuthManager, Constants } from "@modules";
import { PathsManager } from "@modules";
import withAuth from "../hoc/withAuth";
import { staffProfileService } from "@services/Staff";
import { AuthEvents, AuthState } from "@store/auth-module";
import { useStoreon } from "storeon/react";
import { CommonEvents, CommonState } from "@store/common-module";
import BackToTopButton from "@components/Controls/Buttons/BackToTopButton";
import { Lightbox } from "@components/Views/Lightbox";
import { PreloaderScreen } from "@components/Views/Preloader";
import { useLoading } from "@hooks";
import { store } from "@store";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        height: "100%",
        marginTop: "4rem",
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: Constants.Common.SIDEBAR_WIDTH,
    },
}));

const DefaultLayout: React.FC = ({ children }: PropsWithChildren<{}>) => {
    const { enqueueSnackbar } = useSnackbar();
    const { loadingStatus, setLoadingStatus, error, setError } = useLoading();
    const classes = useStyles();
    const { sidebarOpen } = useStoreon<CommonState, CommonEvents>("sidebarOpen");

    const { dispatch } = useStoreon<AuthState, AuthEvents>();
    useEffect(() => {
        async function getUser() {
            try {
                const { result } = await staffProfileService.getCurrentStaffMember();
                dispatch("auth/set/user", result);
            } catch (err) {
                setError("Произошла внутреняя ошибка при попытке входа в учетную запись");
                setLoadingStatus("error");
            }
        }

        setLoadingStatus("loading");
        getUser().then(() => setLoadingStatus("loaded"));
    }, [dispatch, setLoadingStatus, setError]);

    useEffect(() => {
        if (loadingStatus === "error") {
            enqueueSnackbar(error, {
                variant: "error",
                autoHideDuration: 6000,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
            });
            AuthManager.logout(store);
        }
    }, [loadingStatus, enqueueSnackbar, error]);

    if (loadingStatus === "loading") return <PreloaderScreen />;

    return (
        <div className={classes.root}>
            <Appbar />
            <Sidebar />
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: sidebarOpen,
                })}
            >
                {children}
            </main>
            <Lightbox />
            <Modal />
            <BackToTopButton />
        </div>
    );
};

export default withAuth(DefaultLayout, PathsManager.PATHS.LOGIN.url);
