import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateQuantityReserveDto {
    @Expose()
    readonly sku: string;

    @Expose()
    readonly count: number;
}
